import React, { Component, Fragment } from 'react'
import musicListStyle from '../../../assets/jss/views/work/musicListStyle'

//api
import WorkApi from '../../../services/work-api'

//@material-ui
import withStyles from '@material-ui/core/styles/withStyles'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Search from '@material-ui/icons/Search'
import Grid from '@material-ui/core/Grid'

//custom components
import Button from '../../../components/custom-buttons/button'
import GridItem from '../../../components/grid/grid-item'
import Snackbar from '../../../components/snackbar/snackbar'
import CircularProgress from '@material-ui/core/CircularProgress'

//specific components
import MusicRow from './music-row'

class MusicList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      submitting: false,
      statusResponse: false,
      snackMessage: 'Nenhuma obra encontrada',
      snackColor: 'primary',
      visibleCreateButton: false,
      valueSearched: '',
      searchIsValid: true,
    }

    this.buttonName = props.buttonName || ''
    this.workApi = new WorkApi()
  }

  componentDidCatch = (error, errorInfo) => {
    this.props.context.changeState(
      {
        error: error,
        errorInfo: errorInfo,
      },
      () => {
        this.props.changeMode('showError')
      }
    )
  }

  getMusics = filter => {
    this.props.context.changeState(
      {
        musics: [],
      },
      () => {
        this.setState(
          {
            submitting: true,
            statusResponse: false,
          },
          () => {
            this.workApi
              .getWorks(filter)
              .then(data => {
                if (data !== undefined) {
                  this.props.context.changeState(
                    {
                      musics: data,
                    },
                    () => {
                      this.setState({
                        submitting: false,
                        visibleCreateButton: true,
                      })
                    }
                  )
                }
              })
              .catch(error => {
                this.handleError(error)
                this.setState({
                  visibleCreateButton: true,
                })
              })
          }
        )
      }
    )
  }

  handleError = error => {
    console.error('error: ', error)

    if (error.response.status === 404) {
      this.setState({
        statusResponse: 'not-found',
        submitting: false,
        snackMessage: 'Nenhuma obra encontrada',
        snackColor: 'primary',
      })
    } else {
      this.setState({
        statusResponse: 'error',
        submitting: false,
        snackMessage: 'Erro ao pesquisar obra',
        snackColor: 'danger',
      })
    }
  }

  handleSnackClose = () => {
    const { statusResponse } = this.state

    if (statusResponse === 'error' || statusResponse === 'not-found') {
      this.setState({
        statusResponse: false,
      })
    }
  }

  handleSearchMusicKeyDown = event => {
    if (event.key === 'Enter') {
      this.handleSearchMusicClick()
    }
  }

  handleSearchMusicClick = () => {
    const { filter } = this.props.context
    if (filter) {
      this.setState(
        {
          valueSearched: filter.toLowerCase(),
          searchIsValid: true,
        },
        () => {
          this.getMusics(filter.toLowerCase())
        }
      )
    }
  }

  handleCreateMusicClick = event => {
    this.props.changeMode('createMusic')
    event.preventDefault()
  }

  handleChange = event => {
    this.props.context.changeState({
      filter: event.target.value,
    })

    this.whenChangeTermHideResults(event)
  }

  whenChangeTermHideResults(event) {
    if (this.state.valueSearched) {
      this.setState({
        searchIsValid: event.target.value.toLowerCase().trim() === this.state.valueSearched.toLowerCase().trim(),
      })
    }
  }

  render() {
    const { classes } = this.props

    const rows = this.props.context.musics.map(
      function (music, i) {
        return (
          <MusicRow
            key={i}
            music={music}
            context={this.props.context}
            changeMode={this.props.changeMode}
            buttonName={this.buttonName}
            actionButton={this.props.actionButton}
            isButtonVisible={this.props.hasButton(music)}
          />
        )
      }.bind(this)
    )

    return (
      <Fragment>
        <Grid container className={classes.root}>
          <GridItem container className={classes.ingestBody}>
            {!!this.state.visibleCreateButton && this.state.searchIsValid && (
              <Grid item container>
                <Grid item xs sm md></Grid>
                <Grid item xs={9} sm={9} md={9}>
                  <Button
                    id="btn_music_list_criar_musica"
                    size="sm"
                    style={{ float: 'right', marginTop: '15px' }}
                    variant="outlined"
                    color="primary"
                    onClick={event => this.handleCreateMusicClick(event)}
                    automacao="btnCriarObra"
                  >
                    CADASTRAR OBRA
                  </Button>
                </Grid>
                <Grid item xs sm md></Grid>
              </Grid>
            )}

            <Grid item container>
              <Grid item xs sm md></Grid>
              <Grid item xs={9} sm={9} md={9}>
                <Input
                  value={this.props.context.filter}
                  onChange={event => this.handleChange(event)}
                  onKeyDown={event => this.handleSearchMusicKeyDown(event)}
                  style={{ margin: '30px 0 0 0' }}
                  fullWidth
                  id="filter"
                  name="filter"
                  automacao="inputPesquisarObra"
                  type="text"
                  placeholder="Digite o título da obra para pesquisar"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        automacao="btnPesquisarObra"
                        aria-label="Pesquisar obra"
                        onClick={() => this.handleSearchMusicClick()}
                      >
                        {this.state.submitting ? <CircularProgress size={20} /> : <Search />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
              <Grid item xs sm md></Grid>
            </Grid>

            <Grid item container>
              <Grid item xs sm md></Grid>
              <Grid
                item
                style={{
                  backgroundColor: '#fff',
                  width: '90%',
                  marginTop: '40px',
                  padding: '0 5px 10px !important',
                }}
                xs={9}
                sm={9}
                md={9}
              >
                {!!rows.length && this.state.searchIsValid && (
                  <Table className={classes.tbMusicList}>
                    <TableHead>
                      <TableRow>
                        <TableCell classes={{ root: classes.TableCell }}>Obra</TableCell>
                        <TableCell classes={{ root: classes.TableCell }}>Categoria</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>{rows}</TableBody>
                  </Table>
                )}
              </Grid>
              <Grid item xs sm md></Grid>
            </Grid>
          </GridItem>
        </Grid>
        <div id="snackbar-wrapper">
          <Snackbar
            place="bc"
            message={this.state.snackMessage}
            open={this.state.statusResponse === 'not-found' || this.state.statusResponse === 'error'}
            close
            color={this.state.snackColor}
            closeNotification={() => this.handleSnackClose()}
          />
        </div>
      </Fragment>
    )
  }
}

export default withStyles(musicListStyle)(MusicList)
