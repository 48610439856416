import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import MenuIcon from '@material-ui/icons/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Popper from '@material-ui/core/Popper'
import Paper from '@material-ui/core/Paper'
import Fade from '@material-ui/core/Fade'

import IconButton from '@material-ui/core/IconButton'
import menuStyle from 'assets/jss/components/menuStyle.jsx'
import Link from './link'

const MobileRoutes = ({ classes, routes }) => {
  const [anchorEl, setAnchorEl] = useState(false)

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const open = Boolean(anchorEl)

  if (!routes.length) return <div />

  return (
    <Fragment>
      <IconButton color="inherit" aria-label="open drawer" onClick={handleClick}>
        <MenuIcon />
      </IconButton>

      <Popper open={open} anchorEl={anchorEl} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper className={classes.dropdown}>
              <MenuList id="MenuList" role="menu">
                {routes.map((route, key) => (
                  <MenuItem key={key} onClick={handleClick} className={classes.dropdownItemUser}>
                    <Link dark path={route.path} sidebarName={route.sidebarName} />
                  </MenuItem>
                ))}
              </MenuList>
            </Paper>
          </Fade>
        )}
      </Popper>
    </Fragment>
  )
}

MobileRoutes.propTypes = {
  classes: PropTypes.object.isRequired,
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      redirect: PropTypes.any,
      sidebarName: PropTypes.string,
      pathsActiveMenu: PropTypes.array,
    })
  ).isRequired,
}

export default withStyles(menuStyle)(MobileRoutes)
