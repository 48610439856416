import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from './styles'

const Link = ({ path, sidebarName, dark, isActive }) => (
  <NavLink
    to={path}
    $dark={dark}
    $active={isActive}
    automacao={
      sidebarName === 'Ingest' ? 'btnIngest' : sidebarName === 'Pendências' ? 'btnPendencias' : 'btnPendenciasAdm'
    }
  >
    {sidebarName}
  </NavLink>
)

Link.propTypes = {
  dark: PropTypes.bool,
  path: PropTypes.string,
  isActive: PropTypes.bool,
  sidebarName: PropTypes.string,
}

export default Link
