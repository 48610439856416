import React, { Component, Fragment } from 'react'
import phonogramTypeStyle from '../../../assets/jss/views/ingest/phonogramTypeStyle'

import withStyles from '@material-ui/core/styles/withStyles'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'

import GridItem from '../../../components/grid/grid-item.jsx'
import Button from '../../../components/custom-buttons/button'
import WorkSelectedInfo from '../work-selected-info'

import Steps from '../../../components/steps'

class PhonogramType extends Component {
  constructor(props) {
    super(props)
    this.state = {
      phonogramType: props.context.phonogramType,
    }

    this.steps = ['Tipo', 'Adicionar Arquivo(s)', 'Ficha Técnica', 'Associar um produto', 'Confirmar']

    if (this.isMusicComercial()) {
      this.steps = this.removeStep(['Associar um produto'])
    }

    this.definePhonogramType(props.context.phonogramType)
  }

  isMusicOriginal() {
    return this.props.context.musicSelected.category.toUpperCase() === 'MUSICA ORIGINAL'
  }

  isMusicComercial() {
    return this.props.context.musicSelected.category.toUpperCase() === 'MUSICA COMERCIAL'
  }

  componentWillMount() {
    this.resetInfoMusic()
  }

  definePhonogramType(phonogramType) {
    const { changeState } = this.props.context

    let steps = this.steps

    if (phonogramType !== 'Original') {
      steps = this.removeStep(['Ficha Técnica'])
    }

    changeState({
      steps,
      phonogramType,
    })
  }

  onChange(event, value) {
    this.definePhonogramType(value)
    this.setState({
      phonogramType: value,
    })
  }

  backRouter() {
    this.props.changeMode('musicList')
  }

  resetInfoMusic() {
    this.props.context.changeState({
      submixDraft: null,
      musicians: [],
      submixIdx: null,
      recorder: null,
      album: null,
      submixes: [],
    })
  }

  nextRouter() {
    this.props.changeMode('addPhonogram')
  }

  removeStep(arraySteps) {
    return this.steps.filter(step => !step.includes(arraySteps))
  }

  render() {
    const { classes } = this.props
    const { phonogramType } = this.state

    return (
      <Fragment>
        <Steps steps={this.props.context.steps} activeStep={0} />

        <WorkSelectedInfo hidePhonogramType={true} context={this.props.context} />

        <Grid>
          <GridItem xs={12} sm={12} md={12} className={classes.selectorWrapper}>
            <RadioGroup
              id="RadioGroup"
              style={{
                flexDirection: 'row',
              }}
              value={phonogramType}
              onChange={(event, value) => this.onChange(event, value)}
            >
              {!this.isMusicOriginal() ? (
                <FormControlLabel
                  classes={{
                    root: classes.hasProductRadio,
                    label: classes.hasProductLabel,
                  }}
                  value="Comercial"
                  automacao="radioOptionComercial"
                  control={<Radio color="default" />}
                  label="Fonograma Comercial"
                />
              ) : null}

              <FormControlLabel
                classes={{
                  root: classes.hasProductRadio,
                  label: classes.hasProductLabel,
                }}
                value="Original"
                automacao="radioOptionOriginal"
                control={<Radio color="default" />}
                label="Fonograma Original"
              />

              <FormControlLabel
                classes={{
                  root: classes.hasProductRadio,
                  label: classes.hasProductLabel,
                }}
                value="Performance"
                automacao="radioOptionPerformance"
                control={<Radio color="default" />}
                label="Performance"
              />
            </RadioGroup>
          </GridItem>
        </Grid>

        <GridItem container className={classes.ingestFooter}>
          <GridItem className={classes.itemMiddleLeft}>
            <Button
              automacao="btnVoltar"
              id="backButton"
              type="button"
              size="sm"
              value="Voltar"
              style={{ float: 'left' }}
              onClick={() => this.backRouter()}
              color="transparent"
            >
              Voltar
            </Button>
          </GridItem>
          <GridItem className={classes.itemMiddleRight}>
            <Button
              automacao="btnAvancar"
              id="nextButton"
              type="submit"
              size="sm"
              value="Submit"
              color="primary"
              variant="contained"
              onClick={() => this.nextRouter()}
            >
              Avançar
            </Button>
          </GridItem>
        </GridItem>
      </Fragment>
    )
  }
}

export default withStyles(phonogramTypeStyle)(PhonogramType)
