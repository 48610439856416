function getPathName(location) {
  const paramsRoute = location.pathname.split('/').filter(Boolean)
  return paramsRoute && paramsRoute.length > 0 ? `/${paramsRoute[0]}` : ''
}

function getRouteActive(routes, location) {
  const pathname = getPathName(location)
  return routes.filter(item => item.path === pathname)[0]
}

function makeBrand(routes, location) {
  const pathname = getPathName(location)
  return routes.find(route => route.pathsActiveMenu.some(path => path === pathname))?.navbarName || ''
}

export { getRouteActive, makeBrand }
