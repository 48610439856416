import { lazy } from 'react'

const Ingest = lazy(() => import('views/ingest/ingest'))
const Request = lazy(() => import('views/request/request'))
const Pending = lazy(() => import('views/pending/pending'))
const PendingAdm = lazy(() => import('views/pending-adm/index'))
const BatchIngest = lazy(() => import('views/batch-ingest/batch-ingest'))
const RegisterIsrc = lazy(() => import('views/register-isrc/register-isrc'))

const mainRoutes = [
  {
    path: '/request',
    sidebarName: 'Solicitação de Música',
    navbarName: 'Solicitação de Música',
    icon: null,
    component: Request,
    allowedPermissions: ['NotIngestedRequest:Edit'],
    pathsActiveMenu: ['/request'],
  },
  {
    path: '/ingest',
    sidebarName: 'Ingest',
    navbarName: 'Ingest',
    icon: null,
    component: Ingest,
    allowedPermissions: ['Ingest:Edit'],
    pathsActiveMenu: ['/ingest', '/ingestfromrequest'],
  },
  {
    path: '/batchingest',
    sidebarName: 'Ingest em Lote',
    navbarName: 'Ingest em Lote',
    icon: null,
    // eslint-disable-next-line max-len
    help: 'O Ingest em Lote é a possibilidade de carregar mais de 1 fonograma ao mesmo tempo no GMusic. Para isso, baixe a planilha de Ingest em Lote (disponível em Perfil > Planilha de Ingest), preencha com as informações solicitadas e faça o upload no campo abaixo.',
    component: BatchIngest,
    allowedPermissions: ['Ingest:BatchIngest'],
    pathsActiveMenu: ['/batchingest'],
  },
  {
    path: '/pending',
    sidebarName: 'Pendências',
    navbarName: 'Pendências',
    icon: null,
    component: Pending,
    allowedPermissions: ['Ingest:Pending'],
    pathsActiveMenu: ['/pending'],
  },
  {
    path: '/pendingadm',
    sidebarName: 'Pendências Adm',
    navbarName: 'Pendências Adm',
    icon: null,
    component: PendingAdm,
    allowedPermissions: ['Ingest:PendingAdm'],
    pathsActiveMenu: ['/pendingadm'],
  },
  {
    path: '/register-isrc',
    sidebarName: 'Cadastrar ISRC',
    navbarName: 'Cadastrar ISRC',
    icon: null,
    help: 'Para cadastrar os ISRCs, utilize a planilha do lote enviada pelo Produtor Musical.',
    component: RegisterIsrc,
    allowedPermissions: ['Ingest:RegisterISRC'],
    pathsActiveMenu: ['/register-isrc'],
  },
]

export default mainRoutes
