import qs from 'qs'

const postAuth = code => {
  const { client_id, token_base_url, authorization_key } = window.__.auth

  const data = qs.stringify({
    code: code,
    client_id: client_id,
    grant_type: 'authorization_code',
    redirect_uri: `${window.location.origin}/auth`,
  })

  const endpoint = `https://${token_base_url}/oauth2/token`

  return fetch(endpoint, {
    body: data,
    method: 'POST',
    headers: {
      Authorization: `Basic ${authorization_key}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      'cache-control': 'no-cache',
    },
  }).then(data => data.json())
}

const refreshAuth = refreshToken => {
  const { client_id, token_base_url, authorization_key } = window.__.auth

  const data = qs.stringify({
    client_id: client_id,
    grant_type: 'refresh_token',
    refresh_token: refreshToken,
    redirect_uri: `${window.location.origin}/auth`,
  })

  const endpoint = `https://${token_base_url}/oauth2/token`

  return fetch(endpoint, {
    body: data,
    method: 'POST',
    headers: {
      Authorization: `Basic ${authorization_key}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      'cache-control': 'no-cache',
    },
  }).then(data => data.json())
}

const requestAutorizationCode = () => {
  const { scope, client_id, authorize_base_url } = window.__.auth

  const routeParams = qs.stringify(
    {
      scope: scope,
      client_id: client_id,
      response_type: 'code',
      redirect_uri: `${window.location.origin}/auth`,
    },
    { encode: false }
  )

  window.location = `${authorize_base_url}?${routeParams}`
}

const requestLogoutUser = () => {
  const { logout_base_url, scope, client_id } = window.__.auth

  const routeParams = qs.stringify(
    {
      scope: scope,
      isLogout: true,
      client_id: client_id,
      response_type: 'token',
      logout_uri: `${window.location.origin}/auth`,
      redirect_uri: `${window.location.origin}/auth`,
    },
    { encode: false }
  )

  window.location = `${logout_base_url}?${routeParams}`
}

export { postAuth, refreshAuth, requestAutorizationCode, requestLogoutUser }
