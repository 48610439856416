const customAsyncSelectStyle = () => ({
  itemMiddleLeft: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
  },
  verticalHr: {
    width: '1px',
    border: 'none',
    height: '100%',
    borderLeft: '2px solid #eee',
    margin: 0,
  },
  customIcons: {
    color: 'hsl(0,0%,80%)',
    fontSize: '16px',
    display: 'inline-block',
    lineHeight: 1,
    margin: 'auto',
    '&:hover': {
      color: 'hsl(0,0%,60%)',
    },
  },
  boxIcon: {
    color: 'hsl(0,0%,80%)',
    display: 'flex',
    padding: '8px',
    transition: 'color 150ms',
    boxSizing: 'border-box',
  },
  containerIcon: {
    width: '20px',
    height: '20px',
    textAlign: 'center',
    verticalAlign: 'middle',
    display: 'flex',
  },
})

export default customAsyncSelectStyle
