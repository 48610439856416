import React from 'react'
import PropTypes from 'prop-types'
import { getRouteActive, makeBrand } from './util.js'
import { Container, Title, Tooltip, TooltipIconButton } from './styles.js'

const Header = ({ routes = [], location }) => {
  const activeRoute = getRouteActive(routes, location)
  const help = activeRoute && activeRoute.help

  return (
    <Container>
      <Title>{makeBrand(routes, location)}</Title>
      {Boolean(help) && (
        <Tooltip title={help} placement="right" arrow>
          <TooltipIconButton />
        </Tooltip>
      )}
    </Container>
  )
}

Header.propTypes = {
  routes: PropTypes.array,
  location: PropTypes.object,
}

export default Header
