const creditsModalStyle = () => ({
  paper: {
    width: '991px',
    height: '729px',
    borderRadius: '4px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    backgroundColor: '#f1f1f1',
  },
  dialogTitle: {
    padding: '36px 46px 46px',
  },
  title: {
    fontSize: '25px',
  },
})

export default creditsModalStyle
