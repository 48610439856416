import { defaultFont } from '../material-dashboard-react.jsx'

const menuStyle = () => ({
  appbar: {
    height: '50px',
    background: '#000',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    boxShadow: 'unset',
  },
  dropdown: {
    zIndex: 100000,
  },
  logo: {
    height: '50px',
    width: '50px',
    zIndex: '4',
    display: 'flex',
    marginLeft: '10px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    marginRight: '24px',
  },
  user: {
    height: '40px',
    width: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  list: {
    display: 'flex',
    margin: '0',
    padding: '0',
    listStyle: 'none',
    position: 'unset',
    alignItems: 'center',
  },
  item: {
    ...defaultFont,
    padding: '0 16px',
    lineHeight: '33px',
    fontSize: '13px',
    color: '#ccc',
    '&:hover': {
      color: '#fff',
    },
  },
  itemActive: {
    color: '#fff',
  },
})

export default menuStyle
