import React, { Component } from 'react'

//@material-ui
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

class SubmixList extends Component {
  render() {
    const { submixes } = this.props
    const hasId = submixes.filter(s => s.id).length > 0

    return (
      <Table>
        <TableHead>
          <TableRow>
            {hasId ? <TableCell>ID</TableCell> : null}
            <TableCell>Obra</TableCell>
            <TableCell>Submix</TableCell>
            <TableCell>Intérpretes</TableCell>
            {!this.props.disableRecorder ? <TableCell>Gravadora</TableCell> : null}
            {!this.props.disableIsrc ? <TableCell>ISRC</TableCell> : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {submixes.map((item, key) => (
            <TableRow key={key}>
              {hasId ? <TableCell>{item.id ? item.id : '*'}</TableCell> : null}
              <TableCell>{item.workTitle}</TableCell>
              <TableCell>{item.submix}</TableCell>
              <TableCell>
                {item.performers ? item.performers.map(p => p.fullName).join(',') : item.performersSummary}
              </TableCell>
              {!this.props.disableRecorder ? (
                <TableCell>{item.recorder ? item.recorder.name : item.recorderName}</TableCell>
              ) : null}
              {!this.props.disableIsrc ? (
                <TableCell>
                  {item.isrc.toUpperCase().replace(/([A-Z]{2})([A-Z\d]{3})(\d{2})(\d{5})/, '$1-$2-$3-$4')}
                </TableCell>
              ) : null}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )
  }
}

export default SubmixList
