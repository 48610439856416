import React, { Component } from 'react'

//api
import PhonogramApi from '../../services/phonogram-api'

//@material-ui
import withStyles from '@material-ui/core/styles/withStyles'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Grid from '@material-ui/core/Grid'

//custom components
import GridItem from '../../components/grid/grid-item'
import Card from '../../components/card/card'
import CardHeader from '../../components/card/card-header'
import CardBody from '../../components/card/card-body'
import CardFooter from '../../components/card/card-footer'
import Snackbar from '../../components/snackbar/snackbar'
import LinearProgress from '@material-ui/core/LinearProgress'
import Button from '../../components/custom-buttons/button'

const styles = {
  wrapperContainer: {
    margin: '0 30px',
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
  itemMiddleRight: {
    display: 'inline-grid',
    alignItems: 'center',
    justifyContent: 'right',
  },
}

class DownloadsList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      submitting: false,
      statusResponse: false,
    }
    this.handleError = this.handleError.bind(this)
    this.handleSnackClose = this.handleSnackClose.bind(this)

    this.jobId = this.props.match.params.id
    this.phonogramApi = new PhonogramApi()
  }

  componentDidMount = () => {
    this._mounted = true

    if (this.jobId && !isNaN(this.jobId)) {
      this.getDownloads(this.jobId)
    } else {
      this.setState({
        statusResponse: 'not-found',
        submitting: false,
        snackMessage: 'Link de Download Inválido',
        snackColor: 'danger',
      })
    }
  }

  componentWillUnmount = () => {
    //TODO fazer com que cancele qualquer requisição
    //após o desmonte do componente
    this._mounted = false
  }

  componentDidCatch = (error, errorInfo) => {
    // Catch errors in any components below and re-render with error message
    this.props.context.changeState(
      {
        error: error,
        errorInfo: errorInfo,
      },
      () => {
        this.props.changeMode('showError')
      }
    )
    // You can also log error messages to an error reporting service here
  }

  getDownloads = jobId => {
    this.props.context.changeState({ musics: [] }, () => {
      this.setState({ submitting: true, statusResponse: false }, () => {
        this.phonogramApi
          .getDownloadJobUrl(jobId)
          .then(data => {
            if (data !== undefined) {
              this.props.context.changeState({ downloads: data }, () => {
                this.setState({ submitting: false })
              })
            }
          })
          .catch(this.handleError)
      })
    })
  }

  handleError = error => {
    console.error('error: ', error)

    if (error.response.status === 404)
      this.setState({
        statusResponse: 'not-found',
        submitting: false,
        snackMessage: 'Nenhuma música encontrada',
        nackColor: 'primary',
      })
    else
      this.setState({
        statusResponse: 'error',
        submitting: false,
        snackMessage: 'Erro ao buscar músicas',
        snackColor: 'danger',
      })
  }

  handleSnackClose = () => {
    if (this.state.statusResponse === 'error' || this.state.statusResponse === 'not-found') {
      this.setState({ statusResponse: false })
    }
  }

  handleChange = event => {
    this.props.context.changeState({ [event.target.name]: event.target.value })
  }

  downloadAll = () => {
    this.props.context.downloads.forEach(d => {
      window.open(d.url)
    })
  }

  render() {
    const { classes } = this.props
    let downloads = this.props.context.downloads

    return (
      <Grid className={classes.wrapperContainer} direction={'column'}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>
              Download de Músicas Ingestadas - <b>Lote {this.jobId}</b>
            </h4>
            <p className={classes.cardCategoryWhite}>
              Clique nos links abaixo para fazer download ou utilize a opção para baixar todos os arquivos.
            </p>
          </CardHeader>
          <CardBody>
            {this.state.submitting ? <LinearProgress /> : null}
            {downloads.length ? (
              <Grid container>
                <GridItem xs={12} sm={12} md={12} className={classes.itemMiddleRight}>
                  <Button
                    size="sm"
                    variant="outlined"
                    color="primary"
                    onClick={this.downloadAll}
                    disabled={!downloads.length}
                    style={{ margin: 0 }}
                  >
                    Baixar Todos
                  </Button>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>ARQUIVOS DO LOTE {this.jobId}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {downloads.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <a download href={item.url}>
                              {item.tempFileName}
                            </a>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </GridItem>
              </Grid>
            ) : null}
          </CardBody>
          <CardFooter></CardFooter>
        </Card>
        <div>
          <Snackbar
            place="bc"
            message={this.state.snackMessage}
            open={this.state.statusResponse === 'not-found' || this.state.statusResponse === 'error'}
            close
            color={this.state.snackColor}
            closeNotification={this.handleSnackClose}
          />
        </div>
      </Grid>
    )
  }
}

export default withStyles(styles)(DownloadsList)
