import { defaultFont } from '../material-dashboard-react.jsx'

const footerStyle = {
  footer: {
    ...defaultFont,
    height: '50px',
    display: 'flex',
    marginRight: '15px',
    alignItems: 'center',
    borderTop: '1px solid #e7e7e7',
    justifyContent: 'flex-end',
  },
  copyright: {
    fontSize: '14px',
    float: 'right !important',
  },
}

export default footerStyle
