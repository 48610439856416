const listWithSubItemsStyle = () => ({
  gridStyle: {
    display: 'flex',
    overflow: 'hidden',
    padding: '0 0 20px',
    '& ul': {
      listStyle: 'none',
      margin: '19px 0 0 4px',
      padding: '0',
    },
    '& > ul.listPrincipal': {
      marginRight: '48px',
    },
  },
  checkbox: {
    width: '20px',
    height: '20px',
    padding: '0',
  },
  checkBoxIcon: {
    fontSize: '16px',
  },

  listPrincipalItem: {
    '& a': {
      fontSize: '12px',
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontStretch: 'normal',
      letterSpacing: '1.5px',
      color: '#666666',
      cursor: 'pointer',
      textTransform: 'uppercase',
      height: '23px',
      lineHeight: '23px',
      padding: '0 6px',
      textDecoration: 'none',
      display: 'inline-block',
    },
  },

  counter: {
    pointerEvents: 'none',
    width: '15px',
    height: '14px',
    fontFamily: 'Arial',
    fontSize: '12px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#76458f',
    margin: '0px 0 0 4px',
    float: 'right',
  },

  activeItemPrincipal: {
    color: '#333',
    fontWeight: 'bold',
    background: '#ddd',
    borderRadius: '4px',
    '& a': {
      color: '#333',
      fontWeight: 'bold',
    },
  },
  listSubItem: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    maxHeight: '340px',
  },
  listSubItem_Item: {
    background: 'url(../images/Icons/checkbox.svg) no-repeat 0 0',
    display: 'flex',
    alignItems: 'center',
    margin: '0 30px 0 0',

    '& span': {
      fontSize: '14px',
    },
  },
})

export default listWithSubItemsStyle
