import PropTypes from 'prop-types'
import { isEmpty, isNil } from 'lodash'

const checkPermissions = (userPermissions, allowedPermissions) => {
  if (isNil(allowedPermissions) || isEmpty(allowedPermissions)) {
    return true
  }

  return userPermissions.some(permission => allowedPermissions.includes(permission))
}

function AccessControl({ ...props }) {
  const { userPermissions, allowedPermissions, children, renderNoAccess } = props
  const permitted = checkPermissions(userPermissions, allowedPermissions)
  if (permitted) return children
  return renderNoAccess()
}

AccessControl.propTypes = {
  allowedPermissions: PropTypes.array,
  userPermissions: PropTypes.array,
  renderNoAccess: PropTypes.func,
}

export default AccessControl
