import React, { useEffect } from 'react'
import loginStore from '../../shared/lib/login-store'
import { requestLogoutUser } from 'services/auth-api'
import Redirect from 'components/redirect'

function Logout() {
  useEffect(() => {
    loginStore.removeToken()
    loginStore.clear()
    requestLogoutUser()
  }, [])

  return <Redirect message="Saindo..." automacao="logout" />
}

export default Logout
