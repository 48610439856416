import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import CircularProgress from '@material-ui/core/CircularProgress'
import HelpIcon from '../../assets/img/svg/help-icon-20px.svg'
import Tooltip from '@material-ui/core/Tooltip'
import SemelhanteInputAutocomplete from '../semelhante-input-autocomplete/semelhante-input-autocomplete'
import 'react-perfect-scrollbar/dist/css/styles.css'

import semelhanteModalStyle from '../../assets/jss/views/ingest/semelhanteModalStyle.jsx'

class SemelhanteModal extends React.Component {
  timerSelectedSuggest = 0

  constructor(props) {
    super(props)
    this.state = {
      open: true,
      isLoading: false,
      itemsSelected: [],
    }
  }

  componentDidMount() {
    this.setState({
      itemsSelected: this.props.itemsSelected ? this.getItemsSelectedModel(this.props.itemsSelected) : [],
    })
  }

  //Converte para o objeto esperado pelo componente de autocomplete
  getItemsSelectedModel = values =>
    values.length > 0
      ? values.map(item => {
          if (item.value === undefined) {
            return {
              value: item.id,
              label: item.name,
            }
          } else {
            return item
          }
        })
      : []

  shouldComponentUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps) !== JSON.stringify(this.props) ||
      JSON.stringify(prevState) !== JSON.stringify(this.state)
    ) {
      return true
    } else {
      return false
    }
  }

  onCloseModal = () => {
    const { itemsSelected } = this.state

    if (this.props.onAfterCloseModal) {
      this.props.onAfterCloseModal(itemsSelected)
    }
  }

  removeLoading = () => {
    if (this.ModalTagsWrapper && this.ModalTagsWrapper.querySelector('.loading')) {
      this.ModalTagsWrapper.querySelector('.loading').remove()
    }
  }

  loading = () => {
    this.removeLoading()
    const input = this.ModalTagsWrapper.querySelector('.select-tags__input input')
    const span = document.createElement('span')
    span.setAttribute('class', 'loading')
    input.setAttribute('placeholder', 'Escrever aqui...')
    input.before(span)
  }

  onChangeItemSelectedAutocomplete = itemsSelected => {
    itemsSelected = itemsSelected.map(tag => ({ ...tag, value: Number(tag.value) > 0 ? tag.value : tag.label }))

    this.setState({
      itemsSelected,
    })
  }

  render = () => {
    const { open } = this.props
    const { root, paper, loadingContainer, tooltip, content } = this.props.classes
    const { isLoading, itemsSelected, dialogContent } = this.state
    return (
      <Dialog id="DialogTag" open={open} onClose={() => this.onCloseModal()} classes={{ root, paper }}>
        <DialogTitle id="form-dialog-title">
          <span id="title">Semelhante a</span>
          <Tooltip
            classes={{ tooltip }}
            title="Utilize esse campo para indicar nomes de artistas/bandas que tenham um repertório semelhante a essa música"
            placement="left"
            arrow
          >
            <img id="help" alt="help" src={HelpIcon} />
          </Tooltip>
        </DialogTitle>
        <DialogContent classes={{ root: dialogContent }} id="form-dialog-content">
          {isLoading ? (
            <div className={loadingContainer}>
              <CircularProgress size={24} />
            </div>
          ) : (
            <div ref={el => (this.ModalTagsWrapper = el)} className={content}>
              <SemelhanteInputAutocomplete
                values={itemsSelected}
                onChangeItemSelected={arrayValues => this.onChangeItemSelectedAutocomplete(arrayValues)}
              />
            </div>
          )}
        </DialogContent>
      </Dialog>
    )
  }
}
export default withStyles(semelhanteModalStyle)(SemelhanteModal)
