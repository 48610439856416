import React, { Component, Fragment } from 'react'

import workSelectedInfoStyle from '../../assets/jss/views/ingest/workSelectedInfoStyle'

import GridItem from '../../components/grid/grid-item.jsx'
import { toCapitalize } from '../../shared/helpers/text-decorator'

import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import _ from 'lodash'

class WorkSelectedInfo extends Component {
  getWorkType(category) {
    if (!category) {
      return ''
    } else if (_.deburr(category).toUpperCase() === 'TRILHA MUSICAL') {
      return 'Original'
    } else if (_.deburr(category).toUpperCase() === 'MUSICA COMERCIAL') {
      return 'Comercial'
    } else {
      return category
    }
  }

  getFonogramType(phonogramType) {
    if (phonogramType.toUpperCase() === 'ORIGINAL') {
      return 'Fonograma Original'
    } else if (phonogramType.toUpperCase() === 'COMERCIAL') {
      return 'Fonograma Comercial'
    } else {
      return 'Performance'
    }
  }

  separator() {
    return <span>&nbsp;&nbsp;/&nbsp;&nbsp;</span>
  }

  render() {
    const { classes, context } = this.props
    return (
      <Fragment>
        <Grid className={classes.workSelectInfoWrapper}>
          <GridItem className={classes.workGridItem}>
            <ul className={classes.workList}>
              <li>
                <strong>{toCapitalize(context.musicSelected.title)}</strong>
                {this.separator()}
              </li>
              <li>Obra {toCapitalize(this.getWorkType(context.musicSelected.category))}</li>
              {this.props.hidePhonogramType !== true ? (
                <li>
                  {this.separator()}
                  {this.getFonogramType(context.phonogramType)}
                </li>
              ) : null}
            </ul>
          </GridItem>
        </Grid>
      </Fragment>
    )
  }
}

export default withStyles(workSelectedInfoStyle)(WorkSelectedInfo)
