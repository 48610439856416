const listWithSubItemsAccordionStyle = () => ({
  gridStyle: {
    display: 'flex',
    overflow: 'hidden',
    padding: '27px 0 0 0',
    '& ul': {
      listStyle: 'none',
      padding: '0',
      paddingInlineStart: '0px',
    },
    '& > ul.listPrincipal': {
      marginRight: '48px',
    },
    '& .ExpandMoreIcon': {
      transform: 'rotate(90deg)',
    },
  },

  ExpansionPanelStyle: {
    boxShadow: 'none',
  },

  ExpansionPanelSummaryStyle: {
    borderBottom: '1px solid #eee',
    cursor: 'pointer',
    minHeight: '29px',
    lineHeight: '29px',
    position: 'relative',
    padding: '0 0 0 4px',
  },

  ExpansionPanelSummaryContentStyle: {
    margin: '0',
    '& p': {
      fontFamily: 'Arial',
      fontSize: '13px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      letterSpacing: 'normal',
      color: '#666666',
    },
    '&:hover p': {
      color: '#76458f',
    },
  },

  ExpansionPanelSummaryIconStyle: {
    right: '8px',
    width: '8px',
    top: '0',
    transform: 'rotate(0deg)',
    '& span:last-child': {
      display: 'none',
    },
  },

  ExpansionPanelDetailsStyle: {
    padding: '0',
  },
})

export default listWithSubItemsAccordionStyle
