import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import './style.css'

//@material-ui
import withStyles from '@material-ui/core/styles/withStyles'
import Edit from '@material-ui/icons/Edit'

import Grid from '@material-ui/core/Grid'
import { components } from 'react-select'
import AsyncSelect from 'react-select/async'

import customAsyncSelectStyle from '../../assets/jss/components/customAsyncSelectStyle'

class CustomAsyncSelect extends React.Component {
  constructor(props) {
    super(props)

    this.customSelectMenu = this.customSelectMenu.bind(this)
  }

  isEmpty = el => JSON.stringify(el.value) === JSON.stringify({})

  customSelectMenu = props => {
    const { classes } = this.props

    return (
      <div className="custom-select-container">
        <components.Menu className="custom-select-menu" {...props}>
          {props.children}
        </components.Menu>
        <hr style={{ width: '100%', margin: 0, border: 'solid .5px #ddd' }} />
        <div className="custom-select-footer">
          <Grid container>
            <Grid item xs sm md className={classes.itemMiddleLeft}>
              <span>Cadastrar:</span>
              {!!this.props.option_MenuItem_1 && (
                <a onMouseDown={this.props.option_MenuItem_1['onClick']} id="buttonMenuItem_1">
                  {this.props.option_MenuItem_1['text']}
                </a>
              )}
              {!!this.props.option_MenuItem_2 && (
                <Fragment>
                  <hr className={classes.verticalHr} style={{ height: '12px', margin: 0 }} />
                  <a onMouseDown={this.props.option_MenuItem_2['onClick']} id="buttonMenuItem_2">
                    {this.props.option_MenuItem_2['text']}
                  </a>
                </Fragment>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    )
  }

  customIndicatorsContainer = props => {
    const { classes } = this.props
    return !this.isEmpty(this.props) ? (
      <Fragment>
        <components.IndicatorSeparator {...props}>{props.children}</components.IndicatorSeparator>
        <div className={classes.boxIcon}>
          <a
            onMouseDown={() => this.props.option_EditSelected['onClick'](this.props.value)}
            id="buttonIndicator"
            className={classes.containerIcon}
          >
            <Edit className={classes.customIcons} />
          </a>
        </div>
      </Fragment>
    ) : null
  }

  render() {
    const { props } = this
    let extraComponents = { components: {} }

    if (props.option_MenuItem_1 || props.option_MenuItem_2) {
      extraComponents.components = {
        Menu: this.customSelectMenu,
      }
    }

    if (props.option_EditSelected) {
      extraComponents.components = {
        ...extraComponents.components,
        IndicatorsContainer: this.customIndicatorsContainer,
      }
    }
    return <AsyncSelect {...this.props} {...extraComponents} />
  }
}

CustomAsyncSelect.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(customAsyncSelectStyle)(CustomAsyncSelect)
