import React, { Component } from 'react'
import Select from 'react-select'

//api
import PhonogramApi from '../../../services/phonogram-api'
import loginStore from '../../../shared/lib/login-store'

//shared
import { toCapitalize } from '../../../shared/helpers/text-decorator'

//@material-ui
import withStyles from '@material-ui/core/styles/withStyles'
import FormControl from '@material-ui/core/FormControl'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'

//custom components
import Button from '../../../components/custom-buttons/button'
import Snackbar from '../../../components/snackbar/snackbar'
import GridItem from '../../../components/grid/grid-item.jsx'
import Card from '../../../components/card/card.jsx'
import CardHeader from '../../../components/card/card-header.jsx'
import CardBody from '../../../components/card/card-body.jsx'
import Steps from '../../../components/steps'

import productStepStyle from '../../../assets/jss/views/ingest/productStepStyle.jsx'
import WorkSelectedInfo from '../work-selected-info'

class ProductStep extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formIsValid: false,
      submitting: false,
      hasProduct: 'true',
      errorMessage: 'Erro ao obter Produtos',
      utilizationTypes: [
        { value: 65, label: 'Abertura' },
        { value: 84, label: 'Tema' },
        { value: 70, label: 'Fundo' },
        { value: 80, label: 'Performance' },
      ],
    }

    this.handleError = this.handleError.bind(this)
    this.handleSnackClose = this.handleSnackClose.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleBackClick = this.handleBackClick.bind(this)
    this.handleProductSelect = this.handleProductSelect.bind(this)
    this.phonogramApi = new PhonogramApi()
    this.LoginStore = loginStore
  }

  componentDidMount() {
    this.getProducts()

    if (this.isPhonogramPerformance()) {
      this.onChangeUtilizationType(this.state.utilizationTypes[3])
    }
  }

  componentDidCatch = (error, errorInfo) => {
    // Catch errors in any components below and re-render with error message
    this.props.context.changeState(
      {
        error: error,
        errorInfo: errorInfo,
      },
      () => {
        this.props.changeMode('showError')
      }
    )
    // You can also log error messages to an error reporting service here
  }

  handleError = error => {
    console.error('error: ', error)
    this.setState({
      statusResponse: 'error',
      submitting: false,
    })
  }

  handleSnackClose = () => {
    this.setState({
      submitting: false,
      statusResponse: '',
    })
  }

  handleSubmit = event => {
    this.props.changeMode('confirmIngest')
    event.preventDefault()
  }

  isPhonogramOriginal() {
    return this.props.context.phonogramType === 'Original'
  }

  isPhonogramPerformance() {
    return this.props.context.phonogramType === 'Performance'
  }

  handleBackClick = event => {
    if (this.isPhonogramOriginal()) {
      this.props.changeMode('addCredits')
    } else {
      this.props.changeMode('addPhonogram')
    }

    event.preventDefault()
  }

  handleProductSelect = obj => {
    const product =
      obj != null
        ? {
            id: obj.value,
            name: obj.label,
            area: obj.area,
          }
        : null
    this.props.context.changeState({ product })
  }

  defineProductUser(data) {
    if (!data) {
      data = []
    }

    this.props.context.changeState(
      {
        productListItems: data.map(item => ({
          value: item.id,
          label: toCapitalize(item.name),
          area: item.area,
        })),
      },
      () => {
        this.setState({
          submitting: false,
        })
      }
    )
  }

  onChangeUtilizationType(typeSelected) {
    const utilizationType =
      typeSelected != null
        ? {
            id: typeSelected.value,
            name: typeSelected.label,
          }
        : null
    this.props.context.changeState({ utilizationType })
  }

  getProducts = () => {
    this.setState(
      {
        submitting: true,
      },
      () => {
        this.phonogramApi
          .getProducts()
          .then(data => {
            this.defineProductUser(data)
          })
          .catch(this.handleError)
      }
    )
  }

  onChangeHasProduct(event, value) {
    this.setState(
      {
        hasProduct: value,
      },
      () => {
        if (value === 'false') {
          if (!this.isPhonogramPerformance()) {
            this.props.context.changeState({
              product: null,
              utilizationType: null,
            })
          } else {
            this.props.context.changeState({
              product: null,
            })
          }
        }
      }
    )
  }

  isOpenSnackBarError() {
    return this.state.statusResponse === 'error'
  }

  disableButton(hasProduct) {
    const { product } = this.props.context

    if (hasProduct === '') {
      return true
    }
    return hasProduct === 'true' && product === null
  }

  getStepActive() {
    return this.props.context.steps.length - 2
  }

  render() {
    const { classes } = this.props
    const { hasProduct } = this.state

    return (
      <Grid className={classes.wrapperContainer}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Informações do Fonograma</h4>
            <p className={classes.cardCategoryWhite}>Preencha os campos abaixo</p>
          </CardHeader>
          <CardBody>
            <div className={classes.ingestHeader}>
              <Steps steps={this.props.context.steps} activeStep={this.getStepActive()} />
            </div>

            <WorkSelectedInfo context={this.props.context} />

            <GridItem className={classes.center} xs={12} sm={12} md={12}>
              <RadioGroup
                id="RadioGroup"
                style={{ flexDirection: 'row', marginTop: '59px' }}
                name="hasProduct"
                value={hasProduct}
                onChange={(event, value) => this.onChangeHasProduct(event, value)}
              >
                <FormControlLabel
                  classes={{
                    root: classes.hasProductRadio,
                    label: classes.hasProductLabel,
                  }}
                  value="true"
                  control={<Radio color="black" />}
                  label="Produto"
                  automacao="checkProduto"
                />

                <FormControlLabel
                  classes={{
                    root: classes.hasProductRadio,
                    label: classes.hasProductLabel,
                  }}
                  value="false"
                  control={<Radio color="black" />}
                  label="Sem Produto"
                  automacao="checkSemProduto"
                />
              </RadioGroup>
            </GridItem>

            {!this.state.submitting && hasProduct === 'true' ? (
              <Grid className={classes.center} container xs={12} sm={12} md={12}>
                <GridItem className={classes.gridItemList} xs={6} sm={6} md={6}>
                  <FormControl classes={{ root: classes.productList }} style={{ margin: '12px 0 0 0' }}>
                    <Select
                      id="productId"
                      automacao="inputProduct"
                      placeholder="Digite ou selecione"
                      noOptionsMessage={() => 'Nenhum item encontrado.'}
                      onChange={this.handleProductSelect}
                      options={this.props.context.productListItems}
                      value={
                        this.props.context.product !== null
                          ? {
                              value: this.props.context.product.id,
                              label: this.props.context.product.name,
                            }
                          : null
                      }
                      isDisabled={this.props.context.productListItems.length === 0}
                    />
                  </FormControl>
                </GridItem>
              </Grid>
            ) : null}

            {this.state.submitting && hasProduct === 'true' ? (
              <GridItem className={classes.center} xs={1} sm={1} md={1}>
                <CircularProgress size={24} style={{ margin: '12px 0 0 0' }} />
              </GridItem>
            ) : null}
          </CardBody>

          <GridItem id="footerButtons" container className={classes.ingestFooter}>
            <GridItem className={classes.itemMiddleLeft}>
              <Button
                type="button"
                size="sm"
                value="Voltar"
                id="backButton"
                automacao="btnVoltar"
                style={{ float: 'left' }}
                onClick={this.handleBackClick}
                color="transparent"
              >
                Voltar
              </Button>
            </GridItem>

            <GridItem className={classes.itemMiddleRight}>
              <Button
                type="submit"
                size="sm"
                value="Submit"
                automacao="btnAvancar"
                id="nextButton"
                color="primary"
                variant="contained"
                disabled={this.disableButton(hasProduct)}
                onClick={this.handleSubmit}
              >
                Avançar
              </Button>
            </GridItem>
          </GridItem>
        </Card>
        <div className="snackBarWrapper">
          <Snackbar
            place="bc"
            message={this.state.errorMessage}
            open={this.isOpenSnackBarError()}
            close
            color="danger"
            closeNotification={this.handleSnackClose}
          />
        </div>
      </Grid>
    )
  }
}

export default withStyles(productStepStyle)(ProductStep)
