import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import CircularProgress from '@material-ui/core/CircularProgress'
import PhonogramApi from '../../services/phonogram-api'
import ListWithSubItemsAccordion from '../list-with-subitems-accordion/list-with-subitems-accordion'
import HelpIcon from '../../assets/img/svg/help-icon-20px.svg'
import Tooltip from '@material-ui/core/Tooltip'
import TagsInputAutocomplete from '../tags-input-autocomplete/tags-input-autocomplete'
import 'react-perfect-scrollbar/dist/css/styles.css'
import PerfectScrollbar from 'react-perfect-scrollbar'

import tagsModalStyle from '../../assets/jss/views/ingest/tagsModalStyle.jsx'

class TagsModal extends React.Component {
  timerSelectedSuggest = 0

  constructor(props) {
    super(props)
    this.phonogramApi = new PhonogramApi()
    this.state = {
      open: true,
      isLoading: false,
      itemsSelected: [],
      listWithSubItems: [],
    }
  }

  componentDidMount() {
    this.setState(
      {
        itemsSelected: this.props.itemsSelected ? this.getItemsSelectedModel(this.props.itemsSelected) : [],
      },
      () => {
        this.getSuggestsTags()
      }
    )
  }

  //Converte para o objeto esperado pelo componente de autocomplete
  getItemsSelectedModel = values =>
    values.length > 0
      ? values.map(item => {
          if (item.value === undefined) {
            return {
              value: item.id,
              label: item.name,
            }
          } else {
            return item
          }
        })
      : []

  shouldComponentUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps) !== JSON.stringify(this.props) ||
      JSON.stringify(prevState) !== JSON.stringify(this.state)
    ) {
      return true
    } else {
      return false
    }
  }

  getSuggestsTags() {
    this.setState({ isLoading: true })
    if (this.props.context.tagItems && this.props.context.tagItems.length > 0) {
      this.setListWithSubItens()
      return
    }
    this.phonogramApi.getSuggestsTags().then(result => {
      this.props.context.changeState({ tagItems: result }, () => {
        this.setListWithSubItens()
      })
    })
  }

  setListWithSubItens = () => {
    this.setState(
      {
        isLoading: false,
        listWithSubItems: this.getListWithSubItens(this.props.context.tagItems, this.state.itemsSelected),
      },
      () => {
        this._scrollBarRef._container.scrollTop = this._scrollBarRef._container.parentElement.offsetHeight + 100
      }
    )
  }

  getListWithSubItens = (tagItems, itemsSelected) =>
    tagItems.map(item => ({
      groupname: item.groupName,
      items: item.tags.map(tag => ({
        label: tag.name,
        value: String(tag.id),
        checked: itemsSelected.some(itemSelected => String(itemSelected.value) === String(tag.id)) ? true : false,
      })),
    }))

  onCloseModal = () => {
    const { itemsSelected } = this.state
    if (this.props.onAfterCloseModal) {
      this.props.onAfterCloseModal(itemsSelected)
    }
  }

  onChangeItemSelectedAutocomplete = itemsSelected => {
    let { listWithSubItems } = this.state

    listWithSubItems = listWithSubItems.map(listItem => ({
      ...listItem,
      items: listItem.items.map(tag => ({
        ...tag,
        checked: itemsSelected.some(itemSelected => String(itemSelected.value) === String(tag.value)) ? true : false,
      })),
    }))

    itemsSelected = itemsSelected.map(tag => ({ ...tag, value: Number(tag.value) > 0 ? tag.value : tag.label }))

    this.setState(
      {
        itemsSelected,
        listWithSubItems,
      },
      () => {
        this.updateScroll()
      }
    )
  }

  onCheckItemSelectedSuggestTags = (value, event) => {
    event.target.classList.add('checked')

    // Essas propriedades foram criadas para incrementar os valores por causa do timeout.
    // se o usuário clicar rápido (menos de 500ms) deve ficar marcado o numero de itens clicados.
    this.newItemsSelected = this.newItemsSelected || this.state.itemsSelected
    this.newListWithSubItems = this.newListWithSubItems
      ? this.markItemChecked(value, this.newListWithSubItems)
      : this.markItemChecked(value, this.state.listWithSubItems)

    if (this.isItemSelected(value, this.newItemsSelected) === false) {
      this.newItemsSelected = [...this.newItemsSelected, this.getItemChecked(value, this.newListWithSubItems)]
    }

    if (this.newItemsSelected.length > this.state.itemsSelected.length) {
      this.loading()
      window.clearTimeout(this.timerSelectedSuggest)
      this.timerSelectedSuggest = window.setTimeout(() => {
        this.removeLoading()
        this.setState({
          listWithSubItems: this.newListWithSubItems,
          itemsSelected: this.newItemsSelected,
        })

        this.newListWithSubItems = null
        this.newItemsSelected = null
      }, 500)
    } else {
      this.setState({
        listWithSubItems: this.newListWithSubItems,
        itemsSelected: this.newItemsSelected,
      })
      this.newListWithSubItems = null
      this.newItemsSelected = null
    }
  }

  isItemSelected = (value, itemsSelected) => itemsSelected.some(item => item.value === value)

  getItemChecked = (value, list) => {
    const groupNameSelected = list.filter(item => item.items.some(subItem => subItem.value === value))[0]
    return groupNameSelected.items.filter(item => item.value === value)[0]
  }

  removeLoading = () => {
    if (this.ModalTagsWrapper && this.ModalTagsWrapper.querySelector('.loading')) {
      this.ModalTagsWrapper.querySelector('.loading').remove()
    }
  }

  loading = () => {
    this.removeLoading()
    const input = this.ModalTagsWrapper.querySelector('.select-tags__input input')
    const span = document.createElement('span')
    span.setAttribute('class', 'loading')
    input.setAttribute('placeholder', 'Escrever aqui...')
    input.before(span)
  }

  markItemChecked = (value, list) =>
    list.map(item => {
      if (item.items.some(subItem => subItem.value === value)) {
        item.items = item.items.map(subItem => {
          if (subItem.value === value) {
            subItem.checked = true
            return subItem
          } else {
            return subItem
          }
        })
        return item
      } else {
        return item
      }
    })

  componentCheck = item => (
    <span
      id={`componentCheck${item.value}`}
      onClick={event => this.onCheckItemSelectedSuggestTags(item.value, event)}
      className={item.checked ? 'checked checkItem' : 'checkItem'}
    >
      {item.label}
    </span>
  )

  updateScroll = () => {
    window.setTimeout(() => {
      if (this._scrollBarRef && this._scrollBarRef.updateScroll) {
        this._scrollBarRef.updateScroll()
      }
    }, 200)
  }

  render = () => {
    const { open } = this.props
    const { root, paper, loadingContainer, tooltip, content } = this.props.classes
    const { listWithSubItems, isLoading, itemsSelected, dialogContent } = this.state
    return (
      <Dialog id="DialogTag" open={open} onClose={() => this.onCloseModal()} classes={{ root, paper }}>
        <DialogTitle id="form-dialog-title">
          <span id="title">Tag</span>
          <Tooltip
            classes={{ tooltip }}
            title="Essa música remete a um(a) situação, época, lugar, instrumento, etc"
            placement="left"
            arrow
          >
            <img id="help" alt="help" src={HelpIcon} />
          </Tooltip>
        </DialogTitle>
        <DialogContent classes={{ root: dialogContent }} id="form-dialog-content">
          <PerfectScrollbar
            ref={ref => {
              this._scrollBarRef = ref
            }}
          >
            {isLoading ? (
              <div className={loadingContainer}>
                <CircularProgress size={24} />
              </div>
            ) : (
              <div ref={el => (this.ModalTagsWrapper = el)} className={content}>
                <TagsInputAutocomplete
                  values={itemsSelected}
                  onChangeItemSelected={arrayValues => this.onChangeItemSelectedAutocomplete(arrayValues)}
                  moodItems={this.props.context.moodItems}
                  genreItems={this.props.context.genreItems}
                />

                <ListWithSubItemsAccordion
                  list={listWithSubItems}
                  values={itemsSelected}
                  onChange={() => this.updateScroll()}
                  component={item => this.componentCheck(item)}
                />
              </div>
            )}
          </PerfectScrollbar>
        </DialogContent>
      </Dialog>
    )
  }
}
export default withStyles(tagsModalStyle)(TagsModal)
