import styled from 'styled-components'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const Wrapper = styled(FormControlLabel).attrs({
  classes: { label: 'label' },
})`
  margin: 0;

  .label {
    font-size: 14px;
  }
`

export { Wrapper }
