import React, { Component } from 'react'
import { toCapitalize } from '../../shared/helpers/text-decorator'

//api
import PhonogramApi from '../../services/phonogram-api'

//lib
import loginStore from '../../shared/lib/login-store'

//@material-ui
import withStyles from '@material-ui/core/styles/withStyles'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import CircularProgress from '@material-ui/core/CircularProgress'

//custom components
import MusicianRow from '../musician/musician-row'
import Button from '../../components/custom-buttons/button'
import GridItem from '../../components/grid/grid-item.jsx'
import AsyncSelect from 'react-select/async'
import Card from '../../components/card/card.jsx'
import CardHeader from '../../components/card/card-header.jsx'
import CardBody from '../../components/card/card-body.jsx'
import CardFooter from '../../components/card/card-footer.jsx'
import InstrumentsModal from './instruments-modal'
import ConfirmModal from '../../components/modal/confirm-modal'
import Snackbar from '../../components/snackbar/snackbar'

const styles = {
  container: {
    margin: '0 30px',
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
}

class MusicianSelectList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      statusResponse: false,
      submitting: false,
      autoSubmitting: false,
      openInstruments: false,
      openConfirm: false,
      musicianId: null,
      instrumentIdsCheckedItems: [],
      instrumentDefaultItems: [],
      isValid: false,
      isIngestOrEdit: false,
    }
    this.handleSnackClose = this.handleSnackClose.bind(this)
    this.handleAddMusicians = this.handleAddMusicians.bind(this)
    this.handleSend = this.handleSend.bind(this)
    this.handleBackClick = this.handleBackClick.bind(this)
    this.handleCreateMusicianClick = this.handleCreateMusicianClick.bind(this)
    this.handleMusicianSelect = this.handleMusicianSelect.bind(this)
    this.handleRemoveMusicianClick = this.handleRemoveMusicianClick.bind(this)
    this.handleInstrumentsModal = this.handleInstrumentsModal.bind(this)
    this.handleChangeInstruments = this.handleChangeInstruments.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.loadMusicianOptions = this.loadMusicianOptions.bind(this)
    this.handleError = this.handleError.bind(this)
    this.phonogramApi = new PhonogramApi()
    this.loginStore = loginStore
  }

  componentDidMount = () => {
    this._mounted = true

    const isIngestOrEdit = !(this.props.context.phonogramToEdit !== undefined && this.props.context.submixIdx === null)
    if (isIngestOrEdit) {
      this.setState({
        isValid: this.props.context.submixDraft.musicians.length > 0,
        isIngestOrEdit: isIngestOrEdit,
      })
    } else {
      this.loadPhonogramToEdit()
    }

    if (this.props.context.submixDraft) {
      this.setState({
        isValid: this.props.context.submixDraft.musicians.length > 0,
      })
    }
  }

  componentWillUnmount = () => {
    //TODO fazer com que cancele qualquer requisição
    //após o desmonte do componente
    this._mounted = false
  }

  componentDidCatch = (error, errorInfo) => {
    // Catch errors in any components below and re-render with error message
    this.props.context.changeState(
      {
        error: error,
        errorInfo: errorInfo,
      },
      () => {
        this.props.changeMode('showError')
      }
    )
    // You can also log error messages to an error reporting service here
  }

  loadPhonogramToEdit = () => {
    //TODO: Verificar se é necessário consultar dados do músico
    const musicians = this.props.context.phonogramToEdit.musicians
      .filter(m => m.musicalInstruments.length !== 0 && m.recorder === null)
      .map(m => ({
        value: String(m.id),
        label: m.fullName,
        id: m.id,
        fullName: m.fullName,
        stageName: m.stageName,
        favoriteMusicalInstrument: { id: 0, name: null },
        musicalInstruments: m.musicalInstruments,
      }))
    const musicianIds = musicians.map(m => m.id)
    if (!!musicianIds.length && !this.props.context.submixDraft) {
      this.phonogramApi.getMusiciansByIds(musicianIds).then(data => {
        if (data !== undefined) {
          const newSubmixDraft = {
            musicians: musicians.map(instrumentist => {
              let musician = data.find(d => d.id === instrumentist.id) || null

              if (musician === null) return instrumentist

              const musicalInstrumentIds = instrumentist.musicalInstruments.map(mi => mi.id)

              let musicalInstruments = musician.musicalInstruments
                .filter(mi => musicalInstrumentIds.includes(mi.id))
                .map(mi => ({ ...mi, choosed: true }))

              musicalInstruments = musicalInstruments.concat(
                musician.musicalInstruments.filter(mi => !musicalInstrumentIds.includes(mi.id))
              )

              return {
                ...musician,
                musicalInstruments: musicalInstruments,
              }
            }),
          }

          this.props.context.changeState({ submixDraft: newSubmixDraft }, () => {
            this.setState({ isValid: newSubmixDraft.musicians.length > 0 })
          })
        }
      })
    }
    if (!musicianIds.length) {
      this.props.context.changeState({ submixDraft: { musicians: [] } }, () => {
        this.setState({ isValid: false })
      })
    }
  }

  editPhonogram = send => {
    let musicians = []
    const performers = this.props.context.phonogramToEdit.musicians.filter(m => m.type === 73)

    this.props.context.submixDraft.musicians.forEach(musician => {
      musicians.push({
        id: musician.id,
        stageName: musician.stageName,
        fullName: musician.fullName,
        musicalInstruments: musician.musicalInstruments.filter(mi => mi.choosed),
        type: 77,
      })
    })

    performers.forEach(performer => {
      musicians.push({
        id: performer.id,
        stageName: performer.stageName,
        fullName: performer.fullName,
        recorder: performer.recorder,
        type: 73,
      })
    })

    let payload = {
      ...this.props.context.phonogramToEdit,
      musicians: musicians,
      status: send ? 0 : 80,
    }

    payload.category = 4 //Fonograma original
    //TODO: Refazer isso
    payload.product = payload.product && payload.product.id !== 0 ? payload.product : null

    const statusResponse = send ? 'success' : false

    this.setState({ submitting: send, autoSubmitting: !send }, () => {
      this.phonogramApi
        .putPhonogram(payload)
        .then(() => {
          this.setState({
            submitting: false,
            autoSubmitting: false,
            statusResponse: statusResponse,
          })
        })
        .catch(error => {
          this.handleError(error)
        })
    })
  }

  changeMusiciansSubmixDraft = newSubmixDraft => {
    if (this.state.isIngestOrEdit) {
      this.props.context.changeState(
        {
          submixDraft: newSubmixDraft,
        },
        () => {
          this.setState({ isValid: newSubmixDraft.musicians.length > 0 })
        }
      )
    } else {
      this.props.context.changeState(
        {
          submixDraft: newSubmixDraft,
        },
        () => {
          this.setState(
            {
              autoSubmitting: true,
              isValid: newSubmixDraft.musicians.length > 0,
            },
            () => {
              this.editPhonogram(false)
            },
            () => {
              this.setState({ autoSubmitting: false })
            }
          )
        }
      )
    }
  }

  handleSnackClose = () => {
    const isSuccess = this.state.statusResponse === 'success'
    this.setState({ statusResponse: false })

    if (isSuccess) {
      this.props.changeMode('listPending')
      this.props.context.removeResolvedItem(this.props.context.phonogramToEdit.id)
    }
  }

  handleAddMusicians = event => {
    const newMode = this.props.context.phonogramToEdit !== undefined ? 'edit' : 'addSubmix'

    this.props.context.changeState({ musicians: this.props.context.submixDraft.musicians }, () => {
      this.props.changeMode(newMode)
    })
    event.preventDefault()
  }

  handleSend = event => {
    this.editPhonogram(true)
    event.preventDefault()
  }

  handleBackClick = event => {
    const newMode = !this.state.isIngestOrEdit
      ? 'listPending'
      : this.props.context.phonogramToEdit !== undefined
      ? 'edit'
      : 'addSubmix'

    this.props.changeMode(newMode)
    event.preventDefault()
  }

  handleCreateMusicianClick = () => {
    this.props.context.changeState({ musicianId: null }, () => this.props.changeMode('createMusician'))
  }

  handleMusicianSelect = obj => {
    if (obj === null || obj.length === 0) return

    if (obj.favoriteMusicalInstrument !== null) {
      obj.musicalInstruments = obj.musicalInstruments.map(musicalInstrument => {
        if (obj.favoriteMusicalInstrument.id !== musicalInstrument.id) return musicalInstrument

        musicalInstrument.choosed = true
        return musicalInstrument
      })
    }

    const newMusicians = this.props.context.submixDraft.musicians.concat([obj])
    const newSubmixDraft = {
      ...this.props.context.submixDraft,
      musicians: newMusicians,
    }

    const musiciansIds = newMusicians.map(({ id }) => id)

    this.changeMusiciansSubmixDraft(newSubmixDraft)
    this.props.changeEditingMusicians(musiciansIds)
  }

  handleRemoveMusicianClick = id => {
    const newMusicians = this.props.context.submixDraft.musicians.filter(m => m.id !== id)
    const newSubmixDraft = {
      ...this.props.context.submixDraft,
      musicians: newMusicians,
    }

    this.changeMusiciansSubmixDraft(newSubmixDraft)
  }

  handleInstrumentsModal = id => {
    let instrumentIdsCheckedItems = []
    let instrumentDefaultItems = []

    if (id) {
      let musician = this.props.context.submixDraft.musicians.filter(m => m.id === id)[0]
      instrumentIdsCheckedItems = musician.musicalInstruments.filter(mi => mi.choosed === true).map(mi => mi.id)
      instrumentDefaultItems = musician.musicalInstruments
    }

    this.setState({
      musicianIdToChangeInstruments: id,
      instrumentIdsCheckedItems: instrumentIdsCheckedItems,
      instrumentDefaultItems: instrumentDefaultItems,
      openInstruments: !this.state.openInstruments,
    })
  }

  handleChangeInstruments = items => {
    const newMusicians = this.props.context.submixDraft.musicians.map(musician => {
      if (this.state.musicianIdToChangeInstruments !== musician.id) {
        return musician
      } else {
        let newMusicalInstruments = musician.musicalInstruments.map(instrument => ({
          ...instrument,
          choosed: items.filter(item => item.id === instrument.id).length > 0,
        }))
        return { ...musician, musicalInstruments: newMusicalInstruments }
      }
    })
    const newSubmixDraft = {
      ...this.props.context.submixDraft,
      musicians: newMusicians,
    }

    this.changeMusiciansSubmixDraft(newSubmixDraft)
  }

  handleClose = event => {
    this.setState({ openConfirm: !this.state.openConfirm })
    event.preventDefault()
  }

  loadMusicianOptions = (inputValue, callback) => {
    let musiciansIds = this.props.context.submixDraft.musicians.map(m => m.id)

    if (inputValue.length > 2) {
      setTimeout(() => {
        this.phonogramApi.getInstrumentists(inputValue).then(
          data => {
            let filtered = []
            if (data !== undefined) {
              filtered = data
                .filter(d => !musiciansIds.includes(d.id))
                .map(d => ({
                  value: String(d.id),
                  label: toCapitalize(d.stageName + ' - ' + d.fullName),
                  id: d.id,
                  stageName: d.stageName,
                  fullName: d.fullName,
                  favoriteMusicalInstrument: d.favoriteMusicalInstrument,
                  musicalInstruments: d.musicalInstruments,
                  type: d.type,
                }))
            }
            callback(filtered)
          },
          () => {
            callback([])
          }
        )
      }, 500)
    } else callback([])
  }

  handleError = error => {
    console.error('error: ', error)
    this.setState({ statusResponse: 'error', submitting: false, autoSubmitting: false })
  }

  render() {
    const { classes } = this.props
    const { submixDraft } = this.props.context
    const musicians = submixDraft && submixDraft.musicians ? submixDraft.musicians : []
    let rows = musicians.map(
      function (musician, i) {
        return (
          <MusicianRow
            key={i}
            musician={musician}
            context={this.props.context}
            changeMode={this.props.changeMode}
            handleInstrumentsModal={this.handleInstrumentsModal}
            handleRemoveMusicianClick={this.handleRemoveMusicianClick}
            showChangeInstruments
            showUpdateMusician
          />
        )
      }.bind(this)
    )

    return (
      <Grid className={classes.container}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Escolher Músicos</h4>
            <p className={classes.cardCategoryWhite}>Preencha os campos abaixo</p>
          </CardHeader>
          <CardBody>
            <Grid container>
              <GridItem xs={12} sm={12} md={12}>
                <Button
                  size="sm"
                  style={{ float: 'right', top: '15px' }}
                  variant="outlined"
                  color="primary"
                  onClick={this.handleCreateMusicianClick}
                >
                  Cadastrar Músico
                </Button>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <FormControl fullWidth style={{ margin: '30px 0 0 0' }}>
                  <FormLabel>Músico</FormLabel>
                  <AsyncSelect
                    id="musician"
                    name="musician"
                    placeholder="Selecione o músico"
                    loadOptions={this.loadMusicianOptions}
                    onChange={this.handleMusicianSelect}
                    loadingMessage={() => 'Carregando...'}
                    noOptionsMessage={() => 'Nenhum item encontrado.'}
                    isClearable
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                {!rows.length ? (
                  <div />
                ) : (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Nome</TableCell>
                        <TableCell>Instrumento</TableCell>
                        <TableCell />
                        <TableCell>
                          {this.state.autoSubmitting ? (
                            <span>
                              <CircularProgress size={24} className={classes.buttonProgress} /> Salvando
                            </span>
                          ) : null}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>{rows}</TableBody>
                  </Table>
                )}
              </GridItem>
            </Grid>
          </CardBody>
          <CardFooter>
            <Button
              type="button"
              size="sm"
              value="Cancelar"
              onClick={this.state.isIngestOrEdit ? this.handleClose : this.handleBackClick}
              disabled={this.state.submitting}
              color="transparent"
            >
              {this.state.isIngestOrEdit ? 'Cancelar' : 'Continuar depois'}
            </Button>
            {this.state.submitting ? (
              <CircularProgress size={24} className={classes.buttonProgress} />
            ) : (
              <Button
                size="sm"
                color="primary"
                variant="outlined"
                onClick={this.state.isIngestOrEdit ? this.handleAddMusicians : this.handleClose}
                disabled={!this.state.isValid}
              >
                {this.state.isIngestOrEdit ? 'OK' : 'Enviar'}
              </Button>
            )}
          </CardFooter>
        </Card>
        <div>
          <Snackbar
            place={'bc'}
            message={'Erro ao salvar dados do fonograma.'}
            open={this.state.statusResponse === 'error'}
            close
            color={'danger'}
            closeNotification={this.handleSnackClose}
          />
          <Snackbar
            place={'bc'}
            message={'Fonograma alterado com sucesso.'}
            open={this.state.statusResponse === 'success'}
            close
            color={'primary'}
            closeNotification={this.handleSnackClose}
          />
          {this.state.openInstruments ? (
            <InstrumentsModal
              open={this.state.openInstruments}
              handleInstrumentsModal={this.handleInstrumentsModal}
              checkedItems={this.state.instrumentIdsCheckedItems}
              defaultItems={this.state.instrumentDefaultItems}
              handleChangeInstruments={this.handleChangeInstruments}
            />
          ) : null}
          {this.state.isIngestOrEdit && this.state.openConfirm ? (
            <ConfirmModal
              open={this.state.openConfirm}
              automacao="btnEscolherMusico"
              title={'Escolher Músicos'}
              text={'Deseja realmente cancelar a operação?'}
              handleClose={this.handleClose}
              handleConfirm={this.handleBackClick}
            />
          ) : null}
          {!this.state.isIngestOrEdit && this.state.openConfirm ? (
            <ConfirmModal
              open={this.state.openConfirm}
              automacao="btnResolverPendencia"
              title={'Resolver pendência'}
              text={
                'Informações salvas. Deseja mesmo enviar para a SOM Livre? Você não poderá mais realizar alterações no fonograma.'
              }
              handleClose={this.handleClose}
              handleConfirm={this.handleSend}
            />
          ) : null}
        </div>
      </Grid>
    )
  }
}

export default withStyles(styles)(MusicianSelectList)
