import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
// import combineStyles from "../../shared/helpers/combine-styles";

//api
import PhonogramApi from '../../../services/phonogram-api'

//@material-ui
import withStyles from '@material-ui/core/styles/withStyles'
import Checkbox from '@material-ui/core/Checkbox'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import LinearProgress from '@material-ui/core/LinearProgress'
import Reload from '@material-ui/icons/Refresh'
import Clear from '@material-ui/icons/Clear'
import Done from '@material-ui/icons/Done'
import Undo from '@material-ui/icons/Undo'
import Check from '@material-ui/icons/Check'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

//custom components
import { MusicNote } from '../../../components/svg-icons/svg-icons-lib'
import GridItem from '../../../components/grid/grid-item'
import fileUploadStyle from '../../../assets/jss/views/ingest/fileUploadStyle'
import CustomInput from '../../../components/custom-input/custom-input'
import Snackbar from '../../../components/snackbar/snackbar'
import MaskedInput from 'react-text-mask'
import FileUploadAlert from './file-upload-alert'

function TextMaskCustom(props) {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={[
        /[a-zA-Z]/,
        /[a-zA-Z0-9]/,
        '-',
        /[a-zA-Z0-9]/,
        /[a-zA-Z0-9]/,
        /[a-zA-Z0-9]/,
        '-',
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
    />
  )
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
}

class FileUpload extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      statusResponse: false,
      isFullmix: false,
      fullmixReadOnly: false,
      completed: 0,
      preDeletePhono: false,
      submixIsValid: props.file.submixIsValid !== undefined ? props.file.submixIsValid : true,
      isrcIsValid: props.file.isrcIsValid !== undefined ? props.file.isrcIsValid : true,
    }
    this.ref = React.createRef()
    this.phonogramApi = new PhonogramApi()
    this.handleError = this.handleError.bind(this)
    this.handleSuccess = this.handleSuccess.bind(this)
    this.handleSnackClose = this.handleSnackClose.bind(this)
    this.handleProgress = this.handleProgress.bind(this)
    this.handleCheckIsFullmix = this.handleCheckIsFullmix.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleRemoveToggle = this.handleRemoveToggle.bind(this)
  }

  componentDidMount = () => {
    this._mounted = true

    const isFullmix = this.props.file.submix === 'Full Mix'

    if (this.props.file.tempFileName) {
      this.setState({
        statusResponse: 'success',
        isFullmix: isFullmix,
        fullmixReadOnly: isFullmix,
        completed: 100,
        isValid: this.props.file.submix.length > 0,
      })
    } else {
      this.setState({
        isFullmix: isFullmix,
        fullmixReadOnly: isFullmix,
        isValid: false,
      })
      this.uploadFile()
    }
    this.scrollTo()
  }

  componentWillUnmount = () => {
    //TODO fazer com que cancele qualquer requisição
    //após o desmonte do componente
    this._mounted = false
  }

  componentDidUpdate(prevProps) {
    if (this.props.submitCount !== prevProps.submitCount) {
      this.setState({
        isrcIsValid: this.props.file.isrcIsValid,
        submixIsValid: this.props.file.submixIsValid,
      })
    }
  }

  uploadFile = () => {
    this.setState({ statusResponse: false }, () => {
      this.phonogramApi
        .getUploadPhonogramUrl(this.props.file.name)
        .then(data => {
          this.props.file.tempFileName = data.tempFileName
          this.phonogramApi
            .putFileUpload(data.url, this.props.file, this.handleProgress)
            .then(this.handleSuccess)
            .catch(this.handleError)
        })

        .catch(this.handleError)
    })
  }

  scrollTo = () => {
    if (this.ref && this.ref.current) {
      ReactDOM.findDOMNode(this.ref.current).scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }

  getFileSendStatus = () => {
    let tempFileName = this.props.file.tempFileName

    return !tempFileName && this.state.statusResponse === false && this.state.statusResponse !== 'error'
      ? 'Aguarde...'
      : this.state.statusResponse === 'success'
      ? 'Arquivo enviado com sucesso.'
      : this.state.statusResponse === 'error' || this.state.statusResponse === ''
      ? `Erro ao enviar o arquivo`
      : `Enviando o arquivo...`
  }

  handleSuccess = response => {
    console.log('response: ', response)

    const uploadCompleted = this.state.completed === 100

    this.props.file.uploadCompleted = uploadCompleted

    this.setState({ statusResponse: 'success' }, this.props.handleFileUploadChange(this.props.file))
  }

  handleError = error => {
    console.error('error: ', error)
    this.props.file.uploadCompleted = false

    this.setState({ statusResponse: 'error' }, this.props.handleFileUploadChange(this.props.file))
  }

  handleSnackClose = () => {
    if (this.state.statusResponse === 'error') this.setState({ statusResponse: '' })
  }

  handleProgress = event => {
    let total = event.total
    let completed = event.loaded

    if (completed <= total) {
      let percent = Math.round((completed * 100) / total)
      this.setState({ completed: percent })
    }
  }

  handleCheckIsFullmix = () => {
    let isFullmix = !this.state.isFullmix
    let uploadCompleted = this.state.completed === 100
    let submixDescription = isFullmix ? 'Full Mix' : ''

    this.props.file.submix = submixDescription
    this.props.file.uploadCompleted = uploadCompleted

    this.setState(
      {
        isFullmix: isFullmix,
        fullmixReadOnly: isFullmix,
        submixIsValid: isFullmix,
      },
      this.props.handleFileUploadChange(this.props.file)
    )
  }

  handleChange = event => {
    let name = event.target.name
    let value = event.target.value
    let submixIsValid = this.state.submixIsValid
    let isrcIsValid = this.state.isrcIsValid
    let uploadCompleted = this.state.completed === 100

    switch (name) {
      case 'submix':
        submixIsValid = value.length > 0
        this.props.file.submix = value
        break
      case 'isrc':
        isrcIsValid = !!value.toUpperCase().match(/[A-Z]{2}-[A-Z\d]{3}-\d{2}-\d{5}|[A-Z]{2}[A-Z\d]{3}\d{2}\d{5}/)
        this.props.file.isrc = value.toUpperCase()
        break
      default:
        break
    }
    this.props.file.uploadCompleted = uploadCompleted

    this.setState(
      {
        submixIsValid: submixIsValid,
        isrcIsValid: isrcIsValid,
      },
      this.props.handleFileUploadChange(this.props.file)
    )
  }

  handleRemoveToggle = () => {
    this.setState({ preDeletePhono: !this.state.preDeletePhono })
  }

  statusFileUpload() {
    const { classes } = this.props

    return (
      <Grid container className={'progress-info'}>
        <Grid item xs={8} sm={8} md={8} className={classes.itemLeft}>
          <span className="filename-info">
            <b>{this.props.file.name}</b> -&nbsp;
            {this.state.statusResponse === 'error' ? 'Hummmm ocorreu um erro' : 'Carregando...'}
          </span>
        </Grid>
        <Grid item xs={4} sm={4} md={4} className={classes.itemLeft}>
          <div className={classes.boxControls}>
            {this.state.statusResponse === 'error' || this.state.statusResponse === '' ? (
              <ul>
                <li>
                  <Tooltip
                    id="tooltip-reload"
                    title="Enviar Novamente"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <IconButton
                      id="buttonReload"
                      aria-label="Reload"
                      className={classes.actionButtonError}
                      onClick={() => this.uploadFile()}
                    >
                      <Reload className={classes.controlIconError} />
                    </IconButton>
                  </Tooltip>
                </li>
                <li>
                  <Tooltip
                    id="tooltip-remove"
                    title="Remover Arquivo"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <IconButton
                      id="buttonRemoveFileError"
                      aria-label="Remover"
                      className={classes.actionButtonError}
                      onClick={() => this.props.handleRemoveFile(this.props.file)}
                    >
                      <Clear className={classes.controlIconError} />
                    </IconButton>
                  </Tooltip>
                </li>
              </ul>
            ) : null}
          </div>
        </Grid>
      </Grid>
    )
  }

  isPhonogramComercial() {
    return this.props.category === 'Comercial'
  }

  render() {
    const { classes } = this.props
    const corrupted = this.state.completed === 100 && !!this.props.file.size && this.props.file.size <= 1024

    return (
      <Fragment>
        {corrupted && <FileUploadAlert />}

        {this.state.completed === 100 ? (
          <Fragment>
            <Card className={corrupted ? classes.corruptedCard : classes.card}>
              <CardContent className={classes.cover}>
                <MusicNote width={47} height={46} className={classes.coverImg} />
              </CardContent>
              <Grid container>
                <Grid container item xs={5} sm={5} md={5} className={classes.boxName} direction="column">
                  <GridItem default={true} className={classes.itemCenterBottom}>
                    <FormLabel
                      style={{
                        fontSize: '15px',
                        color: '#000',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: '280px',
                      }}
                      component="legend"
                    >
                      {this.props.file.originalFileName}
                    </FormLabel>
                  </GridItem>
                  <GridItem default={true} className={classes.itemCenter}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.isFullmix}
                          automacao="checkFullMix"
                          onClick={this.handleCheckIsFullmix}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                          }}
                        />
                      }
                      label="Full Mix"
                    />
                  </GridItem>
                </Grid>

                <Grid
                  container
                  xs={7}
                  sm={7}
                  md={7}
                  item
                  className={
                    !this.isPhonogramComercial() ? `${classes.boxInput} ${classes.boxInputFullWidth}` : classes.boxInput
                  }
                >
                  <CustomInput
                    labelText="Nome do Submix"
                    automacao="inputNomeSubmix"
                    id="submix"
                    inputProps={{
                      name: 'submix',
                      placeholder: '',
                      maxLength: 100,
                      onChange: this.handleChange,
                      readOnly: this.state.fullmixReadOnly,
                      value: this.props.file.submix,
                      error: this.state.submixIsValid === false,
                    }}
                    formControlProps={{}}
                  />

                  {this.isPhonogramComercial() ? (
                    //exemplo: BR-RGE-18-00001
                    <CustomInput
                      labelText="ISRC"
                      automacao="inputIsrcValue"
                      id="isrc"
                      labelProps={this.props.file.isrc ? { shrink: true } : {}}
                      inputProps={{
                        name: 'isrc',
                        placeholder: '',
                        maxLength: 15,
                        disabled: this.props.file.isrcDisabled,
                        onChange: this.handleChange,
                        value: this.props.file.isrc,
                        error: this.state.isrcIsValid === false,
                        inputComponent: TextMaskCustom,
                        className: this.props.file.isrcDisabled ? classes.disabledIsrc : '',
                      }}
                      formControlProps={{}}
                    />
                  ) : null}

                  <div className={classes.boxControls}>
                    {this.state.preDeletePhono === false && (
                      <Tooltip
                        id="tooltip-remove"
                        title="Remover Arquivo"
                        placement="top"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <IconButton
                          id="buttonRemove"
                          automacao="btnRemoverArquivo"
                          aria-label="Remover"
                          className={classes.actionButton}
                          onClick={() => this.handleRemoveToggle()}
                        >
                          <Clear className={classes.controlIcon} />
                        </IconButton>
                      </Tooltip>
                    )}

                    {this.state.preDeletePhono === true && (
                      <ul>
                        <li>
                          <Tooltip
                            id="tooltip-continuar"
                            title="Continuar usando arquivo"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <IconButton
                              id="buttonCancel"
                              automacao="btnContinuarUsandoArquivo"
                              aria-label="Voltar"
                              className={classes.actionButton}
                              onClick={() => this.handleRemoveToggle()}
                            >
                              <Undo className={classes.controlIcon} />
                            </IconButton>
                          </Tooltip>
                        </li>
                        <li>
                          <Tooltip
                            id="tooltip-remover"
                            title="Remover Definitivamente"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <IconButton
                              automacao="btnRemoverArquivoDefinitivamente"
                              id="buttonRemoveDefinitely"
                              aria-label="Remover Definitivamente"
                              className={classes.actionButton}
                              onClick={() => {
                                this.handleRemoveToggle()
                                this.props.handleRemoveFile(this.props.file)
                              }}
                            >
                              <Done className={classes.controlIcon} />
                            </IconButton>
                          </Tooltip>
                        </li>
                      </ul>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Card>
            <Snackbar
              place="bc"
              message={'Erro ao enviar arquivo ' + this.props.file.name}
              open={this.state.statusResponse === 'error'}
              close
              color="danger"
              closeNotification={this.handleSnackClose}
            />
          </Fragment>
        ) : (
          <div className={classes.progressContainer}>
            <LinearProgress
              classes={{
                colorPrimary:
                  this.state.statusResponse === 'error' ? classes.linearColorError : classes.linearColorPrimary,
                barColorPrimary:
                  this.state.statusResponse === 'error' ? classes.linearColorError : classes.linearBarColorPrimary,
              }}
              variant="determinate"
              className={'progress'}
              value={this.state.completed}
            />
            {this.statusFileUpload()}
          </div>
        )}
        <div ref={this.ref} />
      </Fragment>
    )
  }
}

// const combinedStyles = combineStyles(fileUploadStyle, styles);

export default withStyles(fileUploadStyle)(FileUpload)
