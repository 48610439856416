import ingestStyle from './ingestStyle'

import Attention from '../../../img/svg/attention.svg'
import NotAllowed from '../../../img/svg/not-allowed.svg'

const styles = {
  ...ingestStyle,
  cardInfos: {
    backgroundColor: '#fff',
    paddingBottom: '50px',
    boxShadow:
      '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
    marginTop: '30px',
  },
  infoTitle: {
    fontFamily: 'Roboto',
    fontSize: '21px',
    color: '#000',
  },
  inputTags: {
    '& input': {
      width: '100%',
      whiteSpace: 'nowrap',
      textOverflow: 'text-ellipsis',
    },
  },

  titleSubsection: {
    height: '20px',
    fontFamily: 'Roboto',
    fontSize: '15px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#666666',
    margin: '0 0 12px',

    '& span': {
      color: '#666666',
      fontSize: '12px',
      fontStyle: 'normal',
      fontFamily: 'Roboto',
      fontWeight: 'normal',
      fontStretch: 'normal',
      letterSpacing: 'normal',
      height: '20px',
      display: 'inline-block',
      lineHeight: '20px',
      verticalAlign: 'middle',
      margin: '0 0 0 5px',
    },

    '& .error': {
      background: `url(${Attention}) no-repeat 0 0`,
      color: '#f44335',
      padding: '0 0 0 26px',
      margin: '0 0 0 14px',
    },
  },

  titleSecondSubsection: {
    height: '20px',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#666666',

    '& span': {
      color: '#666666',
      fontSize: '12px',
      fontStyle: 'normal',
      fontFamily: 'Roboto',
      fontWeight: 'normal',
      fontStretch: 'normal',
      letterSpacing: 'normal',
      height: '20px',
      display: 'inline-block',
      lineHeight: '20px',
      verticalAlign: 'middle',
    },

    '& .error': {
      background: `url(${Attention}) no-repeat 0 0`,
      color: '#f44335',
    },
  },

  pnFileUp: {
    display: 'flex !important',
    justifyContent: 'center !important',
    alignItems: 'center !important',
    fontFamily: 'Roboto',
    color: '#999999',
    height: '120px',

    '& .pn-fileup-icon': {
      textAlign: 'right',
    },

    '& .pn-fileup-btn': {
      paddingLeft: '15px',
    },

    '& .drag-files span': {
      fontSize: '23px',
      fontWeight: '500',
      display: 'block',
      textAlign: 'center',
    },

    '& .pn-fileup .ou span': {
      fontSize: '15px',
      fontWeight: '500',
      display: 'block',
      textAlign: 'center',
      height: '15px',
    },
  },

  pnFileUpFormat: {
    textAlign: 'right',
    fontFamily: 'Roboto',

    '& span': {
      display: 'block',
      fontSize: '12px',
      lineHeight: '0',
      marginRight: '20px',
      color: '#666',
    },
  },

  infoCmdList: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    listStyle: 'none',
    height: '100%',
    padding: '0',
    margin: '0',

    '& li': {
      float: 'right',
      minHeight: '100%',
      display: 'flex',
      alignItems: 'center',
      margin: '0 5px',
    },

    '& li a': {
      color: '#4a90e2 !important',
      cursor: 'pointer',
    },
  },

  formContainer: {
    marginTop: '30px !important',

    '& .form-control-row': {
      marginTop: '12px !important',
    },

    '.form-label': {
      marginBottom: '10px !important',
    },
  },

  btnAddRectangle: {
    width: '38px !important',
    height: '38px !important',
    backgroundColor: '#999 !important',
    borderRadius: '4px !important',
  },

  btnCloseAuto: {
    marginTop: '0 !important',
    marginBottom: '11.5px !important',
    height: 'auto !important',
    width: 'auto !important',
    marginRight: '15px !important',
  },

  gridContainer: {
    maxWidth: '845px',
    margin: '0 auto',
    width: '100%',
  },

  unreleasedContainer: {
    padding: '20px 5px 0px 5px',
  },

  unreleasedMessage: {
    fontSize: '12px',
    marginLeft: '12px',
    marginTop: '0',
    color: '#999',
  },

  disabled: {
    cursor: `url(${NotAllowed}), auto`,

    '& #buttonIndicator': {
      display: 'none',
    },
  },
}

export default styles
