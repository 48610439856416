import styled from 'styled-components'

const Main = styled.main`
  width: 100%;
  margin: auto;
  overflow: auto;
  position: relative;
  -webkit-overflow-scrolling: touch;
  transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
  min-height: calc(100vh - 101px);
  max-width: 1366px;
`

const Content = styled.section`
  padding-bottom: 30px;
`

export { Main, Content }
