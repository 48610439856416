import ingestStyle from './ingestStyle'
const productStepStyle = () => ({
  ...ingestStyle,
  wrapperContainer: {
    margin: '0 30px',
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },

  productList: {
    width: '100%',
    maxWidth: '360px',
  },

  gridItemList: {
    width: '100%',
    maxWidth: '360px',
    padding: 0,
  },

  musicTitle: {
    marginBottom: '26px',
  },

  hasProductRadio: {
    margin: '0 51px 0 0',
    '& span:first-child': {
      width: '20px',
    },
  },

  hasProductLabel: {
    margin: '2px 0 0',
    fontSize: '14px',
  },

  center: {
    maxWidth: '845px',
    margin: '0 auto',
    padding: 0,
  },
})

export default productStepStyle
