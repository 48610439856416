import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '../../assets/img/svg/arrow-right-size-7.svg'
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'

import listWithSubItemsAccordionStyle from '../../assets/jss/components/listWithSubItemsAccordionStyle.jsx'

const ExpansionPanelSummary = withStyles({
  root: {
    '&$expanded': {
      background: '#fafafa !important',
      minHeight: '29px !important',
    },
  },
  content: {
    '&$expanded': {
      margin: '0',
      '& p': {
        color: '#76458f !important',
      },
    },
  },
  expandIcon: {
    '&$expanded': {
      right: '8px',
      width: '8px',
      transform: 'rotate(180deg)',
      top: '0',
    },
  },
  expanded: {},
})(props => <MuiExpansionPanelSummary {...props} />)

ExpansionPanelSummary.muiName = 'ExpansionPanelSummary'

class ListWithSubItemsAccordion extends React.Component {
  constructor(props) {
    super()
    this.state = {
      items: this.generateItems(props.list),
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.list !== this.props.list && this.state.items.length === 0) {
      this.setState({
        items: this.generateItems(this.props.list),
      })
    }
  }

  generateItems = list =>
    list.map((data, key) => ({
      index: key,
      expanded: false,
    }))
  onChange = (event, expanded, index) => {
    const items = this.state.items.map((item, key) => ({
      ...item,
      expanded: key === index ? expanded : false,
    }))

    this.setState({ items }, () => {
      if (this.props.onChange) {
        this.props.onChange()
      }
    })
  }

  createAccordion = list => {
    const {
      ExpansionPanelSummaryStyle,
      ExpansionPanelSummaryContentStyle,
      ExpansionPanelSummaryIconStyle,
      ExpansionPanelStyle,
      ExpansionPanelDetailsStyle,
    } = this.props.classes

    return list && list.length > 0 ? (
      <div>
        {list.map((data, key) => (
          <div key={key}>
            <ExpansionPanel
              id={`ExpansionPanel${key}`}
              classes={{
                root: ExpansionPanelStyle,
              }}
              expanded={this.state.items && this.state.items.length > 0 ? this.state.items[key].expanded : false}
              onChange={(event, expanded) => this.onChange(event, expanded, key)}
            >
              <ExpansionPanelSummary
                classes={{
                  root: ExpansionPanelSummaryStyle,
                  content: ExpansionPanelSummaryContentStyle,
                  expandIcon: ExpansionPanelSummaryIconStyle,
                }}
                className="ExpansionPanelSummary"
                expandIcon={<img className="ExpandMoreIcon" src={ExpandMoreIcon} alt="" />}
              >
                <Typography>{data.groupname}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails
                classes={{
                  root: ExpansionPanelDetailsStyle,
                }}
              >
                <div className="accordion-item-wrapper">
                  {data.items.map((item, keyItems) => (
                    <React.Fragment key={keyItems}>{this.props.component(item)}</React.Fragment>
                  ))}
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        ))}
      </div>
    ) : null
  }

  render() {
    const { list } = this.props
    const { gridStyle } = this.props.classes
    return <Grid className={gridStyle}>{this.createAccordion(list)}</Grid>
  }
}

export default withStyles(listWithSubItemsAccordionStyle)(ListWithSubItemsAccordion)
