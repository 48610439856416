import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
// @material-ui/icons
// import Clear from "@material-ui/icons/Clear";
import Check from '@material-ui/icons/Check'
// core components
import customInputStyle from '../../assets/jss/components/customInputStyle'

function CustomInput({ ...props }) {
  const { classes, formControlProps, labelText, id, labelProps, inputProps, error, success, inputRef } = props

  const labelClasses = classNames({
    [' ' + classes.labelRootError]: error,
    [' ' + classes.labelRootSuccess]: success && !error,
  })

  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
  })

  const marginTop = classNames({
    [classes.marginTop]: labelText === undefined,
  })

  return (
    <FormControl {...formControlProps} className={formControlProps.className + ' ' + classes.formControl}>
      {labelText ? (
        <InputLabel className={classes.labelRoot + labelClasses} htmlFor={id} {...labelProps}>
          {labelText}
        </InputLabel>
      ) : null}
      <Input
        inputRef={inputRef}
        classes={{
          root: labelText ? marginTop : '',
          disabled: classes.disabled,
          underline: underlineClasses,
        }}
        id={id}
        {...inputProps}
      />
      {error ? null : success ? ( // <Clear className={classes.feedback + " " + classes.labelRootError} />
        <Check className={classes.feedback + ' ' + classes.labelRootSuccess} />
      ) : null}
    </FormControl>
  )
}

CustomInput.propTypes = {
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool,
}

export default withStyles(customInputStyle)(CustomInput)
