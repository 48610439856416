import styled from 'styled-components'
import { NavLink as NavLinkRD } from 'react-router-dom'

const NavLink = styled(NavLinkRD)`
  padding: 0 16px;
  font-size: 13px;
  font-weight: 400;
  text-decoration: none;
  letter-spacing: initial;

  color: ${({ $active, $dark }) => {
    if ($active) return '#fff'
    if ($dark) return '#000'
    return '#ccc'
  }};

  &:hover {
    color: ${({ $dark }) => ($dark ? '#000' : '#fff')};
  }
`

export { NavLink }
