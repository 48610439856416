import React, { Component, Fragment } from 'react'
import classNames from 'classnames'
import creditsFormStyle from '../../../assets/jss/views/ingest/creditsFormStyle'
import { toCapitalize } from '../../../shared/helpers/text-decorator'
import _ from 'lodash'

//@material-ui
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import DoneIcon from '@material-ui/icons/Done'
import InfoOutlineIcon from '@material-ui/icons/InfoOutlined'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import LibraryAdd from '@material-ui/icons/LibraryAdd'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'
import Tooltip from '@material-ui/core/Tooltip'

//custom components
import Button from '../../../components/custom-buttons/button'
import GridItem from '../../../components/grid/grid-item'
import Snackbar from '../../../components/snackbar/snackbar'
import RedirectModal from '../redirect-modal'
import Steps from '../../../components/steps'

//modal
import CreditsModal from './credits-modal'
import PhonogramModal from '../phonogram/phonogram-modal'

import WorkSelectedInfo from '../work-selected-info'

class CreditsForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      submitting: false,
      statusResponse: false,
      snackMessage: 'Nenhum músico encontrado',
      snackColor: 'primary',
      menuOpen: null,
      anchorEl: null, //Onde vai abrir o menu
      openCredits: false,
      openPhonogram: false,
      openRedirect: false,
      idsChecked: [],
      musiciansToCredits: [],
    }
    this.handleError = this.handleError.bind(this)
    this.handleSnackClose = this.handleSnackClose.bind(this)
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
    this.handleMenuClose = this.handleMenuClose.bind(this)
    this.handleAddSubmix = this.handleAddSubmix.bind(this)
    this.handleCreditsModal = this.handleCreditsModal.bind(this)
    this.handlePhonogramModal = this.handlePhonogramModal.bind(this)
    this.handleRedirectModal = this.handleRedirectModal.bind(this)
  }

  componentDidMount = () => {
    this._mounted = true
  }

  componentWillUnmount = () => {
    //TODO fazer com que cancele qualquer requisição
    //após o desmonte do componente
    this._mounted = false
  }

  componentDidCatch = (error, errorInfo) => {
    // Catch errors in any components below and re-render with error message
    this.props.context.changeState(
      {
        error: error,
        errorInfo: errorInfo,
      },
      () => {
        this.props.changeMode('showError')
      }
    )
    // You can also log error messages to an error reporting service here
  }

  handleError = error => {
    console.error('error: ', error)

    if (error.response.status === 404)
      this.setState({
        statusResponse: 'not-found',
        submitting: false,
        snackMessage: 'Nenhum músico encontrada',
        snackColor: 'primary',
      })
    else
      this.setState({
        statusResponse: 'error',
        submitting: false,
        snackMessage: 'Erro ao pesquisar músico',
        snackColor: 'danger',
      })
  }

  handleSnackClose = () => {
    if (this.state.statusResponse === 'error' || this.state.statusResponse === 'not-found') {
      this.setState({ statusResponse: false })
    }
  }

  handleBackClick = () => {
    this.props.changeMode('addPhonogram')
  }

  handleCheckboxChange = idx => () => {
    const { idsChecked } = this.state

    let newIdsChecked = [...idsChecked]
    const currentIndex = idsChecked.indexOf(idx)

    if (currentIndex === -1) {
      if (!this.isItemCheckedValid(idx)) newIdsChecked = [...idsChecked.filter(i => !this.isItemCheckedValid(i))]
      else newIdsChecked = []

      newIdsChecked.push(idx)
    } else newIdsChecked.splice(currentIndex, 1)

    this.setState({ idsChecked: newIdsChecked })
  }

  handleMenuClick(event, value) {
    this.setState({
      anchorEl: event.currentTarget,
      menuOpen: value,
    })
  }

  isItemCheckedValid = idx =>
    !Array.isArray(this.props.context.submixes[idx].musicians)
      ? false
      : !!this.props.context.submixes[idx].musicians.length

  handleMenuClose = () => {
    this.setState({
      menuOpen: null,
      anchorEl: null,
    })
  }

  handleAddSubmix = event => {
    this.props.changeMode('addPhonogram')
    event.preventDefault()
  }

  handleEditSubmix(idx, event) {
    const performerOptionsSelected = this.props.context.submixes[idx].performers
      .filter(p => p.recorder === null)
      .map(performer => ({
        value: String(performer.id),
        label: toCapitalize(performer.stageName + ' - ' + performer.fullName),
        id: performer.id,
        stageName: performer.stageName,
        fullName: performer.fullName,
      }))
    const newSubmixDraft = {
      performerOptionsSelected: performerOptionsSelected,
      year: this.props.context.submixes[idx].year,
      bpm: this.props.context.submixes[idx].bpm,
      moods: this.props.context.submixes[idx].moods,
      genres: this.props.context.submixes[idx].genres,
      tags: this.props.context.submixes[idx].tags,
      acceptedFiles: [this.props.context.submixes[idx].file],
    }

    const newMusicians = this.props.context.submixes[idx].musicians

    this.props.context.changeState(
      {
        musicians: newMusicians,
        submixDraft: newSubmixDraft,
        submixIdx: idx,
      },
      () => {
        this.handlePhonogramModal()
      }
    )
    event.preventDefault()
  }

  handleCopySubmix(idx, event) {
    const performerOptionsSelected = this.props.context.submixes[idx].performers
      .filter(p => p.recorder === null)
      .map(performer => ({
        value: String(performer.id),
        label: toCapitalize(performer.stageName + ' - ' + performer.fullName),
        id: performer.id,
        stageName: performer.stageName,
        fullName: performer.fullName,
      }))

    const newSubmixDraft = {
      performerOptionsSelected: performerOptionsSelected,
      year: this.props.context.submixes[idx].year,
      bpm: this.props.context.submixes[idx].bpm,
      moods: this.props.context.submixes[idx].moods,
      genres: this.props.context.submixes[idx].genres,
      tags: this.props.context.submixes[idx].tags,
      musicians: _.cloneDeep(this.props.context.submixes[idx].musicians),
      credits: _.cloneDeep(this.props.context.submixes[idx].credits),
      acceptedFiles: [],
    }
    const newMusicians = this.props.context.submixes[idx].musicians

    this.props.context.changeState(
      {
        musicians: newMusicians,
        submixDraft: newSubmixDraft,
        submixIdx: null,
      },
      () => {
        this.props.changeMode('addPhonogram')
      }
    )
    event.preventDefault()
  }

  handleRemoveSubmix(idx, event) {
    let newSubmixes = this.props.context.submixes.filter((s, cidx) => idx !== cidx)

    this.props.context.changeState({ submixes: newSubmixes }, () => {
      this.handleMenuClose()
    })
    event.preventDefault()
  }

  handleChangeCredits = credits => {
    let musicians = this.parseCreditsToMusicians(credits)
    let submixes = this.props.context.submixes

    this.state.idsChecked.forEach(i => {
      submixes[i].musicians = musicians
      submixes[i].credits = _.cloneDeep(credits)
    })

    this.props.context.changeState({ submixes: submixes }, () => {
      this.setState({ idsChecked: [] })
    })
  }

  parseCreditsToMusicians = credits => {
    let musicians = credits.map(item => {
      if (item.musician) {
        return item.musician
      } else if (item.group) {
        return item.group.musicians
      } else return null
    })

    return _.uniqBy(_.compact(_.flatten(musicians)), 'id')
  }

  handleCreditsModal = () => {
    this.setState({ openCredits: !this.state.openCredits })
  }

  handlePhonogramModal = () => {
    this.setState({
      openPhonogram: !this.state.openPhonogram,
      menuOpen: null,
      anchorEl: null,
    })
  }

  handleRedirectModal = event => {
    this.setState({ openRedirect: !this.state.openRedirect })
    event.preventDefault()
  }

  nextRouter = () => {
    const { changeMode, context } = this.props

    if (context.ingestFromRequest || context.musicSelected.category.toUpperCase() === 'MUSICA COMERCIAL') {
      changeMode('confirmIngest')
    } else {
      changeMode('addProductArea')
    }
  }

  render() {
    const { menuOpen, anchorEl } = this.state
    const { classes, context } = this.props
    const activeStep = context.ingestFromRequest ? 1 : 2

    return (
      <Fragment>
        <Grid className={classes.root}>
          <Steps steps={this.props.context.steps} activeStep={activeStep} />

          <WorkSelectedInfo context={this.props.context} />

          <GridItem container className={classes.ingestBody} style={{ marginTop: '55px' }}>
            <Grid item container className={classes.titleBar}>
              <GridItem xs={12} sm={12} md={12} className={classes.itemMiddleRight}>
                <Button
                  size="sm"
                  variant="outlined"
                  color="primary"
                  onClick={this.handleCreditsModal}
                  disabled={!this.state.idsChecked.length}
                  style={{ margin: 0 }}
                >
                  Adicionar Ficha
                </Button>
              </GridItem>
            </Grid>

            <GridItem
              xs={12}
              sm={12}
              md={12}
              className={classNames({ [classes.listPhonograms]: true, [classes.whiteCard]: true })}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      padding="checkbox"
                      className={classNames({ [classes.alignLeft]: true, [classes.cellWidhtCheckbox]: true })}
                    ></TableCell>
                    <TableCell padding="none">Fonograma</TableCell>
                    <TableCell className={classes.alignRight}>
                      <IconButton onClick={this.handleAddSubmix}>
                        <Tooltip title="Adicionar fonogramas" placement="right">
                          <LibraryAdd />
                        </Tooltip>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.context.submixes.map((item, idx) => (
                    <TableRow id={`tableRow_submix${idx}`} automacao={`tableRow_submix${idx}`} key={idx}>
                      <TableCell
                        padding="checkbox"
                        className={classNames({ [classes.alignLeft]: true, [classes.cellWidhtCheckbox]: true })}
                      >
                        <Checkbox
                          id={`submix_${idx}`}
                          name={`submix_${idx}`}
                          checked={!!this.state.idsChecked.filter(i => i === idx).length}
                          onChange={this.handleCheckboxChange(idx)}
                          color="primary"
                        />
                      </TableCell>
                      <TableCell
                        id={`tablecell_submix${idx}`}
                        automacao={`tablecell_submix${idx}`}
                        component="th"
                        scope="row"
                        padding="none"
                      >
                        {item.submix}
                        <br />
                        <small>{item.performers.map(p => toCapitalize(p.stageName)).join(', ')}</small>
                      </TableCell>
                      <TableCell className={classes.alignRight}>
                        <IconButton onClick={() => {}}>
                          {item.musicians.length > 0 ? (
                            <DoneIcon style={{ color: 'green' }} />
                          ) : (
                            <Tooltip
                              // eslint-disable-next-line max-len
                              title="Este fonograma não possui ficha técnica ainda. Se preferir, é possível avançar, realizar o ingest e adicionar ficha depois em Pendências."
                              placement="left"
                            >
                              <InfoOutlineIcon style={{ color: 'orange' }} />
                            </Tooltip>
                          )}
                        </IconButton>
                        <IconButton
                          aria-label="More"
                          aria-owns={menuOpen === `menuOpen${idx}` ? 'submix-menu' : undefined}
                          aria-haspopup="true"
                          onClick={event => this.handleMenuClick(event, `menuOpen${idx}`)}
                        >
                          <MoreHorizIcon />
                        </IconButton>

                        <Menu
                          id="submix-menu"
                          open={menuOpen === `menuOpen${idx}`}
                          onClose={this.handleMenuClose}
                          anchorEl={anchorEl}
                        >
                          <MenuItem
                            id={`button_edit_${idx}`}
                            automacao={`button_edit_${idx}`}
                            onClick={event => this.handleEditSubmix(idx, event)}
                          >
                            Editar
                          </MenuItem>
                          <MenuItem
                            id={`button_duplication_${idx}`}
                            automacao={`button_duplication_${idx}`}
                            onClick={event => this.handleCopySubmix(idx, event)}
                          >
                            Duplicar
                          </MenuItem>
                          <MenuItem
                            id={`button_remove_${idx}`}
                            automacao={`button_remove_${idx}`}
                            onClick={event => this.handleRemoveSubmix(idx, event)}
                          >
                            Excluir
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </GridItem>
          </GridItem>

          <GridItem container className={classes.ingestFooter}>
            <GridItem className={classes.itemMiddleLeft}>
              <Button
                type="button"
                size="sm"
                value="Voltar"
                id="backButton"
                automacao="btnVoltar"
                color="transparent"
                style={{ float: 'left' }}
                onClick={this.handleBackClick} /*this.handleRedirectModal*/
                disabled={this.state.submitting}
              >
                Voltar
              </Button>
            </GridItem>
            <GridItem className={classes.itemMiddleRight}>
              <Button
                size="sm"
                id="nextButton"
                automacao="btnAvancar"
                variant="outlined"
                color="primary"
                onClick={() => this.nextRouter()}
                style={{ margin: 0 }}
              >
                Avançar
              </Button>
            </GridItem>
          </GridItem>
        </Grid>

        <div>
          <Snackbar
            place="bc"
            message={this.state.snackMessage}
            open={this.state.statusResponse === 'not-found' || this.state.statusResponse === 'error'}
            close
            color={this.state.snackColor}
            closeNotification={this.handleSnackClose}
          />
          {this.state.openCredits && (
            <CreditsModal
              open={this.state.openCredits}
              context={this.props.context}
              selectedsIndexs={this.state.idsChecked}
              handleCreditsModal={this.handleCreditsModal}
              handleMusiciansModal={this.handleMusiciansModal}
              handleGroupsModal={this.handleGroupsModal}
              handleMusiciansEditModal={this.handleMusiciansEditModal}
              musicians={this.state.musiciansToCredits}
              handleChangeCredits={this.handleChangeCredits}
            />
          )}
          {this.state.openPhonogram && (
            <PhonogramModal
              open={this.state.openPhonogram}
              context={this.props.context}
              onAfterToggle={this.handlePhonogramModal}
            />
          )}
          {this.state.openRedirect ? (
            <RedirectModal
              open={this.state.openRedirect}
              text={'Fonograma(s) salvo(s)'}
              otherText={'Direcionando para etapa 1'}
              handleRedirectModal={this.handleRedirectModal}
              handleRedirect={this.handleBackClick}
            />
          ) : null}
        </div>
      </Fragment>
    )
  }
}

export default withStyles(creditsFormStyle)(CreditsForm)
