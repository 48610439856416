import mainRoutes from '../../routes/main'

const checkPermissions = (userPermissions, allowedPermissions) => {
  if (!allowedPermissions || !allowedPermissions.length) {
    return true
  }

  return userPermissions.some(permission => allowedPermissions.includes(permission))
}

const getAllowedRoutes = userPermissions =>
  mainRoutes.filter(route => checkPermissions(userPermissions, route.allowedPermissions))

const isAuthRoute = pathname => ['/auth', '/logout', '/forbidden'].includes(pathname)

export { getAllowedRoutes, checkPermissions, isAuthRoute }
