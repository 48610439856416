const tagsModalStyle = () => ({
  root: {
    '& .MuiBackdrop-root': {
      background: 'transparent',
    },

    '& #form-dialog-title': {
      margin: '18px 26px 26px 26px',
      padding: '0 0 0 0',
      '& > h2': {
        backgroundColor: 'blue',
        padding: '0 0 0 0',
        fontFamily: 'Arial',
        fontSize: '15px',
        fontWeight: 'bold',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#000',
        position: 'relative',
        textAlign: 'center',
        width: '100%',
        '& > #title': {
          position: 'absolute',
          left: '0',
          textTransform: 'uppercase',
        },
        '& > #help': {
          width: '20px',
          cursor: 'pointer',
          height: '20px',
          objectFit: 'contain',
          position: 'absolute',
          right: '-3px',
        },
      },
    },

    '& #form-dialog-content': {
      width: '475px',
      height: '336px',
      paddingRight: '0',
    },

    '& .scrollbar-container': {
      position: 'relative',
      height: '100%',
      paddingRight: '25px',
    },

    '& .ps__rail-y': {
      backgroundColor: '#f1f1f1',
      opacity: '1 !important',
      width: '4px',
      borderRadius: '30px',
      right: '6px !important',
      '& .ps__thumb-y': {
        backgroundColor: '#666',
        width: '4px',
        right: '0',
      },
    },
  },

  paper: {
    background: '#fff',
    zIndex: 1029,
    borderRadius: '4px',
    boxShadow: '3px 2px 22px 0 rgba(0, 0, 0, 0.2)',
    border: 'solid 1px #eeeeee',
    backgroundColor: '#ffffff',
    height: '427px',
    width: '502px',
  },

  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
  },

  tooltip: {
    borderRadius: '4px',
    boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.5)',
    backgroundColor: '#000000',
    fontSize: '11px',
    fontStyle: 'normal',
    fontFamily: 'Arial',
    lineHeight: '1.38',
    fontWeight: 'normal',
    fontStretch: 'normal',
    letterSpacing: 'normal',
    opacity: 1,
    padding: '3px 8px',
    '& .MuiTooltip-arrow': {
      color: '#000',
    },
  },

  content: {
    '& .accordion-item-wrapper': {
      display: 'flex',
      listStyle: 'none',
      padding: '0 15px',
      margin: '5px 0',
      flexWrap: 'wrap',
      transition: 'all .5s',
      justifyContent: 'space-between',
    },
    '& .checkItem': {
      cursor: 'pointer',
      fontFamily: 'Arial',
      fontSize: '12px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      letterSpacing: 'normal',
      color: '#999',
      lineHeight: '20px',
      width: '115px',
      textTransform: 'capitalize',

      '&:hover': {
        textDecoration: 'underline',
      },

      '&.checked': {
        color: '#b9b9b9',
        textDecoration: 'line-through',
        '&:hover': {
          textDecoration: 'line-through',
        },
      },
    },
  },
})

export default tagsModalStyle
