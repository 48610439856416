import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import redirectStyle from 'assets/jss/components/redirectStyle'
import loadingIcon from 'assets/img/svg/loading.svg'

function Redirect({ message, automacao = 'redirecting', classes }) {
  return (
    <section automacao={automacao} className={classes.container}>
      <div className={classes.loading}>
        <img src={loadingIcon} alt="loading" />
      </div>
      {message}
    </section>
  )
}

Redirect.propTypes = {
  message: PropTypes.string,
  classes: PropTypes.object,
}

export default withStyles(redirectStyle)(Redirect)
