import base from './base'

const api = base()
const baseApi = base(window.__.endpoints.base)
const userApi = base(window.__.endpoints.user)
const workApi = base(window.__.endpoints.work)
const ingestApi = base(window.__.endpoints.ingest)
const musicianApi = base(window.__.endpoints.musician)
const phonogramApi = base(window.__.endpoints.phonogram)

export { api, baseApi, userApi, workApi, ingestApi, musicianApi, phonogramApi }
