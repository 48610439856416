import {
  container,
  primaryColor,
  defaultBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  help,
} from '../material-dashboard-react.jsx'

const headerStyle = theme => ({
  ...help,

  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  titleContainer: {
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    padding: '0',
    paddingLeft: '60px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0',
    },
  },

  containerHeaderlinks: {
    position: 'absolute',
  },

  appBar: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderBottom: '0',
    marginBottom: '0',
    position: 'absolute',
    width: '100%',
    paddingTop: '10px',
    zIndex: 'unset',
    color: '#555555',
    border: '0',
    borderRadius: '3px',
    padding: '10px 0',
    transition: 'all 150ms ease 0s',
    minHeight: '50px',
    display: 'block',
  },
  container: {
    ...container,
    minHeight: '50px',
  },
  flex: {
    flex: 1,
  },
  title: {
    // ...defaultFont,
    fontFamily: 'Roboto',
    padding: '5px',
    fontSize: '35px',
    fontWeight: 100,
    marginRight: '20px',
  },
  appResponsive: {
    top: '8px',
  },
  primary: {
    backgroundColor: primaryColor,
    color: '#FFFFFF',
    ...defaultBoxShadow,
  },
  info: {
    backgroundColor: infoColor,
    color: '#FFFFFF',
    ...defaultBoxShadow,
  },
  success: {
    backgroundColor: successColor,
    color: '#FFFFFF',
    ...defaultBoxShadow,
  },
  warning: {
    backgroundColor: warningColor,
    color: '#FFFFFF',
    ...defaultBoxShadow,
  },
  danger: {
    backgroundColor: dangerColor,
    color: '#FFFFFF',
    ...defaultBoxShadow,
  },
})

export default headerStyle
