import React from 'react'
import fileUploadStyle from 'assets/jss/views/ingest/fileUploadStyle'
import withStyles from '@material-ui/core/styles/withStyles'

class FileUploadAlert extends React.Component {
  render() {
    const messages = {
      corrupted: 'O Ingest não pode ser realizado, o seguinte arquivo está corrompido. Remova-o e tente novamente.',
    }

    return (
      <div className={this.props.classes.alertError}>
        <div />
        <span>{messages.corrupted}</span>
      </div>
    )
  }
}

export default withStyles(fileUploadStyle)(FileUploadAlert)
