import React, { Suspense } from 'react'
import { Route, Redirect } from 'react-router-dom'
import AccessControl from 'components/security/access-control'
import LoadingRedirect from 'components/redirect'
import Forbidden from 'views/common/forbidden'
import loginStore from '../../shared/lib/login-store'

function PrivateRoute({ component: Component, isLoading, ...rest }) {
  return (
    <Route
      exact
      {...rest}
      render={props => {
        if (isLoading) {
          return <LoadingRedirect />
        }

        if (!loginStore.isLoggedIn()) {
          return <Redirect to={{ pathname: '/auth', state: { from: props.location } }} />
        }

        return (
          <AccessControl
            userPermissions={loginStore.getUserFeatures()}
            allowedPermissions={rest.allowedPermissions}
            renderNoAccess={() => <Forbidden />}
          >
            <Suspense fallback={<LoadingRedirect />}>
              <Component {...props} />
            </Suspense>
          </AccessControl>
        )
      }}
    />
  )
}

export default PrivateRoute
