import loginStore from '../../../shared/lib/login-store'

// ON SUCCESS
const onSuccess = () => config => {
  if (config.url.startsWith(window.__.endpoints.base)) {
    if (!config._retry) {
      config.headers['Authorization'] = loginStore.getIdToken()
      config.headers['x-api-key'] = window.__.auth.api_key
    }
  } else {
    delete config.headers.common['Authorization']
    delete config.headers.common['x-api-key']
  }

  if (!config.headers['Content-Type']) {
    config.headers['Content-Type'] = 'application/json'
  }

  return config
}

// ON ERROR
const onError = () => error => Promise.reject(error)

export default { onSuccess, onError }
