import React from 'react'
import PropTypes from 'prop-types'

import ThemeProvider from './ThemeProvider'
import DateProvider from './DateProvider'

const MainProvider = ({ children }) => (
  <ThemeProvider>
    <DateProvider>{children}</DateProvider>
  </ThemeProvider>
)

MainProvider.propTypes = {
  children: PropTypes.any,
}

export default MainProvider
