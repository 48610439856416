import React, { Component, Fragment } from 'react'
import classNames from 'classnames'
import { toCapitalize } from '../../../shared/helpers/text-decorator'
import creditsGroupRowStyle from '../../../assets/jss/views/ingest/creditsGroupRowStyle'

//@material-ui
import { withStyles } from '@material-ui/core/styles'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import Grid from '@material-ui/core/Grid'
import { Close } from '../../../components/svg-icons/svg-icons-lib'

class CreditsGroupRow extends Component {
  constructor(props) {
    super(props)

    this.state = {
      anchorEl: null,
    }

    this.handleUpdateClick = this.handleUpdateClick.bind(this)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleChangeInstrumentsClick = this.handleChangeInstrumentsClick.bind(this)
  }

  componentDidMount = () => {}

  handleUpdateClick = event => {
    this.handleMenuClose()
    this.props.handleEditGroupClick(this.props.group.id)
    event.preventDefault()
  }

  handleDeleteClick = event => {
    this.handleMenuClose()
    this.props.handleRemoveGroupClick(this.props.group.id)
    event.preventDefault()
  }

  handleDeleteMusicianClick = musicianId => event => {
    this.props.handleRemoveMusicianClick(musicianId)
    event.preventDefault()
  }

  handleChangeInstrumentsClick = musicianId => event => {
    this.props.handleInstrumentsModal(musicianId)
    event.preventDefault()
  }

  handleMenuClose = () => {
    this.setState({ anchorEl: null })
  }

  handleMenuClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  render() {
    const { group, classes } = this.props

    return (
      <Fragment>
        <TableRow className={classes.tdGroupRow}>
          <TableCell>Grupo {toCapitalize(group.name)}</TableCell>
          <TableCell></TableCell>
          <TableCell>
            <IconButton
              aria-label="More"
              aria-owns={this.state.anchorEl ? 'credits-group-menu' : undefined}
              aria-haspopup="true"
              onClick={this.handleMenuClick}
            >
              <MoreHorizIcon />
            </IconButton>
            <Menu
              id="credits-group-menu"
              anchorEl={this.state.anchorEl}
              open={Boolean(this.state.anchorEl)}
              onClose={this.handleMenuClose}
            >
              <MenuItem key={'Editar'} onClick={this.handleUpdateClick}>
                Editar Grupo
              </MenuItem>
              <MenuItem key={'Excluir'} onClick={this.handleDeleteClick}>
                Remover da Ficha Técnica
              </MenuItem>
            </Menu>
          </TableCell>
        </TableRow>
        {group.musicians.map((musician, idx) => {
          let instruments = musician.musicalInstruments
            ? musician.musicalInstruments.filter(mi => mi.choosed === true)
            : []
          let instrumentsText = instruments.length > 0 ? instruments.map(mi => mi.name).join(', ') : null

          return (
            <TableRow key={idx} className={classNames({ [classes.tdGroupRow]: group.musicians.length !== idx + 1 })}>
              <TableCell>
                <Grid container>
                  <Grid item className={classes.itemMiddleRight} xs={2} sm={2} md={2}>
                    <IconButton
                      aria-label="remove_circle"
                      size="sm"
                      onClick={this.handleDeleteMusicianClick(musician.id)}
                    >
                      <Close />
                    </IconButton>
                  </Grid>
                  <Grid item xs={10} sm={10} md={10}>
                    {toCapitalize(musician.stageName)} <br />
                    <small>{toCapitalize(musician.fullName)}</small>
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell colSpan="2">
                {instrumentsText}
                <div>
                  <a
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={this.handleChangeInstrumentsClick(musician.id)}
                  >
                    {instrumentsText ? 'Alterar' : 'Adicionar'} Instrumentos
                  </a>
                </div>
              </TableCell>
            </TableRow>
          )
        })}
      </Fragment>
    )
  }
}

export default withStyles(creditsGroupRowStyle)(CreditsGroupRow)
