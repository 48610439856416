import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

const styles = {
  root: {
    width: '100%',
    fontFamily: 'Roboto',
    textAlign: 'center',
  },
  sanhaco: {
    color: '#CCCC',
    fontSize: '4rem',
    fontWeight: '500',
    marginBottom: '1em',
  },
  sanhacotxt: {
    color: '#4A4A4A',
    fontSize: '1.2rem',
    fontWeight: '400',
    marginBottom: '1em',
  },
  lambada: {
    color: '#9B9B9B',
    fontSize: '1rem',
    fontWeight: '100',
  },
  linkstyle: {
    color: '#6BA5E6',
    textDecoration: 'underline',
  },
}

class ForbiddenPage extends Component {
  render() {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <Typography className={classes.sanhaco}>:( OPS!</Typography>
        <Typography className={classes.sanhacotxt}>Desculpe, para acessar esta página é preciso permissão.</Typography>
        <Typography className={classes.lambada}>
          Solicite acesso via{' '}
          <a
            className={classes.linkstyle}
            target="_blank"
            rel="noopener noreferrer"
            href="https://globoservice.service-now.com/globoservice/"
          >
            Help Desk
          </a>
        </Typography>
      </div>
    )
  }
}

export default withStyles(styles)(ForbiddenPage)
