const ingestResultStyle = {
  paper: {
    minWidth: '300px',
    minHeight: '135px',
    padding: '8px',
  },
  actions: {
    margin: '0 12px 10px 0',
  },
  content: {
    padding: '10px 0 0 0',
    margin: '0 20px 0 24px',
  },
  warningContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
  },
  icon: {
    marginRight: '10px',
  },
  partialErrorMessage: {
    color: 'orange',
    fontWeight: '500',
    margin: '0',
  },
  errorsList: {
    maxHeight: '300px',
    overflow: 'auto',
  },
  errorItem: {
    borderBottom: 'none',
    padding: '9px 0 9px 28px',
  },
  errorText: {
    display: 'block',
    maxWidth: '420px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  title: {
    paddingBottom: '6px',
  },
  titleError: {
    color: '#b30303',
  },
  principalText: {
    borderTop: '1px solid #eeeeee',
    paddingTop: '18px',
    color: '#666666',
  },
  buttonClose: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#9a3296',
  },
}

export default ingestResultStyle
