/* eslint-disable max-len */
import React from 'react'

export const MusicNote = props => {
  let _props = {
    ...props,
    width: props['width'] || '23px',
    height: props['height'] || '35px',
    viewBox: props['viewBox'] || '0 0 23 35',
  }

  return (
    <svg {..._props}>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Adicionar-Fonogramas" transform="translate(-306.000000, -469.000000)">
          <g id="Group" transform="translate(263.000000, 426.000000)">
            <g id="baseline-music_note-24px" transform="translate(30.800000, 37.000000)">
              <polygon id="Path" points="0.2 0 46.2 0 46.2 46 0.2 46"></polygon>
              <path
                d="M23.7,6 L23.7,26.5138889 C22.5691667,25.8527778 21.2658333,25.4444444 19.8666667,25.4444444 C15.6308333,25.4444444 12.2,28.925 12.2,33.2222222 C12.2,37.5194444 15.6308333,41 19.8666667,41 C24.1025,41 27.5333333,37.5194444 27.5333333,33.2222222 L27.5333333,13.7777778 L35.2,13.7777778 L35.2,6 L23.7,6 Z"
                id="Path"
                fill="#F1F1F1"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export const Fileupload = props => {
  let _props = {
    ...props,
    width: props['width'] || '51px',
    height: props['height'] || '35px',
    viewBox: props['viewBox'] || '0 0 51 35',
  }

  return (
    <svg {..._props}>
      <path
        fill="#999"
        fillRule="nonzero"
        d="M41.119 13.335C39.674 6.004 33.235.5 25.5.5c-6.141 0-11.475 3.485-14.131 8.585C4.973 9.765 0 15.184 0 21.75 0 28.784 5.716 34.5 12.75 34.5h27.625C46.24 34.5 51 29.74 51 23.875c0-5.61-4.356-10.157-9.881-10.54zm-11.369 6.29v8.5h-8.5v-8.5h-6.375L25.5 9l10.625 10.625H29.75z"
      />
    </svg>
  )
}

export const Plus = props => {
  let _props = {
    ...props,
    width: props['width'] || '14px',
    height: props['height'] || '15px',
    viewBox: props['viewBox'] || '0 0 14 15',
  }
  return (
    <svg {..._props}>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Ad-Fonog-cenario-2" transform="translate(-831.000000, -908.000000)" fill="#FFFFFF">
          <g id="Group-23" transform="translate(266.000000, 709.500000)">
            <g id="Group-21">
              <g id="Group-3" transform="translate(553.000000, 187.000000)">
                <g id="baseline-add-24px-copy-5" transform="translate(12.000000, 12.000000)">
                  <polygon id="Path" points="14 8 8 8 8 14 6 14 6 8 0 8 0 6 6 6 6 0 8 0 8 6 14 6"></polygon>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export const Close = props => {
  let _props = {
    ...props,
    width: props['width'] || '16px',
    height: props['height'] || '15px',
    viewBox: props['viewBox'] || '0 0 16 15',
  }
  return (
    <svg {..._props}>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Ad-Fonog-cenario-2" transform="translate(-783.000000, -910.000000)" fill="#999999">
          <g id="Group-23" transform="translate(266.000000, 709.500000)">
            <g id="Group-21">
              <g id="baseline-add-24px-copy-6" transform="translate(511.000000, 194.000000)">
                <polygon
                  id="Path"
                  transform="translate(14.000000, 14.000000) rotate(-315.000000) translate(-14.000000, -14.000000) "
                  points="23.3333333 15.3333333 15.3333333 15.3333333 15.3333333 23.3333333 12.6666667 23.3333333 12.6666667 15.3333333 4.66666667 15.3333333 4.66666667 12.6666667 12.6666667 12.6666667 12.6666667 4.66666667 15.3333333 4.66666667 15.3333333 12.6666667 23.3333333 12.6666667"
                ></polygon>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
