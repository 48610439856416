import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

//@material-ui
import withStyles from '@material-ui/core/styles/withStyles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import FormLabel from '@material-ui/core/FormLabel'
import CircularProgress from '@material-ui/core/CircularProgress'
import Snackbar from '../../components/snackbar/snackbar'
import AsyncSelect from 'react-select/async'
import Button from '../../components/custom-buttons/button'
import CustomInput from '../../components/custom-input/custom-input.jsx'
import MusicianRow from './musician-row'
import { toCapitalize } from '../../shared/helpers/text-decorator'
import PhonogramApi from '../../services/phonogram-api'
import LoginStore from '../../shared/lib/login-store'

const styles = theme => ({
  paper: {
    width: '572px',
    height: '579px',
    borderRadius: '4px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    backgroundColor: '#ffffff',
  },
  dialogTitle: {
    padding: '24px 0 0 19px',
  },
  title: {
    fontSize: '21px',
  },
  formControl: {
    margin: '10px 0 0 0',
  },
  musicianGridItem: {
    margin: '45px 0 0 0',
  },
  musiciansTableGridItem: {
    margin: '35px 0 0 0',
  },
  wrapper: {
    margin: theme.spacing(),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  snackbar: {
    position: 'absolute',
  },
})

class GroupsModal extends React.Component {
  constructor(props) {
    super(props)
    this.asyncSelectRef = React.createRef()
    this.groupInputRef = React.createRef()
    this.phonogramApi = new PhonogramApi()
    this.loginStore = LoginStore
    this.state = {
      submitting: false,
      statusResponse: false,
      snackMessage: '',
      snackColor: 'primary',
      open: this.props.open,
      groupId: 0,
      groupName: '',
      musicians: [],
      username: this.loginStore.getUserLogin(),
      formIsValid: false,
      formWasValidated: false,
      clearAsyncSelectValue: '',
    }
  }

  focusGroupInputRef = () => {
    if (this.groupInputRef) this.groupInputRef.current.focus()
  }

  focusAsyncSelectRef = () => {
    if (this.asyncSelectRef) this.asyncSelectRef.current.focus()
  }

  handleToggle = () => {
    this.setState({
      open: !this.state.open,
    })
    this.props.handleGroupsModal()
  }

  validateGroupName = groupName => groupName.length >= 3

  validateMusicians = musicians => musicians.length > 0

  validateForm = () => {
    const musiciansIsValid = this.validateMusicians(this.state.musicians)
    const groupIsValid = this.validateGroupName(this.state.groupName)
    const formIsValid = groupIsValid && musiciansIsValid
    this.setState({
      formIsValid: formIsValid,
      groupIsValid: groupIsValid,
      musiciansIsValid: musiciansIsValid,
      formWasValidated: true,
    })
    return formIsValid
  }

  handleChangeGroupName = event => {
    this.setState({
      groupName: event.target.value,
      groupIsValid: this.validateGroupName(event.target.value),
    })
  }

  loadMusicianOptions = (inputValue, callback) => {
    let musiciansIds = []
    if (Array.isArray(this.state.musicians) && this.state.musicians.length) {
      musiciansIds = this.state.musicians.map(m => m.id)
    }

    if (inputValue.length > 2) {
      setTimeout(() => {
        this.phonogramApi.getInstrumentists(inputValue).then(
          data => {
            let filtered = []
            if (data !== undefined) {
              filtered = data
                .filter(d => !musiciansIds.includes(d.id))
                .map(d => ({
                  value: String(d.id),
                  label: toCapitalize(d.stageName + ' - ' + d.fullName),
                  id: d.id,
                  stageName: d.stageName,
                  fullName: d.fullName,
                  favoriteMusicalInstrument: d.favoriteMusicalInstrument,
                  musicalInstruments: d.musicalInstruments,
                }))
            }
            callback(filtered)
          },
          () => {
            callback([])
          }
        )
      }, 500)
    } else callback([])
  }

  handleMusicianSelect = obj => {
    if (obj === null || obj.length === 0) return

    if (obj.favoriteMusicalInstrument !== null) {
      obj.musicalInstruments = obj.musicalInstruments.map(musicalInstrument => {
        if (obj.favoriteMusicalInstrument.id !== musicalInstrument.id) return musicalInstrument

        musicalInstrument.choosed = true
        return musicalInstrument
      })
    }

    const newMusicians = this.state.musicians.concat([obj])
    this.setState({
      musicians: newMusicians,
      clearAsyncSelectValue: '',
      musiciansIsValid: this.validateMusicians(newMusicians),
    })
  }

  handleRemoveMusicianClick = id => {
    const newMusicians = this.state.musicians.filter(m => m.id !== id)
    this.setState({
      musicians: newMusicians,
      musiciansIsValid: this.validateMusicians(newMusicians),
    })
  }

  handleSave = () => {
    if (this.validateForm()) {
      this.setState({ submitting: true, statusResponse: false }, () => {
        const group = {
          id: this.state.groupId,
          name: this.state.groupName,
          musicians: this.state.musicians,
          username: this.state.username,
        }
        if (this.state.groupId === 0) {
          this.phonogramApi.postGroups(group).then(this.handleSuccessCreate).catch(this.handleError)
        } else {
          this.phonogramApi.putGroup(group).then(this.handleSuccessUpdate).catch(this.handleError)
        }
      })
    } else {
      if (!this.state.musiciansIsValid) this.focusAsyncSelectRef()
      if (!this.state.groupIsValid) this.focusGroupInputRef()
    }
  }

  // eslint-disable-next-line no-unused-vars
  handleSuccessCreate = response => {
    this.setState({
      submitting: false,
      statusResponse: 'success',
      snackMessage: 'Grupo cadastrado com sucesso',
      snackColor: 'primary',
    })
  }

  handleSuccessUpdate = response => {
    this.setState(
      {
        submitting: false,
        statusResponse: 'success',
        snackMessage: 'Grupo alterado com sucesso',
        snackColor: 'primary',
      },
      () => {
        const obj = {
          value: 'group_' + String(response.id),
          label:
            toCapitalize(response.name) +
            ' - grupo > ' +
            toCapitalize(response.musicians.map(m => m.stageName).join(', ')),
          musician: null,
          group: response,
        }
        this.props.handleCreditsOptionsChange(obj)
      }
    )
  }

  handleError = error => {
    console.error('error: ', error)

    let snackMessage = 'Erro ao salvar grupo'

    if (error.response.status === 422) snackMessage = error.response.data

    this.setState({ submitting: false, statusResponse: 'error', snackMessage: snackMessage, snackColor: 'danger' })
  }

  handleSnackClose = () => {
    const isSuccess = this.state.statusResponse === 'success'

    this.setState({ statusResponse: false, submitting: false })

    if (isSuccess) this.handleToggle()
  }

  componentDidMount = () => {
    if (this.props.groupId !== null) {
      this.phonogramApi.getGroup(this.props.groupId).then(response => {
        this.setState(
          {
            groupId: response.id,
            groupName: response.name,
            username: response.username,
            musicians: response.musicians,
          },
          () => this.validateForm()
        )
      })
    }
  }

  render() {
    const { classes } = this.props
    const musicians = this.state.musicians !== null ? this.state.musicians : []

    //Essa doideira aqui foi a única maneira que consegui para alterar a borda para cor vermelha,
    // e tirar a bordar azul quando o select está selecionado, quando o input não for válido
    const asyncSelectStyles = {
      control: (provided, state) =>
        this.state.formWasValidated && !this.state.musiciansIsValid
          ? {
              ...provided,
              borderColor: 'red',
              boxShadow: state.isFocused ? 0 : 0,
              '&:hover': { borderColor: 'red' },
            }
          : {
              ...provided,
              boxShadow: state.isFocused ? 0 : 0,
            },
    }

    let rows = musicians.map(
      function (musician, i) {
        return (
          <MusicianRow
            key={i}
            musician={musician}
            context={this.props.context}
            changeMode={this.props.changeMode}
            handleRemoveMusicianClick={this.handleRemoveMusicianClick}
          />
        )
      }.bind(this)
    )

    return (
      <Fragment>
        <Dialog
          open={this.state.open}
          onClose={this.props.handleGroupsModal}
          aria-labelledby="form-dialog-title"
          maxWidth={'md'}
          classes={{ paper: classes.paper }}
        >
          <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
            <span className={classes.title}>{this.props.groupId !== null ? 'Alterar' : 'Cadastrar'} Grupo</span>
          </DialogTitle>
          <DialogContent>
            <Grid container direction="column">
              <Grid item>
                <CustomInput
                  inputRef={this.groupInputRef}
                  labelText="Nome do Grupo"
                  id="groupName"
                  inputProps={{
                    name: 'groupName',
                    maxLength: 100,
                    onChange: this.handleChangeGroupName,
                    value: this.state.groupName,
                    error: this.state.formWasValidated && !this.state.groupIsValid,
                  }}
                  formControlProps={{
                    fullWidth: true,
                    className: classes.formControl,
                  }}
                />
              </Grid>
              <Grid item className={classes.musicianGridItem}>
                <FormLabel component="legend" error={this.state.formWasValidated && !this.state.musiciansIsValid}>
                  Músicos
                </FormLabel>
                <AsyncSelect
                  ref={this.asyncSelectRef}
                  styles={asyncSelectStyles}
                  id="musician"
                  name="musician"
                  placeholder="Selecione o músico"
                  loadOptions={this.loadMusicianOptions}
                  onChange={this.handleMusicianSelect}
                  loadingMessage={() => 'Carregando...'}
                  noOptionsMessage={() => 'Nenhum item encontrado.'}
                  value={this.state.clearAsyncSelectValue}
                  isClearable
                />
              </Grid>
              <Grid item className={classes.musiciansTableGridItem}>
                {!rows.length ? (
                  <div />
                ) : (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Nome</TableCell>
                        <TableCell>Instrumento</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>{rows}</TableBody>
                  </Table>
                )}
              </Grid>
            </Grid>
            <div>
              <Snackbar
                place="bc"
                message={this.state.snackMessage}
                open={this.state.statusResponse === 'success' || this.state.statusResponse === 'error'}
                close
                color={this.state.snackColor}
                closeNotification={this.handleSnackClose}
                className={classes.snackbar}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <div className={classes.wrapper}>
              <Button onClick={this.handleToggle} color="transparent">
                Cancelar
              </Button>
            </div>
            <div className={classes.wrapper}>
              <Button
                value="OK"
                variant="contained"
                color="primary"
                onClick={this.handleSave}
                disabled={this.state.submitting}
              >
                OK
              </Button>
              {this.state.submitting && <CircularProgress size={24} thickness={4} className={classes.buttonProgress} />}
            </div>
          </DialogActions>
        </Dialog>
      </Fragment>
    )
  }
}

GroupsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleGroupsModal: PropTypes.func.isRequired,
  groupId: PropTypes.number,
}

export default withStyles(styles)(GroupsModal)
