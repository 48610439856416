import React, { Component } from 'react'
import PhonogramApi from '../../services/phonogram-api'
import AsyncCreatableSelect from 'react-select/async-creatable'
import { withStyles } from '@material-ui/core/styles'
import { toCapitalize } from '../../shared/helpers/text-decorator'
import FormControl from '@material-ui/core/FormControl'
import ForbiddenIcon from '../../assets/img/svg/baseline-block-24px.svg'
import WarningIcon from '../../assets/img/svg/warning-24.svg'
import WavyIcon from '../../assets/img/wavy-underline.png'
import CloseIcon from '../../assets/img/svg/close-7.svg'
import LoadingIcon from '../../assets/img/svg/loading.svg'

import _ from 'lodash'

const styles = () => ({
  AutocompleteWrapper: {
    '& .select-tags__control': {
      border: 'none',
      borderBottom: '1px solid #999',
      borderRadius: '0',
      boxShadow: 'none',
    },
    '& .select-tags__control:hover': {
      borderColor: ' #999',
    },
    '& .select-tags__control--is-focused': {
      border: 'none',
      borderBottom: '1px solid #999',
      borderRadius: '0',
    },
    '& .select-tags__indicators': {
      display: 'none',
    },
    '& .select-tags__input': {
      '& .loading': {
        width: '27px',
        height: '24px',
        display: 'inline-block',
        maskImage: `url(${LoadingIcon})`,
        verticalAlign: 'middle',
        backgroundColor: '#000',
        maskRepeat: 'no-repeat',
        maskPosition: '1px 1px',
        maskSize: '20px',
        margin: '0 0 0 -3px',
      },

      '& input': {
        minWidth: '94px !important',
        margin: '0',
        maxWidth: '162px !important',
      },

      '& input::placeholder': {
        fontFamily: 'Arial',
        fontSize: '13px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#cccccc',
      },
    },
    '& .select-tags__value-container': {
      padding: '2px 8px 2px 4px',
    },
    '& .select-tags__placeholder': {
      display: 'none',
    },

    '& .select-tags__multi-value': {
      border: '1px solid #ccc',
      paddingLeft: '2px',
      paddingRight: '2px',
      marginRight: '9px',
      marginLeft: '0',
      borderRadius: '20px',
      color: '#333',
      background: 'transparent !important',
      textTransform: 'lowercase',
      position: 'relative',
    },

    '& .select-tags__multi-value__remove': {
      background: `url(${CloseIcon}) no-repeat center transparent`,
      cursor: 'pointer',
      height: '22px',
      width: '22px',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '& svg': {
        display: 'none',
      },
    },
    '& .select-tags__menu': {
      padding: '2px 0 0',
      boxShadow: '0 4px 5px rgba(0, 0, 0, .15)',
      borderRadius: '0 0 4px 4px',
      margin: '0',
    },
    '& .select-tags__option': {
      backgroundColor: 'transparent',
      cursor: 'pointer',
      fontFamily: 'Arial',
      fontSize: '12px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      letterSpacing: 'normal',
      color: '#999999',
      padding: '0 0 0 7px',
      textTransform: 'capitalize',
      margin: '0',

      '& .select-label-autocomplete': {
        height: '25px',
        lineHeight: '25px',
      },

      '&:hover': {
        backgroundColor: '#f1f1f1',
        color: '#666666',
      },
    },
    '& .select-tags__menu-notice': {
      cursor: 'pointer',
      fontFamily: 'Arial',
      fontSize: '12px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      letterSpacing: 'normal',
      color: '#999999',
      padding: '0 0 0 7px',
      height: '25px',
      lineHeight: '25px',
      textTransform: 'capitalize',
      textAlign: 'left',
      margin: '0',
      '&:hover': {
        backgroundColor: '#f1f1f1',
        color: '#666666',
      },
      '& .forbidden-register-tag': {
        display: 'flex',
        alignItems: 'center',
        '& img': {
          width: '17px',
          margin: '0 4px 0',
        },
        '& span': {
          margin: '1px 0 0',
        },
      },
    },

    '& .select-tags__option--is-disabled': {
      background: `url(${ForbiddenIcon}) no-repeat 3px 3px !important`,
      padding: '0 0 0 24px',
      backgroundSize: '17px !important',
    },

    '& .misspelled': {
      background: `url(${WavyIcon}) repeat-x 5px 15px transparent`,
      textDecoration: 'initial',
      padding: '0 0 4px',
    },
  },
  warning: {
    background: `url(${WarningIcon}) no-repeat 5px 0px transparent`,
    boxSizing: 'border-box',
    minWidth: '158px',
    height: '18px',
    borderRadius: '2px',
    border: 'solid 1px #f1deb5',
    backgroundColor: '#fbf8e3',
    fontFamily: 'Arial',
    fontSize: '12px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '18px',
    letterSpacing: 'normal',
    color: '#8a6c3a',
    paddingLeft: '27px',
    paddingRight: '5px',
    display: 'inline-block',
    alignItems: 'center',
    marginLeft: '92px',
  },
})

class TagsInputAutocomplete extends Component {
  AutocompleteWrapper = null
  timeRequest = 0
  misspelled = false
  data = []

  constructor(props) {
    super(props)
    this.phonogramApi = new PhonogramApi()
    this.state = {
      optionsTags: {
        isValid: false,
        noOptionsMessage: null,
      },
    }
  }
  componentDidMount() {
    this.AutocompleteWrapper.querySelector('.select-tags__input input').setAttribute('placeholder', 'Escrever aqui...')
  }

  isTagMoodGenreEmpty = nameTag => {
    const { optionsTags } = this.state
    const { moodItems, genreItems } = this.props

    if (!nameTag || !moodItems || moodItems.length === 0 || !genreItems || genreItems.length === 0) {
      if (optionsTags.isValid === true || optionsTags.noOptionsMessage) {
        this.setOptionsTags({
          isValid: false,
          noOptionsMessage: null,
        })
      }

      return true
    }

    return false
  }

  setOptionsTags = optionsTags => {
    this.setState({
      optionsTags,
    })
  }

  loadTagsOptions = (inputValue, callback) => {
    window.clearTimeout(this.timeRequest)
    this.timeRequest = window.setTimeout(() => {
      if (inputValue.length > 0) {
        this.misspelled = false
        this.phonogramApi.getTags(this.removeAccents(inputValue)).then(
          data => {
            this.misspelled = data.misspelled

            let filtered = data.tags.map(d => {
              if (this.isSomeMoodOrSubmoodByName(d.name)) {
                return {
                  value: 'invalid_mood',
                  label: `${d.name} é um mood`,
                  isDisabled: true,
                }
              } else if (this.isSomeGenreOrSubGenreByName(d.name)) {
                return {
                  value: 'invalid_genrer',
                  label: `${d.name} é um gênero`,
                  isDisabled: true,
                }
              }
              return {
                value: String(d.id),
                label: toCapitalize(d.name),
              }
            })

            if (this.isSomeMoodOrSubmoodByName(inputValue)) {
              filtered.unshift({
                value: 'invalid_mood',
                label: `${inputValue} é um mood`,
                isDisabled: true,
              })
            } else if (this.isSomeGenreOrSubGenreByName(inputValue)) {
              filtered.unshift({
                value: 'invalid_genrer',
                label: `${inputValue} é um gênero`,
                isDisabled: true,
              })
            }

            this.data = filtered
            callback(filtered)
          },
          () => {
            callback([])
          }
        )
      } else {
        callback([])
      }
    }, 500)
  }

  isSomeGenreOrSubGenreByName = name => {
    name = this.removeAccents(name.trim()).toUpperCase()
    return this.props.genreItems.some(
      genre =>
        this.removeAccents(genre.name).replace(':', '').toUpperCase() === name.replace(':', '') ||
        genre.subGenres.some(subgenre => this.removeAccents(subgenre.name).toUpperCase() === name)
    )
  }

  isSomeMoodOrSubmoodByName = name => {
    name = this.removeAccents(name.trim()).toUpperCase()
    return this.props.moodItems.some(
      mood =>
        this.removeAccents(mood.name).replace(':', '').toUpperCase() === name.replace(':', '') ||
        mood.subMoods.some(submood => this.removeAccents(submood.name).toUpperCase() === name)
    )
  }

  hasDiacriticsAccents(nameTag) {
    return nameTag !== this.removeAccents(nameTag)
  }

  createWarning(text) {
    const { classes } = this.props
    const span = document.createElement('span')
    span.setAttribute('id', `warningLimitCharacter`)
    span.setAttribute('class', `${classes.warning} warning`)
    span.textContent = text
    this.AutocompleteWrapper.querySelector('.select-tags__input').appendChild(span)
  }

  validateTag = nameTag => {
    const { optionsTags } = this.state

    if (this.AutocompleteWrapper.querySelector('.select-tags__input .warning')) {
      this.AutocompleteWrapper.querySelector('.select-tags__input .warning').remove()
    }

    if (this.hasDiacriticsAccents(nameTag) && nameTag.length < 20) {
      this.createWarning('Não é possível usar acentuação.')
    } else if (nameTag.length > 19) {
      this.AutocompleteWrapper.querySelector('.select-tags__input input').setAttribute('maxlength', 20)
      this.createWarning('Limite de 20 caracteres')
    }

    if (this.isTagMoodGenreEmpty(nameTag) === true) {
      return
    } else if (this.isSomeMoodOrSubmoodByName(nameTag)) {
      this.setOptionsTags({
        isValid: false,
        noOptionsMessage: (
          <div className="forbidden-register-tag">
            <img src={ForbiddenIcon} alt="X" />
            <span>{`${nameTag} é um mood`}</span>
          </div>
        ),
      })
    } else if (this.isSomeGenreOrSubGenreByName(nameTag)) {
      this.setOptionsTags({
        isValid: false,
        noOptionsMessage: (
          <div className="forbidden-register-tag">
            <img src={ForbiddenIcon} alt="X" />
            <span>{`${nameTag} é um gênero`}</span>
          </div>
        ),
      })
    } else {
      if (optionsTags.isValid === false) {
        this.setOptionsTags({
          isValid: true,
          noOptionsMessage: null,
        })
      }
    }
  }

  formatOptionLabel = ({ label }, { inputValue }) => {
    label = this.removeAccents(label)
    inputValue = this.removeAccents(inputValue)

    if (label && label.includes('Criar a tag')) {
      if (this.data.some(item => item.label.toUpperCase() === inputValue.toUpperCase())) {
        return <span style={{ display: 'none' }}></span>
      } else {
        return (
          <span>
            Criar a tag <span className={`match-label ${this.misspelled ? 'misspelled' : ''}`}>{inputValue}</span>
          </span>
        )
      }
    } else {
      return <span className="select-label-autocomplete">{label}</span>
    }
  }

  onChange = newValue => {
    const { onChangeItemSelected } = this.props
    if (onChangeItemSelected) {
      onChangeItemSelected(
        newValue.map(item => ({
          ...item,
          label: this.removeAccents(item.label),
        }))
      )
    }
  }

  removeAccents(value) {
    return _.deburr(value)
  }

  render = () => {
    const { classes, values } = this.props

    return (
      <div ref={el => (this.AutocompleteWrapper = el)} className={classes.AutocompleteWrapper}>
        <FormControl component="fieldset" fullWidth>
          <AsyncCreatableSelect
            id="tags"
            name="tags"
            backspaceRemovesValue={false}
            autoFocus={true}
            closeMenuOnSelect={false}
            classNamePrefix="select-tags"
            styles={classes.select}
            placeholder="Digite aqui..."
            loadOptions={this.loadTagsOptions}
            onChange={this.onChange.bind(this)}
            onInputChange={inputValue => this.validateTag(inputValue)}
            formatCreateLabel={inputValue => `Criar a tag ${inputValue}`}
            formatOptionLabel={this.formatOptionLabel.bind(this)}
            isValidNewOption={() => this.state.optionsTags.isValid}
            value={values}
            loadingMessage={() => 'Carregando...'}
            noOptionsMessage={() => this.state.optionsTags.noOptionsMessage}
            isMulti
            isClearable
          />
        </FormControl>
      </div>
    )
  }
}
export default withStyles(styles)(TagsInputAutocomplete)
