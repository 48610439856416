import React from 'react'
import { render, unmountComponentAtNode } from 'react-dom'
import Snackbar from './snackbar'

const SNACKBAR_ID = 'snackbar-container'

const createElement = () => {
  const container = document.createElement('div')
  container.setAttribute('id', SNACKBAR_ID)
  document.body.appendChild(container)
  return getElement()
}

const getElement = () => document.getElementById(SNACKBAR_ID)

const handleClose = () => unmountComponentAtNode(getElement() || createElement())

const showSnackbar = ({ message, onClose, ...other } = {}) => {
  const element = (
    <Snackbar
      open
      close
      place="bc"
      color="primary"
      message={message}
      closeNotification={() => {
        handleClose()
        if (onClose) onClose()
      }}
      {...other}
    />
  )

  handleClose()
  render(element, getElement())
}

export default showSnackbar
