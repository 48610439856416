import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import AppBarMUI from '@material-ui/core/AppBar'
import Hidden from '@material-ui/core/Hidden'
import menuStyle from 'assets/jss/components/menuStyle.jsx'
import logoIcon from 'assets/img/logo/globo_white.svg'
import MobileRoutes from './mobileRoutes'
import UserMenu from './user'
import Link from './link'

const isRouteActive = (routesName, routePathName) =>
  routesName.some(routeName => routePathName === routeName.replace('/', ''))

const getPathName = pathname => {
  const ntEmpty = pathname.split('/').filter(i => i)
  return ntEmpty && ntEmpty.length > 0 ? ntEmpty[0] : ''
}

function AppBar({ classes, routes = [], location, userInfo }) {
  const routePathName = useMemo(() => getPathName(location.pathname), [location.pathname])

  return (
    <AppBarMUI position="static" className={classes.appbar}>
      <a id="logo_div" href="/" className={classes.logo}>
        <img src={logoIcon} alt="logo" />
      </a>

      <div className={classes.content}>
        <Hidden mdUp>
          <MobileRoutes routes={routes} />
        </Hidden>

        <Hidden smDown>
          <ul className={classes.list}>
            {routes.map((prop, key) => {
              if (prop.redirect) return null
              return (
                <Link
                  key={key}
                  path={prop.path}
                  sidebarName={prop.sidebarName}
                  isActive={isRouteActive(prop.pathsActiveMenu, routePathName)}
                />
              )
            })}
          </ul>
        </Hidden>

        <div href="/" className={classes.user}>
          <UserMenu userInfo={userInfo} />
        </div>
      </div>
    </AppBarMUI>
  )
}

AppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  userInfo: PropTypes.shape({
    name: PropTypes.string,
    login: PropTypes.string,
  }),
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      redirect: PropTypes.any,
      sidebarName: PropTypes.string,
      pathsActiveMenu: PropTypes.array,
    })
  ),
}

export default withStyles(menuStyle)(AppBar)
