const ingestStyle = {
  root: {},
  formContainer: {
    marginTop: '30px !important',
  },
  linkDefault: {
    color: '#4a90e2 !important',
    cursor: 'pointer',
  },
  whiteCard: {
    backgroundColor: '#fff',
    paddingBottom: '30px',
    boxShadow:
      '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
  },
  clearButtom: {
    margin: 0,
  },
  hrHorizontal: {
    width: '100%',
    margin: 0,
    border: '.5px solid #f1f1f1',
  },
  itemCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  itemMiddleLeft: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    margin: '0 20px 0 0',
  },
  itemMiddleRight: {
    display: 'inline-grid',
    alignItems: 'center',
    justifyContent: 'right',
  },
  alignLeft: {
    textAlign: 'left',
  },
  alignRight: {
    textAlign: 'right',
  },
  cellWidhtCheckbox: {
    width: '7%',
    minWidth: 0,
  },
  ingestHeader: {
    // outline: "1px solid #09e609"
    margin: '24px 0 -11px',
    width: '100%',
  },
  ingestBody: {
    // outline: "1px solid #fffc22"
    maxWidth: '845px',
    margin: '0 auto',
    padding: 0,
  },
  ingestFooter: {
    // outline: "1px solid #f44336",
    maxWidth: '847px',
    width: '100%',
    margin: '40px auto',
    justifyContent: 'flex-end',
    padding: '0',
  },
  stepper: {
    backgroundColor: '#f1f1f1',
    margin: '0 0 35px',
    padding: '0',
  },
}

export default ingestStyle
