import React, { Fragment } from 'react'

//@material-ui
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import CircularProgress from '@material-ui/core/CircularProgress'

export default class RedirectModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: this.props.open,
    }
    this.handleRedirect = this.handleRedirect.bind(this)
  }

  componentDidMount = () => {
    this._mounted = true

    setTimeout(this.handleRedirect, 3000)
  }

  componentWillUnmount = () => {
    //TODO fazer com que cancele qualquer requisição
    //após o desmonte do componente
    this._mounted = false
  }

  handleRedirect = () => {
    this.setState({ open: !this.state.open }, this.props.handleRedirect())
  }

  render = () => {
    const { text, otherText, maxWidth } = this.props

    return (
      <Fragment>
        <Dialog
          open={this.state.open}
          onClose={this.props.handleRedirect}
          aria-labelledby="form-dialog-title"
          maxWidth={maxWidth}
        >
          <DialogContent>
            <DialogContentText>
              {text}
              <br />
              {otherText}
            </DialogContentText>
            <CircularProgress size={24} />
          </DialogContent>
        </Dialog>
      </Fragment>
    )
  }
}
