import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress'
import PhonogramApi from '../../services/phonogram-api'
import ListWithSubItems from '../list-with-subitems/list-with-subitems'
import genresModalStyle from '../../assets/jss/views/ingest/genresModalStyle.jsx'

class GenresModal extends React.Component {
  constructor(props) {
    super(props)
    this.phonogramApi = new PhonogramApi()
    this.state = {
      isLoading: true,
      checkedItems: this.props.checkedItems || [],
      maxSelection: window.__.genresMaxItems,
    }
  }

  componentDidMount() {
    this.getGenres()
  }

  getGenres = () => {
    const { genreItems, changeState } = this.props.context

    if (genreItems && genreItems.length) {
      this.setListWithSubItems()
      return
    }
    this.phonogramApi.getGenres().then(result => {
      changeState({ genreItems: result }, () => {
        this.setListWithSubItems()
      })
    })
  }

  setListWithSubItems = () => {
    this.setState({
      isLoading: false,
      listWithSubItems: this.getListWithSubItem(this.props.context.genreItems),
    })
  }

  getListWithSubItem = genreItems =>
    (genreItems || []).map(item => ({
      id: item.id,
      name: item.name,
      subItems: item.subGenres,
    }))

  onCloseModal = () => {
    const { checkedItems } = this.state
    if (this.props.onAfterCloseModal) {
      this.props.onAfterCloseModal(checkedItems)
    }
  }

  onChangeItemSelected = newChecked => {
    this.setState({
      checkedItems: newChecked,
    })
  }

  getIdsSubItemsSelected = () => {
    if (!this.props.context.genreItems) {
      return []
    }
    const genresIds = this.props.context.genreItems.map(item => item.id)
    return this.state.checkedItems.filter(id => !genresIds.includes(id))
  }

  getTotalSubItemsChecked = () => this.getIdsSubItemsSelected().length

  render = () => {
    const { open } = this.props
    const { root, paper, loadingContainer, dialogContent } = this.props.classes
    const { listWithSubItems, isLoading, maxSelection } = this.state

    return (
      <Dialog id="genres-modal" open={open} onClose={() => this.onCloseModal()} classes={{ root, paper }}>
        <DialogTitle id="form-dialog-title">
          <span>GÊNERO {`(${this.getTotalSubItemsChecked()}/${maxSelection})`}</span>
        </DialogTitle>
        <DialogContent className={dialogContent}>
          {isLoading ? (
            <div className={loadingContainer}>
              <CircularProgress size={24} />
            </div>
          ) : (
            <ListWithSubItems
              list={listWithSubItems}
              itemsSelectedDefault={this.getIdsSubItemsSelected()}
              onChangeItemSelected={arrayValues => this.onChangeItemSelected(arrayValues)}
              maxSelection={3}
            />
          )}
        </DialogContent>
      </Dialog>
    )
  }
}

export default withStyles(genresModalStyle)(GenresModal)
