import jwt from 'jsonwebtoken'
import UserApi from 'services/user-api'
import { refreshAuth } from 'services/auth-api'

class LoginStore {
  static _accessToken = null
  static _refreshToken = null
  static _idToken = null

  static _loggedUser = null
  static _info = {
    userId: null,
    userLogin: null,
    userName: null,
    features: [],
    products: [],
    areas: [],
  }

  userApi = new UserApi()

  constructor() {
    this.load()
  }

  // GETTERS / SETTERS

  getAccessToken() {
    return LoginStore._accessToken
  }

  getRefreshToken() {
    return LoginStore._refreshToken
  }

  getIdToken() {
    return LoginStore._idToken
  }

  getLoggedUser() {
    return LoginStore._loggedUser
  }

  getUserId() {
    return LoginStore._info.userId
  }

  getUserLogin() {
    return LoginStore._info.userLogin
  }

  getUserEmail() {
    return LoginStore._loggedUser
  }

  getUserName() {
    return LoginStore._info.userName
  }

  getUserFeatures() {
    return LoginStore._info.features || []
  }

  getUserProducts() {
    return LoginStore._info.products || []
  }

  getUserAreas() {
    return LoginStore._info.areas || []
  }

  getTokenExpiration(token) {
    return ((jwt.decode(token) || {}).exp || 0) * 1000
  }

  setAccessToken(accessToken) {
    LoginStore._accessToken = accessToken === 'undefined' ? undefined : accessToken

    localStorage.setItem('accesstoken', LoginStore._accessToken)

    if (LoginStore._accessToken !== 'null' && LoginStore._accessToken !== null) {
      this.extractAccessToken(LoginStore._accessToken)
    } else {
      localStorage.removeItem('info')
    }
  }

  setRefreshToken(refreshToken) {
    LoginStore._refreshToken = refreshToken
    localStorage.setItem('refreshtoken', LoginStore._refreshToken)
  }

  setIdToken(idToken) {
    LoginStore._idToken = idToken
    localStorage.setItem('idtoken', LoginStore._idToken)
  }

  setUserInfo(info) {
    LoginStore._info = this.parseToObject(info)
    localStorage.setItem('info', JSON.stringify(LoginStore._info))
  }

  updateUserInfo(info) {
    LoginStore._info = { ...LoginStore._info, ...info }
  }

  // METHODS

  load() {
    this.setAccessToken(localStorage.getItem('accesstoken'))
    this.setRefreshToken(localStorage.getItem('refreshtoken'))
    this.setIdToken(localStorage.getItem('idtoken'))
    this.setUserInfo(localStorage.getItem('info'))
  }

  isLoggedIn() {
    return this.getAccessToken() && this.getAccessToken() !== 'null'
  }

  validate() {
    return new Promise((resolve, reject) => {
      const now = new Date().getTime()

      const accessTokenExp = this.getTokenExpiration(LoginStore._accessToken)
      if (accessTokenExp > now) return resolve()

      refreshAuth(LoginStore._refreshToken)
        .then(response => this.handleLogin(response))
        .then(resolve)
        .catch(reject)
    })
  }

  handleLogin(token) {
    if (!token) {
      this.removeToken()
      return
    }

    if (token['id_token']) this.setIdToken(token['id_token'])
    if (token['access_token']) this.setAccessToken(token['access_token'])
    if (token['refresh_token']) this.setRefreshToken(token['refresh_token'])
  }

  removeToken() {
    LoginStore._idToken = null
    LoginStore._accessToken = null
    LoginStore._refreshToken = null
    LoginStore._loggedUser = null
    localStorage.removeItem('idtoken')
    localStorage.removeItem('accesstoken')
    localStorage.removeItem('refreshtoken')
  }

  clear() {
    LoginStore._idToken = null
    LoginStore._accessToken = null
    LoginStore._refreshToken = null
    LoginStore._loggedUser = null
    LoginStore._info = {}
    localStorage.clear()
  }

  extractAccessToken(accessToken) {
    if (!accessToken || accessToken === 'null') return

    const data = jwt.decode(accessToken) || {}

    LoginStore._loggedUser = data.username
      .replace('adcognitoprovider_', '')
      .replace('cognito_', '')
      .replace('Cognito-login_', '')
      .replace('AzureAD_', '')
  }

  workingLoadUserInfo(onSuccess, interval) {
    const loadUserInfo = () => {
      if (this.isLoggedIn()) {
        this.loadUserInfo().then(onSuccess)
      }
    }

    setInterval(loadUserInfo, interval)
    setTimeout(loadUserInfo, 1000)
  }

  loadUserInfo() {
    console.debug('Consultando as permissoes do usuario')

    return this.userApi
      .getByEmail(this.getLoggedUser())
      .then(user => this.storeUserInfo(user))
      .catch(() => {
        this.clear()
        window.location = '/forbidden'
      })
  }

  storeUserInfo(user) {
    const features = []
    user.profile.permissions.forEach(element => {
      element.actions.forEach(a => {
        features.push(element.feature + ':' + a)
      })
    })

    this.setUserInfo({
      userId: user.id,
      userName: user.name,
      userLogin: user.login,
      userEmail: user.email,
      products: user.products,
      areas: user.areas,
      features,
    })

    return user
  }

  parseToObject(data) {
    if (!data) return {}
    if (typeof data === 'string') {
      try {
        return JSON.parse(data)
      } catch (err) {
        return {}
      }
    }
    return data
  }
}

export default new LoginStore()
