import React, { Component } from 'react'

//@material-ui
import withStyles from '@material-ui/core/styles/withStyles'

//custom components
import Button from '../../components/custom-buttons/button'

const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
}

class ErrorForm extends Component {
  constructor(props) {
    super(props)
    this.handleBackClick = this.handleBackClick.bind(this)
  }

  componentDidMount() {
    this._mounted = true
  }

  componentWillUnmount() {
    //TODO fazer com que cancele qualquer requisição
    //após o desmonte do componente
    this._mounted = false
  }

  handleBackClick = event => {
    this.props.changeMode('musicList')
    event.preventDefault()
  }

  render() {
    return (
      <div>
        <h2>Something went wrong.</h2>
        <details style={{ whiteSpace: 'pre-wrap' }}>
          {this.props.error && this.props.error.toString()}
          <br />
          {this.props.errorInfo.componentStack}
        </details>
        <div>
          <Button type="button" size="sm" value="Voltar" onClick={this.handleBackClick} variant="outlined">
            Voltar
          </Button>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(ErrorForm)
