import React, { Component } from 'react'
import { toCapitalize } from '../../../shared/helpers/text-decorator'

//@material-ui
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import MoreHorizIcon from '@material-ui/icons/MoreHoriz'

class CreditsMusicianRow extends Component {
  constructor(props) {
    super(props)

    this.state = {
      anchorEl: null,
    }

    this.handleUpdateClick = this.handleUpdateClick.bind(this)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleChangeInstrumentsClick = this.handleChangeInstrumentsClick.bind(this)

    //TODO: Refazer o formato de preencher
    if (
      this.props.musician.musicalInstruments &&
      this.props.musician.musicalInstruments.filter(mi => mi.choosed).length === 0 &&
      this.props.musician.favoriteMusicalInstrument !== null
    ) {
      this.props.musician.musicalInstruments.forEach(mi => {
        mi.choosed = mi.id === this.props.musician.favoriteMusicalInstrument.id
      })
    }
  }

  componentDidMount = () => {}

  handleUpdateClick = event => {
    this.handleMenuClose()
    this.props.handleEditMusicianClick(this.props.musician.id)
    event.preventDefault()
  }

  handleDeleteClick = event => {
    this.handleMenuClose()
    this.props.handleRemoveMusicianClick(this.props.musician.id)
    event.preventDefault()
  }

  handleChangeInstrumentsClick = event => {
    this.handleMenuClose()
    this.props.handleInstrumentsModal(this.props.musician.id)
    event.preventDefault()
  }

  handleMenuClose = () => {
    this.setState({ anchorEl: null })
  }

  handleMenuClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  render() {
    let instruments = this.props.musician.musicalInstruments
      ? this.props.musician.musicalInstruments.filter(mi => mi.choosed === true)
      : []
    let instrumentsText = instruments.length > 0 ? instruments.map(mi => mi.name).join(', ') : null

    return (
      <TableRow>
        <TableCell>
          {toCapitalize(this.props.musician.stageName)}
          <br />
          <small>{toCapitalize(this.props.musician.fullName)}</small>
        </TableCell>
        <TableCell>
          {instrumentsText}
          <div>
            <a style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={this.handleChangeInstrumentsClick}>
              {instrumentsText ? 'Alterar' : 'Adicionar'} Instrumentos
            </a>
          </div>
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="More"
            aria-owns={this.state.anchorEl ? 'credits-musician-menu' : undefined}
            aria-haspopup="true"
            onClick={this.handleMenuClick}
          >
            <MoreHorizIcon />
          </IconButton>
          <Menu
            id="credits-musician-menu"
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={this.handleMenuClose}
          >
            <MenuItem key={'Editar'} onClick={this.handleUpdateClick}>
              Editar Músico
            </MenuItem>
            <MenuItem key={'Excluir'} onClick={this.handleDeleteClick}>
              Remover da Ficha Técnica
            </MenuItem>
          </Menu>
        </TableCell>
      </TableRow>
    )
  }
}

export default CreditsMusicianRow
