import React, { Fragment } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

//@material-ui
import withStyles from '@material-ui/core/styles/withStyles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import FormLabel from '@material-ui/core/FormLabel'
import CircularProgress from '@material-ui/core/CircularProgress'
import Checkbox from '@material-ui/core/Checkbox'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'

//custom components
import Button from '../../components/custom-buttons/button'
import Snackbar from '../../components/snackbar/snackbar'
import CustomInput from '../../components/custom-input/custom-input.jsx'
import { toCapitalize } from '../../shared/helpers/text-decorator'
import AsyncSelect from 'react-select/async'
import MaskedInput from 'react-text-mask'

//api
import PhonogramApi from '../../services/phonogram-api'
import showSnackbar from 'components/snackbar/showSnackbar'

const styles = theme => ({
  paper: {
    width: '572px',
    height: '579px',
    borderRadius: '4px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    backgroundColor: '#ffffff',
  },
  dialogTitle: {
    padding: '24px 0 0 19px',
  },
  title: {
    fontSize: '21px',
  },
  formControl: {
    margin: '10px 0 0 0',
  },
  wrapper: {
    margin: theme.spacing(),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  snackbar: {
    position: 'absolute',
  },
})

function TextMaskCustom(props) {
  const { inputRef, ...other } = props
  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  )
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
}

class MusiciansModal extends React.Component {
  constructor(props) {
    super(props)
    this.fullNameInputRef = React.createRef()
    this.stageNameInputRef = React.createRef()
    this.documentInputRef = React.createRef()
    this.state = {
      open: this.props.open,
      submitting: false,
      error: false,
      errorMessage: '',
      id: 0,
      fullName: '',
      stageName: '',
      type: null,
      isNational: true,
      document: '',
      musicalInstruments: [],
      instrumentist: this.props.instrumentistsOnly === true,
      performer: this.props.performersOnly === true,
      favoriteInstrumentOptionSelected: null,
      formIsValid: false,
      formWasValidated: false,
      clearAsyncSelectValue: '',
    }
    this.phonogramApi = new PhonogramApi()
    this.ref = React.createRef()
  }

  componentDidMount = () => {
    this._mounted = true

    if (this.props.musicianId === null) {
      const type = this.props.performersOnly === true ? 73 : this.props.instrumentistsOnly === true ? 77 : null
      this.setState({ type: type })
    } else this.getMusician(this.props.musicianId)
  }

  componentWillUnmount = () => {
    //TODO fazer com que cancele qualquer requisição
    //após o desmonte do componente
    this._mounted = false
  }

  validateFullName = () => this.state.fullName !== ''

  validateStageName = () => this.state.stageName !== ''

  validateType = () => this.state.performer || this.state.instrumentist

  validateDocument = () =>
    (this.state.isNational &&
      this.state.instrumentist &&
      (this.state.document.match(/\d{11}/) || this.state.document.match(/\d{3}.\d{3}.\d{3}-\d{2}/))) ||
    (this.state.isNational &&
      this.state.performer &&
      !this.state.instrumentist &&
      (this.state.document === null ||
        this.state.document === '' ||
        this.state.document.match(/\d{11}/) ||
        this.state.document.match(/\d{3}.\d{3}.\d{3}-\d{2}/) ||
        this.state.document.match(/\s{3}.\s{3}.\s{3}-\s{2}/))) ||
    (!this.state.isNational && this.state.instrumentist && this.state.document.length > 0) ||
    (!this.state.isNational && this.state.performer && !this.state.instrumentist)

  validateFavoriteInstrument = () => this.state.favoriteInstrumentOptionSelected !== null || !this.state.instrumentist

  validateForm = () => {
    const formIsValid =
      this.validateFullName() &&
      this.validateStageName() &&
      this.validateType() &&
      this.validateDocument() &&
      this.validateFavoriteInstrument()

    this.setState({ formIsValid: formIsValid, formWasValidated: true })

    return formIsValid
  }

  focusFullNameInputRef = () => {
    if (this.fullNameInputRef) this.fullNameInputRef.current.focus()
  }

  focusStageNameInputRef = () => {
    if (this.stageNameInputRef) this.stageNameInputRef.current.focus()
  }

  focusDocumentInputRef = () => {
    if (this.documentInputRef) this.documentInputRef.current.focus()
  }

  getMusician = musicianId => {
    this.setState({ loading: true }, () => {
      this.phonogramApi.getMusician(musicianId).then(musician => {
        if (musician !== undefined) {
          //Verificar se há forma melhor de importar esses tipos
          let performer = false,
            instrumentist = false
          switch (musician.type) {
            case 65:
              performer = true
              instrumentist = true
              break
            case 73:
              performer = true
              break
            case 77:
              instrumentist = true
              break
            default:
              break
          }
          musician.performer = performer
          musician.instrumentist = instrumentist

          if (musician.favoriteMusicalInstrument !== null) {
            musician.musicalInstruments = musician.musicalInstruments.filter(
              mi => mi.id !== musician.favoriteMusicalInstrument.id
            )

            musician.favoriteInstrumentOptionSelected = {
              value: musician.favoriteMusicalInstrument.id,
              label: musician.favoriteMusicalInstrument.name,
              id: musician.favoriteMusicalInstrument.id,
              name: musician.favoriteMusicalInstrument.name,
            }
          }

          this.setState({ loading: false }, () => {
            Object.getOwnPropertyNames(musician).forEach(val => {
              if (val === 'stageName' || val === 'fullName') musician[val] = toCapitalize(musician[val])

              if (val === 'document') musician[val] = musician[val] === null ? '' : musician[val]

              this.setState({ [val]: musician[val] })
            })
          })
        }
      })
    })
  }

  handleToggle = () => {
    this.setState({
      open: !this.state.open,
    })
    this.props.handleMusiciansModal()
  }

  handleSuccessCreate = response => {
    console.log('response: ', response)

    this.setState({ submitting: false }, () => {
      showSnackbar({
        message: this.props.performersOnly ? 'Intérprete cadastrado com sucesso' : 'Músico cadastrado com sucesso',
      })
      this.handleToggle()
    })
  }

  handleSuccessUpdate = response => {
    console.log('response: ', response)

    this.setState({ submitting: false }, () => {
      if (this.props.handleCreditsOptionsChange) {
        const instrumentist = {
          value: 'musician_' + String(response.id),
          label: toCapitalize(response.stageName),
          musician: response,
          group: null,
        }

        this.props.handleCreditsOptionsChange(instrumentist)
      }

      if (this.props.handlePerformersOptionsChange) {
        const performer = {
          value: String(response.id),
          label: toCapitalize(response.stageName + ' - ' + response.fullName),
          id: response.id,
          stageName: response.stageName,
          fullName: response.fullName,
        }

        this.props.handlePerformersOptionsChange(performer)
      }

      showSnackbar({
        message: this.props.performersOnly ? 'Intérprete alterado com sucesso' : 'Músico alterado com sucesso',
      })
      this.handleToggle()
    })
  }

  handleError = error => {
    console.error('error: ', error)

    let errorMessage = this.props.performersOnly === true ? 'Erro ao salvar intérprete' : 'Erro ao salvar músico'

    if (error.response.status === 422) errorMessage = error.response.data

    this.setState({ submitting: false, error: true, errorMessage })
  }

  handleSnackClose = () => {
    this.setState({ error: false, submitting: false })
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value }, () => {
      this.validateForm()
    })
  }

  handleChangeNationality = event => {
    if (event.target.value === 'true') {
      this.setState({ isNational: true, document: '' }, () => {
        this.validateForm()
      })
    } else {
      this.setState({ isNational: false, document: '' }, () => {
        this.validateForm()
      })
    }
  }

  handleChangeMusicianType = event => {
    let p = this.state.performer
    let i = this.state.instrumentist
    let t = this.state.type
    if (event.target.value === '1') {
      p = !p
    }
    if (event.target.value === '2') {
      i = !i
    }
    if (p && i) {
      t = 65
    } else if (p) {
      t = 73
    } else if (i) {
      t = 77
    }
    this.setState(
      {
        type: t,
        performer: p,
        instrumentist: i,
      },
      () => {
        this.validateForm()
      }
    )
  }

  handleFavoriteInstrumentSelect = obj => {
    let musicalInstruments = this.state.musicalInstruments

    if (obj !== null && obj.length !== 0) {
      musicalInstruments = musicalInstruments.filter(mi => mi.id !== obj.id)
    } else {
      obj = null
    }

    this.setState(
      {
        favoriteInstrumentOptionSelected: obj,
        musicalInstruments: musicalInstruments,
      },
      () => {
        this.validateForm()
      }
    )
  }

  handleOtherInstrumentsSelect = items => {
    let newMusicalInstruments = items.map(i => ({ id: i.value, name: i.label }))

    this.setState({ musicalInstruments: newMusicalInstruments })
  }

  handleSave = () => {
    if (this.validateForm()) {
      this.setState({ submitting: true, error: false }, () => {
        let favoriteMusicalInstrument =
          this.state.instrumentist && this.state.favoriteInstrumentOptionSelected
            ? {
                id: this.state.favoriteInstrumentOptionSelected.id,
                name: this.state.favoriteInstrumentOptionSelected.name,
              }
            : null

        let musicalInstruments = this.state.instrumentist ? this.state.musicalInstruments.map(mi => mi) : []

        if (
          favoriteMusicalInstrument !== null &&
          musicalInstruments.filter(mi => mi.id === favoriteMusicalInstrument.id).length === 0
        ) {
          musicalInstruments.push(favoriteMusicalInstrument)
        }

        const regExp = /[.-]/g
        const musician = {
          id: this.state.id,
          fullName: this.state.fullName,
          stageName: this.state.stageName,
          type: this.state.type,
          isNational: this.state.isNational,
          document: this.state.document.replace(regExp, ''),
          isActive: true,
          musicalInstruments: musicalInstruments,
          favoriteMusicalInstrument: favoriteMusicalInstrument,
          favoriteMusicalInstrumentId: favoriteMusicalInstrument ? favoriteMusicalInstrument.id : null,
        }

        if (this.state.id === 0) {
          this.phonogramApi.postMusician(musician).then(this.handleSuccessCreate).catch(this.handleError)
        } else {
          this.phonogramApi.putMusician(musician).then(this.handleSuccessUpdate).catch(this.handleError)
        }
      })
    } else {
      if (!this.validateStageName()) {
        this.focusStageNameInputRef()
        return
      }
      if (!this.validateFullName()) {
        this.focusFullNameInputRef()
        return
      }
      if (!this.validateDocument()) {
        this.focusDocumentInputRef()
        return
      }
    }
  }

  loadInstrumentOptions = (inputValue, callback) => {
    if (inputValue.length > 2) {
      setTimeout(() => {
        this.phonogramApi.getInstruments(inputValue).then(
          data => {
            let filtered = []
            if (data !== undefined) {
              filtered = data.map(d => ({
                value: String(d.id),
                label: d.name,
                id: d.id,
                name: d.name,
              }))
            }

            callback(filtered)
          },
          () => {
            callback([])
          }
        )
      }, 500)
    } else callback([])
  }

  scroll = () => {
    ReactDOM.findDOMNode(this.ref.current).scrollIntoView({ behavior: 'smooth', block: 'end' })
  }

  render() {
    const { classes } = this.props

    //Essa doideira aqui foi a única maneira que consegui para alterar a borda para cor vermelha,
    // e tirar a bordar azul quando o select está selecionado, quando o input não for válido
    const favoriteInstrumentStyle = {
      control: (provided, state) =>
        this.state.formWasValidated && !this.validateFavoriteInstrument()
          ? {
              ...provided,
              borderColor: 'red',
              boxShadow: state.isFocused ? 0 : 0,
              '&:hover': { borderColor: 'red' },
            }
          : {
              ...provided,
              boxShadow: state.isFocused ? 0 : 0,
            },
    }

    return (
      <Fragment>
        <Dialog
          open={this.state.open}
          onClose={this.props.handleMusiciansModal}
          aria-labelledby="form-dialog-title"
          maxWidth={'md'}
          scroll={'body'}
          classes={{ paper: classes.paper }}
        >
          <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
            <span className={classes.title}>
              {this.props.musicianId !== null ? 'Alterar' : 'Cadastrar'}{' '}
              {this.props.performersOnly === true ? 'Intérprete' : 'Músico'}
            </span>
          </DialogTitle>
          <DialogContent>
            <Grid container direction="column">
              <Grid item>
                <CustomInput
                  inputRef={this.stageNameInputRef}
                  labelText="Nome Artístico*"
                  id="stageName"
                  inputProps={{
                    name: 'stageName',
                    placeholder: 'Digite o nome artístico',
                    maxLength: 100,
                    onChange: this.handleChange,
                    value: this.state.stageName,
                    error: this.state.formWasValidated && !this.validateStageName(),
                  }}
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </Grid>
              <Grid item>
                <CustomInput
                  inputRef={this.fullNameInputRef}
                  labelText="Nome Completo*"
                  id="fullName"
                  inputProps={{
                    name: 'fullName',
                    placeholder: 'Digite o nome completo',
                    maxLength: 200,
                    onChange: this.handleChange,
                    value: this.state.fullName,
                    error: this.state.formWasValidated && !this.validateFullName(),
                  }}
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </Grid>
              <Grid item>
                <FormControl component="fieldset">
                  <FormLabel component="legend" error={this.state.formWasValidated && !this.validateType()}>
                    Tipo*
                  </FormLabel>
                  <FormGroup style={{ flexDirection: 'row' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={this.handleChangeMusicianType}
                          value="1"
                          checked={this.state.performer}
                          disabled={this.props.performersOnly === true}
                          error={this.state.formWasValidated && !this.validateType()}
                        />
                      }
                      label="Intérprete"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={this.handleChangeMusicianType}
                          value="2"
                          checked={this.state.instrumentist}
                          disabled={this.props.instrumentistsOnly === true}
                          error={this.state.formWasValidated && !this.validateType()}
                        />
                      }
                      label="Músico"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Nacionalidade</FormLabel>
                  <RadioGroup
                    style={{ flexDirection: 'row' }}
                    aria-label="isNational"
                    name="isNational"
                    value={String(this.state.isNational)}
                    onChange={this.handleChangeNationality}
                  >
                    <FormControlLabel value="true" control={<Radio />} label="Brasileira" />
                    <FormControlLabel value="false" control={<Radio />} label="Estrangeira" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl className={styles.formControl} fullWidth>
                  <InputLabel>{`${this.state.isNational ? 'CPF' : 'Documento'} ${
                    this.state.instrumentist ? '*' : ''
                  }`}</InputLabel>
                  <Input
                    inputRef={this.documentInputRef}
                    id="document"
                    name="document"
                    placeholder={this.state.isNational ? 'Digite o CPF' : 'Digite o documento'}
                    maxLength="100"
                    value={this.state.document}
                    onChange={this.handleChange}
                    error={this.state.formWasValidated && !this.validateDocument()}
                    {...(this.state.isNational ? { inputComponent: TextMaskCustom } : null)}
                  />
                </FormControl>
              </Grid>
              {this.state.instrumentist ? (
                <Grid item>
                  <FormControl component="fieldset" fullWidth style={{ margin: '30px 0 0 0' }}>
                    <FormLabel
                      component="legend"
                      error={this.state.formWasValidated && !this.validateFavoriteInstrument()}
                    >
                      Instrumento preferido {this.state.instrumentist ? '*' : ''}
                    </FormLabel>
                    <AsyncSelect
                      styles={favoriteInstrumentStyle}
                      id="favoriteInstrument"
                      name="favoriteInstrument"
                      placeholder="Selecione o instrumento preferido"
                      loadOptions={this.loadInstrumentOptions}
                      onChange={this.handleFavoriteInstrumentSelect}
                      value={this.state.favoriteInstrumentOptionSelected}
                      loadingMessage={() => 'Carregando...'}
                      noOptionsMessage={() => 'Nenhum item encontrado.'}
                      isClearable
                      cacheOptions
                      onFocus={this.scroll}
                    />
                  </FormControl>
                </Grid>
              ) : null}
              {this.state.instrumentist ? (
                <Grid item>
                  <FormControl component="fieldset" fullWidth style={{ margin: '30px 0 0 0' }}>
                    <FormLabel component="legend">Outros instrumentos</FormLabel>
                    <AsyncSelect
                      id="otherInstruments"
                      name="otherInstruments"
                      placeholder="Selecione os outros instrumentos"
                      loadOptions={this.loadInstrumentOptions}
                      onChange={this.handleOtherInstrumentsSelect}
                      value={
                        this.state.musicalInstruments.length > 0
                          ? this.state.musicalInstruments.map(mi => ({ value: mi.id, label: mi.name }))
                          : []
                      }
                      loadingMessage={() => 'Carregando...'}
                      noOptionsMessage={() => 'Nenhum item encontrado.'}
                      isMulti
                      isClearable
                      cacheOptions
                      onFocus={this.scroll}
                    />
                  </FormControl>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <div ref={this.ref} />
                </Grid>
              ) : null}
            </Grid>
            <div>
              <Snackbar
                close
                place="bc"
                color="danger"
                open={this.state.error}
                message={this.state.errorMessage}
                closeNotification={this.handleSnackClose}
                className={classes.snackbar}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <div className={classes.wrapper}>
              <Button size="sm" onClick={this.handleToggle} color="transparent">
                Cancelar
              </Button>
            </div>
            <div className={classes.wrapper}>
              <Button
                size="sm"
                value="OK"
                variant="contained"
                color="primary"
                onClick={this.handleSave}
                disabled={this.state.submitting}
              >
                OK
              </Button>
              {this.state.submitting && <CircularProgress size={24} thickness={4} className={classes.buttonProgress} />}
            </div>
          </DialogActions>
        </Dialog>
      </Fragment>
    )
  }
}

MusiciansModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleMusiciansModal: PropTypes.func.isRequired,
}

export default withStyles(styles)(MusiciansModal)
