import { v1 as uuid } from 'uuid'
import { userApi } from './api/index'

class UserApi {
  getByEmail(email) {
    return userApi
      .get('/users', {
        params: {
          email: email,
        },
      })
      .then(data => data[0])
  }

  getByLogin(login) {
    return userApi.get(`/users/${login}?no-cache=${uuid()}`)
  }

  getIngestUsers() {
    return userApi.get('/users', {
      params: {
        hasIngest: true,
      },
    })
  }
}

export default UserApi
