import React from 'react'
import PropTypes from 'prop-types'
import CheckboxMUI from '@material-ui/core/Checkbox'
import { Wrapper } from './style'

function Checkbox({ checked, label, onChange, disabled, ...props }) {
  const handleChange = event => {
    onChange(event.target.checked)
  }

  return (
    <Wrapper
      label={label}
      checked={checked}
      disabled={disabled}
      onChange={handleChange}
      control={<CheckboxMUI color="primary" {...props} />}
    />
  )
}

Checkbox.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
}

export default Checkbox
