import React from 'react'
import Typography from '@material-ui/core/Typography'

const modes = {
  CAPITALIZE: 'capitalizer',
  UPPER: 'upper',
  LOWER: 'lower',
}

export const toCapitalize = innerText => {
  if (!innerText) {
    return innerText
  }

  var smallWords =
    // eslint-disable-next-line max-len
    /^(a|e|o|os|as|i|u|um|uma|umas|por|entre|para|desde|com|sem|sob|sobre|trás|após|até|uns|de|an|no|na|nas|nos|do|dos|da|das|em|ao|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|v.?|vs.?|via)$/i
  var alphanumericPattern = /([A-Za-z0-9\u00C0-\u00FF])/
  var wordSeparators = /([ ,:–—-])/

  var newWord = innerText
    .toLowerCase()
    .split(wordSeparators)
    .map(function (current, index, array) {
      if (
        /* Check for small words */
        current.search(smallWords) > -1 &&
        /* Skip first and last word */
        index !== 0 &&
        index !== array.length - 1 &&
        /* Ignore title end and subtitle start */
        array[index - 3] !== ':' &&
        array[index + 1] !== ':' &&
        /* Ignore small words that start a hyphenated phrase */
        (array[index + 1] !== '-' || (array[index - 1] === '-' && array[index + 1] === '-'))
      ) {
        return current.toLowerCase()
      }

      /* Ignore intentional capitalization */
      if (current.substr(1).search(/[A-Z]|\../) > -1) {
        return current
      }

      /* Ignore URLs */
      if (array[index + 1] === ':' && array[index + 2] !== '') {
        return current
      }

      if (current.indexOf('&') > -1 || current.indexOf('-') > -1) {
        return current
      }

      /* Capitalize the first letter */
      return current.replace(alphanumericPattern, function (match) {
        return match.toUpperCase()
      })
    })
    .join('')

  return newWord
}

export const toUpper = (innerText, options) => {
  if (options && options['strip']) innerText = removeAccentuation(innerText)

  return (innerText || '').toUpperCase()
}

export const toLower = (innerText, options) => {
  if (options && options['strip']) innerText = removeAccentuation(innerText)

  return (innerText || '').toLowerCase()
}

const removeAccentuation = text => text.normalize('NFD').replace(/[\u0300-\u036f|\u00b4|\u0060|\u005e|\u007e]/g, '')

export const textTransformation = (mode, innerText) => {
  switch (mode) {
    case modes.CAPITALIZE:
      return toCapitalize(innerText)
    case modes.UPPER:
      return toUpper(innerText)
    case modes.LOWER:
      return toLower(innerText)
    default:
      return innerText
  }
}

export default class TextDecoratorClass extends React.Component {
  render = () => {
    const {
      props: { mode, children, variant, component, style },
    } = this
    const letProps = { variant: variant, component: component, style: style }

    try {
      return <Typography {...letProps}>{textTransformation(mode, children)}</Typography>
    } catch (e) {
      console.log(e)
      return <Typography {...letProps}>{children}</Typography>
    }
  }
}

export const TextDecorator = TextDecoratorClass
