const moodsModalStyle = () => ({
  root: {
    '& .MuiBackdrop-root': {
      background: 'transparent',
    },

    '& #form-dialog-title': {
      borderBottom: '1px solid #ddd',
      padding: '0 0 5px',
      margin: '15px 26px 0 20px',
      minHeight: '25px',

      '& > h2': {
        padding: '0 0 0 7px',
        fontSize: '15px',
        fontWeight: 'bold',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#000',
        '& > span': {
          textTransform: 'uppercase',
        },
      },
    },
  },
  paper: {
    borderRadius: '4px',
    boxShadow: '3px 2px 11px 0 rgba(0, 0, 0, 0.15)',
    backgroundColor: '#ffffff',
    minWidth: '430px',
  },

  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
  },

  dialogTitle: {
    padding: '36px 46px 46px',
  },

  title: {
    fontSize: '25px',
  },

  breadcrumb: {
    display: 'flex',
    alignItems: 'center',
    listStyle: 'none',
    padding: '0',
    margin: '6px 0 0',
  },

  breadcrumbLi: {
    margin: '0 8px 0 0',
    display: 'flex',
    alignItems: 'center',
  },
  breadcrumbCategory: {
    fontSize: '11px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: '1px',
    color: '#666666',
    height: '14px',
    textTransform: 'uppercase',
  },

  breadcrumbSubItems: {
    fontSize: '12px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#76458f',
    height: '14px',
    marginLeft: '2px',
  },
})

export default moodsModalStyle
