import React, { Component } from 'react'
import ReactDOM from 'react-dom'

//provider and consumer context
import { IngestProvider, IngestConsumer } from './ingest-context'

import IngestMusicList from './work/ingest-music-list'
import PhonogramForm from './phonogram/phonogram-form'
import PhonogramType from './phonogram-type/phonogram-type'
import CreditsForm from './credits/credits-form'
import MusicianSelectList from './../musician/musician-select-list'
import WorkForm from './../work/create/work-form'
import ProductStep from './product-step/product-step'
import IngestFromRequest from './ingest-from-request'
import ConfirmIngest from './confirm-ingest/confirm-ingest'
import ErrorForm from '../common/error-form'

class Ingest extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentMode: props.match && props.match.path === '/ingestfromrequest/:id' ? 'ingestFromRequest' : 'musicList',
    }

    this.ref = React.createRef()
    this.changeMode = this.changeMode.bind(this)
  }

  changeMode = newMode => {
    this.setState({ currentMode: newMode }, () => {
      this.scrollToTop()
    })
  }

  scrollToTop() {
    ReactDOM.findDOMNode(this.ref.current).scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  render() {
    let modeComponent = (
      <IngestProvider>
        <IngestConsumer>{context => <IngestMusicList context={context} changeMode={this.changeMode} />}</IngestConsumer>
      </IngestProvider>
    )

    switch (this.state.currentMode) {
      case 'ingestFromRequest':
        modeComponent = (
          <IngestProvider>
            <IngestConsumer>
              {context => (
                <IngestFromRequest
                  context={context}
                  changeMode={this.changeMode}
                  requestId={this.props.match.params.id}
                  history={this.props.history}
                />
              )}
            </IngestConsumer>
          </IngestProvider>
        )
        break

      case 'phonogramType':
        modeComponent = (
          <IngestProvider>
            <IngestConsumer>
              {context => <PhonogramType context={context} changeMode={this.changeMode} />}
            </IngestConsumer>
          </IngestProvider>
        )
        break

      case 'createMusic':
        modeComponent = (
          <IngestProvider>
            <IngestConsumer>
              {context => <WorkForm context={context} changeMode={this.changeMode} nextRouter="phonogramType" />}
            </IngestConsumer>
          </IngestProvider>
        )
        break

      case 'addPhonogram':
        modeComponent = (
          <IngestProvider>
            <IngestConsumer>
              {context => <PhonogramForm context={context} changeMode={this.changeMode} history={this.props.history} />}
            </IngestConsumer>
          </IngestProvider>
        )
        break

      case 'addCredits':
        modeComponent = (
          <IngestProvider>
            <IngestConsumer>{context => <CreditsForm context={context} changeMode={this.changeMode} />}</IngestConsumer>
          </IngestProvider>
        )
        break

      case 'addProductArea':
        modeComponent = (
          <IngestProvider>
            <IngestConsumer>{context => <ProductStep context={context} changeMode={this.changeMode} />}</IngestConsumer>
          </IngestProvider>
        )
        break

      case 'chooseMusician':
        modeComponent = (
          <IngestProvider>
            <IngestConsumer>
              {context => <MusicianSelectList context={context} changeMode={this.changeMode} />}
            </IngestConsumer>
          </IngestProvider>
        )
        break
      case 'confirmIngest':
        modeComponent = (
          <IngestProvider>
            <IngestConsumer>
              {context => <ConfirmIngest context={context} changeMode={this.changeMode} />}
            </IngestConsumer>
          </IngestProvider>
        )
        break

      case 'showError':
        modeComponent = (
          <IngestProvider>
            <IngestConsumer>
              {context => (
                <ErrorForm error={context.error} errorInfo={context.errorInfo} changeMode={this.changeMode} />
              )}
            </IngestConsumer>
          </IngestProvider>
        )
        break
      default:
        break
    }
    return <div ref={this.ref}>{modeComponent}</div>
  }
}

export default Ingest
