/* eslint-disable max-len */
import React, { Component, Fragment } from 'react'
import ReactDOM from 'react-dom'
import { toCapitalize } from '../../../shared/helpers/text-decorator'

import PhonogramApi from '../../../services/phonogram-api'

//@material-ui
import withStyles from '@material-ui/core/styles/withStyles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress'

//custom components
import PhonogramBodyContent from './phonogram-body-content/phonogram-body-content'
import Button from '../../../components/custom-buttons/button'
import Snackbar from '../../../components/snackbar/snackbar'
import ConfirmModal from '../../../components/modal/confirm-modal'

import phonogramModalStyle from '../../../assets/jss/views/ingest/phonogramModalStyle.jsx'

/**
 * Todo: Refatorar essa classe.
 *  Problemas:
 * 	- Lógica de tela de pendencias (phonogramToEdit) misturado com lógica do ingest etapa ficha tecnica (submixIdx e submixes)
 * 	- Toda lógica de validação está exatamente igual ao phonogram-form, logo se alterarmos alguma validação aqui obrigatoriamente devemos alterar o phonogram-form
 * 	  ver uma forma de consolidar essa logica em apenas um lugar.
 */

class PhonogramModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: this.props.open,
      loading: false,
      submitting: false,
      statusResponse: '',
      performerOptionsSelected: [{}], //Interpretes do fonograma
      year: null,
      bpm: null,
      moods: [],
      genres: [],
      tags: [],
      acceptedFiles: [],
      rejectedFiles: [],
      duplicatedFiles: [],
      duplicatedSubmixes: [],
      openConfirm: false,
      openFillData: false,
      openMoods: false,
      openGenres: false,
      openTags: false,
      openMusicians: false,
      openConfirmDuplicated: false,
      mp3Warning: false,
      performersIsValid: true,
      moodsIsValid: true,
      genresIsValid: true,
      tagsIsValid: true,
      yearIsValid: true,
      formIsValid: false,
      submixIsValid: true,
      validationMoodsGenresTags: true,
      validationMoodsGenresTagsText: '(Apenas UM dos campos obrigatório)',
      hasMultipleFilesToEdit: false,
      phonogramToEdit: null,
      yearItems: [],
      modalDuplicatedTitle: '',
      modalDuplicatedDescription: '',
      errorMessage: 'Erro ao realizar a operação.',
      submitCount: 0,
    }
    this.handleClose = this.handleClose.bind(this)
    this.handleEditPhonogram = this.handleEditPhonogram.bind(this)
    this.handleSnackClose = this.handleSnackClose.bind(this)
    this.handleToggle = this.handleToggle.bind(this)
    this.handleError = this.handleError.bind(this)
    this.phonogramApi = new PhonogramApi()
    this.ref = React.createRef()
  }

  componentDidMount = () => {
    this._mounted = true

    this.setState({
      loading: true,
    })

    // Usado no pendências
    if (this.props.context.phonogramToEdit !== undefined) {
      this.loadPhonogramToEdit()
      return
    }

    //Usado no menu editar do ingest
    if (this.props.context.submixDraft !== null) {
      this.loadState(
        this.props.context.submixDraft.performerOptionsSelected,
        this.props.context.submixDraft.year,
        this.props.context.submixDraft.bpm,
        this.props.context.submixDraft.moods,
        this.props.context.submixDraft.genres,
        this.props.context.submixDraft.tags,
        this.props.context.submixDraft.acceptedFiles,
        this.props.context.phonogramToEdit
      )
      return
    }
  }

  resetSubmixDraw() {
    this.props.context.changeState({
      submixDraft: null,
      submixIdx: null,
    })
  }

  componentWillUnmount = () => {
    //TODO fazer com que cancele qualquer requisição
    //após o desmonte do componente
    this._mounted = false
    this.resetSubmixDraw()
  }

  setParentState = (state, callback) => {
    this.setState(state, callback)
  }

  getParentState = () => this.state

  loadPhonogramToEdit = () => {
    let newSubmixDraft = {
      performerOptionsSelected: [],
      year: this.props.context.phonogramToEdit.year,
      bpm: this.props.context.phonogramToEdit.bpm,
      moods: this.props.context.phonogramToEdit.moods,
      genres: this.props.context.phonogramToEdit.genres,
      tags: this.props.context.phonogramToEdit.tags,
      acceptedFiles: [
        {
          name: this.props.context.phonogramToEdit.originalFileName,
          originalFileName: this.props.context.phonogramToEdit.originalFileName,
          type: 'audio/wav',
          tempFileName: this.props.context.phonogramToEdit.fileName,
          submix: toCapitalize(this.props.context.phonogramToEdit.submix),
          isrc: this.props.context.phonogramToEdit.isrc,
          duration: this.props.context.phonogramToEdit.duration,
          isValid: true,
          uploadCompleted: true,
          lastModified: null,
          lastModifiedDate: null,
          preview: null,
          size: null,
          webkitRelativePath: null,
        },
      ],
    }

    if (!this.props.context.recorder) {
      this.props.context.changeState({
        recorder: this.props.context.phonogramToEdit.recorder,
      })
    }
    const performerOptionsSelected = this.props.context.phonogramToEdit.musicians
      .filter(p => p.musicalInstruments.length === 0 && p.recorder === null)
      .map(p => ({
        value: String(p.id),
        label: p.stageName,
        id: p.id,
        stageName: p.stageName,
        fullName: p.fullName,
        type: 73,
      }))

    //TODO: Verificar como obter instrumento favorito
    const musicians = this.props.context.phonogramToEdit.musicians
      .filter(m => m.musicalInstruments.length !== 0 && m.recorder === null)
      .map(m => ({
        value: String(m.id),
        label: m.fullName,
        id: m.id,
        fullName: m.fullName,
        stageName: m.stageName,
        favoriteMusicalInstrument: { id: 0, name: null },
        type: 77,
        musicalInstruments: m.musicalInstruments,
      }))

    let ids = performerOptionsSelected.map(p => p.id)

    if (musicians.length > 0) ids = ids.concat(musicians.map(m => m.id))

    this.phonogramApi.getMusiciansByIds(ids).then(
      data => {
        if (data !== undefined) {
          const newPerformerOptionsSelected = performerOptionsSelected.map(performerOptionSelected => {
            let musician = data.find(d => d.id === performerOptionSelected.id) || null
            if (musician === null) {
              return performerOptionSelected
            }
            return {
              ...performerOptionSelected,
              label: toCapitalize(musician.stageName + ' - ' + musician.fullName),
              stageName: musician.stageName,
              fullName: musician.fullName,
            }
          })

          const newMusicians = musicians.map(instrumentist => {
            let musician = data.find(d => d.id === instrumentist.id) || null

            if (musician === null) return instrumentist

            const musicalInstrumentIds = instrumentist.musicalInstruments.map(mi => mi.id)

            return {
              ...musician,
              musicalInstruments: musician.musicalInstruments
                .filter(mi => musicalInstrumentIds.includes(mi.id))
                .map(mi => ({ ...mi, choosed: true })),
            }
          })

          newSubmixDraft.performerOptionsSelected = newPerformerOptionsSelected

          if (this.props.context.phonogramToEdit !== undefined) {
            this.props.context.phonogramToEdit.loaded = true
            this.props.context.changeState({
              submixes: [newSubmixDraft],
              submixDraft: newSubmixDraft,
              musicians: newMusicians,
              submixIdx: 0,
            })
          } else {
            this.props.context.changeState({
              musicians: newMusicians,
              submixDraft: newSubmixDraft,
            })
          }

          this.loadState(
            newPerformerOptionsSelected,
            newSubmixDraft.year,
            newSubmixDraft.bpm,
            newSubmixDraft.moods,
            newSubmixDraft.genres,
            newSubmixDraft.tags,
            newSubmixDraft.acceptedFiles,
            this.props.context.phonogramToEdit
          )
        }
      },
      error => {
        this.props.handleError(error)
      }
    )
  }

  loadState = (performerOptionsSelected, year, bpm, moods, genres, tags, acceptedFiles, phonogramToEdit) => {
    const subMoods = moods.filter(m => m.parentId !== null)
    const subGenres = genres.filter(g => g.parentId !== null)

    moods.forEach(m => {
      if (m.parentId === null && !Array.isArray(m.subMoods) && !Array.isArray(m.subItems)) {
        m.subMoods = subMoods.filter(sm => sm.parentId === m.id)
        m.subItems = subMoods.filter(sm => sm.parentId === m.id)
      }
    })

    genres.forEach(g => {
      if (g.parentId === null && !Array.isArray(g.subGenres) && !Array.isArray(g.subItems)) {
        g.subGenres = subGenres.filter(sg => sg.parentId === g.id)
        g.subItems = subGenres.filter(sg => sg.parentId === g.id)
      }
    })

    this.setState({
      performerOptionsSelected: performerOptionsSelected,
      year: year,
      bpm: bpm,
      moods: moods,
      genres: genres,
      tags: tags,
      acceptedFiles: acceptedFiles,
      phonogramToEdit: phonogramToEdit !== undefined ? phonogramToEdit : null,
      loading: false,
    })
  }

  //VALIDACAO
  isPhonogramOriginal() {
    return this.props.context.phonogramType === 'Original'
  }

  isPhonogramPerformance() {
    return this.props.context.phonogramType === 'Performance'
  }

  isPhonogramComercial() {
    return this.props.context.phonogramType === 'Comercial'
  }

  isMusicOriginal() {
    return this.props.context.musicSelected.category.toUpperCase() === 'MUSICA ORIGINAL'
  }

  isDuplicatedIsrcInForm(file) {
    return this.state.acceptedFiles.filter(item => item.isrc === file.isrc && item.submix === file.submix).length > 1
  }

  isIsrcValid(file) {
    if (this.isPhonogramOriginal() || this.isPhonogramPerformance()) {
      return true
    }

    if (this.isDuplicatedIsrcInForm(file)) {
      return false
    }
    return !!file.isrc && !!file.isrc.match(/[A-Z]{2}-[A-Z\d]{3}-\d{2}-\d{5}|[A-Z]{2}[A-Z\d]{3}\d{2}\d{5}/)
  }

  isSubmixValid(file) {
    return !!file.submix && this.state.acceptedFiles.filter(item => item.submix === file.submix).length === 1
  }

  getGroupBySubmix(submixList) {
    return submixList.reduce((groups, item) => {
      const filtered = groups.filter(g => g.items[0].submix === item.submix)
      const group = filtered.length > 0 ? groups[groups.indexOf(filtered[0])] : groups[groups.push({ items: [] }) - 1]
      group.items.push(item)
      return groups
    }, [])
  }

  transformListToDuplicatedSubmix(list) {
    if (list.length > 0) {
      return list.map(d => ({
        id: d.id,
        workTitle: d.workTitle,
        authorsSummary: d.authorsSummary,
        workCategory: d.workCategory,
        recorderName: d.recorderName,
        performersSummary: d.performersSummary,
        submix: d.submix,
        isrc: d.isrc,
      }))
    } else {
      return []
    }
  }

  createSubmixesObjectToSend(submixList) {
    const { title, authorsSummary, category } = this.props.context.musicSelected

    return submixList.map(s => ({
      workTitle: title,
      authorsSummary: authorsSummary,
      workCategory: category,
      recorderName: this.isPhonogramPerformance() ? null : this.props.context.recorder.name,
      phonogramCategory: this.getCategory(),
      performersSummary: s.performers.map(item => item.stageName).join('/'),
      isRestrictedPerformance: this.isPhonogramPerformance(),
      submix: s.submix,
      isrc: s.file.isrc,
    }))
  }

  getCategory() {
    if (this.isPhonogramPerformance()) {
      return null
    } else if (this.isPhonogramOriginal()) {
      return 'MUSICA ORIGINAL'
    } else {
      return 'MUSICA COMERCIAL'
    }
  }

  validateForm = callback => {
    let submixIsValid = false
    let isrcIsValid = true
    let acceptedFilesValided = this.state.acceptedFiles

    if (this.state.acceptedFiles.length > 0) {
      acceptedFilesValided = this.state.acceptedFiles.map(file => {
        file.submixIsValid = this.isSubmixValid(file)
        file.isrcIsValid = this.isIsrcValid(file)
        return file
      })

      isrcIsValid = acceptedFilesValided.every(file => !!file.isrcIsValid)
      submixIsValid = acceptedFilesValided.every(file => !!file.submixIsValid)
    } else {
      submixIsValid = this.props.context.submixes.length > 0
      isrcIsValid = this.props.context.submixes.length > 0
    }

    const performersIsValid =
      this.state.performerOptionsSelected.filter(p => p.value !== undefined).length ===
      this.state.performerOptionsSelected.length

    const moodsIsValid = this.state.moods.length > 0 && this.state.moods.length < 7
    const genresIsValid = this.state.genres.length > 0 && this.state.genres.length < 7
    const tagsIsValid = this.state.tags.length > 0
    const validationMoodsGenresTags = moodsIsValid || genresIsValid || tagsIsValid
    const validationMoodsGenresTagsText = validationMoodsGenresTags
      ? '(Apenas UM dos campos obrigatório)'
      : 'Preencha pelo menos UM dos campos'
    const yearIsValid = this.state.year !== null

    const recorderIsValid =
      this.isPhonogramOriginal() || this.isPhonogramPerformance() || this.props.context.recorder !== null

    const formIsValid =
      isrcIsValid && performersIsValid && validationMoodsGenresTags && submixIsValid && recorderIsValid && yearIsValid

    if (formIsValid === false) {
      this.scrollToFormTop()
    }

    this.setState(
      {
        acceptedFiles: acceptedFilesValided,
        performersIsValid,
        validationMoodsGenresTags,
        validationMoodsGenresTagsText,
        yearIsValid,
        formIsValid,
        submixIsValid,
        recorderIsValid,
        submitCount: this.state.submitCount + 1,
      },
      () => {
        callback()
      }
    )
  }

  getDuplicatedSubmixesOnContext(newSubmixList) {
    let groupBySubmix = this.getGroupBySubmix(newSubmixList)

    let duplicatedSubmixes = groupBySubmix.filter(g => g.items.length > 1).map(g => g.items[0])

    newSubmixList.forEach(newSubmix => {
      let submixesFound = this.props.context.submixes.filter(
        submix =>
          submix.submix === newSubmix.submix &&
          submix.performers.map(p => p.stageName).join(';') === newSubmix.performers.map(p => p.stageName).join(';')
      )
      let minItems = this.props.context.submixIdx === null ? 0 : 1

      if (submixesFound.length > minItems) {
        let duplicatedSubmixNotFound =
          duplicatedSubmixes.filter(
            ds =>
              ds.submix === newSubmix.submix &&
              ds.performers.map(p => p.stageName).join(';') === newSubmix.performers.map(p => p.stageName).join(';')
          ).length === 0

        if (duplicatedSubmixNotFound) {
          duplicatedSubmixes.push(newSubmix)
        }
      }
    })

    duplicatedSubmixes.forEach(s => {
      s.workTitle = this.props.context.musicSelected.title
      s.recorder = this.props.context.recorder
    })
    return duplicatedSubmixes
  }

  showModalDuplicatedSubmixes(duplicatedSubmixes, title, description, callback) {
    this.setState(
      {
        submitting: false,
      },
      () => {
        //Se tiver dado duplicado não executa o callback, para não avançar de etapa.
        if (duplicatedSubmixes.length > 0) {
          this.setState({
            openConfirmDuplicated: true,
            duplicatedSubmixes: duplicatedSubmixes,
            handleConfirmDuplicated: callback,
            modalDuplicatedTitle: title,
            modalDuplicatedDescription: description,
          })
        } else {
          callback()
        }
      }
    )
  }

  existDataSameIsrcAndSubmix(data, newObjectSubmixIngest) {
    if (this.isPhonogramOriginal() || this.isPhonogramPerformance()) {
      return false
    }
    let exist = false
    const regexRemoveTrace = new RegExp('-', 'g')

    data.forEach(item => {
      exist = newObjectSubmixIngest.some(
        newSubmix =>
          newSubmix.isrc.toUpperCase().replace(regexRemoveTrace, '') ===
            item.isrc.toUpperCase().replace(regexRemoveTrace, '') &&
          newSubmix.submix.toUpperCase() === item.submix.toUpperCase()
      )
    })
    return exist
  }

  existDataSameIsrc(data, newObjectSubmixIngest) {
    if (this.isPhonogramOriginal() || this.isPhonogramPerformance()) {
      return false
    }
    let exist = false
    const regexRemoveTrace = new RegExp('-', 'g')

    data.forEach(item => {
      exist = newObjectSubmixIngest.some(
        newSubmix =>
          newSubmix.isrc.toUpperCase().replace(regexRemoveTrace, '') ===
          item.isrc.toUpperCase().replace(regexRemoveTrace, '')
      )
    })
    return exist
  }

  validateDuplicatedSubmixes = (newSubmixList, callback) => {
    let duplicatedSubmixes = this.isPhonogramOriginal() ? this.getDuplicatedSubmixesOnContext(newSubmixList) : []

    const submixesObjectToSend = this.createSubmixesObjectToSend(newSubmixList)

    this.setState(
      {
        submitting: true,
        openConfirmDuplicated: false,
        duplicatedSubmixes: [],
      },
      () => {
        this.phonogramApi
          .getPhonogramsDuplicates(submixesObjectToSend)
          .then(data => {
            duplicatedSubmixes = duplicatedSubmixes.concat(this.transformListToDuplicatedSubmix(data))

            if (this.isPhonogramComercial()) {
              if (this.existDataSameIsrcAndSubmix(data, submixesObjectToSend)) {
                this.showModalDuplicatedSubmixes(
                  duplicatedSubmixes,
                  'Erro Duplicidade',
                  'Já existe fonograma com o mesmo ISRC e submix.',
                  callback
                )
              } else if (this.existDataSameIsrc(data, submixesObjectToSend)) {
                this.showModalDuplicatedSubmixes(
                  duplicatedSubmixes,
                  'Erro Duplicidade',
                  'Esse ISRC já existe em outro fonograma.',
                  callback
                )
              } else {
                this.showModalDuplicatedSubmixes(
                  duplicatedSubmixes,
                  'Erro Duplicidade',
                  'Já existe fonograma com a mesma obra (título/autores), submix e intérpretes informados.',
                  callback
                )
              }
            } else if (this.isPhonogramPerformance()) {
              this.showModalDuplicatedSubmixes(
                duplicatedSubmixes,
                'Erro Duplicidade',
                `Já existe fonograma com a mesma obra (título/autores), submix e intérpretes informados.`,
                callback
              )
            } else {
              this.showModalDuplicatedSubmixes(
                duplicatedSubmixes,
                'Confirmar Duplicidade',
                `${
                  duplicatedSubmixes.length > 1 ? 'Já existem fonogramas' : 'Já existe fonograma'
                } com a mesma obra (título/autores), submix, intérpretes e gravadora informados. 
							Deseja Prosseguir assim mesmo?`,
                callback
              )
            }
          })
          .catch(error => {
            if (error.response && error.response.status === 404) {
              if (this.isPhonogramOriginal()) {
                this.showModalDuplicatedSubmixes(
                  duplicatedSubmixes,
                  'Confirmar Duplicidade',
                  `${
                    duplicatedSubmixes.length > 1 ? 'Já existem fonogramas' : 'Já existe fonograma'
                  } com a mesma obra (título/autores), submix, intérpretes e gravadora informados. 
										Deseja Prosseguir assim mesmo?`,
                  callback
                )
              } else {
                this.setState(
                  {
                    submitting: false,
                  },
                  () => {
                    callback()
                  }
                )
              }
            } else {
              this.handleError(error)
            }
          })
      }
    )
  }

  //FIM VALIDACAO

  editPhonogram = submixItem => {
    let musicians = submixItem.musicians.filter(m => m.type === 77)

    submixItem.performers.forEach(performer => {
      musicians.push({
        id: performer.id,
        stageName: performer.stageName,
        fullName: performer.fullName,
        type: 73,
        recorder: performer.recorder,
      })
    })

    let tags = []

    submixItem.tags.forEach(tag => {
      let id = !Number.parseInt(tag.id, 10) ? 0 : tag.id
      tags.push({ id: id, name: tag.name })
    })

    let status = ''

    if (this.props.context.phonogramToEdit) {
      status = this.props.context.phonogramToEdit.status
    } else if (submixItem.musicians.length === 0) {
      status = 80
    } else {
      status = 0
    }

    const regExp = /[\s-]/g
    const payload = {
      ...this.state.phonogramToEdit,
      submix: submixItem.submix,
      isrc: submixItem.isrc ? submixItem.isrc.replace(regExp, '') : null,
      musicians: musicians,
      genres: submixItem.genres,
      moods: submixItem.moods,
      tags: tags,
      bpm: submixItem.bpm,
      status: status,
      fileName: submixItem.tempFileName,
      originalFileName: submixItem.fileName,
      duration: submixItem.duration,
    }

    //TODO: Refazer isso
    payload.product = payload.product && payload.product.id !== 0 ? payload.product : null

    this.setState({ submitting: true }, () => {
      this.phonogramApi
        .putPhonogram(payload)
        .then(() => {
          this.setState({
            submitting: false,
            statusResponse: 'success',
          })
        })
        .catch(error => {
          this.handleError(error)
        })
    })
  }

  handleEditPhonogram = event => {
    this.validateForm(() => this.updateEditionPhonogram(event))
  }

  updateEditionPhonogram = event => {
    if (this.state.formIsValid) {
      let performers = this.state.performerOptionsSelected.map(performerOptionSelected => ({
        id: performerOptionSelected.id,
        stageName: performerOptionSelected.stageName,
        fullName: performerOptionSelected.fullName,
        recorder: null,
      }))

      let newSubmixes = []
      let musicians = []

      //Usado em pendencia
      if (this.props.context.phonogramToEdit && this.props.context.phonogramToEdit.musicians.length > 0) {
        musicians = this.props.context.phonogramToEdit.musicians
      } else {
        //Usado em ficha tecnica
        musicians = this.props.context.musicians
      }

      this.state.acceptedFiles.forEach(af => {
        newSubmixes = newSubmixes.concat([
          {
            file: af,
            fileName: af.originalFileName,
            tempFileName: af.tempFileName,
            duration: af.duration,
            submix: toCapitalize(af.submix, { strip: true }),
            isrc: af.isrc,
            year: this.state.year,
            performers: performers,
            musicians: musicians,
            bpm: this.state.bpm,
            moods: this.state.moods,
            genres: this.state.genres,
            tags: this.state.tags,
          },
        ])
      })

      if (this.shouldValidateDuplicatedSubmixes(newSubmixes)) {
        this.validateDuplicatedSubmixes(newSubmixes, () => this.saveChanges(newSubmixes))
      } else {
        this.saveChanges(newSubmixes)
      }
    }
    event.preventDefault()
  }

  /* Validar duplicidade apenas se o usuário mudar o nome (submix) e/ou interpretes */
  shouldValidateDuplicatedSubmixes(newSubmixes) {
    const { context } = this.props

    if (context.phonogramToEdit) {
      //Validar a tela de pendência
      return (
        context.phonogramToEdit.submix.toUpperCase() !== newSubmixes[0].submix.toUpperCase() ||
        context.phonogramToEdit.performers[0].id !== newSubmixes[0].performers[0].id
      )
    } else if (context.submixIdx !== null) {
      //Validar a tela de adicionar ficha tecnica
      return (
        context.submixes[context.submixIdx].submix.toUpperCase() !== newSubmixes[0].submix.toUpperCase() ||
        context.submixes[context.submixIdx].performers[0].id !== newSubmixes[0].performers[0].id
      )
    }
  }

  saveChanges(newSubmixes) {
    const newContextSubmixes = this.props.context.submixes.map((submix, idx) => {
      if (this.props.context.submixIdx !== idx) return submix
      return newSubmixes[0]
    })

    if (this.state.phonogramToEdit !== null) {
      this.editPhonogram(newContextSubmixes[0])
    } else {
      this.props.context.changeState(
        {
          submixes: newContextSubmixes,
          musicians: [],
          submixDraft: null,
          submixIdx: null,
        },
        () => {
          this.handleToggle(this.state.statusResponse === 'success')
        }
      )
    }
  }

  handleClose = event => {
    this.setState({ openConfirm: !this.state.openConfirm })
    event.preventDefault()
  }

  handleSnackClose = () => {
    const isSuccess = this.state.statusResponse === 'success'

    this.setState({
      statusResponse: false,
      rejectedFiles: [],
      duplicatedFiles: [],
      hasMultipleFilesToEdit: false,
    })

    if (isSuccess) {
      this.handleToggle(isSuccess)
    }
  }

  handleToggle = isSaved => {
    this.setState({
      open: !this.state.open,
    })
    this.props.onAfterToggle(isSaved)
  }

  handleError = error => {
    console.error('error: ', error)

    this.setState({ statusResponse: 'error', submitting: false })
  }

  scrollToFormTop = () => {
    ReactDOM.findDOMNode(this.ref.current).scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  render() {
    const { classes, context, changeMode } = this.props
    const hasCorruptedFiles = this.state.acceptedFiles.some(file => !!file.size && file.size <= 1024)

    return (
      <Dialog
        open={this.state.open}
        onClose={() => this.props.onAfterToggle()}
        aria-labelledby="form-dialog-title"
        maxWidth={'md'}
        classes={{ paper: classes.paper }}
      >
        <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
          <span className={classes.title}>Editar Fonograma</span>
        </DialogTitle>
        <DialogContent>
          {this.state.loading ? (
            <CircularProgress size={24} />
          ) : (
            <Fragment>
              <PhonogramBodyContent
                context={context}
                ref={this.ref}
                changeMode={changeMode}
                classes={classes}
                getParentState={this.getParentState}
                setParentState={this.setParentState}
                handleError={this.handleError}
              />
              <Snackbar
                place="bc"
                message="É permitido carregar somente um arquivo de aúdio na edição."
                open={this.state.hasMultipleFilesToEdit}
                close
                color="danger"
                closeNotification={this.handleSnackClose}
                className={classes.snackbar}
              />
              <Snackbar
                place="bc"
                message={this.state.errorMessage}
                open={this.state.statusResponse === 'error'}
                close
                color="danger"
                closeNotification={this.handleSnackClose}
                className={classes.snackbar}
              />
              <Snackbar
                place="bc"
                message={'Fonograma alterado com sucesso.'}
                open={this.state.statusResponse === 'success'}
                close
                color="primary"
                closeNotification={this.handleSnackClose}
                className={classes.snackbar}
              />
              {this.state.openConfirm && (
                <ConfirmModal
                  open={this.state.openConfirm}
                  title={'Editar Fonograma'}
                  text={'Deseja realmente cancelar a operação?'}
                  handleClose={this.handleClose}
                  handleConfirm={this.handleToggle}
                />
              )}
            </Fragment>
          )}
        </DialogContent>
        {!!this.state.acceptedFiles.length && (
          <DialogActions>
            <div className={classes.wrapper}>
              <Button size="sm" color="transparent" onClick={this.handleClose} disabled={this.state.submitting}>
                Cancelar
              </Button>
            </div>
            <div className={classes.wrapper}>
              <Button
                size="sm"
                value="OK"
                variant="contained"
                color="primary"
                onClick={this.handleEditPhonogram}
                disabled={this.state.submitting || hasCorruptedFiles}
              >
                OK
              </Button>
              {this.state.submitting && <CircularProgress size={24} thickness={4} className={classes.buttonProgress} />}
            </div>
          </DialogActions>
        )}
      </Dialog>
    )
  }
}

export default withStyles(phonogramModalStyle)(PhonogramModal)
