/* eslint-disable max-len */
import React, { Component } from 'react'
import { upperFirst } from 'lodash'

//api
import PhonogramApi from '../../../services/phonogram-api'

//shared
import loginStore from '../../../shared/lib/login-store'

//@material-ui
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import InfoOutlineIcon from '@material-ui/icons/InfoOutlined'
import Tooltip from '@material-ui/core/Tooltip'

//custom components
import Button from '../../../components/custom-buttons/button'
import Snackbar from '../../../components/snackbar/snackbar'
import Card from '../../../components/card/card.jsx'
import CardHeader from '../../../components/card/card-header.jsx'
import CardBody from '../../../components/card/card-body.jsx'
import GridItem from '../../../components/grid/grid-item.jsx'
import Steps from '../../../components/steps'
import confirmIngestStyle from '../../../assets/jss/views/ingest/confirmIngestStyle.jsx'
import { toCapitalize } from '../../../shared/helpers/text-decorator'
import { isFullMixPhonogram } from '../utils/phonogram'
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import IngestResultModal from 'components/modal/ingest-result-modal'

class ConfirmIngest extends Component {
  constructor(props) {
    super(props)
    this.state = {
      submitting: false,
      statusResponse: '',
      confirmIngest: false,
      showEndIngestModal: false,
      showProcessingModal: false,
      errorMessage: 'Erro ao salvar fonograma',
      isPolling: false,
      isPollingRequestDone: false,
      isPollingEnded: false,
      isWaitingPolling: false,
      pollingInterval: 1000,
      pollingTimeout: this.props.context.submixes.length * 4000 + 20000,
      phonogramId: null,
      phonogramsStatus: null,
    }
    this.handleSuccess = this.handleSuccess.bind(this)
    this.handleSuccessConfirm = this.handleSuccessConfirm.bind(this)
    this.handleError = this.handleError.bind(this)
    this.handleSnackClose = this.handleSnackClose.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleConfirmIngest = this.handleConfirmIngest.bind(this)
    this.handleBackClick = this.handleBackClick.bind(this)
    this.startPollingApi = this.startPollingApi.bind(this)
    this.stopPollingApi = this.stopPollingApi.bind(this)
    this.checkPhonogramStatus = this.checkPhonogramStatus.bind(this)
    this.handleAfterPollingTimeout = this.handleAfterPollingTimeout.bind(this)
    this.sendToExecMatch = this.sendToExecMatch.bind(this)
    this.pollingStatus = this.pollingStatus.bind(this)
    this.phonogramApi = new PhonogramApi()
    this.loginStore = loginStore
    this.MUSICA_COMERCIAL = 'MUSICA COMERCIAL'
    this.MUSICA_ORIGINAL = 'MUSICA ORIGINAL'
    this.pollingTimer = null
  }

  componentDidMount() {
    const confirmIngest =
      this.props.context.submixes.filter(s => s.musicians.length === 0).length === this.props.context.submixes.length
    this.setState({ confirmIngest: confirmIngest })
  }

  componentDidCatch = (error, errorInfo) => {
    // Catch errors in any components below and re-render with error message
    this.props.context.changeState(
      {
        error: error,
        errorInfo: errorInfo,
      },
      () => {
        this.props.changeMode('showError')
      }
    )
    // You can also log error messages to an error reporting service here
  }

  ingestPhonograms = data => {
    console.log('data dentro do ingest', data)
    this.setState({ submitting: true })
    this.phonogramApi.postIngests(data).then(this.handleSuccess).then(this.startPollingApi).catch(this.handleError)
  }

  handleSuccess = response => {
    const isPhonogramIdsValid = response.phonograms.every(phonogram => !!phonogram.id)
    this.setState({
      phonogramId: response.phonograms.map(phonogram => phonogram.id),
      submitting: false,
      statusResponse: isPhonogramIdsValid ? 'success' : 'error',
      showProcessingModal: isPhonogramIdsValid,
      isPhonogramIdsValid,
    })
  }

  startPollingApi = () => {
    console.log('entrou no startPolling')
    if (!this.state.isPhonogramIdsValid) return

    this.setState({ isPolling: true, isPollingRequestDone: false })
    this.phonogramApi
      .getPhonogramsStatusFromManualIngest(this.state.phonogramId)
      .then(response => {
        this.checkPhonogramStatus({ phonogramArray: response })
      })
      .catch(() => {
        this.stopPollingApi()
      })
  }

  stopPollingApi = () => {
    this.setState({ isPolling: false, isPollingRequestDone: false, isPollingEnded: true })
    clearTimeout(this.pollingTimer)
    clearTimeout(this.endPollingTimer)
  }

  componentDidUpdate() {
    if (this.state.isPhonogramIdsValid && !this.state.isPollingEnded && this.state.isPollingRequestDone) {
      this.pollingStatus()
    }

    if (this.state.isPhonogramIdsValid && this.state.isPollingEnded && !this.state.submittedExecMatch) {
      this.sendToExecMatch()
    }

    if (this.state.statusResponse === 'success' && !this.state.isWaitingPolling) {
      this.setState({ isWaitingPolling: true })
      this.endPollingTimer = setTimeout(() => {
        this.stopPollingApi()
        if (this.state.phonogramArray?.length) this.handleAfterPollingTimeout()
      }, this.state.pollingTimeout)
    }
  }

  pollingStatus = () => {
    if (!this.state.isPollingRequestDone) return
    this.pollingTimer = setTimeout(() => {
      this.phonogramApi
        .getPhonogramsStatusFromManualIngest(this.state.phonogramId)
        .then(response => {
          this.checkPhonogramStatus({ phonogramArray: response })
        })
        .catch(() => {
          this.stopPollingApi()
        })
    }, this.state.pollingInterval)
  }

  sendToExecMatch = () => {
    this.setState({ submittedExecMatch: true })
    this.phonogramApi
      .postCheckAndUpdatePhonograms(this.state.phonogramArrayStatus)
      .then(() => {
        this.setState({ showEndIngestModal: true, showProcessingModal: false })
      })
      .catch(error => {
        this.setState({ showEndIngestModal: true, showProcessingModal: false })
        console.log(error)
      })
  }

  checkPhonogramStatus = ({ phonogramArray }) => {
    const errorStatus = ['1', '3', 'M', 'B', 'L']
    const isAllOk = phonogramArray.every(
      ({ status, statusPhonogramManualIngest }) =>
        !['M', 'B', 'L'].includes(status) && statusPhonogramManualIngest === '2'
    )
    const isError = phonogramArray.some(
      ({ status, statusPhonogramManualIngest }) =>
        errorStatus.includes(status) || errorStatus.includes(statusPhonogramManualIngest)
    )
    const errorPhonograms = phonogramArray.filter(
      ({ status, statusPhonogramManualIngest }) =>
        errorStatus.includes(status) || errorStatus.includes(statusPhonogramManualIngest)
    )
    const arrayStatusWithIdAndIsSent = phonogramArray.map(({ id, isSent }) => ({ id, isSent }))

    this.setState({
      phonogramArrayStatus: arrayStatusWithIdAndIsSent,
      phonogramsStatus: {
        isAllOk,
        isError,
        errorPhonograms: errorPhonograms.map(phonogram => ({
          ...phonogram,
        })),
        isTotalError: errorPhonograms.length === this.props.context.submixes.length,
      },
      phonogramArray,
      isPooling: false,
      isPollingRequestDone: true,
    })

    if (isAllOk) this.stopPollingApi()
  }

  handleAfterPollingTimeout = () => {
    const phonogramArray = this.state.phonogramArray
    this.checkPhonogramStatus({ phonogramArray: phonogramArray.length ? phonogramArray : [] })
  }

  handleContinueError = () => {
    this.sendToExecMatch()
  }

  handleSuccessConfirm = () => {
    this.props.context.changeState({
      musics: [],
      filter: '',
      musicSelected: null,
      work: null,
      recorder: null,
      year: null,
      label: null,
      submixes: [],
      product: null,
      isRestrictedPerformance: false,
      submixDraft: null,
      submixIdx: null,
      musicians: [],
      musicianId: null,
    })

    this.props.changeMode('musicList')
  }

  handleError = err => {
    const error = err.response
    console.error('error: ', error)

    let errorMessage = 'Erro ao salvar fonograma'
    if (error.status === 422) errorMessage = error.data

    if (error.message) errorMessage = error.message

    this.setState({
      statusResponse: 'error',
      errorMessage,
    })
  }

  handleSnackClose = () => {
    this.setState({
      submitting: false,
      statusResponse: '',
    })
  }

  handleSubmit = event => {
    this.ingestPhonograms(this.defineDataToSend())
    event.preventDefault()
  }

  defineDataToSend() {
    const phonograms = this.props.context.submixes.map(item => {
      const musicians = []

      console.log(item)

      musicians.push(
        ...item.performers.map(performer => ({
          type: 73,
          id: performer.id,
          recorder: performer.recorder,
          fullName: performer.fullName,
          stageName: performer.stageName,
        }))
      )

      musicians.push(
        ...item.musicians.map(musician => ({
          type: 77,
          id: musician.id,
          fullName: musician.fullName,
          stageName: musician.stageName,
          musicalInstruments: musician.musicalInstruments.filter(mi => mi.choosed),
        }))
      )

      const moods = item.moods.map(m => ({ id: m.id, name: m.name }))
      const genres = item.genres.map(g => ({ id: g.id, name: g.name }))
      const tags = item.tags.map(t => ({
        id: !Number.parseInt(t.id, 10) ? 0 : t.id,
        name: t.name,
      }))

      const phonogram = {
        id: 0,
        musicRequestId: this.props.context.musicRequestId,
        year: item.year,
        submix: item.submix,
        isrc: item.isrc.replace(/[\s-]/g, ''),
        musicians: musicians,
        genres: genres,
        moods: moods,
        tags: tags,
        bpm: item.bpm,
        bpmNew: item.bpmValue,
        recorder: this.isPhonogramPerformance() ? null : this.props.context.recorder,
        originalFileName: item.fileName,
        fileName: item.tempFileName,
        duration: item.duration,
        product: this.props.context.product,
        utilizationType:
          this.props.context.utilizationType && !this.isPhonogramPerformance()
            ? this.props.context.utilizationType.id
            : null,
        work: this.props.context.musicSelected,
        isRestrictedPerformance: this.isPhonogramPerformance(),
        category: this.getCategory(),
        similar: item.semelhante,
        instruments: item.instrumentos,
        isVocal: item.vocal,
        vocalType: item.tipoVocal,
        recommended: item.recomendadoPara,
        modeTone: item.tom,
      }

      console.log('phonogram', phonogram)

      if (this.isAbleToBeUnreleased(item)) phonogram.isUnreleased = !!item.isUnreleased

      return phonogram
    })

    return {
      id: 0,
      type: 77,
      phonograms: phonograms,
      username: this.loginStore.getUserLogin(),
    }
  }

  isPhonogramPerformance() {
    return this.props.context.phonogramType === 'Performance'
  }

  isPhonogramOriginal() {
    return this.props.context.phonogramType === 'Original'
  }

  isAbleToBeUnreleased(phonogram) {
    return this.props.context.canBeUnreleased && isFullMixPhonogram(phonogram)
  }

  getCategory() {
    const { phonogramType } = this.props.context
    if (!phonogramType) return null
    if (phonogramType.toUpperCase() === 'COMERCIAL') return 2
    if (phonogramType.toUpperCase() === 'ORIGINAL') return 4
    return null
  }

  handleBackClick = event => {
    event.preventDefault()
    const { context, changeMode } = this.props
    if (context.ingestFromRequest || context.musicSelected.category.toUpperCase() === 'MUSICA COMERCIAL') {
      if (this.isPhonogramOriginal()) return changeMode('addCredits')
      return changeMode('addPhonogram')
    }
    return changeMode('addProductArea')
  }

  handleConfirmIngest = event => {
    this.setState({ [event.target.name]: event.target.checked })
  }

  getRecorder() {
    const recorderNameExists = this.props.context.recorder && this.props.context.recorder.name
    return recorderNameExists ? this.props.context.recorder.name : '-'
  }

  render() {
    const { classes } = this.props
    const bpmItems = this.props.context.bpmItems
    const activeStep = this.props.context.steps.length - 1

    return (
      <Grid className={classes.wrapperContainer}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Informações do Fonograma</h4>
            <p className={classes.cardCategoryWhite}>Preencha os campos abaixo</p>
          </CardHeader>
          <CardBody>
            <div>
              <div className={classes.ingestHeader}>
                <Steps steps={this.props.context.steps} activeStep={activeStep} />
              </div>

              <div className={classes.container}>
                <div>
                  <span className={classes.musica}>
                    {upperFirst(this.props.context.musicSelected.title.toLowerCase())}
                  </span>
                </div>
                <div>
                  <span className={classes.categoria} automacao="txtAutores">
                    Autores:
                  </span>
                  <span>
                    {this.props.context.musicSelected.authorsSummary
                      ? this.props.context.musicSelected.authorsSummary
                          .split(',')
                          .map(a => a.toLowerCase())
                          .join(', ')
                      : ''}
                  </span>
                </div>
                <div className="recorderWrapper">
                  <span className={classes.categoria} automacao="txtGravadora">
                    Gravadora:
                  </span>
                  <span>{toCapitalize(this.getRecorder().toLowerCase())}</span>
                </div>

                <div>
                  <span className={classes.categoria} style={{ textTransform: 'initial' }} automacao="txtTipoFonograma">
                    Tipo de fonograma:
                  </span>
                  <span>{this.props.context.phonogramType}</span>
                </div>

                {this.props.context.musicSelected.category.toUpperCase() === 'MUSICA ORIGINAL' ? (
                  <div>
                    <span className={classes.categoria} automacao="txtProduto">
                      Produto:
                    </span>
                    <span>
                      {this.props.context.product != null
                        ? upperFirst(this.props.context.product.name.toLowerCase())
                        : '-'}
                    </span>
                  </div>
                ) : null}

                {this.props.context.submixes.map((item, i) => (
                  <div key={i}>
                    <div className="submixWrapper">
                      <span className={classes.submix}>{`${i + 1}. ${item.submix}`}</span>
                    </div>
                    <hr />
                    {this.props.context.phonogramType.toUpperCase() === 'COMERCIAL' ? (
                      <div className="isrcWrapper">
                        <span className={classes.categoria} automacao="txtIsrc">
                          ISRC:
                        </span>
                        <span>{item.isrc.toUpperCase()}</span>
                      </div>
                    ) : null}
                    <div className="fileWrapper">
                      <span className={classes.categoria} automacao="txtArquivo">
                        Arquivo:
                      </span>
                      <span className={classes.caixabaixa}>{item.fileName}</span>
                    </div>
                    <div className="yearWrapper">
                      <span className={classes.categoria} automacao="txtAno">
                        Ano:
                      </span>
                      <span>{item.year}</span>
                    </div>
                    <div className="performerWrapper">
                      <span className={classes.categoria}>Intérpretes:</span>
                      <span>
                        {item.performers
                          .filter(p => p.recorder === null)
                          .map(performer => upperFirst(performer.stageName.toLowerCase()))
                          .join(', ')}
                      </span>
                    </div>

                    {this.props.context.phonogramType.toUpperCase() === 'ORIGINAL' ? (
                      <div className="musicianWrapper">
                        <span className={classes.categoria} automacao="txtMusicos">
                          Músicos:
                        </span>
                        {item.musicians.length > 0 ? (
                          <span>
                            {item.musicians
                              .map(
                                musician =>
                                  `${upperFirst(musician.fullName.toLowerCase())} (${musician.musicalInstruments
                                    .filter(musicalInstrument => musicalInstrument.choosed === true)
                                    .map(musicalInstrument => upperFirst(musicalInstrument.name.toLowerCase()))
                                    .join(', ')})`
                              )
                              .join(', ')}
                          </span>
                        ) : (
                          <span className={classes.warningText}>
                            Pendente
                            <Tooltip
                              // eslint-disable-next-line max-len
                              title="As informações deste fonograma não poderão ser enviadas agora a Direitos Musicais e ficarão em pendência."
                              placement="right"
                            >
                              <InfoOutlineIcon style={{ verticalAlign: '-6px' }} />
                            </Tooltip>
                          </span>
                        )}
                      </div>
                    ) : null}
                    {item.moods.length > 0 ? (
                      <div className="moodsWrapper">
                        <span className={classes.categoria} automacao="txtMoods">
                          Moods:
                        </span>
                        <span>
                          {item.moods
                            .map(mood => {
                              if (mood.name && mood.name.indexOf(':') === -1) {
                                return upperFirst(mood.name.toLowerCase())
                              }
                              return undefined
                            })
                            .filter(value => value !== undefined)
                            .join(', ')}
                        </span>
                      </div>
                    ) : null}
                    {item.genres.length > 0 ? (
                      <div className="genresWrapper">
                        <span className={classes.categoria} automacao="txtGeneros">
                          Gêneros:
                        </span>
                        <span>
                          {item.genres
                            .map(genre => {
                              if (genre.name && genre.name.indexOf(':') === -1) {
                                return upperFirst(genre.name.toLowerCase())
                              }
                              return undefined
                            })
                            .filter(value => value !== undefined)
                            .join(', ')}
                        </span>
                      </div>
                    ) : null}
                    {item.bpm !== null ? (
                      <div className="bpmWrapper">
                        <span className={classes.categoria} automacao="txtAndamento">
                          Andamento:
                        </span>
                        <span>{upperFirst(bpmItems.find(i => i.value === item.bpm).label.toLowerCase())}</span>
                      </div>
                    ) : null}
                    {item.tags.length > 0 ? (
                      <div className="tagsWrapper">
                        <span className={classes.categoria} automacao="txtTags">
                          Tags:
                        </span>
                        <span>{item.tags.map(tag => upperFirst(tag.name.toLowerCase())).join(', ')}</span>
                      </div>
                    ) : null}
                    {this.isAbleToBeUnreleased(item) && (
                      <div>
                        <span className={classes.categoria} automacao="txtInedito">
                          Inédito:
                        </span>
                        <span>{item.isUnreleased ? 'Sim' : 'Não'}</span>
                      </div>
                    )}
                  </div>
                ))}
                {this.props.context.submixes.filter(s => s.musicians.length > 0).length > 0 ? (
                  <div className="formGroupInfoWrapper">
                    <FormGroup
                      row
                      style={{
                        margin: '60px 0 0 0',
                        background: '#f1f1f1',
                        padding: '20px',
                        borderRadius: '4px',
                        textTransform: 'none',
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="confirmIngest"
                            name="confirmIngest"
                            automacao="checkConfirmIngest"
                            checked={this.state.confirmIngest}
                            onChange={this.handleConfirmIngest}
                            color="primary"
                            style={{ float: 'left' }}
                          />
                        }
                        label="Concordo que ao concluir esse ingest estou fornecendo todas as informações necessárias para o registro deste fonograma e geração do seu ISRC por Direitos Musicais e estou ciente de que qualquer alteração após o ingest deverá ser informada imediatamente ao departamento."
                      />
                    </FormGroup>
                  </div>
                ) : null}
              </div>
            </div>
          </CardBody>

          <GridItem id="footerButtons" container className={classes.ingestFooter}>
            <GridItem className={classes.itemMiddleLeft}>
              <Button
                type="button"
                size="sm"
                value="Voltar"
                automacao="btnVoltar"
                style={{ float: 'left' }}
                onClick={this.handleBackClick}
                disabled={this.state.submitting}
                color="transparent"
              >
                Voltar
              </Button>
            </GridItem>
            {this.state.submitting ? (
              <CircularProgress size={24} className={classes.buttonProgress} />
            ) : (
              <GridItem className={classes.itemMiddleRight}>
                <Button
                  type="submit"
                  size="sm"
                  value="Submit"
                  automacao="btnIngestar"
                  color="primary"
                  variant="contained"
                  disabled={!this.state.confirmIngest}
                  onClick={this.handleSubmit}
                >
                  Ingestar
                </Button>
              </GridItem>
            )}
          </GridItem>
        </Card>

        {this.state.showProcessingModal && (
          <Dialog id="processing-phonograms-modal" open={true}>
            <DialogTitle id="form-dialog-title">
              <span>{this.state.phonogramId.length > 1 ? 'Ingestando fonogramas' : 'Ingestando fonograma'}</span>
            </DialogTitle>

            <DialogContent>
              <DialogContentText className={classes.principalText}>
                {this.state.phonogramId.length > 1
                  ? 'Por favor, aguarde enquanto os fonogramas são processados. Essa ação pode levar alguns minutos.'
                  : 'Por favor, aguarde enquanto o fonograma é processado. Essa ação pode levar alguns minutos.'}
              </DialogContentText>
              <div className={classes.processingLoadingContainer}>
                <CircularProgress size={64} className={classes.processingCircularLoading} />
              </div>
            </DialogContent>
          </Dialog>
        )}

        {this.state.showEndIngestModal && (
          <IngestResultModal
            okText="OK"
            open={this.state.showEndIngestModal}
            handleClose={this.handleSuccessConfirm}
            titles={{
              success: 'Ingest realizado com sucesso!',
              error: {
                partial: 'Ingest realizado!',
                total: 'Erro no Ingest!',
              },
            }}
            messages={{
              success:
                'Arquivos adicionados à biblioteca, aguarde alguns minutos para que o processamento da marca d’água seja concluído antes de pode realizar o download.',
              error: {
                partial: (
                  <>
                    <p className={classes.errorMessage}>
                      {
                        'Demais arquivos foram adicionados à biblioteca, verifique os arquivos com erro e tente novamente.'
                      }
                    </p>
                    <p className={classes.suportMessage}>
                      {`Caso o erro persista contate o `}
                      <a
                        href="https://globoservice.service-now.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.suportLink}
                      >
                        suporte
                      </a>
                      {'.'}
                    </p>
                  </>
                ),
                total: (
                  <>
                    <p
                      className={classes.errorMessage}
                    >{`Ocorreu um erro ao realizar o ingest. Verifique os arquivos e as informações inseridas e tente novamente`}</p>
                    <p className={classes.suportMessage}>
                      {`Caso o erro persista contate o `}
                      <a
                        href="https://globoservice.service-now.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.suportLink}
                      >
                        suporte
                      </a>
                      {'.'}
                    </p>
                  </>
                ),
              },
            }}
            isError={this.state.phonogramsStatus?.isError}
            isTotalError={this.state.phonogramsStatus?.isTotalError}
            phonogramsWithErrors={this.state.phonogramsStatus?.errorPhonograms}
          />
        )}
        <div>
          <Snackbar
            place="bc"
            message={this.state.errorMessage}
            open={this.state.statusResponse === 'error'}
            close
            color="danger"
            closeNotification={this.handleSnackClose}
          />
        </div>
      </Grid>
    )
  }
}

export default withStyles(confirmIngestStyle)(ConfirmIngest)
