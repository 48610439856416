import ingestStyle from '../ingest/ingestStyle'
const musicRowStyle = {
  ...ingestStyle,
  TableCell: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '0.8125rem',
    fontWeight: 400,
    padding: '4px 24px 4px 24px',
    lineHeight: '14px',
    height: '55px',
  },
}
export default musicRowStyle
