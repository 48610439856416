import React from 'react'

const Context = React.createContext({
  downloads: [],
  changeState: (newState, callback) => {
    this.setState(state => reducer(state, newState), callback)
  },
})

const reducer = (currentState, newState) => ({ ...currentState, ...newState })

export class DownloadsProvider extends React.Component {
  state = {
    downloads: [],
    changeState: (newState, callback) => {
      this.setState(state => reducer(state, newState), callback)
    },
  }

  render = () => {
    const {
      state,
      props: { children },
    } = this
    return <Context.Provider value={state}>{children}</Context.Provider>
  }
}

export const DownloadsConsumer = Context.Consumer
