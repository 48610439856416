import ingestStyle from './ingestStyle'
const confirmIngestStyle = () => ({
  ...ingestStyle,
  wrapperContainer: {
    margin: '0 30px',
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
  warningText: {
    color: 'orange',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
  },
  container: {
    fontSize: '16px',
    color: '#000',
    maxWidth: '800px',
    letterSpacing: '1px',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    display: 'block',
    textAlign: 'left',
    margin: '43px auto 0',
    fontWeight: '400',
    lineHeight: '31px',
    textTransform: 'capitalize',
  },
  musica: {
    fontSize: '21px',
    display: 'block',
    margin: '0 0 24px 0',
    fontWeight: '500',
  },
  submix: {
    display: 'block',
    margin: '30px 0px -7px 0',
    fontWeight: '500',
  },
  categoria: {
    display: 'block',
    float: 'left',
    margin: '0 7px 0 0',
    color: '#757575',
    fontWeight: 600,
  },
  hr: {
    color: '#f00',
    backgroundColor: '#f00',
    height: '5px',
  },
  caixabaixa: {
    textTransform: 'none',
  },
  processingLoadingContainer: {
    width: '100%',
    margin: '24px 0',
  },
  processingCircularLoading: {
    margin: '0 0 0 45%',
  },
  buttonToRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '12px 0 4px 0',
    padding: '0',
  },
  principalText: {
    borderTop: '1px solid #eeeeee',
    paddingTop: '18px',
    color: '#666666',
  },
  errorMessage: {
    margin: 0,
  },
  suportMessage: {
    margin: '16px 0 0 0',
  },
  suportLink: {
    textDecoration: 'underline',
  },
})

export default confirmIngestStyle
