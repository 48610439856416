import React, { Component } from 'react'
import { toCapitalize } from '../../shared/helpers/text-decorator'

//@material-ui
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import { Close } from '../../components/svg-icons/svg-icons-lib'

class MusicianRow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showChangeInstruments: this.props.showChangeInstruments,
      showUpdateMusician: this.props.showUpdateMusician,
    }
    this.handleUpdateClick = this.handleUpdateClick.bind(this)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleChangeInstrumentsClick = this.handleChangeInstrumentsClick.bind(this)

    //TODO: Refazer o formato de preencher
    if (
      this.props.musician.musicalInstruments &&
      this.props.musician.musicalInstruments.filter(mi => mi.choosed).length === 0 &&
      this.props.musician.favoriteMusicalInstrument !== null
    ) {
      this.props.musician.musicalInstruments.forEach(mi => {
        mi.choosed = mi.id === this.props.musician.favoriteMusicalInstrument.id
      })
    }
  }

  componentDidMount = () => {
    this._mounted = true
  }

  componentWillUnmount = () => {
    //TODO fazer com que cancele qualquer requisição
    //após o desmonte do componente
    this._mounted = false
  }

  handleUpdateClick = event => {
    this.props.context.changeState({ musicianId: this.props.musician.id }, () =>
      this.props.changeMode('updateMusician')
    )
    event.preventDefault()
  }

  handleDeleteClick = event => {
    this.props.handleRemoveMusicianClick(this.props.musician.id)
    event.preventDefault()
  }

  handleChangeInstrumentsClick = event => {
    this.props.handleInstrumentsModal(this.props.musician.id)
    event.preventDefault()
  }

  render() {
    let instruments = this.props.musician.musicalInstruments
      ? this.props.musician.musicalInstruments.filter(mi => mi.choosed === true)
      : []
    let instrumentsText = instruments.length > 0 ? instruments.map(mi => mi.name).join(',') : '-'

    return (
      <TableRow>
        <TableCell>
          {toCapitalize(this.props.musician.stageName)}
          <br />
          <small>{toCapitalize(this.props.musician.fullName)}</small>
        </TableCell>
        <TableCell>
          {instrumentsText}
          {this.state.showChangeInstruments ? (
            <div>
              <a style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={this.handleChangeInstrumentsClick}>
                {instrumentsText === '-' ? 'Adicionar' : 'Alterar'} Instrumentos
              </a>
            </div>
          ) : null}
        </TableCell>
        {this.state.showUpdateMusician ? (
          <TableCell>
            <IconButton aria-label="Edit" size="sm" onClick={this.handleUpdateClick}>
              <EditIcon />
            </IconButton>
          </TableCell>
        ) : null}
        <TableCell style={{ paddingRight: '32px' }}>
          <IconButton aria-label="remove_circle" size="sm" onClick={this.handleDeleteClick}>
            <Close />
          </IconButton>
        </TableCell>
      </TableRow>
    )
  }
}

export default MusicianRow
