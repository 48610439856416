'use strict'

window.__ = {
  endpoints: {
    base: 'https://api.dev.gmusic.g.globo',
    ingest: 'https://api.dev.gmusic.g.globo/ingest-service/v1',
    work: 'https://api.dev.gmusic.g.globo/work-service/v1',
    musician: 'https://api.dev.gmusic.g.globo/musician-service/v1',
    phonogram: 'https://api.dev.gmusic.g.globo/phonogram-service/v1',
    user: 'https://api.dev.gmusic.g.globo/user-service/v1',
  },
  auth: {
    token_base_url: 'entretenimento-dev.auth.us-east-1.amazoncognito.com',
    authorize_base_url: 'https://entretenimento-dev.auth.us-east-1.amazoncognito.com/oauth2/authorize',
    logout_base_url: 'https://entretenimento-dev.auth.us-east-1.amazoncognito.com/logout',
    api_key: 'XUKUhcGjm68uYdPBAbEib2kGvayDyKDiaEj2EB0r',
    client_id: '34ebkjdrasnd3o3l2tgc20tmvr',
    scope: 'email+openid+aws.cognito.signin.user.admin+profile',
    authorization_key:
      'MzRlYmtqZHJhc25kM28zbDJ0Z2MyMHRtdnI6MTlrM2tpM2ljZGdlbmoyOG5sMGpnYTkwZjJzbDB1dDZ2amw0MmhucjE4ZmwyZjQ0aHRmOA==',
  },
  moodsMaxItems: 3,
  genresMaxItems: 3,
}
