import React, { Component, Fragment } from 'react'
import MusicList from '../../work/list/music-list'

class IngestMusicList extends Component {
  componentDidMount() {
    this.resetInfoMusic()
  }

  resetInfoMusic = () => {
    this.props.context.changeState({
      utilizationType: null,
      submixDraft: null,
      musicians: [],
      submixIdx: null,
      musicSelected: null,
      product: null,
      recorder: null,
      steps: ['Tipo', 'Adicionar Arquivo(s)', 'Ficha Técnica', 'Associar um produto', 'Confirmar'],
      album: null,
      submixes: [],
    })
  }

  hasButton(music) {
    if (this.isMusicOriginal(music) || this.isMusicComercial(music)) {
      return true
    } else {
      return false
    }
  }

  isMusicComercial(music) {
    return music.category && music.category.toUpperCase() === 'MUSICA COMERCIAL'
  }

  isMusicOriginal(music) {
    return music.category && music.category.toUpperCase() === 'MUSICA ORIGINAL'
  }

  nextRouter() {
    this.props.changeMode('phonogramType')
  }

  render() {
    return (
      <Fragment>
        <MusicList
          context={this.props.context}
          changeMode={this.props.changeMode}
          hasButton={music => this.hasButton(music)}
          actionButton={() => this.nextRouter()}
          buttonName="Associar"
        />
      </Fragment>
    )
  }
}

export default IngestMusicList
