import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import mainRoutes from '../../routes/main'
import otherRoutes from '../../routes/others'
import PrivateRoute from 'components/security/private-route'

const applicationRoutes = [...mainRoutes, ...otherRoutes]

function SwitchRoutes({ isLoading }) {
  return (
    <Switch>
      {applicationRoutes.map((prop, key) => {
        if (prop.isPublic) return <Route path={prop.path} component={prop.component} key={key} />

        if (prop.redirect) return <Redirect from={prop.path} to={prop.to} key={key} />

        return (
          <PrivateRoute
            key={key}
            path={prop.path}
            isLoading={isLoading}
            component={prop.component}
            allowedPermissions={prop.allowedPermissions}
          />
        )
      })}
    </Switch>
  )
}

export default SwitchRoutes
