import React from 'react'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import Button from 'components/custom-buttons/button'
import { Container, Content, Icon, Message, WarningMessage } from './style'

const ACCEPTED_FILES = 'audio/*'

function DropzoneAudio({ handleDropFiles, automacao = 'inputUploadAudio', ...props }) {
  const { getRootProps, getInputProps } = useDropzone({
    accept: ACCEPTED_FILES,
    onDrop: handleDropFiles,
    ...props,
  })

  return (
    <Container automacao={automacao} {...getRootProps()}>
      <Content>
        <Icon />
        <Message>
          Arraste arquivos <span>ou</span>
        </Message>
        <Button size="sm" type="button" variant="outlined" value="Escolher arquivos" automacao="btnEscolherArquivos">
          Escolher arquivos
        </Button>
      </Content>

      <WarningMessage>*Formato recomendado: WAV</WarningMessage>
      <input {...getInputProps()} />
    </Container>
  )
}

DropzoneAudio.propTypes = {
  automacao: PropTypes.string,
  handleDropFiles: PropTypes.func.isRequired,
}

export default DropzoneAudio
