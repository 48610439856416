import ingestStyle from './ingestStyle'

const styles = {
  ...ingestStyle,
  removeCustomButtom: {
    fontSize: '14px',
  },
  tdGroupRow: {
    '& td': {
      borderBottom: 'none !Important',
    },
  },
}

export default styles
