const DEFAULT_DEBOUNCE_WAIT = 700

/**
 * Debounce some function
 * @param {Function} func function to debounce
 * @param {Number} wait wait in milliseconds (default wait configured above)
 * @returns
 */
function debounce(func, wait = DEFAULT_DEBOUNCE_WAIT) {
  let timeout

  return (...args) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => func.apply(this, args), wait)
  }
}

export default debounce
