import React, { Fragment } from 'react'

//api
import PhonogramApi from '../../services/phonogram-api'

//custom components
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import Button from '../../components/custom-buttons/button'

export default class InstrumentsModal extends React.Component {
  constructor(props) {
    super(props)
    this.handleToggle = this.handleToggle.bind(this)
    this.handleSaveInstruments = this.handleSaveInstruments.bind(this)
    this.phonogramApi = new PhonogramApi()
    this.state = {
      open: this.props.open,
      instruments: [],
      checkedItems: this.props.checkedItems,
      isValid: false,
    }
  }

  componentDidMount = () => {
    if (this.props.defaultItems && this.props.defaultItems.length > 0) {
      let instruments = this.props.defaultItems

      if (this.props.excludeItems && this.props.excludeItems.length > 0)
        instruments = instruments.filter(r => !this.props.excludeItems.includes(r.id))

      const isValid = this.state.checkedItems.length > 0

      this.setState({ instruments: instruments, isValid: isValid })
    } else this.getInstruments()
  }

  componentWillUnmount = () => {
    //TODO fazer com que cancele qualquer requisição
    //após o desmonte do componente
    this._mounted = false
  }

  getInstruments = () => {
    let excludeItems = this.props.excludeItems

    this.phonogramApi.getInstruments().then(result => {
      if (excludeItems && excludeItems.length > 0) result = result.filter(r => !excludeItems.includes(r.id))

      const isValid = this.state.checkedItems.length > 0

      this.setState({ instruments: result, isValid: isValid })
    })
  }

  handleToggle = () => {
    this.setState({ open: !this.state.open }, () => this.props.handleInstrumentsModal())
  }

  handleSaveInstruments = () => {
    let selected = this.state.instruments.filter(e => this.state.checkedItems.includes(e.id))
    this.props.handleChangeInstruments(selected)
    this.handleToggle()
  }

  handleChange = value => () => {
    const { checkedItems } = this.state
    const currentIndex = checkedItems.indexOf(value)
    let newChecked = [...checkedItems]

    if (currentIndex === -1) newChecked.push(value)
    else newChecked.splice(currentIndex, 1)

    const isValid = newChecked.length > 0

    this.setState({ checkedItems: newChecked, isValid: isValid })
  }

  render() {
    const instrumentsList = this.state.instruments.map(option => (
      <FormControlLabel
        key={option.id}
        control={
          <Checkbox
            checked={this.state.checkedItems.includes(option.id)}
            onChange={this.handleChange(option.id)}
            value={String(option.id)}
          />
        }
        label={option.name}
      />
    ))
    return (
      <Fragment>
        <Dialog open={this.state.open} onClose={this.props.handleInstrumentsModal} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Instrumentos</DialogTitle>
          <DialogContent>
            <DialogContentText>Escolha os instrumentos musicais.</DialogContentText>
            {instrumentsList}
          </DialogContent>
          <DialogActions>
            <Button size="sm" onClick={this.handleToggle} color="transparent">
              Cancelar
            </Button>
            <Button size="sm" onClick={this.handleSaveInstruments} disabled={!this.state.isValid} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    )
  }
}
