import { v1 as uuid } from 'uuid'
import { workApi } from './api'

class WorkApi {
  //TODO: Configurar no API Gateway esse endpoint sem cache e retirar o no-cache da url
  getWorks(title) {
    return workApi.post(`/works/filter?no-cache=${uuid()}`, {
      data: {
        title: title,
      },
    })
  }

  getAuthors(stageName) {
    return workApi.get('/authors', {
      params: {
        stageName: stageName,
        nocache: uuid(),
      },
    })
  }

  getPublishingCompanies(tradingName) {
    return workApi.get('/publishingcompanies', {
      params: {
        tradingName: tradingName,
      },
    })
  }

  getWorkById(id) {
    return workApi.get(`/works/${id}`)
  }

  //TODO: Configurar no API Gateway esse endpoint sem cache e retirar o no-cache da url
  getWorksByIds(ids) {
    return workApi.post(`/works/filter?no-cache=${uuid()}`, {
      data: {
        ids: ids,
      },
    })
  }

  getNewPhonogramIds(id) {
    return workApi.get(`/works/${id}/newphonogramids`)
  }

  postWork(payload) {
    return workApi.post('/works', { data: payload })
  }
}

export default WorkApi
