import 'moment/locale/pt-br'
import { v1 as uuid } from 'uuid'
import loginStore from '../shared/lib/login-store'
import { api, phonogramApi } from './api'

var moment = require('moment')

const DT_FMAT_FILTER = 'YYYY-MM-DD'
const DT_FMAT_DISPLAY = 'DD-MM-YYYY'

class PhonogramApi {
  //TODO: Configurar no API Gateway esse endpoint sem cache e retirar o no-cache da querystring
  getUploadSpreadSheetUrl(filename, referenceCode, folder) {
    const file = encodeURIComponent(filename)

    return phonogramApi.get(
      `/presignedurls/upload/spreadsheet?folder=${folder}&filename=${file}&referencecode=${referenceCode}&no-cache=${uuid()}`
    )
  }

  //TODO: Configurar no API Gateway esse endpoint sem cache e retirar o no-cache da querystring
  getUploadPhonogramUrl(filename, folder) {
    const file = encodeURIComponent(filename)

    return phonogramApi.get(`/presignedurls/upload/phonogram?folder=${folder}&filename=${file}&no-cache=${uuid()}`)
  }

  //TODO: Configurar no API Gateway esse endpoint sem cache e retirar o no-cache da url
  getDownloadJobUrl(jobId) {
    return phonogramApi.get(`/presignedurls/download/job/${jobId}?no-cache=${uuid()}`)
  }

  //TODO: Configurar no API Gateway esse endpoint sem cache e retirar o no-cache do payload
  //TODO: test
  postDownloadPhonogramsUrl(fileNamesAndIds, compressed) {
    const data = {
      fileNamesAndIds: fileNamesAndIds,
      compressed: encodeURIComponent(compressed),
    }

    return phonogramApi.post(`/presignedurls/download/phonograms?no-cache=${uuid()}`, { data })
  }

  //TODO: Configurar no API Gateway esse endpoint sem cache e retirar o no-cache da querystring
  getDownloadSpreadSheetUrl(filename, referencecode, folder) {
    const file = encodeURIComponent(filename)
    const reference = encodeURIComponent(referencecode)

    return phonogramApi.get(
      `/presignedurls/download/spreadsheet?folder=${folder}&filename=${file}&referencecode=${reference}&no-cache=${uuid()}`
    )
  }

  postValidateSpreadSheet(payload) {
    return phonogramApi.post('/batchingest/spreadsheet/validate', {
      data: payload,
    })
  }

  getLastStatusValidateSpreadSheet(referenceCode) {
    return phonogramApi.get(`/batchingest/summaries/${encodeURIComponent(referenceCode)}/last`)
  }

  postBatchCreate(payload) {
    return phonogramApi.post('/batchingest/folder/create', { data: payload })
  }

  postStartBatchIngest(payload) {
    return phonogramApi.post('/batchingest/start', { data: payload })
  }

  getBatchIngestValidateAudio(referenceCode) {
    const reference = encodeURIComponent(referenceCode)

    return phonogramApi.get(`/batchingest/audio/validate?referencecode=${reference}&no-cache=${uuid()}`)
  }

  getListBatchCreated(offset, limit) {
    return phonogramApi.get(`/batchingest/summaries?offset=${offset}&limit=${limit}&no-cache=${uuid()}`)
  }

  postValidateSpreadSheetIsrc(payload) {
    return phonogramApi.post('/batchisrc/spreadsheet/validate', {
      data: payload,
    })
  }

  getLastStatusValidateSpreadSheetIsrc(referenceCode) {
    return phonogramApi.get(`/batchisrc/summaries/${encodeURIComponent(referenceCode)}/last`)
  }

  postStartRegisterIsrc(payload) {
    return phonogramApi.post('/batchisrc/start', { data: payload })
  }

  getListIsrcRegistered(offset, limit) {
    return phonogramApi.get(`/batchisrc/summaries?offset=${offset}&limit=${limit}&no-cache=${uuid()}`)
  }

  // TODO: test
  putFileUpload(url, file, progress) {
    return api.put(url, {
      data: file,
      headers: { 'Content-Type': file.type },
      onUploadProgress: event => progress(event),
    })
  }

  postIngests(payload) {
    return phonogramApi.post('/ingests', { data: payload })
  }

  getPhonogramsStatusFromManualIngest(phonogramId) {
    return phonogramApi.post('/phonograms/getPhonogramsStatusFromManualIngest', { data: phonogramId })
  }

  postCheckAndUpdatePhonograms(phonogramArrayStatus) {
    return phonogramApi.post('/phonograms/checkandupdatephonograms', { data: phonogramArrayStatus })
  }

  getRecorders(filter) {
    return phonogramApi.get(`/recorders?no-cache=${uuid()}`, {
      params: {
        filter: filter,
      },
    })
  }

  postRecorder(payload) {
    return phonogramApi.post('/recorders', { data: payload })
  }

  getMoods() {
    return phonogramApi.get('/moods').then(result =>
      result
        .sort((a, b) => (a.name > b.name) - (a.name < b.name))
        .map(item => ({
          ...item,
          subMoods: item.subMoods.sort((subA, subB) => (subA.name > subB.name) - (subA.name < subB.name)),
        }))
    )
  }

  getGenres() {
    return phonogramApi.get('/genres').then(result =>
      result
        .sort((a, b) => (a.name > b.name) - (a.name < b.name))
        .map(item => ({
          ...item,
          subGenres: item.subGenres.sort((subA, subB) => (subA.name > subB.name) - (subA.name < subB.name)),
        }))
    )
  }

  getSimilars(filter) {
    return phonogramApi.get('/similars', {
      params: {
        filter: filter,
      },
    })
  }

  getTags(filter) {
    return phonogramApi.get('/tags', {
      params: {
        filter: filter,
        spellcheck: true,
      },
    })
  }

  getSuggestsTags() {
    return phonogramApi.get('/tags/suggests')
  }

  getAreas() {
    return phonogramApi.get('/areas', {
      params: {
        userId: loginStore.getUserId(),
      },
    })
  }

  getProducts() {
    return phonogramApi.get('/products', {
      params: {
        userId: loginStore.getUserId(),
      },
    })
  }

  getPhonogramsByISRCs(isrcs) {
    return phonogramApi.post('/legacyphonograms/filter', { data: isrcs })
  }

  getPhonogramsDuplicates(phonograms) {
    return phonogramApi.post('/legacyphonograms/duplicates', {
      data: phonograms,
    })
  }

  getFullMix(phonograms) {
    return phonogramApi.post('/legacyphonograms/fullmix', { data: phonograms })
  }

  getPhonogramsPendency(filters) {
    return phonogramApi.post(`/products/pendencies?no-cache=${uuid()}`, {
      data: filters,
    })
  }

  putPhonogram(payload) {
    return phonogramApi.put(`/phonograms/${payload.id}`, { data: payload })
  }

  getPhonogram(phonogramId) {
    return phonogramApi.get(`/phonograms/${phonogramId}`, {
      params: {
        nocache: uuid(),
      },
    })
  }

  //TODO: Configurar no API Gateway esse endpoint sem cache e retirar o no-cache da url
  getAdmPendingList(filters) {
    const data = {
      ...filters,
    }

    if (data['date']) {
      let convertedDate = moment(data['date'], DT_FMAT_DISPLAY)
      if (convertedDate.isValid()) {
        data['ingestDate'] = convertedDate.format(DT_FMAT_FILTER)
        delete data['date']
      }
    }

    return phonogramApi.post(`/products/pendenciesamount?no-cache=${uuid()}`, {
      data,
    })
  }

  //TODO: Configurar no API Gateway esse endpoint sem cache e retirar o no-cache da url
  getAdmPendingDetail(productId, filters) {
    const data = {
      ...filters,
      status: 'P',
    }

    if (data['date']) {
      let convertedDate = moment(data['date'], DT_FMAT_DISPLAY)
      if (convertedDate.isValid()) {
        data['ingestDate'] = convertedDate.format(DT_FMAT_FILTER)
        delete data['date']
      }
    }

    return phonogramApi.post(`/products/${productId}/pendencies?no-cache=${uuid()}`, { data })
  }

  //TODO: Configurar no API Gateway esse endpoint sem cache e retirar o no-cache da url
  getAllJobWithPhonograms(filters) {
    const data = {
      ...filters,
    }

    if (data['date']) {
      let convertedDate = moment(data['date'], DT_FMAT_DISPLAY)
      if (convertedDate.isValid()) {
        data['ingestDate'] = convertedDate.format(DT_FMAT_FILTER)
        delete data['date']
      }
    }

    return phonogramApi.post(`/jobs/filter?no-cache=${uuid()}`, { data })
  }

  //TODO: Configurar no API Gateway esse endpoint sem cache e retirar o no-cache da url
  getPhonogramsByIds(phonogramIds) {
    return phonogramApi.post(`/products/pendencies?no-cache=${uuid()}`, {
      data: {
        phonogramIds,
      },
    })
  }

  //TODO: Configurar no API Gateway esse endpoint sem cache e retirar o no-cache da url
  getMusicians(name) {
    return phonogramApi.post(`/musicians/filter?no-cache=${uuid()}`, {
      data: {
        name,
      },
    })
  }

  //TODO: Configurar no API Gateway esse endpoint sem cache e retirar o no-cache da url
  getPerformers(name) {
    return phonogramApi.post(`/musicians/filter?no-cache=${uuid()}`, {
      data: {
        name: name,
        type: 73,
      },
    })
  }

  //TODO: Configurar no API Gateway esse endpoint sem cache e retirar o no-cache da url
  getInstrumentists(name) {
    return phonogramApi.post(`/musicians/filter?no-cache=${uuid()}`, {
      data: {
        name: name,
        type: 77,
      },
    })
  }

  //TODO: Configurar no API Gateway esse endpoint sem cache e retirar o no-cache do payload
  getMusician(musicianId) {
    return phonogramApi.get(`/musicians/${musicianId}`, {
      params: {
        nocache: uuid(),
      },
    })
  }

  //TODO: Configurar no API Gateway esse endpoint sem cache e retirar o no-cache da url
  getMusiciansByIds(musicianIds) {
    return phonogramApi.post(`/musicians/filter?no-cache=${uuid()}`, {
      data: {
        ids: musicianIds,
      },
    })
  }

  postMusician(payload) {
    return phonogramApi.post('/musicians', { data: payload })
  }

  putMusician(payload) {
    return phonogramApi.put(`/musicians/${payload.id}`, { data: payload })
  }

  getInstruments(filter) {
    return phonogramApi.get('/instruments', {
      params: {
        filter: filter,
      },
    })
  }

  getInstrumentsList() {
    return phonogramApi.get('/instruments-new')
  }

  //TODO: Configurar no API Gateway esse endpoint sem cache e retirar o no-cache do payload
  getGroups(filter, login, includeMusicians) {
    let params = {
      name: filter,
      nocache: uuid(),
      userName: loginStore.getUserLogin(),
    }

    if (includeMusicians) {
      params = {
        ...params,
        include: 'musician',
        musicianName: filter,
      }
    }
    return phonogramApi.get('/groups', { params })
  }

  //TODO: Configurar no API Gateway esse endpoint sem cache e retirar o no-cache do payload
  getGroup(groupId) {
    return phonogramApi.get(`/groups/${groupId}`, {
      params: {
        nocache: uuid(),
      },
    })
  }

  postGroups(payload) {
    return phonogramApi.post(`/groups`, { data: payload })
  }

  putGroup(payload) {
    return phonogramApi.put(`/groups/${payload.id}`, { data: payload })
  }

  postMusicRequest(payload) {
    return phonogramApi.post('/musicrequests', { data: payload })
  }

  getSlasById(isNational) {
    return phonogramApi.get('/musicrequests/getslasbyids', {
      params: {
        isNational,
      },
    })
  }

  getMusicRequest(requestId) {
    return phonogramApi.get(`/musicrequests/${requestId}?no-cache=${uuid()}`)
  }
}

export default PhonogramApi
