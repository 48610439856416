const stepsStyle = {
  steps: {
    width: '100%',
    margin: '0 auto',
    maxWidth: '845px',
  },

  stepper: {
    backgroundColor: 'transparent',
    padding: 0,
    margin: '0 0 35px',
  },
}
export default stepsStyle
