import { createGlobalStyle } from 'styled-components'

const globalStyle = createGlobalStyle`
    body {
        font-family: 'Roboto', Helvetica, Arial, sans-serif;
        background-color: ${({ theme }) => theme.palette.background.default};
    }

    #root {
      overflow: hidden;
    }
`

export default globalStyle
