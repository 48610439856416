import React, { Component, Fragment } from 'react'

import PhonogramForm from '../phonogram/phonogram-form'
import PhonogramApi from '../../../services/phonogram-api'
import Snackbar from '../../../components/snackbar/snackbar'

import loginStore from '../../../shared/lib/login-store'

import CircularProgress from '@material-ui/core/CircularProgress'
import withStyles from '@material-ui/core/styles/withStyles'

import ingestFromRequestStyle from '../../../assets/jss/views/ingest/ingestFromRequestStyle.jsx'
import _ from 'lodash'
import { toCapitalize } from '../../../shared/helpers/text-decorator'

class IngestFromRequest extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      statusResponse: '',
      snackbarMessage: 'Erro ao obter dados da solicitação',
    }

    this.loginStore = loginStore
    this.phonogramApi = new PhonogramApi()
  }

  componentDidMount() {
    this.phonogramApi
      .getMusicRequest(this.props.requestId)
      .then(data => {
        if (data.canIngest) {
          const work = data.work
          const musicRequestId = data.id
          const canBeUnreleased = data.allowUnreleased
          const phonogramType = this.getPhonogramType(data.phonogramType)
          const phonogram = this.getPhonogramData(data)
          const product = _.deburr(data.work.category).toUpperCase() === 'MUSICA ORIGINAL' ? data.product : null

          if (phonogramType === 'Performance') {
            this.setPerformanceSelected(phonogram, product, work, phonogramType, musicRequestId, canBeUnreleased)
          } else if (phonogramType === 'Comercial') {
            this.setCommercialSelected(phonogram, product, work, phonogramType, musicRequestId, canBeUnreleased)
          } else {
            this.setOriginalSelected(phonogram, product, work, phonogramType, musicRequestId, canBeUnreleased)
          }

          this.setState({
            loading: false,
          })
        } else {
          this.setState({
            statusResponse: 'error',
            snackbarMessage: 'Solicitação inválida.',
            loading: false,
          })
        }
      })
      .catch(error => {
        console.error('error: ', error)
        this.setState({
          statusResponse: 'error',
          loading: false,
        })
      })
  }

  getPhonogramData(data) {
    return {
      performerOptionsSelected: data.performers.map(performer => ({
        id: performer.id,
        fullName: performer.fullName,
        label: toCapitalize(performer.stageName + ' - ' + performer.fullName),
        stageName: performer.stageName,
        value: String(performer.id),
        recorder: performer.recorder,
      })),
      musicians: [],
      recorder: data.recorder,
      isrc: data.isrc,
      acceptedFiles: [],
      year: null,
      bpm: null,
      moods: [],
      genres: [],
      tags: [],
    }
  }

  setPerformanceSelected(phonogram, product, work, phonogramType, id, canBeUnreleased) {
    this.props.context.changeState({
      musicRequestId: id,
      ingestFromRequest: true,
      musicSelected: work,
      canBeUnreleased,
      phonogramType,
      submixDraft: phonogram,
      isrc: null,
      product,
      steps: ['Adicionar Arquivo(s)', 'Confirmar'],
    })
  }

  setCommercialSelected(phonogram, product, work, phonogramType, id, canBeUnreleased) {
    this.props.context.changeState({
      musicRequestId: id,
      ingestFromRequest: true,
      musicSelected: work,
      canBeUnreleased,
      phonogramType,
      submixDraft: phonogram,
      recorder: phonogram.recorder,
      isrc: phonogram.isrc,
      product,
      steps: ['Adicionar Arquivo(s)', 'Confirmar'],
    })
  }

  setOriginalSelected(phonogram, product, work, phonogramType, id, canBeUnreleased) {
    this.props.context.changeState({
      musicRequestId: id,
      ingestFromRequest: true,
      musicSelected: work,
      canBeUnreleased,
      phonogramType,
      submixDraft: phonogram,
      submixes: [],
      product,
      steps: ['Adicionar Arquivo(s)', 'Ficha Técnica', 'Confirmar'],
    })
  }

  getPhonogramType(phonogramType) {
    if (phonogramType === null) {
      return 'Performance'
    } else if (phonogramType === 2) {
      return 'Comercial'
    } else {
      return 'Original'
    }
  }

  handleCloseSnackError() {
    this.props.history.push('/ingest')
  }

  isOpenSnackBarError() {
    return this.state.statusResponse === 'error'
  }

  render() {
    const { classes } = this.props

    return (
      <Fragment>
        {this.state.loading ? (
          <div className={classes.loadingContainer}>
            <CircularProgress size={24} />
          </div>
        ) : (
          <Fragment>
            {!this.state.statusResponse ? (
              <PhonogramForm
                context={this.props.context}
                history={this.props.history}
                changeMode={this.props.changeMode}
              />
            ) : (
              <div className="snackBarWrapper">
                <Snackbar
                  place="bc"
                  message={this.state.snackbarMessage}
                  open={this.isOpenSnackBarError()}
                  close
                  color="danger"
                  closeNotification={() => this.handleCloseSnackError()}
                />
              </div>
            )}
          </Fragment>
        )}
      </Fragment>
    )
  }
}

export default withStyles(ingestFromRequestStyle)(IngestFromRequest)
