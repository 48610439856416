import client from './client'

const base = (api = '') => ({
  request(path, options) {
    return client.request(`${api}${path}`, options)
  },

  get(path, options) {
    return client.request(`${api}${path}`, { ...options, data: null })
  },

  put(path, options = {}) {
    return client.request(`${api}${path}`, { ...options, method: 'PUT' })
  },

  post(path, options = {}) {
    return client.request(`${api}${path}`, { ...options, method: 'POST' })
  },

  delete(path, options = {}) {
    return client.request(`${api}${path}`, { ...options, method: 'DELETE' })
  },
})

export default base
