import axios from 'axios'
import qs from 'qs'
import reqInterceptor from './interceptors/request'
import respInterceptor from './interceptors/response'

const client = axios.create({
  paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
})

client.interceptors.request.use(reqInterceptor.onSuccess(), reqInterceptor.onError())

client.interceptors.response.use(respInterceptor.onSuccess(), respInterceptor.onError(client))

export { reqInterceptor, respInterceptor }
export default client
