import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import listWithSubItemsStyle from '../../assets/jss/components/listWithSubItemsStyle.jsx'

class ListWithSubItems extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      indexItemActive: null,
      itemsSelected: props.itemsSelectedDefault || [],
      maxSelection: props.maxSelection || 3,
      itemsSelectedCounter: props.itemsSelectedDefault ? this.getItemSelectedCounter(props.itemsSelectedDefault) : {},
    }
  }

  isMaxSelection = () => this.state.itemsSelected.length === this.state.maxSelection

  onChangeItemSelected = subItem => {
    const newItemsSelected = [...this.state.itemsSelected]
    const indexItemSelected = newItemsSelected.indexOf(subItem.id)

    if (indexItemSelected === -1) {
      newItemsSelected.push(subItem.id)
    } else {
      newItemsSelected.splice(indexItemSelected, 1)
    }

    this.setState(
      {
        itemsSelected: newItemsSelected,
        itemsSelectedCounter: this.getItemSelectedCounter(newItemsSelected),
      },
      () => {
        if (this.props.onChangeItemSelected) {
          this.props.onChangeItemSelected(newItemsSelected)
        }
      }
    )
  }

  getItemSelectedCounter = newItemsSelected => {
    var itemsSelectedCounter = {}

    newItemsSelected.map(idItemSelected => {
      let parentItem = this.props.list.filter(item => item.subItems.some(subItem => subItem.id === idItemSelected))[0]
      if (parentItem) {
        if (itemsSelectedCounter[parentItem.name]) {
          itemsSelectedCounter[parentItem.name].push(idItemSelected)
        } else {
          itemsSelectedCounter[parentItem.name] = [idItemSelected]
        }
      }
      return itemsSelectedCounter
    })
    return itemsSelectedCounter
  }

  createListSubItem = activeItemIndex => {
    const { listSubItem, listSubItem_Item, checkbox, checkBoxIcon } = this.props.classes

    const { itemsSelected } = this.state

    if (activeItemIndex === null) {
      return null
    }

    return (
      <ul className={listSubItem}>
        {this.props.list[activeItemIndex].subItems.map((subItem, index) => (
          <li className={listSubItem_Item} key={index}>
            <FormControlLabel
              control={
                <Checkbox
                  id={`subItem${activeItemIndex}${index}`}
                  className={checkbox}
                  icon={<CheckBoxOutlineBlankIcon className={checkBoxIcon} />}
                  checkedIcon={<CheckBoxIcon className={checkBoxIcon} />}
                  checked={itemsSelected.includes(subItem.id)}
                  onChange={() => this.onChangeItemSelected(subItem)}
                  disabled={this.isMaxSelection() && !itemsSelected.includes(subItem.id)}
                  value={subItem.id.toString()}
                />
              }
              label={subItem.name}
            />
          </li>
        ))}
      </ul>
    )
  }

  setItemActive = index => {
    this.setState({
      indexItemActive: index,
    })
  }

  createListPrincipal = list => {
    const { listPrincipalItem, activeItemPrincipal, counter } = this.props.classes
    const { indexItemActive } = this.state

    return list ? (
      <ul className="listPrincipal">
        {list.map((item, index) => (
          <li className={`${listPrincipalItem} ${indexItemActive === index ? activeItemPrincipal : ''}`} key={index}>
            <a id={`title${index}`} onClick={() => this.setItemActive(index)}>
              {item.name.replace(':', '')}
              {this.state.itemsSelectedCounter[item.name] ? (
                <span className={counter}>({this.state.itemsSelectedCounter[item.name].length})</span>
              ) : null}
            </a>
          </li>
        ))}
      </ul>
    ) : null
  }

  render() {
    const { list } = this.props
    const { gridStyle } = this.props.classes
    const { indexItemActive } = this.state

    return (
      <Grid className={gridStyle}>
        {this.createListPrincipal(list)}
        {this.createListSubItem(indexItemActive)}
      </Grid>
    )
  }
}

export default withStyles(listWithSubItemsStyle)(ListWithSubItems)
