import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { toCapitalize } from '../../../shared/helpers/text-decorator'
import _ from 'lodash'

//api
import PhonogramApi from '../../../services/phonogram-api'

//@material-ui
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import MusicNoteIcon from '@material-ui/icons/MusicNote'

import Button from '../../../components/custom-buttons/button'
import CustomAsyncSelect from '../../../components/custom-asyncselect/custom-asyncselect'
import CreditsMusicianRow from './credits-musician-row'
import CreditsGroupRow from './credits-group-row'
import MusiciansModal from '../../musician/musicians-modal'
import GroupsModal from '../../musician/groups-modal'
import InstrumentsModal from '../../musician/instruments-modal'
import creditsModalStyle from '../../../assets/jss/views/ingest/creditsModalStyle.jsx'
import loginStore from '../../../shared/lib/login-store'

class CreditsModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: this.props.open,
      openMusicians: false,
      openGroups: false,
      openInstruments: false,
      credits: [],
    }
    this.handleMusiciansModal = this.handleMusiciansModal.bind(this)
    this.handleEditMusicianClick = this.handleEditMusicianClick.bind(this)
    this.handleEditGroupClick = this.handleEditGroupClick.bind(this)
    this.phonogramApi = new PhonogramApi()
  }

  componentDidMount = () => {
    this.load()
  }

  load = () => {
    const { selectedsIndexs } = this.props
    const submixes = this.props.context.submixes

    if (Array.isArray(submixes) && selectedsIndexs) {
      let _cdts = submixes[_.first(selectedsIndexs)].credits

      if (Array.isArray(_cdts)) {
        this.setState({ credits: _cdts })
      }
    }
  }

  handleToggle = () => {
    this.props.handleCreditsModal()
  }

  handleSaveCredits = () => {
    this.props.handleChangeCredits(this.state.credits)
    this.handleToggle()
  }

  handleCreditsOptionSelect = obj => {
    if (obj === null || obj.length === 0) return

    if (obj.musician !== null && obj.musician.favoriteMusicalInstrument !== null) {
      let musicianFound = this._getMusicianById(obj.musician.id)
      if (musicianFound) {
        obj.musician.musicalInstruments = musicianFound.musicalInstruments
      } else if (obj.musician.favoriteMusicalInstrument !== null) {
        obj.musician.musicalInstruments.forEach(musicalInstrument => {
          musicalInstrument.choosed = obj.musician.favoriteMusicalInstrument.id === musicalInstrument.id
        })
      }
    }
    if (obj.group !== null) {
      obj.group.musicians.forEach(musician => {
        let musicianFound = this._getMusicianById(musician.id)
        if (musicianFound) {
          musician.musicalInstruments = musicianFound.musicalInstruments
        } else if (musician.favoriteMusicalInstrument !== null) {
          musician.musicalInstruments.forEach(musicalInstrument => {
            musicalInstrument.choosed = musician.favoriteMusicalInstrument.id === musicalInstrument.id
          })
        }
      })
    }

    const newCredits = this.state.credits.concat(obj)
    this.setState({ credits: newCredits })
  }

  handleCreditsOptionsChange = obj => {
    if (obj === null) return

    const newCredits = this.state.credits.map(newCredit => {
      if (newCredit.value === obj.value && obj.musician !== null) {
        return {
          ...newCredit,
          label: toCapitalize(obj.musician.stageName),
          musician: {
            ...obj.musician,
            musicalInstruments: obj.musician.musicalInstruments.map(instrument => ({
              ...instrument,
              choosed:
                newCredit.musician.musicalInstruments.filter(i => i.id === instrument.id && i.choosed).length > 0,
            })),
          },
        }
      }

      if (newCredit.value === obj.value && obj.group !== null) {
        let musicians = obj.group.musicians.map(musician => {
          if (newCredit.group.musicians.filter(m => m.id === musician.id).length === 0) {
            if (musician.favoriteMusicalInstrument !== null) {
              musician.musicalInstruments.forEach(musicalInstrument => {
                musicalInstrument.choosed = musician.favoriteMusicalInstrument.id === musicalInstrument.id
              })
            }
          } else
            musician.musicalInstruments = newCredit.group.musicians.filter(
              m => m.id === musician.id
            )[0].musicalInstruments

          return musician
        })

        return {
          ...newCredit,
          label:
            toCapitalize(obj.group.name) +
            ' - grupo > ' +
            toCapitalize(obj.group.musicians.map(m => m.stageName).join(', ')),
          group: {
            ...obj.group,
            musicians: musicians,
          },
        }
      }

      return newCredit
    })

    this.setState({ credits: newCredits })
  }

  handleMusiciansModal = () => {
    this.setState({ openMusicians: !this.state.openMusicians, musicianId: null })
  }

  handleGroupsModal = () => {
    this.setState({ openGroups: !this.state.openGroups, groupId: null })
  }

  handleEditMusicianClick = id => {
    this.setState({ openMusicians: !this.state.openMusicians, musicianId: id })
  }

  handleEditGroupClick = id => {
    this.setState({ openGroups: !this.state.openGroups, groupId: id })
  }

  loadCreditsOptions = (inputValue, callback) => {
    if (inputValue.length > 2) {
      let selectedGroupsIds = []
      let selectedMusiciansIds = []
      this.state.credits.forEach(c => {
        if (c.group != null) {
          selectedGroupsIds.push(c.group.id)
          c.group.musicians.forEach(m => {
            selectedMusiciansIds.push(m.id)
          })
        }
        if (c.musician != null) {
          selectedMusiciansIds.push(c.musician.id)
        }
      })
      setTimeout(() => {
        const musiciansPromise = this.phonogramApi.getInstrumentists(inputValue).catch(() => Promise.resolve(undefined))
        const groupsPromise = this.phonogramApi
          .getGroups(inputValue, loginStore.getUserLogin(), true)
          .catch(() => Promise.resolve(undefined))
        Promise.all([musiciansPromise, groupsPromise]).then(
          results => {
            const musicians = results[0]
            const groups = results[1]

            let filtered = []
            if (groups !== undefined) {
              filtered = groups
                .filter(g => !selectedGroupsIds.includes(g.id))
                .map(g => ({
                  value: 'group_' + String(g.id),
                  label:
                    toCapitalize(g.name) + ' - grupo > ' + toCapitalize(g.musicians.map(m => m.stageName).join(', ')),
                  musician: null,
                  group: g,
                }))
            }
            if (musicians !== undefined) {
              filtered = filtered.concat(
                musicians
                  .filter(m => !selectedMusiciansIds.includes(m.id))
                  .map(m => ({
                    value: 'musician_' + String(m.id),
                    label: toCapitalize(m.stageName),
                    musician: m,
                    group: null,
                  }))
              )
            }

            callback(filtered)
          },
          () => {
            callback([])
          }
        )
      }, 500)
    } else {
      callback([])
    }
  }

  handleRemoveMusicianClick = musicianId => {
    let newCredits = this.state.credits
      .filter(c => c.musician === null || c.musician.id !== musicianId)
      .map(credit => {
        if (credit.group !== null) {
          credit.group.musicians = credit.group.musicians.filter(musician => musician.id !== musicianId)
          return credit
        } else {
          return credit
        }
      })
      .filter(c => c.musician !== null || (c.group !== null && c.group.musicians.length > 0))
    this.setState({ credits: newCredits })
  }

  handleRemoveGroupClick = groupId => {
    let newCredits = this.state.credits.filter(c => c.musician !== null || (c.group !== null && c.group.id !== groupId))
    this.setState({ credits: newCredits })
  }

  handleInstrumentsModal = id => {
    let instrumentIdsCheckedItems = []
    let instrumentDefaultItems = []

    if (id) {
      let musician = this._getMusicianById(id)
      instrumentIdsCheckedItems = musician.musicalInstruments.filter(mi => mi.choosed === true).map(mi => mi.id)
      instrumentDefaultItems = musician.musicalInstruments
    }

    this.setState({
      musicianIdToChangeInstruments: id,
      instrumentIdsCheckedItems: instrumentIdsCheckedItems,
      instrumentDefaultItems: instrumentDefaultItems,
      openInstruments: !this.state.openInstruments,
    })
  }

  handleChangeInstruments = items => {
    const newCredits = this.state.credits.map(credit => {
      if (credit.musician !== null && credit.musician.id === this.state.musicianIdToChangeInstruments) {
        credit.musician.musicalInstruments = credit.musician.musicalInstruments.map(instrument => ({
          ...instrument,
          choosed: items.filter(item => item.id === instrument.id).length > 0,
        }))
        return credit
      } else if (credit.group !== null) {
        credit.group.musicians = credit.group.musicians.map(musician => {
          if (musician.id === this.state.musicianIdToChangeInstruments) {
            musician.musicalInstruments = musician.musicalInstruments.map(instrument => ({
              ...instrument,
              choosed: items.filter(item => item.id === instrument.id).length > 0,
            }))
            return musician
          } else {
            return musician
          }
        })
        return credit
      } else {
        return credit
      }
    })
    this.setState({ credits: newCredits })
  }

  _getMusicianById = musicianId => {
    let filtered = this.state.credits.filter(c => c.musician !== null && c.musician.id === musicianId)
    if (filtered.length > 0) {
      return filtered[0].musician
    } else {
      filtered = this.state.credits.filter(
        c => c.group !== null && c.group.musicians.filter(m => m.id === musicianId).length > 0
      )
      if (filtered.length > 0) {
        return filtered[0].group.musicians.filter(m => m.id === musicianId)[0]
      }
    }
  }

  render = () => {
    const { classes, open } = this.props

    let rows = []
    this.state.credits.forEach((c, i) => {
      if (c.musician !== null) {
        rows.push(
          <CreditsMusicianRow
            key={i}
            musician={c.musician}
            handleInstrumentsModal={this.handleInstrumentsModal}
            handleEditMusicianClick={this.handleEditMusicianClick}
            handleRemoveMusicianClick={this.handleRemoveMusicianClick}
          />
        )
      }
      if (c.group !== null) {
        rows.push(
          <CreditsGroupRow
            key={c.group + '_' + i}
            group={c.group}
            handleInstrumentsModal={this.handleInstrumentsModal}
            handleEditGroupClick={this.handleEditGroupClick}
            handleRemoveGroupClick={this.handleRemoveGroupClick}
            handleRemoveMusicianClick={this.handleRemoveMusicianClick}
          />
        )
      }
    })

    return (
      <Dialog
        open={open}
        onClose={this.props.handleCreditsModal}
        aria-labelledby="form-dialog-title"
        maxWidth={'md'}
        classes={{ paper: classes.paper }}
      >
        <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
          <span className={classes.title}> Ficha Técnica </span>
        </DialogTitle>
        <DialogContent>
          {this.state.submitting ? (
            <CircularProgress size={24} />
          ) : (
            <Fragment>
              <div style={{ paddingBottom: '8px' }}>
                <MusicNoteIcon />
                {this.props.selectedsIndexs.length === 1
                  ? this.props.context.submixes[this.props.selectedsIndexs[0]].submix
                  : this.props.selectedsIndexs.map(idxSelected => {
                      if (_.last(this.props.selectedsIndexs) === idxSelected)
                        return this.props.context.submixes[idxSelected].submix

                      return `${this.props.context.submixes[idxSelected].submix},`
                    })}
              </div>
              <CustomAsyncSelect
                id="selectCredits"
                inputId="selectCreditsInput"
                placeholder="Selecione o músico ou o grupo de músicos"
                loadOptions={this.loadCreditsOptions}
                onChange={this.handleCreditsOptionSelect}
                value={null}
                loadingMessage={() => 'Carregando...'}
                noOptionsMessage={() => 'Nenhum item encontrado.'}
                isClearable
                option_MenuItem_1={{ text: 'Músico', onClick: this.handleMusiciansModal }}
                option_MenuItem_2={{ text: 'Grupo', onClick: this.handleGroupsModal }}
              />
            </Fragment>
          )}
          {!!rows.length && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  <TableCell colSpan="2">Instrumento</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{rows}</TableBody>
            </Table>
          )}
          {this.state.openInstruments ? (
            <InstrumentsModal
              open={this.state.openInstruments}
              handleInstrumentsModal={this.handleInstrumentsModal}
              checkedItems={this.state.instrumentIdsCheckedItems}
              defaultItems={this.state.instrumentDefaultItems}
              handleChangeInstruments={this.handleChangeInstruments}
            />
          ) : null}
          {this.state.openMusicians ? (
            <MusiciansModal
              context={this.props.context}
              open={this.state.openMusicians}
              musicianId={this.state.musicianId}
              instrumentistsOnly={true}
              handleMusiciansModal={this.handleMusiciansModal}
              handleCreditsOptionsChange={this.handleCreditsOptionsChange}
            />
          ) : null}
          {this.state.openGroups ? (
            <GroupsModal
              context={this.props.context}
              open={this.state.openGroups}
              groupId={this.state.groupId}
              handleGroupsModal={this.handleGroupsModal}
              handleCreditsOptionsChange={this.handleCreditsOptionsChange}
            />
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleToggle} color="transparent">
            Cancelar
          </Button>
          <Button onClick={this.handleSaveCredits} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

CreditsModal.propTypes = {
  submixes: PropTypes.object.isRequired,
  selectedsIndexs: PropTypes.array.isRequired,
  handleCreditsModal: PropTypes.func.isRequired,
  handleChangeCredits: PropTypes.func.isRequired,
}

export default withStyles(creditsModalStyle)(CreditsModal)
