const WorkSelectedInfoStyle = () => ({
  workSelectInfoWrapper: {
    width: '845px',
    height: '46px',
    margin: '0 auto',
    display: 'flex',
    padding: '0 15px',
    boxSizing: 'border-box',
    alignItems: 'center',
    borderRadius: '4px',
    backgroundColor: '#eaeaea',
  },

  workGridItem: {
    padding: '0',
    display: 'flex',
    marginRight: '16px',
    height: '18px',
  },

  workList: {
    display: 'flex',
    listStyle: 'none',
    padding: '0',
    margin: '0',
    // width: "465px",
    whiteSpace: 'nowrap',
    '& > li': {
      fontFamily: 'Roboto',
      fontSize: '13px',
      height: '15px',
      color: '#000',
    },
  },
})

export default WorkSelectedInfoStyle
