import ingestStyle from './ingestStyle'

const styles = {
  ...ingestStyle,
  workTitleH5: {
    fontFamily: 'Roboto',
    fontSize: '22px',
    color: '#c4c4c4',
    marginLeft: '8px',
    fontWeight: 400,
    margin: 0,
    display: 'inline-table',
  },
  workTitleLabel: {
    fontWeight: 600,
    fontFamily: 'Roboto',
    marginTop: '5px',
    float: 'left',
  },
  listPhonograms: {
    paddingBottom: '15px !important',
  },
  titleBar: {
    height: '35px',
    marginBottom: '16px',
  },
}

export default styles
