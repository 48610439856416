import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'perfect-scrollbar'
import 'perfect-scrollbar/css/perfect-scrollbar.css'

import Header from 'components/header/header'
import Footer from 'components/footer/footer'
import Appbar from 'components/appbar'
import otherRoutes from '../../routes/others'
import loginStore from '../../shared/lib/login-store'
import { getAllowedRoutes, isAuthRoute } from './util'
import { Main as MainWrapper, Content } from './styles'
import SwitchRoutes from './routes'

function Main({ location, history }) {
  const [routes, setRoutes] = useState()
  const [userInfo, setUserInfo] = useState({
    name: loginStore.getUserName(),
    login: loginStore.getUserLogin(),
  })

  const loadRoutes = () => {
    const userPermissions = loginStore.getUserFeatures()
    const allowedRoutes = getAllowedRoutes(userPermissions)

    setRoutes(allowedRoutes)
  }

  const redirectToAuth = () => {
    if (!isAuthRoute(location.pathname)) {
      localStorage.setItem('routerAfterLogin', location.pathname)
      history.push('/auth')
    }
  }

  const triggerPermissionsEvent = () => {
    const quantityMinutes = 15
    const interval = 60000 * quantityMinutes

    console.debug(`Iniciando uma trigger de perfil a cada  ${quantityMinutes} min`)
    loginStore.workingLoadUserInfo(userInfo => {
      loadRoutes()
      setUserInfo(userInfo)
    }, interval)
  }

  useEffect(() => {
    if (!loginStore.isLoggedIn()) {
      redirectToAuth()
      return
    }

    loginStore
      .validate()
      .then(() => {
        loadRoutes()
        triggerPermissionsEvent()
      })
      .catch(() => {
        loginStore.clear()
        history.push('/auth')
      })
    // eslint-disable-next-line
  }, [loginStore.isLoggedIn()])

  useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      new PerfectScrollbar('#mainPanel')
    }
  }, [])

  useEffect(() => {
    //resolvendo parcialmente o problema do pendingadm->resolved
    //TODO: a mudança de aba em pendindadm para pendindadm/resolved não deveria existir
    const resource = `/${location.pathname.split('/')[1]}`
    handlePageTitle()
    if (
      routes &&
      routes.length &&
      routes.some(route => route.path === resource) === false &&
      otherRoutes.some(route => route.path.toUpperCase().includes(resource.toUpperCase())) === false
    ) {
      history.push('/forbidden')
    }
    // eslint-disable-next-line
  }, [location, routes])

  const handlePageTitle = () => {
    const pageTitles = {
      '/': '',
      '/ingest': '- Ingest',
      '/request': '- Solicitação de Música',
      '/batchingest': '- Ingest em Lote',
      '/pending': '- Pendências',
      '/pendingadm': '- Pendências ADM',
      '/register-isrc': '- Cadastrar ISRC',
    }
    const currentTitle = pageTitles[location.pathname] || ''
    const customAppTitle = `GMusic ${currentTitle}`
    document.title = customAppTitle
  }

  return (
    <>
      <Appbar routes={routes} userInfo={userInfo} location={location} />

      <MainWrapper id="mainPanel">
        <Header routes={routes} location={location} />
        <Content>
          <SwitchRoutes isLoading={!routes} />
        </Content>
      </MainWrapper>

      <Footer />
    </>
  )
}

Main.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
}

export default Main
