import React from 'react'
import PropTypes from 'prop-types'
import { ptBR } from 'date-fns/locale'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

const DateProvider = ({ children }) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
    {children}
  </MuiPickersUtilsProvider>
)

DateProvider.propTypes = {
  children: PropTypes.any,
}

export default DateProvider
