import tooltipStyle from '../../components/tooltipStyle.jsx'
import checkboxAdnRadioStyle from '../../components/checkboxAdnRadioStyle'

import NotAllowed from '../../../img/svg/not-allowed.svg'
import AlertIcon from '../../../img/svg/alert.svg'

const fileUploadStyle = theme => ({
  ...tooltipStyle,
  ...checkboxAdnRadioStyle,
  corruptedCard: {
    border: '2px solid #B30303',
    display: 'flex',
    width: '100%',
    margin: '12px auto 0 auto',
    borderRadius: '4px',
    '&:first-child': {
      marginTop: '30px',
    },
  },
  card: {
    display: 'flex',
    width: '100%',
    margin: '12px auto 0 auto',
    borderRadius: '4px',
    '&:first-child': {
      marginTop: '30px',
    },
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    backgroundColor: '#ddd',
    width: 128,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
  },
  coverImg: {
    margin: 'auto',
  },
  boxName: {
    borderRight: '2px solid #eee',
    width: '300px',
    minHeight: '100%',
  },
  boxInput: {
    padding: '20px 0 20px 20px',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    '& > div': {
      width: '170px',
      margin: '0 32px 0 0',

      '&:last-child': {
        margin: '0',
      },

      '& input::placeholder': {
        fontSize: '14px',
      },
    },
  },
  boxInputFullWidth: {
    '& > div': {
      width: 'calc(100% - 57px)',
    },
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(),
    paddingBottom: theme.spacing(),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  verticalHr: {
    width: '1px',
    border: 'none',
    height: '100%',
    borderLeft: '2px solid #eee',
    margin: 0,
  },
  itemCenter: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 0 0 20px',
    height: '45%',
  },
  itemLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  itemCenterBottom: {
    display: 'flex',
    alignItems: 'flex-end',
    padding: '0 0 0 20px',
    height: '54%',
  },
  closeIcon: {
    backgroundColor: 'transparent',
    color: '#000',
    boxShadow: 'none',
  },
  actionButton: {
    width: '27px',
    height: '27px',
    margin: '3px 2px 0 0',
  },
  actionButtonError: {
    width: '27px',
    height: '27px',
    margin: '0 4px 0 0',
  },
  linearColorPrimary: {
    backgroundColor: '#999999',
  },
  linearBarColorPrimary: {
    backgroundColor: '#666666',
  },
  linearColorError: {
    backgroundColor: '#d3b1b5',
  },
  controlIcon: {
    width: '17px',
    height: '17px',
    color: '#000',
  },
  controlIconError: {
    height: '22px',
    color: '#fff',
  },
  boxControls: {
    position: 'absolute',
    right: '0',
    top: '0',
    display: 'block !important',
    width: '100%',
    textAlign: 'right',

    '& ul': {
      float: 'right',
      listStyle: 'none',
      margin: '0',
      padding: '0',
    },

    '& ul li': {
      float: 'left',
      listStyle: 'none',
      margin: '0',
      padding: '0',
    },
  },
  progressContainer: {
    position: 'relative',
    width: '100%',
    height: '28px',
    margin: '12px auto 0 auto',

    '&:first-child': {
      marginTop: '30px',
    },

    '& .progress': {
      position: 'absolute',
      top: '0',
      width: '100%',
      height: '100%',
      minHeight: '100%',
      zIndex: '1000',
    },

    '& .progress-info': {
      position: 'absolute',
      top: '0',
      width: '100%',
      height: '100%',
      minHeight: '100%',
      zIndex: '1010',
    },

    '& .filename-info': {
      position: 'absolute',
      top: '21%',
      left: '11px',
      color: '#fff',
      fontFamily: 'Arial',
      fontSize: '14px',
    },

    '& .filename-info b': {
      fontFamily: 'Arial',
    },
  },

  disabledIsrc: {
    backgroundColor: '#eaeaea',
    padding: '0 4px',
    '& input': {
      cursor: `url(${NotAllowed}), auto`,
      opacity: 1,
      fontSize: '14px',
      color: '#000',
    },
  },
  alertError: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#F7E6E6',
    padding: '16px',
    marginTop: '24px',
    borderRadius: '4px',
    '& span': {
      color: '#B30303',
    },
    '&:before': {
      position: 'relative',
      background: '#b30303',
      maskImage: `url(${AlertIcon})`,
      maskRepeat: 'no-repeat',
      maskSize: 'cover',
      content: '""',
      right: '10px',
      top: '0',
      width: '18px',
      height: '18px',
      bottom: '2px',
    },
  },
})

export default fileUploadStyle
