import React, { Component } from 'react'
import { toCapitalize } from '../../../shared/helpers/text-decorator'

//@material-ui
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Button from '../../../components/custom-buttons/button'

import musicRowStyle from '../../../assets/jss/views/work/musicRowStyle'
import withStyles from '@material-ui/core/styles/withStyles'

class MusicRow extends Component {
  getWorkType(category) {
    if (!category) {
      return ''
    } else if (category.toUpperCase() === 'MUSICA ORIGINAL') {
      return 'Original'
    } else if (category.toUpperCase() === 'MUSICA COMERCIAL') {
      return 'Comercial'
    } else {
      return toCapitalize(category)
    }
  }

  handleMusicClick = event => {
    this.props.context.changeState(
      {
        musicSelected: this.props.music,
        phonogramType: this.getWorkType(this.props.music.category),
      },
      () => {
        this.props.actionButton()
      }
    )
    event.preventDefault()
  }

  render() {
    const { classes } = this.props
    return (
      <TableRow>
        <TableCell classes={{ root: classes.TableCell }}>
          {toCapitalize(this.props.music.title)}
          <br />
          <small>
            {toCapitalize(this.props.music.authorsSummary)}
            <br />
            {this.props.music.subtitle !== null ? toCapitalize(this.props.music.subtitle) : ''}
          </small>
        </TableCell>
        <TableCell classes={{ root: classes.TableCell }}>
          {toCapitalize(this.getWorkType(this.props.music.category))}
        </TableCell>
        <TableCell>
          {this.props.isButtonVisible ? (
            <Button
              id="buttonName"
              style={{ textTransform: 'capitalize', color: '#4A90E2' }}
              onClick={event => this.handleMusicClick(event)}
              automacao="btnAssociarObra"
              color="transparent"
            >
              {this.props.buttonName}
            </Button>
          ) : null}
        </TableCell>
      </TableRow>
    )
  }
}

export default withStyles(musicRowStyle)(MusicRow)
