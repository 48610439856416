import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import CircularProgress from '@material-ui/core/CircularProgress'
import PhonogramApi from '../../services/phonogram-api'
import ListWithSubItems from '..//list-with-subitems/list-with-subitems'
import moodsModalStyle from '../../assets/jss/views/ingest/moodsModalStyle.jsx'

class MoodsModal extends React.Component {
  constructor(props) {
    super(props)
    this.phonogramApi = new PhonogramApi()
    this.state = {
      isLoading: true,
      checkedItems: props.checkedItems || [],
      maxSelection: window.__.moodsMaxItems,
    }
  }

  componentDidMount() {
    this.getMoods()
  }

  getMoods = () => {
    if (this.props.context.moodItems && this.props.context.moodItems.length) {
      this.setListWithSubItems()
      return
    }
    this.phonogramApi.getMoods().then(result => {
      this.props.context.changeState({ moodItems: result }, () => {
        this.setListWithSubItems()
      })
    })
  }

  setListWithSubItems = () => {
    this.setState({
      isLoading: false,
      listWithSubItems: this.getListWithSubItem(this.props.context.moodItems),
    })
  }

  getListWithSubItem = moodItems =>
    moodItems.map(item => ({
      id: item.id,
      name: item.name,
      subItems: item.subMoods,
    }))

  onCloseModal = () => {
    const { checkedItems } = this.state
    if (this.props.onAfterCloseModal) {
      this.props.onAfterCloseModal(checkedItems)
    }
  }

  onChangeItemSelected = newChecked => {
    this.setState({
      checkedItems: newChecked,
    })
  }

  getIdsSubItemsSelected = () => {
    if (!this.props.context.moodItems) {
      return []
    }
    const ids = this.props.context.moodItems.map(item => item.id)
    return this.state.checkedItems.filter(id => !ids.includes(id))
  }

  getTotalSubItemsChecked = () => this.getIdsSubItemsSelected().length

  render = () => {
    const { open } = this.props
    const { root, paper, loadingContainer, dialogContent } = this.props.classes
    const { listWithSubItems, isLoading, maxSelection } = this.state

    return (
      <Dialog id="moods-modal" open={open} onClose={() => this.onCloseModal()} classes={{ root, paper }}>
        <DialogTitle id="form-dialog-title">
          <span>Moods {`(${this.getTotalSubItemsChecked()}/${maxSelection})`}</span>
        </DialogTitle>
        <DialogContent className={dialogContent}>
          {isLoading ? (
            <div className={loadingContainer}>
              <CircularProgress size={24} />
            </div>
          ) : (
            <ListWithSubItems
              list={listWithSubItems}
              itemsSelectedDefault={this.getIdsSubItemsSelected()}
              onChangeItemSelected={arrayValues => this.onChangeItemSelected(arrayValues)}
              maxSelection={3}
            />
          )}
        </DialogContent>
      </Dialog>
    )
  }
}

export default withStyles(moodsModalStyle)(MoodsModal)
