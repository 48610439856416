import React, { Component, Fragment } from 'react'
import ReactDOM from 'react-dom'
import { toCapitalize } from '../../../shared/helpers/text-decorator'
import phonogramFormStyle from '../../../assets/jss/views/ingest/phonogramFormStyle'

import WorkApi from '../../../services/work-api'
import PhonogramApi from '../../../services/phonogram-api'

//@material-ui
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import Steps from '../../../components/steps'
import CircularProgress from '@material-ui/core/CircularProgress'

//custom components
import PhonogramBodyContent from './phonogram-body-content/phonogram-body-content'
import Snackbar from '../../../components/snackbar/snackbar'
import Button from '../../../components/custom-buttons/button'
import GridItem from '../../../components/grid/grid-item'

import WorkSelectedInfo from '../work-selected-info'

class PhonogramForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      submitting: false,
      statusResponse: '',
      performerOptionsSelected: [{}], //Interpretes do fonograma
      year: null,
      bpm: null,
      bpmValue: '',
      moods: [],
      genres: [],
      tags: [],
      semelhante: [],
      instrumentos: [], // lista de instrumentos selecionados na tela
      listaDeInstrumentos: [], // lista de instrumentos que é carregada da api
      toggleBpm: true,
      musicians: [],
      acceptedFiles: [],
      rejectedFiles: [],
      duplicatedFiles: [],
      duplicatedSubmixes: [],
      openFillData: false,
      openMoods: false,
      openGenres: false,
      openTags: false,
      openSemelhante: false,
      openInstrumentos: false,
      openMusicians: false,
      openConfirmDuplicated: false,
      mp3Warning: false,
      performersIsValid: true,
      yearIsValid: true,
      isUnreleased: false,
      submixIsValid: true,
      formIsValid: false,
      validationMoodsGenresTags: true,
      validationMoodsGenresTagsText: '(Apenas UM dos campos obrigatório)',
      steps: [],
      yearItems: [],
      modalDuplicatedTitle: '',
      modalDuplicatedDescription: '',
      errorMessage: 'Erro ao realizar a operação.',
      submitCount: 0,
      vocal: false,
      recomendadoPara: '',
      tipoVocal: '',
      tom: '',
    }
    this.workApi = new WorkApi()
    this.phonogramApi = new PhonogramApi()
    this.ref = React.createRef()

    this.regExpISRC = /[\s-]/g
  }

  componentDidMount = () => {
    this._mounted = true

    const { recorder } = this.props.context

    if (this.isPhonogramOriginal() && recorder === null) {
      this.loadOriginalMusicRecorder()
    }

    /**
     * Usado para editar fonogramas em obras comerciais e
     * duplicar o fonograma em .
     */
    if (this.isEditionPhonogram()) {
      this.loadDataSubmixDraft()
      return
    }
  }

  componentWillUnmount = () => {
    //TODO fazer com que cancele qualquer requisição
    //após o desmonte do componente
    this._mounted = false
  }

  isEditionPhonogram() {
    return this.props.context.submixDraft !== null
  }

  setPhonogramSubmixDraftForEdition = () => {
    const { submixes } = this.props.context
    const performerOptionsSelected = submixes[0].performers
      .filter(p => p.recorder === null)
      .map(performer => ({
        value: String(performer.id),
        label: toCapitalize(performer.stageName + ' - ' + performer.fullName),
        id: performer.id,
        stageName: performer.stageName,
        fullName: performer.fullName,
      }))

    const newSubmixDraft = {
      ...submixes[0],
      performerOptionsSelected,
      isUnreleased: this.state.isUnreleased,
      acceptedFiles: submixes.map(item => item.file),
    }

    const newMusicians = submixes[0].musicians

    this.props.context.changeState({
      musicians: newMusicians,
      submixDraft: newSubmixDraft,
    })
  }

  //VALIDACAO
  isPhonogramOriginal() {
    return this.props.context.phonogramType === 'Original'
  }

  isPhonogramPerformance() {
    return this.props.context.phonogramType === 'Performance'
  }

  isPhonogramComercial() {
    return this.props.context.phonogramType === 'Comercial'
  }

  isMusicOriginal() {
    return this.props.context.musicSelected.category.toUpperCase() === 'MUSICA ORIGINAL'
  }

  isDuplicatedIsrcInForm(file) {
    return this.state.acceptedFiles.filter(item => item.isrc === file.isrc && item.submix === file.submix).length > 1
  }

  isIsrcValid(file) {
    if (this.isPhonogramOriginal() || this.isPhonogramPerformance()) {
      return true
    }

    if (this.isDuplicatedIsrcInForm(file)) {
      return false
    }
    return !!file.isrc && !!file.isrc.match(/[A-Z]{2}-[A-Z\d]{3}-\d{2}-\d{5}|[A-Z]{2}[A-Z\d]{3}\d{2}\d{5}/)
  }

  isSubmixValid(file) {
    return !!file.submix && this.state.acceptedFiles.filter(item => item.submix === file.submix).length === 1
  }

  getGroupBySubmix(submixList) {
    return submixList.reduce((groups, item) => {
      const filtered = groups.filter(g => g.items[0].submix === item.submix)
      const group = filtered.length > 0 ? groups[groups.indexOf(filtered[0])] : groups[groups.push({ items: [] }) - 1]
      group.items.push(item)
      return groups
    }, [])
  }

  transformListToDuplicatedSubmix(list) {
    if (list.length > 0) {
      return list.map(d => ({
        id: d.id,
        workTitle: d.workTitle,
        authorsSummary: d.authorsSummary,
        workCategory: d.workCategory,
        recorderName: d.recorderName,
        performersSummary: d.performersSummary,
        submix: d.submix,
        isrc: d.isrc,
      }))
    } else {
      return []
    }
  }

  createSubmixesObjectToSend(submixList) {
    const { title, authorsSummary, category } = this.props.context.musicSelected

    if (submixList && submixList.length === 0) {
      return []
    }

    return submixList.map(s => ({
      workTitle: title,
      authorsSummary: authorsSummary,
      workCategory: category,
      recorderName: this.isPhonogramPerformance() ? null : this.props.context.recorder.name,
      phonogramCategory: this.getCategory(),
      performersSummary: s.performers.map(item => item.stageName).join('/'),
      isRestrictedPerformance: this.isPhonogramPerformance(),
      submix: s.submix,
      isrc: s.file.isrc,
    }))
  }

  getCategory() {
    if (this.isPhonogramPerformance()) {
      return null
    } else if (this.isPhonogramOriginal()) {
      return 'MUSICA ORIGINAL'
    } else {
      return 'MUSICA COMERCIAL'
    }
  }

  validateForm = callback => {
    let submixIsValid = false
    let isrcIsValid = true
    let acceptedFilesValided = this.state.acceptedFiles

    if (this.state.acceptedFiles.length > 0) {
      acceptedFilesValided = this.state.acceptedFiles.map(file => {
        file.isrcIsValid = this.isIsrcValid(file)
        file.submixIsValid = this.isSubmixValid(file)
        return file
      })

      isrcIsValid = acceptedFilesValided.every(file => !!file.isrcIsValid)
      submixIsValid = acceptedFilesValided.every(file => !!file.submixIsValid)
    } else {
      submixIsValid = this.props.context.submixes.length > 0
      isrcIsValid = this.props.context.submixes.length > 0
    }

    const performersIsValid =
      this.state.performerOptionsSelected.filter(p => p.value !== undefined).length ===
      this.state.performerOptionsSelected.length
    const moodsIsValid = this.state.moods.length > 0 && this.state.moods.length < 7
    const genresIsValid = this.state.genres.length > 0 && this.state.genres.length < 7
    const tagsIsValid = this.state.tags.length > 0
    const validationMoodsGenresTags = moodsIsValid || genresIsValid || tagsIsValid
    const validationMoodsGenresTagsText = validationMoodsGenresTags
      ? '(Apenas UM dos campos obrigatório)'
      : 'Preencha pelo menos UM dos campos'
    const yearIsValid = this.state.year !== null

    const recorderIsValid =
      this.isPhonogramOriginal() || this.isPhonogramPerformance() || this.props.context.recorder !== null

    const formIsValid =
      isrcIsValid && performersIsValid && validationMoodsGenresTags && submixIsValid && recorderIsValid && yearIsValid

    if (formIsValid === false) {
      this.scrollToFormTop()
    }

    this.setState(
      {
        acceptedFiles: acceptedFilesValided,
        performersIsValid,
        validationMoodsGenresTags,
        validationMoodsGenresTagsText,
        yearIsValid,
        formIsValid,
        submixIsValid,
        recorderIsValid,
        submitCount: this.state.submitCount + 1,
      },
      () => {
        callback()
      }
    )
  }

  getDuplicatedSubmixesOnContext(newSubmixList) {
    let groupBySubmix = this.getGroupBySubmix(newSubmixList)

    let duplicatedSubmixes = groupBySubmix.filter(g => g.items.length > 1).map(g => g.items[0])

    newSubmixList.forEach(newSubmix => {
      let submixesFound = this.props.context.submixes.filter(
        submix =>
          submix.submix === newSubmix.submix &&
          submix.performers.map(p => p.stageName).join(';') === newSubmix.performers.map(p => p.stageName).join(';')
      )
      let minItems = this.props.context.submixIdx === null ? 0 : 1

      if (submixesFound.length > minItems) {
        let duplicatedSubmixNotFound =
          duplicatedSubmixes.filter(
            ds =>
              ds.submix === newSubmix.submix &&
              ds.performers.map(p => p.stageName).join(';') === newSubmix.performers.map(p => p.stageName).join(';')
          ).length === 0

        if (duplicatedSubmixNotFound) {
          duplicatedSubmixes.push(newSubmix)
        }
      }
    })

    duplicatedSubmixes.forEach(s => {
      s.workTitle = this.props.context.musicSelected.title
      s.recorder = this.props.context.recorder
    })
    return duplicatedSubmixes
  }

  showModalDuplicatedSubmixes(duplicatedSubmixes, title, description, callback) {
    this.setState(
      {
        submitting: false,
      },
      () => {
        //Se tiver dado duplicado não executa o callback, para não avançar de etapa.
        if (duplicatedSubmixes.length > 0) {
          this.setState({
            openConfirmDuplicated: true,
            duplicatedSubmixes: duplicatedSubmixes,
            handleConfirmDuplicated: callback,
            modalDuplicatedTitle: title,
            modalDuplicatedDescription: description,
          })
        } else {
          callback()
        }
      }
    )
  }

  existDataSameIsrcAndSubmix(data, newObjectSubmixIngest) {
    if (this.isPhonogramOriginal() || this.isPhonogramPerformance()) {
      return false
    }
    let exist = false
    const regexRemoveTrace = new RegExp('-', 'g')

    data.forEach(item => {
      exist = newObjectSubmixIngest.some(
        newSubmix =>
          newSubmix.isrc.toUpperCase().replace(regexRemoveTrace, '') ===
            item.isrc.toUpperCase().replace(regexRemoveTrace, '') &&
          newSubmix.submix.toUpperCase() === item.submix.toUpperCase()
      )
    })
    return exist
  }

  existDataSameIsrc(data, newObjectSubmixIngest) {
    if (this.isPhonogramOriginal() || this.isPhonogramPerformance()) {
      return false
    }
    let exist = false
    const regexRemoveTrace = new RegExp('-', 'g')

    data.forEach(item => {
      exist = newObjectSubmixIngest.some(
        newSubmix =>
          newSubmix.isrc.toUpperCase().replace(regexRemoveTrace, '') ===
          item.isrc.toUpperCase().replace(regexRemoveTrace, '')
      )
    })
    return exist
  }

  validateDuplicatedSubmixes = (newSubmixList, callback) => {
    let duplicatedSubmixes = this.isPhonogramOriginal() ? this.getDuplicatedSubmixesOnContext(newSubmixList) : []

    const submixesObjectToSend = this.createSubmixesObjectToSend(newSubmixList)

    this.setState(
      {
        submitting: true,
        openConfirmDuplicated: false,
        duplicatedSubmixes: [],
      },
      () => {
        this.phonogramApi
          .getPhonogramsDuplicates(submixesObjectToSend)
          .then(data => {
            duplicatedSubmixes = duplicatedSubmixes.concat(this.transformListToDuplicatedSubmix(data))

            if (this.isPhonogramComercial()) {
              if (this.existDataSameIsrcAndSubmix(data, submixesObjectToSend)) {
                this.showModalDuplicatedSubmixes(
                  duplicatedSubmixes,
                  'Erro Duplicidade',
                  'Já existe fonograma com o mesmo ISRC e submix.',
                  callback
                )
              } else if (this.existDataSameIsrc(data, submixesObjectToSend)) {
                this.showModalDuplicatedSubmixes(
                  duplicatedSubmixes,
                  'Erro Duplicidade',
                  'Esse ISRC já existe em outro fonograma.',
                  callback
                )
              } else {
                this.showModalDuplicatedSubmixes(
                  duplicatedSubmixes,
                  'Erro Duplicidade',
                  'Já existe fonograma com a mesma obra (título/autores), submix e intérpretes informados.',
                  callback
                )
              }
            } else if (this.isPhonogramPerformance()) {
              this.showModalDuplicatedSubmixes(
                duplicatedSubmixes,
                'Erro Duplicidade',
                `Já existe fonograma com a mesma obra (título/autores), submix e intérpretes informados.`,
                callback
              )
            } else {
              this.showModalDuplicatedSubmixes(
                duplicatedSubmixes,
                'Confirmar Duplicidade',
                `${
                  duplicatedSubmixes.length > 1 ? 'Já existem fonogramas' : 'Já existe fonograma'
                } com a mesma obra (título/autores), submix, intérpretes e gravadora informados.
							Deseja Prosseguir assim mesmo?`,
                callback
              )
            }
          })
          .catch(error => {
            if (error.response && error.response.status === 404) {
              if (this.isPhonogramOriginal()) {
                this.showModalDuplicatedSubmixes(
                  duplicatedSubmixes,
                  'Confirmar Duplicidade',
                  `${
                    duplicatedSubmixes.length > 1 ? 'Já existem fonogramas' : 'Já existe fonograma'
                  } com a mesma obra (título/autores), submix, intérpretes e gravadora informados.
									Deseja Prosseguir assim mesmo?`,
                  callback
                )
              } else {
                this.setState(
                  {
                    submitting: false,
                  },
                  () => {
                    callback()
                  }
                )
              }
            } else {
              this.handleError(error)
            }
          })
      }
    )
  }

  //FIM VALIDACAO
  loadDataSubmixDraft = () => {
    const { submixDraft } = this.props.context

    this.setState({
      performerOptionsSelected: submixDraft.performerOptionsSelected,
      year: submixDraft.year,
      bpm: submixDraft.bpm,
      moods: submixDraft.moods,
      genres: submixDraft.genres,
      tags: submixDraft.tags,
      musicians: submixDraft.musicians,
      credits: submixDraft.credits,
      isUnreleased: Boolean(submixDraft.isUnreleased),
      acceptedFiles: this.isPhonogramOriginal() ? [] : submixDraft.acceptedFiles,
    })
  }

  loadOriginalMusicRecorder = () => {
    const recorder = 'Globo'

    this.phonogramApi.getRecorders(recorder).then(data => {
      if (data !== undefined) this.props.context.changeState({ recorder: data[0] })
    })
  }

  getNewPhonogramsToIngest(newSubmixes) {
    const { submixIdx, submixes } = this.props.context

    let newContextSubmixes = []
    const submixIndex = this.isPhonogramOriginal() ? submixIdx : 0
    if (submixIndex !== null && submixes.length > 0) {
      newContextSubmixes = submixes.map((submix, idx) => {
        if (submixIndex !== idx) return submix
        return newSubmixes[0]
      })
    }
    if (this.isPhonogramOriginal()) {
      newContextSubmixes = this.props.context.submixes.concat(newSubmixes)
    } else {
      newContextSubmixes = newSubmixes
    }
    return newContextSubmixes
  }

  goNextRouter() {
    const { context, changeMode } = this.props

    if (this.isPhonogramOriginal()) {
      changeMode('addCredits')
      return
    } else if (context.ingestFromRequest || context.musicSelected.category.toUpperCase() === 'MUSICA COMERCIAL') {
      changeMode('confirmIngest')
    } else {
      changeMode('addProductArea')
    }
    this.setPhonogramSubmixDraftForEdition()
  }

  setSubmixesContext(context, callback) {
    this.props.context.changeState(context, callback)
  }

  validateNewPhonograms(newSubmixes, successCallback) {
    const newSubmixesContextToIngest = {
      submixes: this.getNewPhonogramsToIngest(newSubmixes),
      musicians: [],
      submixIdx: null,
    }
    this.validateDuplicatedSubmixes(newSubmixes, () => {
      this.setSubmixesContext(newSubmixesContextToIngest, successCallback)
    })
  }

  getNewSubmixObject() {
    let performers = this.state.performerOptionsSelected.map(performerOptionSelected => ({
      id: performerOptionSelected.id,
      stageName: performerOptionSelected.stageName,
      fullName: performerOptionSelected.fullName,
      recorder: null,
    }))

    let newSubmixes = []

    this.state.acceptedFiles.forEach(af => {
      newSubmixes = newSubmixes.concat([
        {
          file: af,
          fileName: af.originalFileName,
          tempFileName: af.tempFileName,
          duration: af.duration,
          submix: toCapitalize(af.submix, { strip: true }),
          isrc: af.isrc,
          year: this.state.year,
          performers: performers,
          bpm: this.state.bpm,
          moods: this.state.moods,
          genres: this.state.genres,
          tags: this.state.tags,
          musicians: this.state.musicians,
          credits: this.state.credits,
          isUnreleased: this.state.isUnreleased,
          semelhante: this.state.semelhante,
          bpmValue: this.state.bpmValue,
          instrumentos: this.state.instrumentos,
          vocal: this.state.vocal,
          tipoVocal: this.state.tipoVocal,
          recomendadoPara: this.state.recomendadoPara,
          tom: this.state.tom,
        },
      ])
    })

    return newSubmixes
  }

  nextStepAddPhonogram() {
    const { submixes } = this.props.context

    //Em musica original se o usuário voltar e pode avançar novamente sem validar.
    if (submixes.length > 0 && this.state.acceptedFiles.length === 0) {
      this.props.changeMode('addCredits')
    }

    if (this.state.formIsValid) {
      this.validateNewPhonograms(this.getNewSubmixObject(), this.goNextRouter.bind(this))
    }
  }

  backRouter() {
    if (this.isMusicOriginal()) {
      this.props.changeMode('musicList')
    } else {
      this.props.changeMode('phonogramType')
    }
  }

  handleSnackClose = () => {
    this.setState({
      statusResponse: '',
    })
  }

  handleError = error => {
    console.error('error: ', error)
    this.setState({ statusResponse: 'error', submitting: false })
  }

  scrollToFormTop = () => {
    ReactDOM.findDOMNode(this.ref.current).scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  render() {
    const { classes, context, changeMode } = this.props
    const activeStep = context.ingestFromRequest ? 0 : 1
    const hasCorruptedFiles = this.state.acceptedFiles.some(file => file.size <= 1024)

    return (
      <Fragment>
        <Grid>
          <Steps steps={this.props.context.steps} activeStep={activeStep} />

          <Grid className={classes.gridContainer}>
            <WorkSelectedInfo context={this.props.context} />

            <PhonogramBodyContent
              context={context}
              ref={this.ref}
              changeMode={changeMode}
              getParentState={() => this.state}
              setParentState={(state, callback) => this.setState(state, callback)}
              handleError={() => this.handleError()}
            />

            <GridItem container className={classes.ingestFooter}>
              {!this.props.context.ingestFromRequest ? (
                <GridItem className={`${classes.itemMiddleLeft}`}>
                  <Button
                    type="button"
                    size="sm"
                    id="backButton"
                    value="Voltar"
                    automacao="btnVoltar"
                    style={{ float: 'left' }}
                    color="transparent"
                    onClick={event => {
                      this.backRouter()
                      event.preventDefault()
                    }}
                  >
                    {' '}
                    Voltar{' '}
                  </Button>
                </GridItem>
              ) : null}

              <GridItem className={classes.itemMiddleRight}>
                {this.state.submitting ? (
                  <CircularProgress size={24} thickness={4} className={classes.buttonProgress} />
                ) : (
                  <Button
                    size="sm"
                    id="nextButton"
                    automacao="btnAvancar"
                    variant="outlined"
                    color="primary"
                    className={classes.clearButtom}
                    onClick={() => this.validateForm(this.nextStepAddPhonogram.bind(this))}
                    disabled={
                      !(
                        (this.props.context.submixes.length > 0 && !hasCorruptedFiles) ||
                        (this.props.context.submixes.length === 0 &&
                          this.state.acceptedFiles.length > 0 &&
                          !hasCorruptedFiles)
                      )
                    }
                  >
                    {' '}
                    Avançar{' '}
                  </Button>
                )}
              </GridItem>
            </GridItem>
          </Grid>
        </Grid>

        <Snackbar
          place="bc"
          message={this.state.errorMessage}
          open={this.state.statusResponse === 'error'}
          close
          color="danger"
          closeNotification={() => this.handleSnackClose()}
        />
      </Fragment>
    )
  }
}

export default withStyles(phonogramFormStyle)(PhonogramForm)
