import ingestStyle from '../ingest/ingestStyle'
const musicListStyle = {
  ...ingestStyle,
  TableCell: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.75rem',
    fontWeight: 500,
    padding: '4px 56px 4px 24px',
    height: '48px',
  },
  tbMusicList: {
    '& td': {
      padding: '4px 24px 4px 24px',
    },
  },
}
export default musicListStyle
