import React from 'react'

const SnackbarContext = React.createContext()

class SnackbarProvider extends React.Component {
  state = {
    snackbarMessage: '',
    snackbarColor: 'info',
    place: 'bl',
    callbackClose: () => {},
    onCloseSnack: () => {
      this.setState(
        {
          snackbarColor: '',
          snackbarMessage: '',
        },
        () => {
          this.state.callbackClose()
        }
      )
    },
    onSnackbarChangeState: (newState, callback) => {
      this.setState(
        state => ({
          ...state,
          ...newState,
        }),
        callback
      )
    },
  }

  render = () => {
    const {
      state,
      props: { children },
    } = this
    return <SnackbarContext.Provider value={state}>{children}</SnackbarContext.Provider>
  }
}

const SnackbarConsumer = SnackbarContext.Consumer

export { SnackbarContext, SnackbarProvider, SnackbarConsumer }
