import { createTheme } from '@material-ui/core/styles'

const theme = {
  palette: {
    primary: { main: '#9B2C98' },
    secondary: { main: '#999' },
    background: {
      default: '#f1f1f1',
    },
    maxWidth: '846px',
  },
}

export default createTheme(theme)
