import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import footerStyle from 'assets/jss/components/footerStyle'

function Footer({ classes }) {
  return (
    <footer className={classes.footer}>
      <span className={classes.copyright}>&copy; {1900 + new Date().getYear()} Aplicações - Tecnologia</span>
    </footer>
  )
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(footerStyle)(Footer)
