import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Popper from '@material-ui/core/Popper'
import Fade from '@material-ui/core/Fade'
import Paper from '@material-ui/core/Paper'
import userIcon from 'assets/img/svg/user.svg'
import headerLinksStyle from 'assets/jss/components/headerLinksStyle'

function UserMenu({ classes, userInfo }) {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleUserClick = event => {
    setAnchorEl(init => (init ? null : event.currentTarget))
  }

  const handleLogout = () => {
    window.location = '/logout?outer=signout'
  }

  const open = Boolean(anchorEl)
  const hasUserInfo = Boolean(userInfo?.name)

  return (
    <Fragment>
      <div href="/" className={classes.user} onClick={handleUserClick}>
        <img src={userIcon} alt="user" />
      </div>

      <Popper open={open} anchorEl={anchorEl} transition placement="bottom-end">
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper className={classes.dropdown}>
              <MenuList id="UserMenuList" role="menu">
                {hasUserInfo && (
                  <MenuItem className={classes.dropdownItemUser}>
                    <p>{userInfo.name}</p>
                    <p>{`(${userInfo.login})`}</p>
                  </MenuItem>
                )}
                {hasUserInfo && <li className={classes.divider} />}
                <MenuItem id="MenuItemLogout" onClick={handleLogout} className={classes.dropdownItem}>
                  Sair
                </MenuItem>
              </MenuList>
            </Paper>
          </Fade>
        )}
      </Popper>
    </Fragment>
  )
}

UserMenu.propTypes = {
  userInfo: PropTypes.shape({
    name: PropTypes.string,
    login: PropTypes.string,
  }),
}

export default withStyles(headerLinksStyle)(UserMenu)
