import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import loginStore from '../../shared/lib/login-store'
import withStyles from '@material-ui/core/styles/withStyles'
import { postAuth, requestAutorizationCode } from 'services/auth-api'
import authStyle from 'assets/jss/components/headerStyle'
import Redirect from 'components/redirect'

const redirectToRootPage = history => () => {
  const routerAfterLogin = localStorage.getItem('routerAfterLogin') || '/ingest'
  localStorage.removeItem('routerAfterLogin')
  history.push(routerAfterLogin)
}

const redirectToLogoutPage = history => () => {
  history.push('/logout?outer=signout')
}

function Auth({ history, location }) {
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)

    if (loginStore.isLoggedIn()) {
      redirectToRootPage(history)()
      return
    }

    if (queryParams.get('code')) {
      postAuth(queryParams.get('code'))
        .then(res => loginStore.handleLogin(res))
        .then(() => loginStore.loadUserInfo())
        .then(redirectToRootPage(history))
        .catch(redirectToLogoutPage(history))
      return
    }

    if (!queryParams.get('isLogout')) {
      requestAutorizationCode()
    }
    // eslint-disable-next-line
  }, [])

  return <Redirect message="Aguarde, você será redirecionado." />
}

Auth.propTypes = {
  location: PropTypes.object,
  classes: PropTypes.object,
}

export default withStyles(authStyle)(Auth)
