import React, { Component } from 'react'
import Snackbar from 'components/snackbar/snackbar'
import { SnackbarConsumer } from './snackbar-context'

class SnackbarDefault extends Component {
  render() {
    return (
      <SnackbarConsumer>
        {({ snackbarMessage, snackbarColor, place, onCloseSnack }) => (
          <div id="snackbar-wrapper">
            <Snackbar
              place={place}
              message={snackbarMessage}
              open={!!snackbarMessage}
              close
              color={snackbarColor}
              closeNotification={() => onCloseSnack()}
            />
          </div>
        )}
      </SnackbarConsumer>
    )
  }
}

export default SnackbarDefault
