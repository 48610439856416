/* eslint-disable max-len */
import React, { Component, Fragment } from 'react'
import classNames from 'classnames'
import { toCapitalize } from '../../../../shared/helpers/text-decorator'
import phonogramFormStyle from '../../../../assets/jss/views/ingest/phonogramFormStyle'

import WorkApi from '../../../../services/work-api'
import PhonogramApi from '../../../../services/phonogram-api'

//lib
import loginStore from '../../../../shared/lib/login-store'

//@material-ui
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import IconButton from '@material-ui/core/IconButton'
import Collapse from '@material-ui/core/Collapse'

//custom components
import AudioDropzone from 'components/dropzone/audio'
import Snackbar from '../../../../components/snackbar/snackbar'
import GridItem from '../../../../components/grid/grid-item'
import CustomInput from '../../../../components/custom-input/custom-input'
import AsyncCreatableSelect from 'react-select/async-creatable'
import CustomAsyncSelect from '../../../../components/custom-asyncselect/custom-asyncselect'
import FileUpload from '../file-upload'
import Select from 'react-select'
import GenresModal from '../../../../components/genres/genres-modal'
import MoodsModal from '../../../../components/moods/moods-modal'
import ConfirmModal from '../../../../components/modal/confirm-modal'
import SubmixList from './submix-list'
import MusiciansModal from '../../../musician/musicians-modal'
import { Plus, Close } from '../../../../components/svg-icons/svg-icons-lib'
import TagsModal from '../../../../components/tags/tags-modal'
import Checkbox from 'components/checkbox'
import { isFullMixPhonogram } from '../../utils/phonogram'
import debounce from 'commons/utils/debouce'
import SemelhanteModal from 'components/semelhante/semelhante-modal'
import InstrumentosModal from 'components/instrumentos/instrumentos-modal'

/*
 * Esse componente não tem ESTADO.
 * Caso esse componente precise de um ESTADO, insera no PAI dele e use o metodo getParentState.
 *
 ******* TODO ***********
 * Rever todo o arquivo para que ele tenha ESTADO, para assim, evitar código duplicado como por exemplo o phonogram modal.
 * e isolar algumas regras em outros componentes, classes ou hooks, para diminuir a complexidade e o acoplamento desse componente.
 */

class PhonogramBodyContent extends Component {
  constructor(props) {
    super(props)
    this.handleSnackClose = this.handleSnackClose.bind(this)
    this.handleSnackMp3Close = this.handleSnackMp3Close.bind(this)
    this.handleDropFiles = this.handleDropFiles.bind(this)
    this.handleRemoveFile = this.handleRemoveFile.bind(this)
    this.handleFileUploadChange = this.handleFileUploadChange.bind(this)
    this.handleIsUnreleasedChange = this.handleIsUnreleasedChange.bind(this)
    this.handleAddPerformer = this.handleAddPerformer.bind(this)
    this.handleRemovePerformer = this.handleRemovePerformer.bind(this)
    this.handlePerformerSelect = this.handlePerformerSelect.bind(this)
    this.handleYearChange = this.handleYearChange.bind(this)
    this.handleMusiciansModal = this.handleMusiciansModal.bind(this)
    this.handlePerformersOptionsChange = this.handlePerformersOptionsChange.bind(this)
    this.saveMoods = this.saveMoods.bind(this)
    this.saveInstrumentos = this.saveInstrumentos.bind(this)
    this.saveInstrumentsList = this.saveInstrumentsList.bind(this)
    this.handleSelectBpm = this.handleSelectBpm.bind(this)
    this.handleSelectVocal = this.handleSelectVocal.bind(this)
    this.handleSelectRecomendadoPara = this.handleSelectRecomendadoPara.bind(this)
    this.handleTagsSelect = this.handleTagsSelect.bind(this)
    this.handleClearForm = this.handleClearForm.bind(this)
    this.handleConfirmDuplicatedModal = this.handleConfirmDuplicatedModal.bind(this)
    this.loadPerformerOptions = this.loadPerformerOptions.bind(this)
    this.loadRecorderOptions = this.loadRecorderOptions.bind(this)
    this.moodsOrGenresToString = this.moodsOrGenresToString.bind(this)
    this.workApi = new WorkApi()
    this.phonogramApi = new PhonogramApi()
    this.loginStore = loginStore

    this.MUSICA_COMERCIAL = 'MUSICA COMERCIAL'
    this.MUSICA_ORIGINAL = 'MUSICA ORIGINAL'
  }

  componentDidMount = () => {
    this._mounted = true
    const currentYear = new Date().getFullYear()
    this.setParentState({
      yearItems: Array.from(
        Array.from(Array(Math.ceil((currentYear - 1900) / 1)).keys()),
        x => currentYear - x * 1
      ).map(yearItem => ({
        value: yearItem,
        label: yearItem,
      })),
    })
    this.getMoods()
    this.getGenres()
  }

  componentWillUnmount = () => {
    //TODO fazer com que cancele qualquer requisição
    //após o desmonte do componente
    this._mounted = false
  }

  componentDidCatch = (error, errorInfo) => {
    this.props.context.changeState(
      {
        error: error,
        errorInfo: errorInfo,
      },
      () => {
        this.props.changeMode('showError')
      }
    )
  }

  isPhonogramOriginal() {
    return this.props.context.phonogramType === 'Original'
  }

  isPhonogramPerformance() {
    return this.props.context.phonogramType === 'Performance'
  }

  isPhonogramComercial() {
    return this.props.context.phonogramType === 'Comercial'
  }

  getMoods = () => {
    this.setParentState({ submitting: true }, () => {
      this.phonogramApi.getMoods().then(result => {
        this.props.context.changeState({ moodItems: result }, () => {
          this.setParentState({
            submitting: false,
          })
        })
      })
    })
  }

  getGenres = () => {
    this.setParentState(
      {
        submitting: true,
      },
      () => {
        this.phonogramApi.getGenres().then(result => {
          this.props.context.changeState(
            {
              genreItems: result,
            },
            () => {
              this.setParentState({
                submitting: false,
              })
            }
          )
        })
      }
    )
  }

  getTags = filter => {
    this.setParentState(
      {
        submitting: true,
      },
      () => {
        this.phonogramApi.getTags(filter).then(result => {
          this.props.context.changeState(
            {
              tagItems: result,
            },
            () => {
              this.setParentState({
                submitting: false,
              })
            }
          )
        })
      }
    )
  }

  setParentState = (state, callback) => {
    this.props.setParentState(state, callback)
  }

  getState = () => this.props.getParentState()

  handleBpmValueChange = event => {
    const inputValue = event.target.value
    const numericValue = parseInt(inputValue)

    if (!isNaN(numericValue)) {
      this.setParentState({ bpmValue: numericValue, bpm: null })
    } else {
      this.setParentState({ bpmValue: '' })
    }
  }

  setDuration = file => {
    const audio = document.createElement('AUDIO')

    if (!audio) return

    audio.src = URL.createObjectURL(file)
    audio.addEventListener('loadedmetadata', () => {
      const totalDurationInSeconds = Math.trunc(audio.duration)
      const hours = Math.trunc(totalDurationInSeconds / 3600)
      const minutes = Math.trunc((totalDurationInSeconds % 3600) / 60)
      const seconds = Math.trunc(totalDurationInSeconds % 60)

      const padValue = value => String(value).padStart(2, '0')

      file.duration = `${padValue(hours)}:${padValue(minutes)}:${padValue(seconds)}`
    })
  }

  handleSnackClose = () => {
    this.setParentState({
      statusResponse: false,
      rejectedFiles: [],
      duplicatedFiles: [],
      hasMultipleFilesToEdit: false,
    })
  }

  handleSnackMp3Close = () => {
    this.setParentState({ mp3Warning: false })
  }

  isSomeFileInForm(file) {
    return this.getState().acceptedFiles.some(f => f.name === file.name && f.lastModified === file.lastModified)
  }

  isSomeFileInSubmixContext(file) {
    return this.props.context.submixes.some(s => s.file && s.file.originalFileName === file.name)
  }

  isMP3(file) {
    //A partir do chrome 81 não funciona mais o type.includes
    return (file.type && file.type.includes('mp3')) || file.originalFileName.includes('mp3')
  }

  //TODO: Refatorar após implementação da edição
  handleDropFiles = (accepted, rejected) => {
    let { context } = this.props
    let acceptedFiles = this.getState().acceptedFiles
    let duplicatedFiles = []
    let mp3Warning = false
    let hasMultipleFilesToEdit = false

    //Usado em ficha tecnica
    if (context.submixIdx !== null) {
      hasMultipleFilesToEdit = accepted.length > 1

      if (accepted.length === 1) {
        const file = accepted[0]

        if (
          !context.submixes[context.submixIdx].file ||
          context.submixes[context.submixIdx].file.originalFileName === file.name ||
          (context.submixes.length > 0 && !this.isSomeFileInSubmixContext(file))
        ) {
          this.setDuration(file)

          file.originalFileName = file.name
          file.submix = acceptedFiles.length > 0 ? acceptedFiles[0].submix : ''
          file.isrc = acceptedFiles.length > 0 ? acceptedFiles[0].isrc : ''

          mp3Warning = this.isMP3(file)

          acceptedFiles = [file]
        } else {
          duplicatedFiles.push(file)
        }
      }
    } else {
      accepted.forEach(file => {
        if (!this.isSomeFileInForm(file)) {
          if (!this.isSomeFileInSubmixContext(file)) {
            this.setDuration(file)

            file.originalFileName = file.name
            file.submix = ''
            file.isrc = context.ingestFromRequest && context.isrc ? context.isrc : ''
            file.isrcDisabled = context.ingestFromRequest && !!context.isrc
            mp3Warning = this.isMP3(file)

            acceptedFiles.push(file)
          } else {
            duplicatedFiles.push(file)
          }
        } else {
          duplicatedFiles.push(file)
        }
      })
    }

    this.setParentState({
      rejectedFiles: rejected,
      acceptedFiles,
      mp3Warning,
      duplicatedFiles,
      hasMultipleFilesToEdit,
    })
  }

  handleRemoveFile = fileObject => {
    const idx = this.getState().acceptedFiles.indexOf(fileObject)
    if (idx > -1) {
      const newAcceptedFiles = this.getState().acceptedFiles.filter((f, cidx) => idx !== cidx)
      this.setParentState({ acceptedFiles: newAcceptedFiles })
    }
  }

  handleFileUploadChange = file => {
    const newAcceptedFiles = this.getState().acceptedFiles.map(acceptedFile => {
      if (acceptedFile.name !== file.name || acceptedFile.lastModified !== file.lastModified) {
        return acceptedFile
      }
      return file
    })

    this.setParentState({ acceptedFiles: newAcceptedFiles })
  }

  handleIsUnreleasedChange = checked => {
    this.setParentState({ isUnreleased: checked })
  }

  handleAddPerformer = event => {
    this.setParentState({ performerOptionsSelected: this.getState().performerOptionsSelected.concat([{}]) })
    event.preventDefault()
  }

  handleRemovePerformer = idx => event => {
    this.setParentState({
      performerOptionsSelected: this.getState().performerOptionsSelected.filter((s, cidx) => idx !== cidx),
    })
    event.preventDefault()
  }

  handlePerformerSelect = (idx, obj) => {
    const newPerformerOptionsSelected = this.getState().performerOptionsSelected.map(
      (performerOptionSelected, cidx) => {
        if (idx !== cidx) {
          return performerOptionSelected
        }
        return obj !== null ? obj : {}
      }
    )

    const performersIsValid = Object.keys(newPerformerOptionsSelected[0])

    this.setParentState({
      performerOptionsSelected: newPerformerOptionsSelected,
      performersIsValid: performersIsValid.length > 0,
    })
  }

  handleYearChange = obj => {
    let year = obj != null ? obj.value : null
    this.setParentState({ year, yearIsValid: !!year })
  }

  handleEditPerformerClick = obj => {
    this.setParentState({ openMusicians: !this.getState().openMusicians, musicianId: obj.id })
  }

  handleMusiciansModal = () => {
    this.setParentState({ openMusicians: !this.getState().openMusicians, musicianId: null })
  }

  toggleGenresModal = () => {
    this.setParentState({ openGenres: !this.getState().openGenres })
  }

  toggleMoodsModal = () => {
    this.setParentState({ openMoods: !this.getState().openMoods })
  }

  toggleInstrumentosModal = () => {
    this.setParentState({ openInstrumentos: !this.getState().openInstrumentos })
  }

  toggleTagsModal = () => {
    this.setParentState({ openTags: !this.getState().openTags })
  }

  toggleSemelhanteModal = () => {
    this.setParentState({ openSemelhante: !this.getState().openSemelhante })
  }

  // semelhanteModal = () => {
  //   this.setState({ showSemelhantes:!this.state.showSemelhantes})
  // }

  getMoodsWithSubmoodsById = checkedItems => {
    const { moodItems } = this.props.context
    let selected = []

    //Get submoods
    moodItems.forEach(mood => {
      let subSelected = mood.subMoods.filter(subMood => checkedItems.includes(subMood.id))
      if (subSelected.length > 0) {
        selected = selected.concat(subSelected)
      }
    })

    //Get moods
    const parentItems = moodItems.filter(
      mood => mood.subMoods.length > 0 && mood.subMoods.filter(submood => checkedItems.includes(submood.id)).length > 0
    )

    //concat moods and submoods.
    if (parentItems.length > 0) {
      selected = parentItems.concat(selected)
    }

    return [...new Set(selected)]
  }

  getGenresWithSubGenresById = checkedItems => {
    const { genreItems } = this.props.context
    let selected = []

    //Get subGenres
    genreItems.forEach(genre => {
      let subSelected = genre.subGenres.filter(subGenre => checkedItems.includes(subGenre.id))
      if (subSelected.length > 0) {
        selected = selected.concat(subSelected)
      }
    })

    //Get genres
    const parentItems = genreItems.filter(
      genre =>
        genre.subGenres.length > 0 && genre.subGenres.filter(subGenre => checkedItems.includes(subGenre.id)).length > 0
    )

    //concat genres and subgenres.
    if (parentItems.length > 0) {
      selected = parentItems.concat(selected)
    }

    return [...new Set(selected)]
  }

  onCloseMoodsModal = checkedItems => {
    this.saveMoods(this.getMoodsWithSubmoodsById(checkedItems))
    this.toggleMoodsModal()
  }

  onCloseInstrumentosModal = checkedItems => {
    this.saveInstrumentos(checkedItems)
    this.toggleInstrumentosModal()
  }

  onCloseGenresModal = checkedItems => {
    this.saveGenres(this.getGenresWithSubGenresById(checkedItems))
    this.toggleGenresModal()
  }

  onCloseTagsModal = itemsSelected => {
    this.handleTagsSelect(itemsSelected)
    this.toggleTagsModal()
  }

  onCloseSemelhanteModal = itemsSelected => {
    this.handleSemelhanteSelect(itemsSelected)
    this.toggleSemelhanteModal()
  }

  hasSomeFullmixFile() {
    return this.getState().acceptedFiles.some(isFullMixPhonogram)
  }

  handlePerformersOptionsChange = obj => {
    if (obj === null) {
      return
    }

    const newPerformerOptionsSelected = this.getState().performerOptionsSelected.map(performerOptionSelected => {
      if (obj.id !== performerOptionSelected.id) {
        return performerOptionSelected
      }
      return obj
    })

    this.setParentState({ performerOptionsSelected: newPerformerOptionsSelected })
  }

  saveGenres = items => {
    this.setParentState(
      {
        genres: items,
      },
      () => {
        this.setValidationMoodsGenresTags()
      }
    )
  }

  saveMoods = items => {
    this.setParentState(
      {
        moods: items,
      },
      () => {
        this.setValidationMoodsGenresTags()
      }
    )
  }

  saveInstrumentos = items => {
    this.setParentState({
      instrumentos: items,
    })
  }

  saveInstrumentsList = items => {
    this.setParentState({
      listaDeInstrumentos: items,
    })
  }

  setValidationMoodsGenresTags() {
    const isValid = this.validationMoodsGenresTags()

    let text = ''

    if (this.getState().moods.length > 7) {
      text = 'Só é permitido 7 moods'
    } else if (this.getState().genres.length > 7) {
      text = 'Só é permitido 7 gêneros'
    } else {
      text = isValid === true ? '(Apenas UM dos campos obrigatório)' : 'Preencha pelo menos UM dos campos'
    }

    this.setParentState({
      validationMoodsGenresTags: isValid,
      validationMoodsGenresTagsText: text,
    })
  }

  validationMoodsGenresTags() {
    const moodsIsValid = this.getState().moods.length > 0 && this.getState().moods.length < 7
    const genresIsValid = this.getState().genres.length > 0 && this.getState().genres.length < 7
    const tagsIsValid = this.getState().tags.length > 0
    const validationMoodsGenresTags = moodsIsValid || genresIsValid || tagsIsValid
    return !!validationMoodsGenresTags
  }

  handleSelectBpm = obj => {
    let value = obj != null ? obj.value : null
    this.setParentState({ bpm: value, bpmValue: '' })
  }

  handleSelectVocal = obj => {
    this.setParentState({ vocal: obj.value })
  }

  handleSelectRecomendadoPara = obj => {
    this.setParentState({ recomendadoPara: obj.value })
  }

  handleTipoVocal = obj => {
    this.setParentState({ tipoVocal: obj.value })
  }

  handleTom = obj => {
    this.setParentState({ tom: obj.value })
  }

  handleTagsSelect = items => {
    let newTags = items.map(i => ({ id: i.value, name: toCapitalize(i.label) }))
    this.setParentState(
      {
        tags: newTags,
      },
      () => {
        this.setValidationMoodsGenresTags()
      }
    )
  }

  handleSemelhanteSelect = items => {
    console.log(items)
    let newSemelhante = items.map(i => ({ id: i.id ? i.id : 0, name: toCapitalize(i.label) }))

    this.setParentState({
      semelhante: newSemelhante,
    })
  }

  handleClearForm = () => {
    const { recorder, ingestFromRequest, changeState } = this.props.context

    changeState({
      recorder: !ingestFromRequest && this.isPhonogramComercial() ? null : recorder,
    })

    this.setParentState({
      performerOptionsSelected: !ingestFromRequest ? [{}] : this.getState().performerOptionsSelected,
      moods: [],
      genres: [],
      bpm: null,
      tags: [],
      year: null,
    })
  }

  handleConfirmDuplicatedModal = event => {
    this.setParentState({ openConfirmDuplicated: !this.getState().openConfirmDuplicated })
    event.preventDefault()
  }

  loadPerformerOptions = debounce((inputValue, callback) => {
    let performerOptionsSelectedIds = this.getState()
      .performerOptionsSelected.filter(p => p.value !== undefined)
      .map(p => p.id)

    if (inputValue.length > 2) {
      this.phonogramApi.getPerformers(inputValue).then(
        data => {
          let filtered = []

          if (data !== undefined) {
            filtered = data
              .filter(d => performerOptionsSelectedIds.length === 0 || !performerOptionsSelectedIds.includes(d.id))
              .map(d => ({
                value: String(d.id),
                label: toCapitalize(d.stageName + ' - ' + d.fullName),
                id: d.id,
                stageName: d.stageName,
                fullName: d.fullName,
              }))
          }

          callback(filtered)
        },
        () => {
          callback([])
        }
      )
    } else callback([])
  })

  loadRecorderOptions = debounce((inputValue, callback) => {
    if (inputValue.length > 2) {
      this.phonogramApi.getRecorders(inputValue).then(
        data => {
          let filtered = []
          if (data !== undefined) {
            filtered = data.map(d => ({
              value: String(d.id),
              label: toCapitalize(d.name),
              id: d.id,
              name: d.name,
            }))
          }

          callback(filtered)
        },
        () => {
          callback([])
        }
      )
    } else callback([])
  })

  handleRecorderCreateOption = inputValue => {
    setTimeout(() => {
      let data = { name: inputValue.toUpperCase() }
      this.phonogramApi.postRecorder(data).then(data => {
        if (data !== undefined) {
          this.props.context.changeState({
            recorder: data,
          })
        }
      })
    }, 500)
  }

  moodsOrGenresToString = arr => {
    let valueToReturn = ''
    arr.forEach(i => {
      if (Array.isArray(i.subMoods) || Array.isArray(i.subGenres)) {
        arr.forEach(s => {
          if (typeof s.subGenres === 'undefined' && typeof s.subMoods === 'undefined') {
            if (
              (Array.isArray(i.subGenres) && i.subGenres.includes(s)) ||
              (Array.isArray(i.subMoods) && i.subMoods.includes(s))
            ) {
              valueToReturn += s.name + ', '
            }
          }
        })
        valueToReturn = valueToReturn.substring(0, valueToReturn.length - 2)
        valueToReturn += ', '
      }
    })
    return valueToReturn.substring(0, valueToReturn.lastIndexOf(',')).trim()
  }

  tagsToString = arrayItems => {
    let valueToReturn = ''
    arrayItems.forEach(item => {
      valueToReturn += `${item.name}, `
    })
    return valueToReturn.substring(0, valueToReturn.lastIndexOf(',')).trim()
  }

  semelhantesToString = arrayItems => {
    let valueToReturn = ''
    arrayItems.forEach(item => {
      valueToReturn += `${item.name}, `
    })
    return valueToReturn.substring(0, valueToReturn.lastIndexOf(',')).trim()
  }

  instrumentoToString = arrayItems => {
    let valueToReturn = ''
    arrayItems.forEach(item => {
      valueToReturn += `${item.name}, `
    })
    return valueToReturn.substring(0, valueToReturn.lastIndexOf(',')).trim()
  }

  handleRecorderChange = obj => {
    let recorder = obj != null ? { id: obj.value, name: obj.label } : null
    this.props.context.changeState({ recorder: recorder })
  }

  isSelectDisabled(value) {
    return this.props.context.ingestFromRequest && value && Object.keys(value).length > 0
  }

  render = () => {
    const { classes } = this.props
    return (
      <Fragment>
        <GridItem container className={classes.ingestBody}>
          <Grid item xs={12} sm={12} md={12}>
            <AudioDropzone automacao="inputUploadAudio" handleDropFiles={this.handleDropFiles} />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <div ref={this.props.ref}>
              {this.getState().acceptedFiles.map((file, idx) => (
                <FileUpload
                  key={idx}
                  file={file}
                  category={this.props.context.phonogramType}
                  handleRemoveFile={this.handleRemoveFile}
                  handleFileUploadChange={this.handleFileUploadChange}
                  submitCount={this.getState().submitCount}
                />
              ))}
            </div>
          </Grid>

          {!!this.getState().acceptedFiles['length'] && (
            <Grid item xs={12} sm={12} md={12} className={classes.cardInfos}>
              <Grid container style={{ height: '82px' }}>
                <GridItem xs sm md className={classes.itemMiddleLeft}>
                  <span className={classes.infoTitle}>Informações</span>
                </GridItem>
                <GridItem xs sm md>
                  <ul className={classes.infoCmdList}>
                    <li>
                      <a id="linkClear" onClick={this.handleClearForm}>
                        Limpar
                      </a>
                    </li>
                  </ul>
                </GridItem>
              </Grid>

              <hr className={classes.hrHorizontal} />

              <Grid container className={classes.formContainer}>
                <GridItem default={true} xs={6} sm={6} md={6}>
                  <GridItem xs={12} sm={12} md={12} className="form-label">
                    <FormLabel component="legend" error={this.getState().performersIsValid === false}>
                      Intérpretes*
                    </FormLabel>
                  </GridItem>
                  {this.getState().performerOptionsSelected.map((performerOptionSelected, idx) => (
                    <Grid id={`Grid-selectPerformer${idx}`} container key={idx} className="form-control-row">
                      <GridItem
                        className={this.isSelectDisabled(performerOptionSelected) ? classes.disabled : ''}
                        xs={10}
                        sm={10}
                        md={10}
                      >
                        <CustomAsyncSelect
                          styles={{
                            indicatorsContainer: provided => ({
                              ...provided,
                              display: this.isSelectDisabled(this.props.context.recorder) ? 'none' : 'flex',
                            }),
                            indicatorSeparator: provided => ({
                              ...provided,
                              display: this.isSelectDisabled(performerOptionSelected) ? 'none' : 'flex',
                            }),
                            option: provided => ({
                              ...provided,
                              fontSize: '.875rem',
                            }),
                            loadingMessage: provided => ({
                              ...provided,
                              fontSize: '.875rem',
                            }),
                            noOptionsMessage: provided => ({
                              ...provided,
                              fontSize: '.875rem',
                            }),
                          }}
                          inputId={`inputPerformer${idx}`}
                          id={`selectPerformer${idx}`}
                          placeholder="Selecione o músico ou o grupo de músicos"
                          loadOptions={this.loadPerformerOptions}
                          onChange={obj => this.handlePerformerSelect(idx, obj)}
                          loadingMessage={() => 'Carregando...'}
                          noOptionsMessage={() => 'Nenhum item encontrado.'}
                          value={performerOptionSelected}
                          isClearable
                          option_EditSelected={{ tooltip: 'Editar Intérprete', onClick: this.handleEditPerformerClick }}
                          option_MenuItem_1={{ text: 'Intérprete', onClick: this.handleMusiciansModal }}
                          automacao="inputInformarInterprete"
                          isDisabled={this.isSelectDisabled(performerOptionSelected)}
                        />
                      </GridItem>
                      <Grid item xs={2} sm={2} md={2}>
                        {!this.isSelectDisabled(performerOptionSelected) &&
                          this.getState().performerOptionsSelected.length > 1 && (
                            <IconButton
                              aria-label="remove_circle"
                              size="sm"
                              id={`removePerformer${idx}`}
                              className={classes.btnCloseAuto}
                              onClick={this.handleRemovePerformer(idx)}
                            >
                              <Close />
                            </IconButton>
                          )}
                        {!this.isSelectDisabled(performerOptionSelected) &&
                          this.getState().performerOptionsSelected.length === idx + 1 && (
                            <IconButton
                              aria-label="Add"
                              size="sm"
                              id={`addPerformer`}
                              className={classes.btnAddRectangle}
                              onClick={this.handleAddPerformer}
                            >
                              <Plus />
                            </IconButton>
                          )}
                      </Grid>
                    </Grid>
                  ))}
                </GridItem>

                <GridItem xs={6} sm={6} md={6}>
                  <Grid container>
                    {this.isPhonogramComercial() ? (
                      <GridItem item xs={7} sm={7} md={7}>
                        <Grid item xs={12} sm={12} md={12} className="form-label">
                          <FormLabel component="legend" error={this.getState().recorderIsValid === false}>
                            Gravadora*
                          </FormLabel>
                        </Grid>

                        <Grid
                          className={
                            this.isSelectDisabled(this.props.context.recorder)
                              ? `${classes.disabled} form-control-row`
                              : 'form-control-row'
                          }
                          item
                          xs={12}
                          sm={12}
                          md={12}
                        >
                          <AsyncCreatableSelect
                            id="recorder"
                            name="recorder"
                            automacao="inputRecorder"
                            inputId={'inputRecorder'}
                            placeholder=""
                            styles={{
                              indicatorsContainer: provided => ({
                                ...provided,
                                display: this.isSelectDisabled(this.props.context.recorder) ? 'none' : 'flex',
                              }),
                              indicatorSeparator: provided => ({
                                ...provided,
                                display: this.isSelectDisabled(this.props.context.recorder) ? 'none' : 'flex',
                              }),
                              option: provided => ({
                                ...provided,
                                fontSize: '.875rem',
                              }),
                              loadingMessage: provided => ({
                                ...provided,
                                fontSize: '.875rem',
                              }),
                              noOptionsMessage: provided => ({
                                ...provided,
                                fontSize: '.875rem',
                              }),
                            }}
                            loadOptions={this.loadRecorderOptions}
                            onCreateOption={inputValue => this.handleRecorderCreateOption(inputValue)}
                            onChange={obj => this.handleRecorderChange(obj)}
                            formatCreateLabel={inputValue => `Criar a gravadora ${inputValue}...`}
                            loadingMessage={() => 'Carregando...'}
                            noOptionsMessage={() => 'Nenhum item encontrado.'}
                            isClearable
                            value={
                              this.props.context.recorder
                                ? {
                                    value: this.props.context.recorder.id,
                                    label: toCapitalize(this.props.context.recorder.name),
                                  }
                                : null
                            }
                            isDisabled={this.isSelectDisabled(this.props.context.recorder)}
                          />
                        </Grid>
                      </GridItem>
                    ) : null}
                    <GridItem item xs={5} sm={5} md={5}>
                      <Grid item xs={12} sm={12} md={12} className="form-label">
                        <FormLabel component="legend" error={this.getState().yearIsValid === false}>
                          Ano*
                        </FormLabel>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} className="form-control-row">
                        <Select
                          id="year"
                          automacao="inputYear"
                          name="year"
                          placeholder=""
                          styles={{
                            option: provided => ({
                              ...provided,
                              fontSize: '.875rem',
                            }),
                            loadingMessage: provided => ({
                              ...provided,
                              fontSize: '.875rem',
                            }),
                            noOptionsMessage: provided => ({
                              ...provided,
                              fontSize: '.875rem',
                            }),
                          }}
                          defaultValue={this.getState().yearItems[this.getState().yearItems.length - 1]}
                          noOptionsMessage={() => 'Nenhum item encontrado.'}
                          onChange={this.handleYearChange}
                          options={this.getState().yearItems}
                          value={
                            this.getState().year != null
                              ? { value: this.getState().year, label: this.getState().year }
                              : null
                          }
                          isClearable
                          isSearchable
                        />
                      </Grid>
                    </GridItem>
                  </Grid>
                </GridItem>
              </Grid>

              <Collapse in={Boolean(this.props.context.canBeUnreleased && this.hasSomeFullmixFile())}>
                <hr className={classNames({ [classes.hrHorizontal]: true, [classes.formContainer]: true })} />

                <Grid container className={classes.unreleasedContainer}>
                  <Checkbox
                    label="Fonograma inédito restrito"
                    checked={this.getState().isUnreleased}
                    onChange={this.handleIsUnreleasedChange}
                  />
                  <div className={classes.unreleasedMessage}>
                    (Marcando essa opção, o(s) fonograma(s) não serão encontrados por usuários de outros produtos.
                    Utilize essa funcionalidade para músicas que ainda não foram lançadas e desative mais tarde nos
                    metadados do fonograma.)
                  </div>
                </Grid>
              </Collapse>

              <hr className={classNames({ [classes.hrHorizontal]: true, [classes.formContainer]: true })} />

              <Grid container className={classes.formContainer}>
                <GridItem xs={12} sm={12} md={12}>
                  <h3 className={classes.titleSubsection}>
                    Classificação*
                    <span className={this.getState().validationMoodsGenresTags === false ? 'error' : ''}>
                      {this.getState().validationMoodsGenresTagsText}
                    </span>
                  </h3>
                </GridItem>
                <GridItem id="moodsInputContainer" xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Moods"
                    automacao="inputMoods"
                    id="moods"
                    labelProps={{
                      shrink: this.getState().moods.length > 0,
                    }}
                    inputProps={{
                      name: 'moods',
                      error: false,
                      value: this.getState().moods.length > 0 ? this.moodsOrGenresToString(this.getState().moods) : '',
                    }}
                    formControlProps={{
                      style: { margin: '0' },
                      fullWidth: true,
                      onClick: this.toggleMoodsModal,
                    }}
                  />
                </GridItem>
                <GridItem id="genresInputContainer" xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Gêneros"
                    automacao="inputGenres"
                    id="genres"
                    labelProps={{
                      shrink: this.getState().genres.length > 0,
                    }}
                    inputProps={{
                      name: 'genres',
                      error: false,
                      value:
                        this.getState().genres.length > 0 ? this.moodsOrGenresToString(this.getState().genres) : '',
                    }}
                    formControlProps={{
                      style: { margin: '0' },
                      fullWidth: true,
                      onClick: this.toggleGenresModal,
                    }}
                  />
                </GridItem>

                <GridItem id="tagsInputContainer" xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Tags"
                    automacao="inputTags"
                    id="tags"
                    labelProps={{
                      shrink: this.getState().tags.length > 0,
                    }}
                    inputProps={{
                      className: classes.inputTags,
                      name: 'tags',
                      readOnly: true,
                      error: false,
                      value: this.getState().tags.length > 0 ? this.tagsToString(this.getState().tags) : '',
                    }}
                    formControlProps={{
                      style: { margin: '0' },
                      fullWidth: true,
                      onClick: this.toggleTagsModal,
                    }}
                  />
                </GridItem>
              </Grid>

              <Grid container className={classes.formContainer} style={{ marginBottom: '50px' }}>
                <GridItem xs={12} sm={12} md={12} className="form-label">
                  <h3 className={classes.titleSecondSubsection}>
                    Tempo&nbsp;
                    <span>(Apenas UM dos campos precisa ser preenchido)</span>
                  </h3>
                </GridItem>

                <Grid container className={classes.formContainer} style={{ padding: '0p 15px' }}>
                  <GridItem default={true} xs={6} sm={6} md={6}>
                    <GridItem xs={6} sm={6} md={6} className="form-label">
                      <FormLabel component="legend">Andamento</FormLabel>
                    </GridItem>

                    <GridItem container xs={8} sm={8} md={8} className="form-control-row">
                      <FormControl fullWidth>
                        <Select
                          id="bpm"
                          automacao="inputBpm"
                          name="bpm"
                          placeholder="Selecione o andamento"
                          noOptionsMessage={() => 'Nenhum item encontrado.'}
                          onChange={this.handleSelectBpm}
                          options={this.props.context.bpmItems}
                          value={
                            this.getState().bpm != null
                              ? this.props.context.bpmItems.find(i => i.value === this.getState().bpm)
                              : null
                          }
                          isClearable
                          isSearchable
                          styles={{
                            option: provided => ({
                              ...provided,
                              fontSize: '.875rem',
                            }),
                            placeholder: provided => ({
                              ...provided,
                              fontSize: '.875rem',
                            }),
                          }}
                        />
                      </FormControl>
                    </GridItem>
                  </GridItem>
                  <GridItem id="tagsInputContainer" xs={5} sm={5} md={5}>
                    <CustomInput
                      labelText="BPM"
                      automacao="inputBpmValue"
                      id="bpmValue"
                      inputProps={{
                        className: classes.inputTags,
                        name: 'bpmValue',
                        error: false,
                        value: String(this.getState().bpmValue),
                        type: 'text',
                        step: '1',
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                        onKeyPress: event => {
                          const charCode = event.which ? event.which : event.keyCode
                          if (event.target.value.length == 0 && charCode == 48) {
                            event.preventDefault()
                          } else if (charCode !== 8 && charCode !== 0 && (charCode < 48 || charCode > 57)) {
                            event.preventDefault()
                          }
                        },
                        onChange: this.handleBpmValueChange,
                      }}
                      formControlProps={{
                        style: { margin: '0' },
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                </Grid>
              </Grid>

              <Grid container className={classes.formContainer}>
                <GridItem default={true} xs={12} sm={12} md={12}>
                  <GridItem xs={12} sm={12} md={12} className="form-label">
                    <FormLabel component="legend">Outras Classificações</FormLabel>
                  </GridItem>
                  <GridItem container xs={12} sm={12} md={12} className="form-control-row">
                    <FormControl fullWidth>
                      <GridItem id="SemelhanteInputContainer" xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Semelhante a"
                          automacao="semelhante"
                          id="semelhante"
                          labelProps={{
                            shrink: this.getState().semelhante.length > 0,
                          }}
                          inputProps={{
                            className: classes.inputTags,
                            name: 'Semelhante a',
                            readOnly: true,
                            error: false,
                            value:
                              this.getState().semelhante.length > 0
                                ? this.semelhantesToString(this.getState().semelhante)
                                : '',
                          }}
                          formControlProps={{
                            style: { margin: '0' },
                            fullWidth: true,
                            onClick: this.toggleSemelhanteModal,
                          }}
                        />
                      </GridItem>

                      <Grid
                        container
                        spacing={2}
                        style={{ marginTop: '15px', marginBottom: '15px', paddingLeft: '15px', paddingRight: '15px' }}
                      >
                        <GridItem item xs={12} sm={3} md={3}>
                          <Grid item xs={12} sm={12} md={12} className="form-label">
                            <FormLabel component="legend">Vocal</FormLabel>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} className="form-control-row">
                            <Select
                              id="vocal"
                              automacao="inputVocal"
                              name="vocal"
                              placeholder=""
                              styles={{
                                option: provided => ({
                                  ...provided,
                                  fontSize: '.875rem',
                                }),
                                loadingMessage: provided => ({
                                  ...provided,
                                  fontSize: '.875rem',
                                }),
                                noOptionsMessage: provided => ({
                                  ...provided,
                                  fontSize: '.875rem',
                                }),
                              }}
                              options={[
                                { value: true, label: 'Sim' },
                                { value: false, label: 'Não' },
                              ]}
                              onChange={this.handleSelectVocal}
                              defaultValue={{ value: false, label: 'Não' }}
                            />
                          </Grid>
                        </GridItem>

                        {this.getState().vocal && (
                          <GridItem item xs={12} sm={4} md={4}>
                            <Grid item xs={12} sm={12} md={12} className="form-label">
                              <FormLabel component="legend" error={this.getState().yearIsValid === false}>
                                Tipo de Vocal
                              </FormLabel>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} className="form-control-row">
                              <Select
                                id="tipoVocal"
                                automacao="tipoVocal"
                                name="tipoVocal"
                                placeholder=""
                                styles={{
                                  option: provided => ({
                                    ...provided,
                                    fontSize: '.875rem',
                                  }),
                                  loadingMessage: provided => ({
                                    ...provided,
                                    fontSize: '.875rem',
                                  }),
                                  noOptionsMessage: provided => ({
                                    ...provided,
                                    fontSize: '.875rem',
                                  }),
                                }}
                                noOptionsMessage={() => 'Nenhum item encontrado.'}
                                onChange={this.handleTipoVocal}
                                options={[
                                  { label: 'Cantado', value: 'cantado' },
                                  { label: 'Vocalise', value: 'vocalise' },
                                  { label: 'Coro', value: 'coro' },
                                ]}
                                isClearable
                                isSearchable
                              />
                            </Grid>
                          </GridItem>
                        )}
                      </Grid>

                      <GridItem item xs={12} sm={6} md={6}>
                        <Grid item xs={12} sm={12} md={12} className="form-label">
                          <FormLabel component="legend" error={this.getState().yearIsValid === false}>
                            Recomendado para
                          </FormLabel>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} className="form-control-row">
                          <Select
                            id="recomendado"
                            automacao="inputRecomendado"
                            name="recomendado"
                            placeholder=""
                            styles={{
                              option: provided => ({
                                ...provided,
                                fontSize: '.875rem',
                              }),
                              loadingMessage: provided => ({
                                ...provided,
                                fontSize: '.875rem',
                              }),
                              noOptionsMessage: provided => ({
                                ...provided,
                                fontSize: '.875rem',
                              }),
                              control: provided => ({
                                ...provided,
                                padding: '0',
                              }),
                            }}
                            noOptionsMessage={() => 'Nenhum item encontrado.'}
                            onChange={this.handleSelectRecomendadoPara}
                            options={[
                              { label: 'Dramaturgia', value: 'Dramaturgia' },
                              { label: 'Documentário', value: 'Documentário' },
                              { label: 'Entrevista', value: 'Entrevista' },
                              { label: 'Esporte', value: 'Esporte' },
                              { label: 'Game', value: 'Game' },
                              { label: 'Humor', value: 'Humor' },
                              { label: 'Institucional', value: 'Institucional' },
                              { label: 'Infantil', value: 'Infantil' },
                              { label: 'Jornalismo', value: 'Jornalismo' },
                              { label: 'Trailer/Promocional', value: 'Trailer/Promocional' },
                              { label: 'Variedades', value: 'Variedades' },
                            ]}
                            isClearable
                            isSearchable
                          />
                        </Grid>
                      </GridItem>
                      <GridItem id="instrumentosInputContainer" xs={12} sm={12} md={12} style={{ marginTop: '15px' }}>
                        <CustomInput
                          labelText="Instrumentos Principais"
                          automacao="inputInstrumentos"
                          id="instrumentos"
                          labelProps={{
                            shrink: this.getState().instrumentos.length > 0,
                          }}
                          inputProps={{
                            name: 'instrumentos',
                            error: false,
                            value:
                              this.getState().instrumentos.length > 0
                                ? this.instrumentoToString(this.getState().instrumentos)
                                : '',
                          }}
                          formControlProps={{
                            style: { margin: '0' },
                            fullWidth: true,
                            onClick: this.toggleInstrumentosModal,
                          }}
                        />
                      </GridItem>

                      <GridItem item xs={12} sm={3} md={3} style={{ marginTop: '15px', marginBottom: '15px' }}>
                        <Grid item xs={12} sm={12} md={12} className="form-label">
                          <FormLabel component="legend" error={this.getState().yearIsValid === false}>
                            Tom e Modo
                          </FormLabel>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} className="form-control-row">
                          <Select
                            id="tom"
                            automacao="tom"
                            name="tom"
                            placeholder=""
                            styles={{
                              option: provided => ({
                                ...provided,
                                fontSize: '.875rem',
                              }),
                              loadingMessage: provided => ({
                                ...provided,
                                fontSize: '.875rem',
                              }),
                              noOptionsMessage: provided => ({
                                ...provided,
                                fontSize: '.875rem',
                              }),
                              control: provided => ({
                                ...provided,
                                padding: '0',
                              }),
                            }}
                            noOptionsMessage={() => 'Nenhum item encontrado.'}
                            onChange={this.handleTom}
                            options={[
                              { label: 'C', value: 'c' },
                              { label: 'Db', value: 'db' },
                              { label: 'D', value: 'd' },
                              { label: 'Eb', value: 'eb' },
                              { label: 'E', value: 'e' },
                              { label: 'F', value: 'f' },
                              { label: 'F#', value: 'f#' },
                              { label: 'G', value: 'g' },
                              { label: 'Ab', value: 'ab' },
                              { label: 'A', value: 'a' },
                              { label: 'Bb', value: 'bb' },
                              { label: 'B', value: 'b' },
                            ]}
                            isClearable
                            isSearchable
                          />
                        </Grid>
                      </GridItem>
                    </FormControl>
                  </GridItem>
                </GridItem>
              </Grid>
            </Grid>
          )}
        </GridItem>

        <div id="snackbarContainer">
          <Snackbar
            place="bc"
            message={
              this.getState().acceptedFiles.length > 0
                ? 'Somente os arquivos de áudio foram adicionados'
                : 'O formato não pode ser processado, por favor, selecione um arquivo de áudio válido. Recomendamos o ingest de arquivos em formato WAV.'
            }
            open={this.getState().rejectedFiles.length > 0}
            close
            color={this.getState().acceptedFiles.length > 0 ? 'warning' : 'danger'}
            closeNotification={this.handleSnackClose}
            className={classes.snackbar}
          />
          <Snackbar
            place="bc"
            message={'O formato MP3 é considerado de baixa qualidade. Recomendamos o ingest de arquivos em formato WAV'}
            open={
              this.getState().mp3Warning &&
              !this.getState().statusResponse &&
              this.getState().rejectedFiles.length === 0 &&
              this.getState().duplicatedFiles.length === 0
            }
            close
            color="warning"
            closeNotification={this.handleSnackMp3Close}
            automacao="snackBarMp3Format"
            className={classes.snackbar}
          />
          <Snackbar
            place={'bc'}
            message={
              this.getState().duplicatedFiles.length === 1
                ? `O arquivo ${this.getState().duplicatedFiles[0].name} já foi adicionado anteriormente.`
                : `Os seguintes arquivos já foram adicionados anteriormente: ${this.getState()
                    .duplicatedFiles.map(f => f.name)
                    .join(', ')}`
            }
            open={this.getState().duplicatedFiles.length > 0}
            close
            color={this.getState().acceptedFiles.length > 0 ? 'warning' : 'danger'}
            closeNotification={this.handleSnackClose}
            className={classes.snackbar}
          />
          {this.getState().openGenres && (
            <GenresModal
              context={this.props.context}
              open={this.getState().openGenres}
              onAfterCloseModal={checkedItems => {
                this.onCloseGenresModal(checkedItems)
              }}
              checkedItems={Array.isArray(this.getState().genres) ? this.getState().genres.map(e => e.id) : []}
            />
          )}
          {this.getState().openMoods && (
            <MoodsModal
              context={this.props.context}
              open={this.getState().openMoods}
              onAfterCloseModal={checkedItems => {
                this.onCloseMoodsModal(checkedItems)
              }}
              checkedItems={Array.isArray(this.getState().moods) ? this.getState().moods.map(e => e.id) : []}
            />
          )}

          {this.getState().openInstrumentos && (
            <InstrumentosModal
              saveList={this.saveInstrumentsList}
              lista={this.getState().listaDeInstrumentos}
              open={this.getState().openInstrumentos}
              onAfterCloseModal={checkedItems => {
                this.onCloseInstrumentosModal(checkedItems)
              }}
              checkedItems={this.getState().instrumentos ? this.getState().instrumentos : []}
            />
          )}

          {this.getState().openTags && (
            <TagsModal
              context={this.props.context}
              open={this.getState().openTags}
              itemsSelected={Array.isArray(this.getState().tags) ? this.getState().tags : []}
              onAfterCloseModal={itemsSelected => {
                this.onCloseTagsModal(itemsSelected)
              }}
            />
          )}

          {this.getState().openSemelhante && (
            <SemelhanteModal
              context={this.props.context}
              open={this.getState().openSemelhante}
              itemsSelected={Array.isArray(this.getState().semelhante) ? this.getState().semelhante : []}
              onAfterCloseModal={itemsSelected => {
                this.onCloseSemelhanteModal(itemsSelected)
              }}
            />
          )}
          {this.getState().openMusicians && (
            <div id="MusiciansModal">
              <MusiciansModal
                context={this.props.context}
                open={this.getState().openMusicians}
                musicianId={this.getState().musicianId}
                performersOnly={true}
                handleMusiciansModal={this.handleMusiciansModal}
                handlePerformersOptionsChange={this.handlePerformersOptionsChange}
              />
            </div>
          )}
          {this.getState().openConfirmDuplicated && (
            <div id="ConfirmModal">
              <ConfirmModal
                open={this.getState().openConfirmDuplicated}
                title={this.getState().modalDuplicatedTitle}
                text={this.getState().modalDuplicatedDescription}
                content={
                  <SubmixList
                    disableRecorder={this.isPhonogramPerformance()}
                    disableIsrc={this.isPhonogramOriginal() || this.isPhonogramPerformance()}
                    submixes={this.getState().duplicatedSubmixes}
                  />
                }
                handleClose={this.handleConfirmDuplicatedModal}
                handleConfirm={this.isPhonogramOriginal() ? this.getState().handleConfirmDuplicated : null}
                maxWidth="md"
              />
            </div>
          )}
        </div>
      </Fragment>
    )
  }
}

export default withStyles(phonogramFormStyle)(PhonogramBodyContent)
