import React from 'react'
// @material-ui/core components
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'

const style = {
  grid: {
    padding: '0 15px',
  },
}

function GridItem({ ...props }) {
  const { classes, children, ...rest } = props

  const grid = classNames({
    [classes.grid]: !props.default,
    [props.className]: !!props.className,
  })

  return (
    <Grid item {...rest} className={grid}>
      {children}
    </Grid>
  )
}

export default withStyles(style)(GridItem)
