import React from 'react'
import styled from 'styled-components'
import TooltipMUI from '@material-ui/core/Tooltip'
import HelpIcon from 'assets/img/svg/help-icon-20px.svg'

const Container = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
  margin-bottom: 10px;
`

const Title = styled.div`
  font-family: Arial;
  font-size: 24px;
  text-align: center;
  color: #333333;
`

const Tooltip = styled(props => (
  <TooltipMUI classes={{ popper: props.className, tooltip: 'tooltip', arrow: 'arrow' }} {...props} />
))`
  .tooltip {
    background-color: #000000;
    font-size: 11px;
  }

  .arrow {
    color: #000000;
  }
`

const TooltipIconButton = styled.button`
  right: 0;
  width: 20px;
  height: 20px;
  border: none;
  outline: none;
  margin: 0 0 0 0;
  margin-left: 20px;
  background: url(${HelpIcon}) no-repeat 0 0;
`

export { Container, Title, Tooltip, TooltipIconButton }
