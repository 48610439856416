import React, { Fragment } from 'react'

//@material-ui
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '../../components/custom-buttons/button'

class Modal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: this.props.open,
    }
    this.handleCancel = this.handleCancel.bind(this)
    this.handleConfirm = this.handleConfirm.bind(this)
  }

  handleCancel = event => {
    this.setState({ open: false }, this.props.handleClose(event))
    event.preventDefault()
  }

  handleConfirm = event => {
    this.setState(
      {
        open: !this.props.cancelCloseAfterConfirm ? false : true,
      },
      this.props.handleConfirm(event)
    )
    event.preventDefault()
  }

  render = () => {
    const {
      title,
      text,
      style,
      content,
      maxWidth,
      handleConfirm,
      handleClose,
      cancelText,
      okText,
      closeText,
      enableButtonClose,
      classes,
      disableActions,
      disableCancel,
    } = this.props

    return (
      <Fragment>
        <Dialog
          style={style}
          open={this.state.open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          maxWidth={maxWidth}
          classes={{ paper: classes.paper, root: classes.root }}
        >
          <DialogTitle className={classes.title} id="form-dialog-title">
            {title}
          </DialogTitle>
          {enableButtonClose ? (
            <Button onClick={this.handleCancel} className={classes.buttonCloseIcon}>
              &nbsp;
            </Button>
          ) : null}
          <DialogContent className={classes.content}>
            {text ? <DialogContentText className={classes.principalText}>{text}</DialogContentText> : null}
            {content ? content : null}
          </DialogContent>

          {!disableActions ? (
            <DialogActions className={classes.actions}>
              {handleConfirm ? (
                <Fragment>
                  {!disableCancel ? (
                    <Button id="ButtonNo" onClick={this.handleCancel} size="sm" color="transparent">
                      {cancelText ? cancelText : 'Não'}
                    </Button>
                  ) : null}
                  <Button id="ButtonYes" onClick={this.handleConfirm} size="sm" color="primary">
                    {okText ? okText : 'Sim'}
                  </Button>
                </Fragment>
              ) : (
                <Button
                  className={classes.buttonClose}
                  id="ButtonClose"
                  onClick={this.handleCancel}
                  size="sm"
                  color="transparent"
                >
                  {closeText ? closeText : 'fechar'}
                </Button>
              )}
            </DialogActions>
          ) : null}
        </Dialog>
      </Fragment>
    )
  }
}

export default Modal
