import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { toCapitalize } from '../../../shared/helpers/text-decorator'
//api
import WorkApi from '../../../services/work-api'

//lib
import loginStore from '../../../shared/lib/login-store'

//@material-ui
import withStyles from '@material-ui/core/styles/withStyles'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import InfoOutlineIcon from '@material-ui/icons/InfoOutlined'
import CircularProgress from '@material-ui/core/CircularProgress'

//custom components
import Button from '../../../components/custom-buttons/button'
import Snackbar from '../../../components/snackbar/snackbar'
import AsyncSelect from 'react-select/async'

// import Select from 'react-select';
import GridItem from '../../../components/grid/grid-item'
import CustomInput from '../../../components/custom-input/custom-input'
import Card from '../../../components/card/card'
import CardHeader from '../../../components/card/card-header'
import CardBody from '../../../components/card/card-body'
import CardFooter from '../../../components/card/card-footer'
import workFormStyle from '../../../assets/jss/views/work/workFormStyle'

import SwitchPurple from '../../../components/switch/SwitchPurple'
import showSnackbar from 'components/snackbar/showSnackbar'
import debounce from 'commons/utils/debouce'
class WorkForm extends Component {
  MIN_LENGTH_SEARCH = 2

  constructor(props) {
    super(props)

    this.INITIAL_COMPOSITION = [
      {
        authorOptionSelected: null,
        publishingCompanyOptionSelected: null,
        copyrightPercentage: '0',
        authorIsHired: null,
        authorIsValid: true,
        publishingCompanyIsValid: true,
        copyrightPercentageIsValid: true,
      },
    ]

    this.PublishingIdDefaultComercial = null

    this.state = {
      submitting: false,
      title: props.context.filter || '',
      subtitle: '',
      category: props.categorySelected || '',
      categoryDisabled: props.categoryDisabled || '',
      nationality: '',
      countryOptionSelected: null,
      isOriginal: props.categorySelected === 'MUSICA COMERCIAL' ? false : null,
      isDerivation: false,
      derivation: null,
      compositions: this.INITIAL_COMPOSITION,
      countryItems: [],
      derivationTypeItems: [
        { value: '1', label: 'Versão' },
        { value: '2', label: 'Adaptação' },
        { value: '3', label: 'Sample' },
        { value: '4', label: 'Mashup' },
        { value: '5', label: 'Pot-pourri/Medley' },
      ],
      SIGEMPublishingCompanyOption: null,
      error: false,
      errorMessage: 'Obra salva com sucesso',
      derivationIsValid: true,
      subtitleIsValid: true,
      categoryIsValid: true,
      nationalityIsValid: true,
      countryIsValid: true,
      formIsValid: true,
      publishingCompaniesAreValid: true,
      authorsAreValid: true,
    }
    this.handleSuccess = this.handleSuccess.bind(this)
    this.handleError = this.handleError.bind(this)
    this.handleSnackClose = this.handleSnackClose.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleBackClick = this.handleBackClick.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleMusicsClick = this.handleMusicsClick.bind(this)
    this.handleCategoryChange = this.handleCategoryChange.bind(this)
    this.handleNationalityChange = this.handleNationalityChange.bind(this)
    this.handleRemoveComposition = this.handleRemoveComposition.bind(this)
    this.handleAuthorSelect = this.handleAuthorSelect.bind(this)
    this.handlePublishingCompanySelect = this.handlePublishingCompanySelect.bind(this)
    this.handleCopyrightPercentageChange = this.handleCopyrightPercentageChange.bind(this)
    this.loadAuthorOptions = this.loadAuthorOptions.bind(this)
    this.loadPublishingCompanyOptions = this.loadPublishingCompanyOptions.bind(this)
    this.workApi = new WorkApi()
    this.loginStore = loginStore
  }

  componentDidMount = () => {
    this.getPublishingComercialNull()
  }

  componentDidCatch = (error, errorInfo) => {
    // Catch errors in any components below and re-render with error message
    this.props.context.changeState(
      {
        error: error,
        errorInfo: errorInfo,
      },
      () => {
        this.props.changeMode('showError')
      }
    )
    // You can also log error messages to an error reporting service here
  }

  saveWork = data => {
    this.setState({ submitting: true })
    this.workApi.postWork(data).then(this.handleSuccess).catch(this.handleError)
  }

  validateComposition = (idx, name) => {
    let newCompositions = null

    switch (name) {
      case 'author':
        newCompositions = this.state.compositions.map((composition, cidx) => {
          if (idx !== cidx) return composition
          return {
            ...composition,
            authorIsValid: composition.authorOptionSelected !== null,
          }
        })
        break
      case 'publishingCompany':
        newCompositions = this.state.compositions.map((composition, cidx) => {
          if (idx !== cidx) {
            return composition
          }

          return {
            ...composition,
            publishingCompanyIsValid: composition.publishingCompanyOptionSelected !== null,
          }
        })
        break
      case 'copyrightPercentage':
        newCompositions = this.state.compositions.map((composition, cidx) => {
          if (idx !== cidx) return composition
          return {
            ...composition,
            copyrightPercentageIsValid: Number.parseInt(composition.copyrightPercentage || 0, 10) <= 100,
          }
        })
        break
      default:
        return false
    }

    this.setState(
      {
        compositions: newCompositions,
      },
      () => {
        this.validateForm()
      }
    )
  }

  isAuthorValid(newComposition) {
    if (!newComposition.authorOptionSelected) {
      return false
    }

    return (
      this.state.compositions.filter(
        composition =>
          composition.authorOptionSelected &&
          composition.authorOptionSelected.value === newComposition.authorOptionSelected.value
      ).length === 1
    )
  }

  isPublishingCompanyValid(newComposition) {
    return !!newComposition.publishingCompanyOptionSelected || !this.state.isOriginal
  }

  isCopyrightPercentagesValid() {
    if (!this.state.isOriginal) {
      return true
    }

    return (
      this.state.compositions.filter(c => Number(c.copyrightPercentage || 0) === 0).length ===
        this.state.compositions.length ||
      (this.state.compositions.filter(c => Number(c.copyrightPercentage || 0) !== 0).length ===
        this.state.compositions.length &&
        this.state.compositions.map(c => Number(c.copyrightPercentage || 0)).reduce((a, b) => a + b, 0) === 100)
    )
  }

  //Se for musica original e valor diferente de 0 ou null
  getPercentageValided(composition) {
    return !!composition.copyrightPercentage && this.state.isOriginal ? composition.copyrightPercentage : null
  }

  validateForm = () => {
    let authorsAreValid = true
    let publishingCompaniesAreValid = true
    let copyrightPercentagesAreValid = true
    let newCompositions = this.state.compositions
    let subtitleIsValid = this.state.subtitle.length === 0 || this.state.subtitle.length <= 100
    let categoryIsValid = this.state.category.length > 0
    let nationalityIsValid = this.state.nationality.length > 0
    let derivationIsValid = this.state.isOriginal || !this.state.isDerivation || this.state.derivation !== null

    if (this.state.category.length > 0 && this.state.isOriginal) {
      nationalityIsValid = this.state.nationality.length > 0
    }

    copyrightPercentagesAreValid = this.isCopyrightPercentagesValid()

    newCompositions = this.state.compositions.map(composition => ({
      ...composition,
      copyrightPercentage: this.getPercentageValided(composition),
      authorIsValid: this.isAuthorValid(composition),
      publishingCompanyIsValid: this.isPublishingCompanyValid(composition),
      copyrightPercentageIsValid: copyrightPercentagesAreValid,
    }))

    authorsAreValid = newCompositions.every(item => item.authorIsValid === true)
    publishingCompaniesAreValid = newCompositions.every(item => item.publishingCompanyIsValid === true)

    const formIsValid =
      subtitleIsValid &&
      categoryIsValid &&
      nationalityIsValid &&
      derivationIsValid &&
      authorsAreValid &&
      publishingCompaniesAreValid &&
      copyrightPercentagesAreValid

    this.setState({
      compositions: newCompositions,
      subtitleIsValid: subtitleIsValid,
      categoryIsValid: categoryIsValid,
      nationalityIsValid: nationalityIsValid,
      derivationIsValid: derivationIsValid,
      formIsValid: formIsValid,
      authorsAreValid,
      publishingCompaniesAreValid,
    })

    return formIsValid
  }

  //TODO: Rever preenchimento do objeto musicCreated
  handleSuccess = response => {
    this.workApi
      .getWorkById(response.id)
      .then(work => {
        this.setState({ submitting: false }, () => {
          this.props.context.changeState({
            musics: [work],
            musicSelected: work,
            phonogramType: work.category.toUpperCase() === 'MUSICA COMERCIAL' ? 'Comercial' : 'Original',
          })

          showSnackbar({ message: 'Obra salva com sucesso' })
          this.props.changeMode(this.props.nextRouter)
        })
      })
      .catch(this.handleError)
  }

  handleError = error => {
    console.error('error: ', error)

    const errorMessage = error.response.status === 422 ? error.response.data : 'Erro ao salvar obra'

    this.setState({ submitting: false, error: true, errorMessage })
  }

  handleSnackClose = () => {
    this.setState({ error: false, submitting: false })
  }

  isOriginalOrNotDerivated() {
    if (this.state.isOriginal) {
      return true
    }
    return this.state.isDerivation ? false : true
  }

  getPublishingCompanyId(composition) {
    if (this.state.isOriginal) {
      return composition.publishingCompanyOptionSelected.value
    } else {
      return composition.publishingCompanyOptionSelected
        ? composition.publishingCompanyOptionSelected.value
        : this.PublishingIdDefaultComercial
    }
  }

  defineDataToSend() {
    const getPercentage = percentage => {
      if (!this.state.isOriginal) {
        return undefined
      }
      return percentage !== '0' && !!percentage ? percentage : null
    }
    return {
      Title: this.state.title,
      Subtitle: this.state.subtitle,
      Category: this.state.category,
      IsNational: this.state.isOriginal ? true : this.state.nationality === 'N',
      IsOriginal: this.isOriginalOrNotDerivated(),
      DerivationTypeId: this.state.isOriginal || this.state.derivation === null ? null : this.state.derivation,
      User: this.loginStore.getUserLogin(),
      Compositions: this.state.compositions.map(c => ({
        AuthorId: c.authorOptionSelected.value,
        PublishingCompanyId: this.getPublishingCompanyId(c),
        CopyrightPercentage: getPercentage(c.copyrightPercentage),
      })),
    }
  }

  handleSubmit = event => {
    if (!this.validateForm()) {
      return
    }

    this.saveWork(this.defineDataToSend())
    event.preventDefault()
  }

  handleBackClick = event => {
    this.props.context.changeState(
      {
        musics: [],
        musicSelected: null,
      },
      () => {
        this.props.changeMode('musicList')
        event.preventDefault()
      }
    )
  }

  handleChange = event => {
    this.setState({
      subtitle: event.target.value,
    })
  }

  handleMusicsClick = event => {
    this.props.changeMode('musicList')
    event.preventDefault()
  }

  handleCategoryChange = event => {
    const value = event.target.value
    this.setState({
      category: value,
      nationality: value === 'TRILHA MUSICAL' ? 'N' : this.state.nationality,
      isOriginal: value === 'TRILHA MUSICAL',
      compositions: this.INITIAL_COMPOSITION,
      authorsAreValid: true,
      publishingCompaniesAreValid: true,
    })
  }

  handleNationalityChange = event => {
    const value = event.target.value
    this.setState({
      nationality: value,
      nationalityIsValid: true,
    })
  }

  handleAddComposition = event => {
    event.preventDefault()
    this.setState({
      compositions: this.state.compositions.concat([
        {
          authorOptionSelected: null,
          publishingCompanyOptionSelected: null,
          copyrightPercentage: '0',
          authorIsHired: null,
          confirmAuthorNonHired: false,
          authorIsValid: true,
          publishingCompanyIsValid: true,
          copyrightPercentageIsValid: true,
        },
      ]),
    })
  }

  handleRemoveComposition = idx => event => {
    let newCompositions = this.state.compositions.filter((s, cidx) => idx !== cidx)
    let formIsValid = newCompositions.map(c => Number(c.copyrightPercentage || 0)).reduce((a, b) => a + b, 0) === 100

    this.setState(
      {
        compositions: newCompositions,
        formIsValid: formIsValid,
      },
      () => {
        this.validateForm()
      }
    )
    event.preventDefault()
  }

  handleAuthorSelect = idx => obj => {
    const AUTHOR_OPTION_SELECTED = 'authorOptionSelected'
    const AUTHOR_IS_HIRED = 'authorIsHired'
    const CONFIRM_AUTHOR_NON_HIRED = 'confirmAuthorNonHired'
    const AUTHOR_IS_VALID = 'authorIsValid'
    const PUBLISHING_COMPANY_OPTION_SELECTED = 'publishingCompanyOptionSelected'
    const AUTHOR = 'author'
    const SIGEM = 'SIGEM'
    const isOriginalMusic = this.state.isOriginal

    let SIGEMPublishingCompanyOption = this.state.SIGEMPublishingCompanyOption

    let newCompositions = this.state.compositions.map((composition, cidx) => {
      if (idx !== cidx) return composition

      if (obj === null) {
        return {
          ...composition,
          [AUTHOR_OPTION_SELECTED]: null,
          [AUTHOR_IS_HIRED]: null,
          [CONFIRM_AUTHOR_NON_HIRED]: false,
          [AUTHOR_IS_VALID]: false,
        }
      }

      if (isOriginalMusic && obj.isHired && SIGEMPublishingCompanyOption !== null) {
        return {
          ...composition,
          [AUTHOR_OPTION_SELECTED]: obj,
          [PUBLISHING_COMPANY_OPTION_SELECTED]: SIGEMPublishingCompanyOption,
          [AUTHOR_IS_HIRED]: true,
          [CONFIRM_AUTHOR_NON_HIRED]: false,
          [AUTHOR_IS_VALID]: true,
          publishingCompanyIsValid: true,
        }
      }

      return {
        ...composition,
        [AUTHOR_OPTION_SELECTED]: obj,
        [PUBLISHING_COMPANY_OPTION_SELECTED]: null,
        [AUTHOR_IS_HIRED]: obj.isHired,
        [CONFIRM_AUTHOR_NON_HIRED]: false,
        [AUTHOR_IS_VALID]: obj.isHired,
        publishingCompanyIsValid: false,
      }
    })

    const value = obj !== null ? obj.value : null

    if (isOriginalMusic && newCompositions[idx].authorIsHired) {
      if (SIGEMPublishingCompanyOption === null) {
        setTimeout(() => {
          this.workApi.getPublishingCompanies(SIGEM).then(
            data => {
              let filtered = []

              if (data !== undefined) {
                filtered = data.map(d => ({
                  value: String(d.id),
                  label: d.tradingName,
                }))

                SIGEMPublishingCompanyOption = filtered[0]
                newCompositions[idx].publishingCompanyOptionSelected = SIGEMPublishingCompanyOption
                newCompositions[idx].publishingCompanyIsValid = true

                this.setState(
                  {
                    compositions: newCompositions,
                    SIGEMPublishingCompanyOption: SIGEMPublishingCompanyOption,
                  },
                  () => {
                    this.validateComposition(idx, AUTHOR, value)
                  }
                )
              }
            },
            () => {
              this.setState(
                {
                  compositions: newCompositions,
                  SIGEMPublishingCompanyOption: null,
                },
                () => {
                  this.validateComposition(idx, AUTHOR, value)
                }
              )
            }
          )
        }, 500)
      }
    }

    this.setState(
      {
        compositions: newCompositions,
        SIGEMPublishingCompanyOption: SIGEMPublishingCompanyOption,
      },
      () => {
        this.validateComposition(idx, AUTHOR, value)
      }
    )
  }

  handlePublishingCompanySelect = index => obj => {
    const newCompositions = this.state.compositions.map((composition, compositionIndex) => {
      if (index !== compositionIndex) return composition
      return {
        ...composition,
        publishingCompanyOptionSelected: obj,
      }
    })

    const value = obj !== null ? obj.value : null

    this.setState(
      {
        compositions: newCompositions,
      },
      () => {
        this.validateComposition(index, 'publishingCompany', value)
      }
    )
  }

  handleCopyrightPercentageChange = index => event => {
    const value = event.target.value

    const newCompositions = this.state.compositions.map((composition, compositionIndex) => {
      if (index !== compositionIndex) {
        return composition
      }
      return {
        ...composition,
        copyrightPercentage: value,
      }
    })

    this.setState(
      {
        compositions: newCompositions,
      },
      () => {
        this.validateComposition(index, 'copyrightPercentage', value)
      }
    )
  }

  handleConfirmAuthorNotHired = event => {
    this.setState({
      confirmAuthorNotHired: event.target.checked,
    })
  }

  loadAuthorOptions = (inputValue, callback) => {
    if (inputValue.length > 2) {
      setTimeout(() => {
        this.workApi.getAuthors(inputValue).then(
          data => {
            let filtered = []
            if (data !== undefined) {
              filtered = data.map(d => ({
                value: String(d.id),
                label: toCapitalize(d.stageName.toLowerCase()),
                isHired: d.isHired,
              }))
            }

            callback(filtered)
          },
          () => {
            callback([])
          }
        )
      }, 500)
    } else callback([])
  }

  getPublishingComercialNull() {
    this.workApi.getPublishingCompanies('A DESCOBRIR').then(data => {
      this.PublishingIdDefaultComercial = data[0].id
    })
  }

  loadPublishingCompanyOptions = debounce((inputValue, callback) => {
    if (inputValue.trim().length >= this.MIN_LENGTH_SEARCH) {
      this.workApi.getPublishingCompanies(inputValue).then(
        data => {
          let filtered = []
          if (data !== undefined) {
            filtered = data.map(d => ({
              value: String(d.id),
              label: toCapitalize(d.tradingName.toLowerCase()),
            }))
          }
          callback(filtered)
        },
        () => {
          callback([])
        }
      )
    } else {
      callback([])
    }
  })

  toggleDerivation(event) {
    this.setState({
      isDerivation: event.target.checked,
      derivation: null,
      derivationIsValid: true,
    })
  }

  changeDerivation(event) {
    this.setState({
      derivation: event.target.value,
      derivationIsValid: true,
    })
  }

  isSaveDisabled() {
    return (
      !this.state.category ||
      (this.state.isOriginal === true && this.isAuthorHired() && !this.state.confirmAuthorNotHired)
    )
  }

  isAuthorHired() {
    return this.state.compositions.filter(c => c.authorIsHired === false).length > 0
  }

  render() {
    const { classes } = this.props

    return (
      <Grid className={classes.wrapperContainer}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Criar Obra</h4>
            <p className={classes.cardCategoryWhite}>Preencha os campos abaixo</p>
          </CardHeader>
          <CardBody style={{ paddingRight: 0, paddingLeft: 0 }}>
            <Grid container>
              <div className={classes.wrapper}>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Título*"
                    automacao="inputTitleObra"
                    id="title"
                    style={{ margin: '30px 0 0 0' }}
                    inputProps={{
                      name: 'title',
                      value: this.state.title,
                      readOnly: true,
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                  {this.props.context.musics.filter(m => m.isCreated === undefined).length > 0 ? (
                    <div className={classes.warningText}>
                      <InfoOutlineIcon style={{ float: 'left' }} />
                      <div
                        style={{
                          marginTop: '5px',
                          marginLeft: '2px',
                          float: 'left',
                        }}
                      >
                        Existem músicas com o mesmo título.{' '}
                        <a
                          id="buttonMusicList"
                          style={{ textDecoration: 'underline', cursor: 'pointer' }}
                          onClick={this.handleMusicsClick}
                        >
                          Clique aqui
                        </a>{' '}
                        para ver a lista.
                      </div>
                      <div style={{ float: 'none' }}></div>
                    </div>
                  ) : null}
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Subtítulo (opcional)"
                    automacao="inputSubTitleObra"
                    id="subtitle"
                    style={{ margin: '30px 0 0 0' }}
                    inputProps={{
                      name: 'subtitle',
                      placeholder: 'Digite o subtítulo da obra',
                      maxLength: 100,
                      error: !this.state.subtitleIsValid,
                      onChange: this.handleChange,
                      value: this.state.subtitle,
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>

                <Grid container style={{ margin: '39px 0 0' }}>
                  <GridItem item xs={4} sm={4} md={4}>
                    <FormLabel error={!this.state.categoryIsValid} className={classes.formLabel}>
                      Categoria*
                    </FormLabel>
                    <RadioGroup
                      id="RadioGroupCategory"
                      className={classes.RadioGroup}
                      style={{ flexDirection: 'row' }}
                      value={this.state.category}
                      onChange={this.handleCategoryChange}
                    >
                      <FormControlLabel
                        disabled={this.state.categoryDisabled === 'TRILHA MUSICAL'}
                        value="TRILHA MUSICAL"
                        automacao="checkObraOriginal"
                        style={{ marginRight: '50px' }}
                        control={<Radio color="primary" />}
                        label="Original"
                      />

                      <FormControlLabel
                        disabled={this.state.categoryDisabled === 'MUSICA COMERCIAL'}
                        value="MUSICA COMERCIAL"
                        automacao="checkObraComercial"
                        control={<Radio color="primary" />}
                        label="Comercial"
                      />
                    </RadioGroup>
                  </GridItem>
                  <GridItem item xs={4} sm={4} md={4}>
                    <FormLabel className={classes.formLabel} error={!this.state.nationalityIsValid}>
                      Nacionalidade*
                    </FormLabel>

                    <RadioGroup
                      id="radioGroupNationality"
                      style={{ flexDirection: 'row' }}
                      className={classes.RadioGroup}
                      onChange={this.handleNationalityChange}
                      value={this.state.nationality}
                    >
                      <FormControlLabel
                        value="N"
                        automacao="checkObraNacional"
                        style={{ marginRight: '50px' }}
                        control={<Radio color="primary" />}
                        label="Nacional"
                      />

                      <FormControlLabel
                        value="I"
                        automacao="checkObraInternacional"
                        disabled={this.state.isOriginal}
                        control={<Radio color="primary" />}
                        label="Internacional"
                      />
                    </RadioGroup>
                  </GridItem>
                </Grid>
              </div>
              {this.state.isOriginal === false ? (
                <Grid fullWidth className={classes.derivationWrapper}>
                  <div className={classes.wrapper}>
                    <FormLabel
                      error={!this.state.derivationIsValid}
                      className={`${classes.formLabel} ${classes.formLabelDerivation}`}
                    >
                      Derivada
                    </FormLabel>

                    <SwitchPurple
                      id="SwitchPurple"
                      checked={this.state.isDerivation}
                      automacao="toggleDerivation"
                      onChange={event => this.toggleDerivation(event)}
                    />

                    {this.state.isDerivation ? (
                      <RadioGroup
                        id="radioGroupDerivation"
                        style={{
                          flexDirection: 'row',
                          margin: '-11px 0 0',
                        }}
                        value={this.state.derivation}
                        onChange={event => this.changeDerivation(event)}
                      >
                        {this.state.derivationTypeItems.map((item, index) => (
                          <FormControlLabel
                            key={index}
                            value={item.value}
                            style={{ marginRight: '50px' }}
                            control={<Radio color="primary" />}
                            label={item.label}
                          />
                        ))}
                      </RadioGroup>
                    ) : null}
                  </div>
                </Grid>
              ) : null}

              {this.state.category ? (
                <div className={classes.wrapper}>
                  <Grid
                    style={{
                      width: '100%',
                      margin: '5px 0 0',
                    }}
                  >
                    <FormControl
                      fullWidth
                      style={{
                        margin: '30px 0 0 0',
                      }}
                    >
                      <FormLabel className={classes.formLabelComposition}>Composições</FormLabel>

                      {this.state.compositions.map((composition, idx) => (
                        <Grid container key={idx} className={classes.compositionWrapper} id={'composition_item_' + idx}>
                          <GridItem xs={4}>
                            <FormControl fullWidth>
                              {idx === 0 ? (
                                <FormLabel className={classes.formLabel} error={!this.state.authorsAreValid}>
                                  Autor*
                                </FormLabel>
                              ) : null}
                              <AsyncSelect
                                styles={{
                                  control: provided => {
                                    if (!composition.authorIsValid) {
                                      return {
                                        ...provided,
                                        outline: 'none',
                                        border: '1px solid #f44336',
                                      }
                                    } else {
                                      return {
                                        ...provided,
                                        outline: 'none',
                                      }
                                    }
                                  },
                                  option: provided => ({
                                    ...provided,
                                    fontSize: '.875rem',
                                  }),
                                  loadingMessage: provided => ({
                                    ...provided,
                                    fontSize: '.875rem',
                                  }),
                                  noOptionsMessage: provided => ({
                                    ...provided,
                                    fontSize: '.875rem',
                                  }),
                                }}
                                id={`author${idx}`}
                                name="author"
                                automacao={'inputAuthor' + idx}
                                components={{ DropdownIndicator: null }}
                                placeholder="Selecione o autor"
                                loadOptions={this.loadAuthorOptions}
                                onChange={this.handleAuthorSelect(idx)}
                                loadingMessage={() => 'Carregando...'}
                                noOptionsMessage={() => 'Nenhum item encontrado.'}
                                value={composition.authorOptionSelected}
                                isClearable
                                cacheOptions
                              />
                            </FormControl>
                          </GridItem>
                          <GridItem xs={4}>
                            <FormControl fullWidth>
                              {idx === 0 ? (
                                <FormLabel
                                  className={classes.formLabel}
                                  error={!this.state.publishingCompaniesAreValid}
                                >
                                  {this.state.isOriginal ? 'Editora*' : 'Editora'}
                                </FormLabel>
                              ) : null}
                              <AsyncSelect
                                id={`publishingCompany${idx}`}
                                name="publishingCompany"
                                automacao={'inputPublishingCompany' + idx}
                                components={{ DropdownIndicator: null }}
                                placeholder="Selecione a editora"
                                loadOptions={this.loadPublishingCompanyOptions}
                                onChange={this.handlePublishingCompanySelect(idx)}
                                loadingMessage={() => 'Carregando...'}
                                noOptionsMessage={() => 'Nenhum item encontrado.'}
                                value={composition.publishingCompanyOptionSelected}
                                isClearable
                                cacheOptions
                                styles={{
                                  control: provided => {
                                    if (!composition.publishingCompanyIsValid) {
                                      return {
                                        ...provided,
                                        outline: 'none',
                                        border: '1px solid #f44336',
                                      }
                                    } else {
                                      return {
                                        ...provided,
                                        outline: 'none',
                                      }
                                    }
                                  },
                                  option: provided => ({
                                    ...provided,
                                    fontSize: '.875rem',
                                  }),
                                  loadingMessage: provided => ({
                                    ...provided,
                                    fontSize: '.875rem',
                                  }),
                                  noOptionsMessage: provided => ({
                                    ...provided,
                                    fontSize: '.875rem',
                                  }),
                                }}
                              />
                            </FormControl>
                          </GridItem>

                          {this.state.isOriginal ? (
                            <GridItem xs={3}>
                              <CustomInput
                                labelText={idx === 0 ? 'Percentual Autoral' : ''}
                                id={`copyrightPercentage${idx}`}
                                automacao="inputCopyrightPercentage"
                                inputProps={{
                                  type: 'number',
                                  name: 'copyrightPercentage',
                                  placeholder: 'Digite o percentual autoral',
                                  onChange: this.handleCopyrightPercentageChange(idx),
                                  value: composition.copyrightPercentage,
                                  error: !composition.copyrightPercentageIsValid,
                                  min: 0,
                                  max: 100,
                                  step: 1,
                                  adornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                                formControlProps={{
                                  fullWidth: true,
                                  style: idx === 0 ? { margin: '0 0 10px' } : { marginTop: 0 },
                                }}
                              />
                            </GridItem>
                          ) : null}
                          {this.state.compositions.length > 1 ? (
                            <GridItem xs={1}>
                              <IconButton
                                id={`removeComposition${idx}`}
                                aria-label="remove_circle"
                                size="sm"
                                onClick={this.handleRemoveComposition(idx)}
                              >
                                <RemoveCircleIcon />
                              </IconButton>
                            </GridItem>
                          ) : null}
                          {this.state.isOriginal &&
                          composition.authorIsHired !== null &&
                          composition.authorIsHired === false ? (
                            <GridItem xs={12} sm={12} md={12}>
                              <div className={classes.warningText}>
                                <InfoOutlineIcon style={{ float: 'left' }} />
                                <div
                                  automacao="txtAuthorNotHired"
                                  style={{
                                    marginTop: '5px',
                                    marginLeft: '2px',
                                    float: 'left',
                                  }}
                                >
                                  Autor não contratado
                                </div>
                              </div>
                            </GridItem>
                          ) : null}
                        </Grid>
                      ))}
                    </FormControl>
                  </Grid>

                  <GridItem xs={12} sm={12} md={12}>
                    <IconButton
                      id="buttonAddComposition"
                      aria-label="add_circle"
                      size="sm"
                      onClick={event => this.handleAddComposition(event)}
                    >
                      <AddCircleIcon />
                    </IconButton>
                  </GridItem>
                </div>
              ) : null}
            </Grid>

            <div className={classes.wrapper}>
              {this.state.isOriginal && this.isAuthorHired() ? (
                <div className={classes.container}>
                  <FormGroup row className={classes.formGroupNotHired}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="confirmAuthorNotHired"
                          name="confirmAuthorNotHired"
                          automacao="checkAuthorNotHired"
                          checked={this.state.confirmAuthorNotHired}
                          onChange={this.handleConfirmAuthorNotHired}
                          color="primary"
                          style={{ float: 'left' }}
                        />
                      }
                      label={
                        <Fragment>
                          <b>Esta Obra Musical possui autor(es) não contratado(s).</b>
                          <br />
                          Estou ciente que para utilização desta Obra é necessário ter obtido o Termo de Cessão de
                          Direitos assinado por esse(s) autor(es). Para maiores detalhes e acesso ao documento, entre em
                          contato com a equipe de Direitos Musicais pelos e-mails: #DIREITOS-MUSICAIS-DRAMATURGIA,
                          #DIREITOS-MUSICAIS-VARIEDADES, #DIREITOS-MUSICAIS-JORNALISMO, #DIREITOS-MUSICAIS-ESPORTE
                        </Fragment>
                      }
                    />
                  </FormGroup>
                </div>
              ) : null}
            </div>
          </CardBody>
          <CardFooter>
            <Button
              id="cancelar"
              type="button"
              size="sm"
              value="Cancelar"
              automacao="btnCancelar"
              style={{ float: 'left' }}
              color="transparent"
              onClick={this.handleBackClick}
              disabled={this.state.submitting}
            >
              Voltar
            </Button>
            {this.state.submitting ? (
              <CircularProgress size={24} className={classes.buttonProgress} />
            ) : (
              <Button
                id="btn_work_form_salvar"
                type="submit"
                automacao="btnSalvar"
                size="sm"
                value="Submit"
                color="primary"
                variant="contained"
                onClick={this.handleSubmit}
                disabled={this.isSaveDisabled()}
              >
                Salvar
              </Button>
            )}
          </CardFooter>
        </Card>
        <div>
          <Snackbar
            close
            place="bc"
            color="danger"
            open={this.state.error}
            message={this.state.errorMessage}
            closeNotification={this.handleSnackClose}
          />
        </div>
      </Grid>
    )
  }
}

WorkForm.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  category: PropTypes.string,
  nationality: PropTypes.string,
  countryOptionSelected: PropTypes.object,
  compositions: PropTypes.arrayOf(PropTypes.object),
}

export default withStyles(workFormStyle)(WorkForm)
