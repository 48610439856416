import { defaultFont } from '../material-dashboard-react.jsx'

const loadingPageStyle = () => ({
  container: {
    ...defaultFont,
    width: '100%',
    display: 'flex',
    fontSize: '20px',
    alignItems: 'center',
    flexDirection: 'column',
  },
  loading: {
    width: '80px',
    height: '80px',
    display: 'flex',
    borderRadius: '50%',
    marginBottom: '40px',
    backgroundColor: '#ccc',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

export default loadingPageStyle
