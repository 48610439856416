import loginStore from '../../../shared/lib/login-store'
import { refreshAuth } from '../../auth-api'

let isRefreshing = false
let subscribers = []

function subscribeTokenRefresh(cb) {
  subscribers.push(cb)
}

function onRefreshed(token) {
  subscribers.forEach(cb => cb(token))
  subscribers = []
}

// ON SUCCESS
const onSuccess = () => resp => resp.data

// ON ERROR
const logoutUser = () => {
  loginStore.clear()
  window.location = '/auth'
}

const handleUnauthorized = (client, request) => {
  console.error('Unauthorized Access, sending refresh token')

  if (request._retry) {
    logoutUser()
    return Promise.reject()
  }

  if (!isRefreshing) {
    isRefreshing = true

    refreshAuth(loginStore.getRefreshToken())
      .then(response => {
        loginStore.handleLogin(response)
        onRefreshed(response.id_token)
      })
      .catch(() => {
        logoutUser()
      })
      .finally(() => {
        isRefreshing = false
        subscribers = []
      })
  }

  const requestSubscribers = new Promise(resolve => {
    subscribeTokenRefresh(token => {
      request.headers.Authorization = token
      request._retry = true
      resolve(client(request))
    })
  })

  return requestSubscribers
}

const onError = client => error => {
  console.error('API Request Failed:', {
    Request: error.config,
    Message: error.message,
    Data: error.response ? error.response.data : '',
    Status: error.response ? error.response.status : '',
    Headers: error.response ? error.response.headers : '',
  })

  const responseStatus = error.response ? error.response.status : 0

  if (responseStatus === 401) {
    return handleUnauthorized(client, error.config)
  }

  return Promise.reject(error)
}

export default { onSuccess, onError }
