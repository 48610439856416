import React from 'react'

//@material-ui
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '../custom-buttons/button'
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import ingestResultStyle from 'assets/jss/components/modal/ingestResultStyle'
import informationIcon from 'assets/img/svg/information.svg'
import errorIcon from 'assets/img/svg/error.svg'

class IngestResultModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: this.props.open,
    }
    this.handleCancel = this.handleCancel.bind(this)
    this.handleConfirm = this.handleConfirm.bind(this)
  }

  handleCancel = event => {
    this.setState({ open: false }, this.props.handleClose(event))
    event.preventDefault()
  }

  handleConfirm = event => {
    this.setState(
      {
        open: !this.props.cancelCloseAfterConfirm ? false : true,
      },
      this.props.handleConfirm(event)
    )
    event.preventDefault()
  }

  render = () => {
    const {
      titles,
      messages,
      style,
      content,
      maxWidth,
      handleClose,
      okText,
      classes,
      isError,
      isTotalError,
      phonogramsWithErrors,
    } = this.props

    return (
      <Dialog
        style={style}
        open={this.state.open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth={maxWidth}
        classes={{ paper: classes.paper, root: classes.root }}
        id="IngestResultModal"
      >
        <DialogTitle
          className={`${classes.title} ${isError && isTotalError ? classes.titleError : ''}`}
          id="form-dialog-title"
        >
          {!isError && titles.success}
          {isError && !isTotalError && titles.error.partial}
          {isError && isTotalError && <img src={errorIcon} alt="ícone de alerta" className={classes.icon} />}
          {isError && isTotalError && titles.error.total}
        </DialogTitle>

        <DialogContent className={classes.content}>
          {content ? content : null}

          {isError && !isTotalError && (
            <div className={classes.warningContainer}>
              <img src={informationIcon} alt="ícone de alerta" className={classes.icon} />
              <DialogContentText className={classes.partialErrorMessage}>
                {phonogramsWithErrors.length > 1
                  ? 'Ocorreu um erro ao processar os seguintes fonogramas:'
                  : 'Ocorreu um erro ao processar o seguinte fonograma:'}
              </DialogContentText>
            </div>
          )}

          {isError && !isTotalError && (
            <div className={classes.errorsList}>
              <Table>
                <TableBody>
                  {phonogramsWithErrors.map((phonogram, key) => (
                    <TableRow key={key}>
                      <TableCell className={classes.errorItem}>
                        <span className={classes.errorText}>{phonogram.submix}</span>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          )}

          <DialogContentText className={classes.principalText}>
            {!isError && messages.success}
            {isError && !isTotalError && messages.error.partial}
            {isError && isTotalError && messages.error.total}
          </DialogContentText>
        </DialogContent>

        <DialogActions className={classes.actions}>
          <Button id="ButtonYes" onClick={this.handleCancel} size="sm" color="primary">
            {okText ? okText : 'ok'}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(ingestResultStyle)(IngestResultModal)
