import styled, { css } from 'styled-components'
import { Fileupload as FileuploadIcon } from 'components/svg-icons/svg-icons-lib'

const Container = styled.div`
  padding-top: 10px;
  display: flex;
  height: 148px;
  max-width: 846px;
  position: relative;
  border: 2px dashed #c7c7c7;
  background-color: #e1e1e1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 24px auto 0 auto;

  ${({ active }) =>
    active &&
    css`
      border-color: #76458f;
    `}
`

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  color: #999999;
  height: 120px;

  & > button {
    margin: 0;
  }
`

const Message = styled.div`
  font-size: 23px;
  font-weight: 500;
  text-align: center;

  & > span {
    font-size: 15px;
    font-weight: 500;
    margin: 0 30px 0 20px;
  }
`

const Icon = styled(FileuploadIcon)`
  width: 51px;
  height: 34px;
  text-align: right;
  margin-right: 10px;
`

const WarningMessage = styled.p`
  margin: 0;
  padding: 0 15px 6px 0;
  color: #666;
  width: 100%;
  font-size: 12px;
  text-align: right;
  font-family: Roboto;
  font-weight: normal;
`

export { Container, Content, Icon, Message, WarningMessage }
