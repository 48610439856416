const workFormStyle = () => ({
  wrapperContainer: {
    margin: '0 30px',
  },
  wrapper: {
    display: 'block',
    width: '100%',
    padding: '0 20px',
  },
  container: {
    fontSize: '16px',
    color: '#000',
    letterSpacing: '1px',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    display: 'block',
    textAlign: 'left',
    margin: '43px 30px 0 30px',
    fontWeight: '400',
    lineHeight: '31px',
    textTransform: 'capitalize',
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
  warningText: {
    color: '#ffa502',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontSize: '12px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  formLabelComposition: {
    padding: '0 0 0 15px',
    fontSize: '16px',
  },
  compositionWrapper: {
    margin: '12px 0 0',
    fontSize: '15px',
    alignItems: 'center',
  },
  formLabel: {
    fontSize: '15px',
    margin: '0 0 3px',
  },
  derivationWrapper: {
    borderTop: 'solid 1px #eaeaea',
    borderBottom: 'solid 1px #eaeaea',
    backgroundColor: '#fafafa',
    padding: '5px 15px 5px',
    width: '100%',
    margin: '30px 0 0',
  },
  formLabelDerivation: {
    fontFamily: 'Roboto',
    fontSize: '15px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#000',
  },
  teste: { valueContainer: 'red' },

  formGroupNotHired: {
    margin: '60px 0 0 0',
    background: '#f1f1f1',
    padding: '20px',
    borderRadius: '4px',
    textTransform: 'none',
    '& span': {
      fontSize: '14px',
    },
  },

  RadioGroup: {
    '& span': {
      fontSize: '14px',
    },
  },
})

export default workFormStyle
