import Auth from '../views/auth/auth'
import Logout from '../views/auth/logout'
import Forbidden from '../views/common/forbidden'
import Downloads from '../views/downloads/downloads'
import Ingest from '../views/ingest/ingest'

const othersRoutes = [
  {
    path: '/auth',
    component: Auth,
    isPublic: true,
  },
  {
    path: '/logout',
    component: Logout,
    isPublic: true,
  },
  {
    path: '/forbidden',
    component: Forbidden,
    isPublic: true,
  },
  {
    path: '/downloads/:id',
    component: Downloads,
    isPublic: true,
  },
  {
    path: '/ingestfromrequest/:id',
    navbarName: 'Ingest',
    component: Ingest,
    allowedPermissions: ['Ingest:Edit'],
  },
  {
    redirect: true,
    path: '/',
    to: '/ingest',
  },
]

export default othersRoutes
