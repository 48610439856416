import React from 'react'

const Context = React.createContext()

const reducer = (currentState, newState) => ({ ...currentState, ...newState })

export class IngestProvider extends React.Component {
  state = {
    ingestFromRequest: false,
    musicRequestId: null,
    musics: [],
    bpmItems: [
      { value: -1, label: 'Variavel' },
      { value: 1, label: 'Lento' },
      { value: 83, label: 'Moderado' },
      { value: 116, label: 'Rapido' },
    ],
    genreItems: [],
    moodItems: [],
    tagItems: [],
    filter: '',
    utilizationType: null,
    musicSelected: null,
    recorder: null,
    album: null,
    label: null,
    submixes: [],
    product: null,
    productListItems: [],
    isRestrictedPerformance: false,
    submixDraft: null,
    submixIdx: null,
    musicians: [],
    musicianId: null,
    steps: [],
    canBeUnreleased: false,
    phonogramType: 'Original',
    phonogramsProcessing: null,
    changeState: (newState, callback) => {
      this.setState(state => reducer(state, newState), callback)
    },
  }

  render = () => {
    const {
      state,
      props: { children },
    } = this
    return <Context.Provider value={state}>{children}</Context.Provider>
  }
}

export const IngestConsumer = Context.Consumer
