import ingestStyle from './ingestStyle'
const PhonogramType = () => ({
  ...ingestStyle,
  selectorWrapper: {
    maxWidth: '845px',
    height: '156px',
    borderRadius: '4px',
    boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.5)',
    backgroundColor: '#fff',
    margin: '59px auto 0',
    display: 'flex',
    alignItems: 'center',
    padding: '0 32px',
  },

  hasProductRadio: {
    marginRight: '60px',

    '& span': {
      fontSize: '14px',
    },
  },
})

export default PhonogramType
