import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'

import stepsStyle from '../../assets/jss/components/stepsStyle.jsx'

const steps = props => {
  const { classes, activeStep, steps } = props

  return (
    <Grid item className={classes.steps} container>
      <Grid item xs={12} sm={12} md={12}>
        <Stepper activeStep={activeStep} alternativeLabel className={classes.stepper}>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
    </Grid>
  )
}

export default withStyles(stepsStyle)(steps)
