const phonogramModalStyle = theme => ({
  paper: {
    width: '991px',
    height: '729px',
    borderRadius: '4px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    backgroundColor: '#f1f1f1',
  },
  dialogTitle: {
    padding: '36px 46px 46px',
  },
  title: {
    fontSize: '25px',
  },
  wrapper: {
    margin: theme.spacing(),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  snackbar: {
    position: 'absolute',
  },
})

export default phonogramModalStyle
