import React, { Component } from 'react'
import ReactDOM from 'react-dom'

//provider and consumer context
import { DownloadsProvider, DownloadsConsumer } from './downloads-context'
import ErrorForm from './../common/error-form'

import DownloadsList from './downloads-list'

class Downloads extends Component {
  constructor() {
    super()
    this.state = {
      currentMode: 'listDownloads',
    }
    this.ref = React.createRef()
  }

  changeMode = newMode => {
    this.setState({ currentMode: newMode }, () => {
      this.scrollToTop()
    })
  }

  scrollToTop() {
    ReactDOM.findDOMNode(this.ref.current).scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  render() {
    let modeComponent = (
      <DownloadsProvider>
        <DownloadsConsumer>
          {context => <DownloadsList context={context} changeMode={this.changeMode} match={this.props.match} />}
        </DownloadsConsumer>
      </DownloadsProvider>
    )

    switch (this.state.currentMode) {
      case 'listDownloads':
        break

      case 'showError':
        modeComponent = (
          <DownloadsProvider>
            <DownloadsConsumer>
              {context => (
                <ErrorForm error={context.error} errorInfo={context.errorInfo} changeMode={this.changeMode} />
              )}
            </DownloadsConsumer>
          </DownloadsProvider>
        )
        break

      default:
        break
    }
    return <div ref={this.ref}>{modeComponent}</div>
  }
}

export default Downloads
