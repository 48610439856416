/* eslint-disable no-unused-vars */
import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'

import CircularProgress from '@material-ui/core/CircularProgress'
import PhonogramApi from '../../services/phonogram-api'
import moodsModalStyle from '../../assets/jss/views/ingest/moodsModalStyle.jsx'
import { intersection } from 'lodash'

class InstrumentosModal extends React.Component {
  constructor(props) {
    super(props)
    this.phonogramApi = new PhonogramApi()
    this.state = {
      isLoading: true,
      instrumentsList: [],
      checkedItems: props.checkedItems || [],
      maxSelection: window.__.moodsMaxItems,
      isComponenteMonted: null,
    }
  }

  componentDidMount() {
    this.setState(
      {
        isComponenteMonted: true,
      },
      () => this.getInstruments()
    )
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lista !== this.props.lista) {
      this.setState(
        {
          isLoading: true,
        },
        () => this.getInstruments()
      )
    }
  }

  componentWillUnmount() {
    this.setState({ isComponenteMonted: false })
  }

  getInstruments = () => {
    this.phonogramApi
      .getInstrumentsList()
      .then(result => {
        this.setState({
          instrumentsList: result,
        })
        this.props.saveList(result)
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        })
      })
  }

  onCloseModal = () => {
    const { checkedItems } = this.state
    if (this.props.onAfterCloseModal) {
      this.props.onAfterCloseModal(checkedItems)
    }
  }

  onChangeItemSelected = instrumento => {
    const { checkedItems } = this.state

    const index = checkedItems.findIndex(item => item.id === instrumento.id)

    if (index === -1) {
      this.setState(prevState => ({
        checkedItems: [...prevState.checkedItems, instrumento],
      }))
    } else {
      this.setState(prevState => ({
        checkedItems: prevState.checkedItems.filter(item => item.id !== instrumento.id),
      }))
    }
  }

  // getIdsSubItemsSelected = () => {
  //   if (!this.props.context.moodItems) {
  //     return []
  //   }
  //   const ids = this.props.context.moodItems.map(item => item.id)
  //   return this.state.checkedItems.filter(id => !ids.includes(id))
  // }

  // getTotalSubItemsChecked = () => {
  //   return this.getIdsSubItemsSelected().length
  // }

  render = () => {
    const { open, lista } = this.props
    const { root, paper, loadingContainer, dialogContent } = this.props.classes
    const { instrumentsList, isLoading } = this.state

    return (
      <Dialog id="moods-modal" open={open} onClose={() => this.onCloseModal()} classes={{ root, paper }}>
        <DialogTitle id="form-dialog-title">
          <span>Instrumentos Principais </span>
        </DialogTitle>
        <DialogContent className={dialogContent}>
          {isLoading ? (
            <div className={loadingContainer}>
              <CircularProgress size={24} />
            </div>
          ) : (
            lista &&
            lista.map(instrumento => (
              <div key={instrumento.id} style={{ marginBottom: '8px' }}>
                <FormControlLabel
                  key={instrumento.id}
                  control={
                    <Checkbox
                      checked={this.state.checkedItems.some(item => item.id === instrumento.id)}
                      onChange={() => this.onChangeItemSelected(instrumento)}
                      icon={<CheckBoxOutlineBlankIcon style={{ fontSize: '14px' }} />}
                      checkedIcon={<CheckBoxIcon style={{ fontSize: '14px' }} />}
                    />
                  }
                  label={<span style={{ fontSize: '14px' }}>{instrumento.name}</span>}
                />
              </div>
            ))
          )}
        </DialogContent>
      </Dialog>
    )
  }
}

export default withStyles(moodsModalStyle)(InstrumentosModal)
