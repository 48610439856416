const confirmModalStyle = {
  paper: {
    minWidth: '300px',
    minHeight: '135px',
  },
  buttonClose: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#4a90e2',
  },
}

export default confirmModalStyle
